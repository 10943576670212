import { useState, useEffect } from 'react';
import { AppConstants } from '../../../../constants/AppConstants';
import { AuthorizedDataService } from '../../../../services/AuthorizedDataService';
import { StudentRecentlyPublishedCompetitionCards } from '../../../cards/StudentRecentlyPublishedCompetitionCards';
import { DateTimeUtil } from '../../../../utils/DateTimeUtil';

export const StudentFeaturedCompetition = (props) => {
    const [competitions, setCompetitions] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const preferredTimeZone = DateTimeUtil.getPreferredTimeZoneForUser();

    useEffect(() => {
        const fetchFeaturedCompetitions = async () => {
            const url = `${AppConstants.getFeaturedCompetitionsForStudentAPI}`;
            const requestHeaders = {
                preferredTimeZone
            }
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString,
                requestHeaders
            );

            if (response) {
                setCompetitions(response);
                setFilteredList(response);
            }
            return null;
        }

        if (props.categoryList?.length > 0) {
            fetchFeaturedCompetitions();
        }
    }, [props.categoryList]);

    useEffect(() => {
        if (props.selectedCategory) {
            if (props.selectedCategory === AppConstants.AllText) {
                setFilteredList(competitions);
            } else {
                setFilteredList(competitions.filter(competition => competition.categoryName === props.selectedCategory));
            }
        } else {
            setFilteredList(competitions);
        }
    }, [props.selectedCategory]);

    return (
        <div>
            {filteredList?.length > 0 && <StudentRecentlyPublishedCompetitionCards minimumAgeLimit={props.minimumAgeLimit} cardDetails={filteredList} category="Featured" />}
            {(!filteredList || filteredList?.length === 0) && props.selectedCategory !== AppConstants.emptyString && props.selectedCategory !== AppConstants.AllText && <p className='noDataAvailableError'>{`No featured competitions for skill category '${props.selectedCategory}' to display at the moment`}</p>}
            {(!filteredList || filteredList?.length === 0) && (props.selectedCategory === AppConstants.emptyString || props.selectedCategory === AppConstants.AllText) && <p className='noDataAvailableError'>No featured competitions to display at the moment</p>}
        </div>
    )
}