import classes from './AdminDashboardPage.module.css';
import { LearnersOnQrenciaStats } from '../../component/adminNumberTrackingComponents/learnersOnQrenciaStats/LearnersOnQenciaStats';
import { LearningActivitiesOnQrenciaStats } from '../../component/adminNumberTrackingComponents/learningActivityOnQrenciaStats/LearningActivitiesOnQrenciaStats';
import { CreatorOnQrenciaStats } from '../../component/adminNumberTrackingComponents/creatorOnQrenciaStats/CreatorOnQrenciaStats';
import { NudgeDetailsStats } from '../../component/adminNumberTrackingComponents/nudgeDetailsStats/NudgeDetailsStats';
import { EnrolmentsOnQrenciaSection } from '../../component/adminNumberTrackingComponents/enrolmentsOnQrencia/EnrolmentsOnQrencia';
import { InsightStats } from '../../component/adminNumberTrackingComponents/insightStats/InsightStats';
import { AdminOfflinePaymentDetailsStats } from '../../component/adminNumberTrackingComponents/adminOfflinePaymentDetailsStats/AdminOfflinePaymentDetailsStats';

export const AdminNumberTrackingPage = () => {
    return (
        <>
            <div className="adminContentSection">
                <h3 className={classes.heading}>Insights</h3>
                <div className={classes.dashboardContainer}>
                    <InsightStats />
                </div>
                <h3 className={classes.heading}>Learners on Qrencia</h3>
                <div className={classes.dashboardContainer}>
                    <LearnersOnQrenciaStats />
                </div>
                <h3 className={classes.heading}>Enrolments on Qrencia</h3>
                <div className={classes.dashboardContainer}>
                    <EnrolmentsOnQrenciaSection />
                </div>
                <h3 className={classes.heading}>Creators on Qrencia</h3>
                <div className={classes.dashboardContainer}>
                    <CreatorOnQrenciaStats />
                </div>
                <h3 className={classes.heading}>Offline Payment Requests</h3>
                <div className={classes.dashboardContainer}>
                    <AdminOfflinePaymentDetailsStats />
                </div>
                <h3 className={classes.heading}>Learning Activity on Qrencia</h3>
                <div className={classes.dashboardContainer}>
                    <LearningActivitiesOnQrenciaStats />
                </div>
                <h3 className={classes.heading}>Nudge Details</h3>
                <div className={classes.dashboardContainer}>
                    <NudgeDetailsStats />
                </div>
            </div>
        </>
    )
}