import { Tabs, Tab } from "react-bootstrap";
import "../expertManageCompetitionComponent/viewCompetitionTabs.css";
import { useEffect, useState } from "react";

export const EventStatusNavTab = (props) => {
    const [tabItems, setTabItems] = useState([]);

    useEffect(() => {
        setTabItems(Object.keys(props.tabs));
    }, [props]);

    function selectCourseType(e) {
        props.changeStatus(e);
    };

    return (
        <div className="expertViewCompetitionsTabs">
            <Tabs className="expertViewCompetitionsTabs"
                defaultActiveKey={props.tabs[tabItems[0]]}
                id="viewCometitionsTabs"
                fill
                onSelect={(e) => selectCourseType(e)}
            >
                {tabItems.map(item => (<Tab eventKey={props.tabs[item]} title={props.tabs[item]}></Tab>))}
            </Tabs>
        </div>
    );
}