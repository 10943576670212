import { useState, useEffect } from "react";
import { MdClear } from "react-icons/md";
import { ViewCourseDetailAccordion } from "../../component/expertManageCourses/viewCourseDetailsAccordion/ViewCourseDetailAccordion";
import { ViewCourseCards } from "../../component/cards/viewCourseCards/ViewCourseCards";
import { AppConstants } from "../../constants/AppConstants";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import { ErrorMessages } from "../../constants/ErrorMessages";
import { EventStatusNavTab } from "../../component/expertManageCourses/EventStatusNavTabs";
import classes from "./ExpertManageCoursesPage.module.css";

export const ExpertAssignedCoursesPage = () => {
    const courseStatus = AppConstants.courseStatus;
    const [selectedTab, setSelectedTab] = useState(AppConstants.courseStatus.upcoming);
    const [upcomingCoursesSection, setUpcomingCoursesSection] = useState(true);
    const [ongoingCoursesSection, setOngoingCoursesSection] = useState(false);
    const [pastCoursesSection, setPastCoursesSection] = useState(false);
    const [showCourseDetailsSection, setShowCourseDetailsSection] = useState(false);
    const [upcomingCoursesArray, setUpcomingCoursesArray] = useState([]);
    const [ongoingCoursesArray, setOngoingCoursesArray] = useState([]);
    const [pastCoursesArray, setPastCoursesArray] = useState([]);
    const [filteredCourses, setFilteredCourses] = useState([]);
    const [filterCourseText, setFilterCourseText] = useState(AppConstants.emptyString);
    const [selectedCourse, setSelectedCourse] = useState();
    const [showEditCourseSection, setShowEditCourseSection] = useState(false);

    useEffect(() => {
        //fetch Upcoming Courses
        async function getUpcomingCoursesData() {
            const url = `${AppConstants.fetchAssignedCoursesByStatusAPI}${courseStatus.upcoming}`;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response !== undefined) {
                setUpcomingCoursesArray(response);
                setFilteredCourses(response);
            } else {
                setUpcomingCoursesArray([]);
                setFilteredCourses([]);
            }
        }
        getUpcomingCoursesData();
    }, [])

    function changeStatus(status) {
        setSelectedTab(status);
        setFilteredCourses([]);
        setFilterCourseText(AppConstants.emptyString);
        setShowCourseDetailsSection(false);
        setShowEditCourseSection(false);
        setSelectedCourse([]);
        if (status === courseStatus.upcoming) {
            setUpcomingCoursesSection(true);
            setOngoingCoursesSection(false);
            setPastCoursesSection(false);
            async function getUpcomingCoursesData() {
                const url = `${AppConstants.fetchAssignedCoursesByStatusAPI}${courseStatus.upcoming}`;
                const response = await AuthorizedDataService.getRequest(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );
                if (response !== undefined) {
                    setUpcomingCoursesArray(response);
                    setFilteredCourses(response);
                } else {
                    setUpcomingCoursesArray([]);
                    setFilteredCourses([]);
                }
            }
            getUpcomingCoursesData();
        } else if (status === courseStatus.ongoing) {
            setOngoingCoursesSection(true);
            setUpcomingCoursesSection(false);
            setPastCoursesSection(false);
            //fetch ongoing courses
            async function getOngoingCoursesData() {
                const url = `${AppConstants.fetchAssignedCoursesByStatusAPI}${courseStatus.ongoing}`;
                const response = await AuthorizedDataService.getRequest(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );
                if (response !== undefined) {
                    setOngoingCoursesArray(response);
                    setFilteredCourses(response);
                } else {
                    setOngoingCoursesArray([]);
                    setFilteredCourses([]);
                }
            }
            getOngoingCoursesData();
        } else if (status === courseStatus.past) {
            setUpcomingCoursesSection(false);
            setPastCoursesSection(true);
            setOngoingCoursesSection(false);
            //fetch past courses
            async function getPastCoursesData() {
                const url = `${AppConstants.fetchAssignedCoursesByStatusAPI}${courseStatus.past}`;
                const response = await AuthorizedDataService.getRequest(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );
                if (response !== undefined) {
                    setPastCoursesArray(response);
                    setFilteredCourses(response);
                } else {
                    setPastCoursesArray([]);
                    setFilteredCourses([]);
                }
            }
            getPastCoursesData();
        }
    };

    const searchCourse = (e) => {
        setFilterCourseText(e.target.value);
        let coursesArray;
        if (selectedTab === courseStatus.upcoming) {
            coursesArray = upcomingCoursesArray;
        } else if (selectedTab === courseStatus.ongoing) {
            coursesArray = ongoingCoursesArray
        } else if (selectedTab === courseStatus.past) {
            coursesArray = pastCoursesArray;
        }
        let filteredArray = e.target.value.toLowerCase() !== AppConstants.emptyString
            ? coursesArray.filter((item) => ((item.courseTitle.toLowerCase()).includes(e.target.value.toLowerCase()) || (item.courseType.toLowerCase()).includes(e.target.value.toLowerCase()) || (item.uniqueId.toLowerCase()).includes(e.target.value.toLowerCase()) || (item.courseId.toLowerCase()).includes(e.target.value.toLowerCase())))
            : coursesArray;
        setFilteredCourses(filteredArray);
    };

    const resetFilterText = () => {
        setFilterCourseText(AppConstants.emptyString);
        if (selectedTab === courseStatus.upcoming) {
            setFilteredCourses(upcomingCoursesArray);
        } else if (selectedTab === courseStatus.ongoing) {
            setFilteredCourses(ongoingCoursesArray);
        } else if (selectedTab === courseStatus.past) {
            setFilteredCourses(pastCoursesArray);
        }

    };


    const onClickView = async (course) => {
        setSelectedCourse(course);
        setShowCourseDetailsSection(true);
    }

    const onHideDetails = () => {
        setSelectedCourse([]);
        setShowCourseDetailsSection(false);
    }

    return (
        <div className="expertContentSection">
            <div className={classes.manageCoursesHeading}>Assigned Courses</div>
            <EventStatusNavTab changeStatus={changeStatus} tabs={AppConstants.courseStatus} />
            {!showCourseDetailsSection && !showEditCourseSection && <div>
                <div className={classes.inputBoxContainer}>
                    <input type="text" className={classes.searchInputBox} value={filterCourseText} onChange={(e) => { searchCourse(e) }} placeholder="Search course" />
                    {filterCourseText.length > 0 && <MdClear className={classes.resetSearchIcon} onClick={() => resetFilterText()} />}
                </div>
                {upcomingCoursesSection &&
                    <div>
                        {filteredCourses.length !== 0 && <ViewCourseCards
                            courseData={filteredCourses}
                            onClickView={onClickView}
                            showViewButton={AppConstants.trueText} showEditButton={AppConstants.falseText} showAssignButton={AppConstants.falseText}
                            eventStatus={AppConstants.courseStatus.upcoming}
                        />}
                        {filteredCourses.length === 0 && <div>
                            <p className="noDataAvailableError">{ErrorMessages.noAssignedCoursesMessage}</p>
                        </div>}
                    </div>
                }
                {ongoingCoursesSection && <div>
                    {filteredCourses.length !== 0 && <ViewCourseCards
                        courseData={filteredCourses}
                        onClickView={onClickView}
                        showViewButton={AppConstants.trueText} showEditButton={AppConstants.falseText} showAssignButton={AppConstants.falseText}
                        eventStatus={AppConstants.courseStatus.ongoing}
                    />}
                    {filteredCourses.length === 0 && <div>
                        <p className="noDataAvailableError">{ErrorMessages.noAssignedCoursesMessage}</p>
                    </div>}
                </div>}
                {pastCoursesSection && <div>
                    {filteredCourses.length !== 0 && <ViewCourseCards
                        courseData={filteredCourses}
                        onClickView={onClickView}
                        showViewButton={AppConstants.trueText} showEditButton={AppConstants.falseText} showAssignButton={AppConstants.falseText}
                        eventStatus={AppConstants.courseStatus.past}
                    />}
                    {filteredCourses.length === 0 && <div>
                        <p className="noDataAvailableError">{ErrorMessages.noAssignedCoursesMessage}</p>
                    </div>}
                </div>}
            </div>}

            {showCourseDetailsSection && <div className={classes.viewCourseDetailsSection}>
                <div><ViewCourseDetailAccordion assignedCourseFlag={AppConstants.yText} selectedTab={selectedTab} selectedCourse={selectedCourse.uniqueId} onClickHide={onHideDetails} /></div>
            </div>}
        </div>
    )
}