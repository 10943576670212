import { useState, useEffect, useRef } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import moment from "moment";
import * as Yup from 'yup';
import validator from "validator";
import ReactQuill, { Quill } from 'react-quill';
import { MdDelete } from 'react-icons/md';
import Modal from 'react-bootstrap/Modal';
import { Tooltip } from "react-tooltip";
import { AppConstants } from "../../../constants/AppConstants";
import { ErrorMessages } from "../../../constants/ErrorMessages";
import formClass from '../../../common/FormStyling.module.css';
import 'react-quill/dist/quill.snow.css';
import classes from "./AddCompetitionDetails.module.css";
import { RiInformationFill } from "react-icons/ri";
import { DateTime } from "luxon";
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import { GenerateAiImagesComponent } from "../../generateAiImagesComponent/GenerateAiImagesComponent";
import { ErrorSuccessAlertMessage } from "../../errorSuccessMessage/ErrorSuccessAlertMessage";
import Auth from "../../../utils/Auth";
import { StringUtils } from "../../../utils/StringUtils";
import { FileUploadInput } from "../../fileUploadInput/FileUploadInput";
import { FileOperationsUtil } from "../../../utils/FileOperationsUtil";
import { BiLoaderAlt } from "react-icons/bi";
import { AiOutlineDownload } from "react-icons/ai";

export const AddCompetitionDetails = (props) => {

    const { saveCurrentStep, changeBasicDetailsFormError, skills = [], changeBasicFormDetailsData, stage, selectedCompetitionDetails, minimumSignupAge } = props;
    const supportedImageFormats = AppConstants.supportedImageFormats;

    const competitionTypes = AppConstants.competitionTypes;
    const formLabels = AppConstants.addCompetitionDetailsLabels;
    const stages = AppConstants.createCompetitionActions;
    const [validated, setValidated] = useState(false);
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [guidelines, setGuidelines] = useState(selectedCompetitionDetails.guidelinesText !== AppConstants.nullText ? selectedCompetitionDetails.guidelinesText : AppConstants.emptyEditorString);
    const [guidelinesPdf, setGuidelinesPdf] = useState({})
    const [guidelinesError, setGuidelinesError] = useState(AppConstants.emptyString);
    const [registrationDateAdded, setRegistrationDateAdded] = useState(AppConstants.falseText);
    const [registrationTimeAdded, setRegistrationTimeAdded] = useState(AppConstants.falseText);
    const [resultByAdded, setResultByAdded] = useState(AppConstants.falseText);
    const [resultDateAdded, setResultDateAdded] = useState(AppConstants.falseText);
    const [showResultDeclareRow, setShowResultDeclareRow] = useState(AppConstants.falseText);
    const [description, setDescription] = useState(AppConstants.emptyEditorString);
    const [resultDeclareCheckbox, setResultDeclareCheckbox] = useState(AppConstants.falseText);
    const [aiGeneratedImages, setAiGeneratedImages] = useState({});
    const [thumbnailPreview, setThumbnailPreview] = useState(AppConstants.emptyString);
    const [webBannerPreview, setWebBannerPreview] = useState(AppConstants.emptyString);
    const [tabletBannerPreview, setTabletBannerPreview] = useState(AppConstants.emptyString);
    const [mobileBannerPreview, setMobileBannerPreview] = useState(AppConstants.emptyString);
    const [imageGeneratedArgs, setImageGeneratedArgs] = useState({});
    const [aiErrorMessage, setAiErrorMessage] = useState(AppConstants.emptyString);
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [showModal, setShowModal] = useState(AppConstants.emptyString);
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const qrenciaGlobeImage = `${mediaContent}${AppConstants.staticContentImagesS3Path}${AppConstants.forwardSlash}${AppConstants.performanceAnalysisPageS3Path}${AppConstants.forwardSlash}${AppConstants.qrenciaGlobeImage}`;
    const defaultImagePath = `${mediaContent}${AppConstants.staticContentImagesS3Path}${AppConstants.forwardSlash}create-competition-page/ai-image-gen-static-image.png`;
    const reactQuillRef = useRef(null);

    const [guidelinesDocumentSpinner, setGuidelinesDocumentSpinner] = useState(AppConstants.falseText);
    const [thumbnailSpinner, setThumbnailSpinner] = useState(AppConstants.falseText);
    const [promotionImageWebSpinner, setPromotionImageWebSpinner] = useState(AppConstants.falseText);
    const [promotionImageTabletSpinner, setPromotionImageTabletSpinner] = useState(AppConstants.falseText);
    const [promotionImageMobileSpinner, setPromotionImageMobileSpinner] = useState(AppConstants.falseText);

    const thumbnailImageInput = document.getElementById(formLabels.thumbnailImage.name);
    const webBannerInput = document.getElementById(formLabels.promotionImageForWeb.name);
    const tabletBannerInput = document.getElementById(formLabels.promotionImageForTablet.name);
    const mobileBannerInput = document.getElementById(formLabels.promotionImageForMobile.name);

    const Link = Quill.import('formats/link');
    Link.sanitize = function (url) {
        try {
            // quill by default creates relative links if scheme is missing.
            if (!url.startsWith(`${AppConstants.internetProtocols.http}://`) && !url.startsWith(`${AppConstants.internetProtocols.https}://`)) {
                return `${AppConstants.internetProtocols.http}://${url}`
            }
            return url;
        } catch (e) {
            return url;
        }
    }

    const initialValues = {
        title: selectedCompetitionDetails?.title || AppConstants.emptyString,
        skill: selectedCompetitionDetails.skillUniqueId ? selectedCompetitionDetails.skillUniqueId : skills?.length === 1 ? skills[0]?.skillId : AppConstants.emptyString,
        cost: selectedCompetitionDetails.cost ? selectedCompetitionDetails.cost : AppConstants.emptyString,
        date: AppConstants.emptyString,
        endDate: AppConstants.emptyString,
        registrationClosingDate: AppConstants.emptyString,
        registrationClosingTime: AppConstants.emptyString,
        startTime: AppConstants.emptyString,
        endTime: AppConstants.emptyString,
        competitionType: selectedCompetitionDetails?.type || AppConstants.emptyString,
        vcLink: AppConstants.emptyString,
        geoLocation: selectedCompetitionDetails?.geoLocation || AppConstants.emptyString,
        venueLocation: selectedCompetitionDetails?.venueLocation || AppConstants.emptyString,
        promotionImageForMobile: AppConstants.emptyString,
        promotionImageForWeb: AppConstants.emptyString,
        promotionImageForTablet: AppConstants.emptyString,
        thumbnailImage: AppConstants.emptyString,
        allowedNumberOfParticipants: selectedCompetitionDetails?.allowedNumberOfParticipants || AppConstants.emptyString,
        maxAge: (selectedCompetitionDetails?.maximumAge === 0 ? AppConstants.emptyString : selectedCompetitionDetails?.maximumAge) || AppConstants.emptyString,
        minAge: (selectedCompetitionDetails?.minimumAge === 0 ? AppConstants.emptyString : selectedCompetitionDetails?.minimumAge) || AppConstants.emptyString,
        submission: selectedCompetitionDetails?.allowSubmissionFlag || AppConstants.nText,
        resultDeclareDate: AppConstants.emptyString,
        resultDeclareBy: AppConstants.emptyString,
    };

    const getRemainingLengthText = (limit, textField) => {
        if (!textField || textField === AppConstants.emptyString) {
            return AppConstants.emptyString;
        }

        return `${limit - textField.length} out of ${limit} characters remaining`;
    }

    const validationSchema = Yup.object().shape({
        title: Yup.string().trim().max(50, ErrorMessages.invalidTitle).matches(AppConstants.quotesRegex, { message: ErrorMessages.doubleQuotesTitleError }).required(`${ErrorMessages.pleaseEnterText}${AppConstants.space}${formLabels.title.label}`),
        skill: Yup.string().required(ErrorMessages.selectSkill).oneOf(skills?.map(s => s.skillId)),
        cost: (!props.clubQrenciaFlg && !props.feeWaiverFlag) ? Yup.number().required("Please enter a valid competition cost").min(props.competitionMinimumCost, ErrorMessages.minimumCompetitionCostError + props.competitionMinimumCost) : Yup.number().notRequired(),
        date: Yup.date().required(`${ErrorMessages.pleaseEnterText}${AppConstants.space}${formLabels.date.label}`).min(DateTimeUtil.getConvertedJsDateInPreferredTimeZone(new Date(Date.now() - 86400000), timeZone), `${formLabels.date.label} ${ErrorMessages.pastDateError}`),
        endDate: Yup.date().required(`${ErrorMessages.pleaseEnterText}${AppConstants.space}${formLabels.endDate.label}`).min(Yup.ref(formLabels.date.name), `${ErrorMessages.competitionEndDateError}`),
        startTime: Yup.string().required(`${ErrorMessages.pleaseEnterText}${AppConstants.space}${formLabels.startTime.label}`).test(
            "start_time_test", ErrorMessages.startTimeError, function (value) {
                const { date } = this.parent;
                if (date) {
                    const d = new Date(date);
                    let hourMins = value.split(":");
                    let currentDateTime = DateTime.now().setZone(timeZone);
                    const selectedDateTime = DateTime.fromObject({ year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate(), hour: hourMins[0], minute: hourMins[1], second: "00" }, { zone: timeZone });
                    return selectedDateTime.toMillis() > currentDateTime.toMillis()
                } else {
                    return AppConstants.trueText;
                }
            }
        ),
        endTime: Yup.string().required(`${ErrorMessages.pleaseEnterText}${AppConstants.space}${formLabels.endTime.label}`).test(
            "end_time_test", ErrorMessages.endTimeBeforeStartTimeError,
            function (value) {
                const { startTime, date, endDate } = this.parent;
                // if start date and end date are same then check end time must be greater than start time
                if (moment(endDate).isSame(moment(date))) {
                    return moment(value, 'HH:mm').isAfter(moment(startTime, 'HH:mm'));
                } else {
                    return true;
                }
            }
        ).test("end_time_before_current_test", "Invalid competition end time. Please select a time that is later than the current time", function (value) {
            const { endDate } = this.parent;
            const d = new Date(endDate);
            let hourMins = value.split(":");
            let currentDateTime = DateTime.now().setZone(timeZone);
            const selectedEndDate = DateTime.fromObject({ year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate(), hour: hourMins[0], minute: hourMins[1], second: "00" }, { zone: timeZone });
            return selectedEndDate.toMillis() > currentDateTime.toMillis()
        }),
        registrationClosingDate: registrationTimeAdded ? Yup.date().required(`${ErrorMessages.pleaseAddText}${AppConstants.space}${formLabels.registrationClosingDate.label}`).min(DateTimeUtil.getConvertedJsDateInPreferredTimeZone(new Date(Date.now() - 86400000), timeZone), `${formLabels.registrationClosingDate.label} ${ErrorMessages.pastDateError}`).max(Yup.ref(formLabels.endDate.name), `${ErrorMessages.registrationDateBeforeEndDate}`)
            : registrationDateAdded ? Yup.date().min(DateTimeUtil.getConvertedJsDateInPreferredTimeZone(new Date(Date.now() - 86400000), timeZone), `${formLabels.registrationClosingDate.label} ${ErrorMessages.pastDateError}`).max(Yup.ref(formLabels.endDate.name), `${ErrorMessages.registrationDateBeforeEndDate}`) : Yup.date().notRequired(),
        registrationClosingTime: registrationDateAdded ? Yup.string().required(`${ErrorMessages.pleaseEnterText}${AppConstants.space}${formLabels.registrationClosingTime.label}`).test(
            "registration_time_test", ErrorMessages.registrationTimeBeforeEndTimeError,
            function (value) {
                const { endTime, registrationClosingDate, endDate } = this.parent;
                if (moment(registrationClosingDate).isSame(moment(endDate)))
                    return moment(endTime, 'HH:mm').isAfter(moment(value, 'HH:mm'));
                else return true;
            }).test("registration_time_before_current_test", ErrorMessages.registrationBeforeCurrentTimeError, function (value) {
                const { registrationClosingDate } = this.parent;
                if (registrationClosingDate) {
                    const d = new Date(registrationClosingDate);
                    let hourMins = value.split(":");
                    let currentDateTime = DateTime.now().setZone(timeZone);
                    const selectedDateTime = DateTime.fromObject({ year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate(), hour: hourMins[0], minute: hourMins[1], second: "00" }, { zone: timeZone });
                    return selectedDateTime.toMillis() > currentDateTime.toMillis();
                } else {
                    return AppConstants.trueText;
                }

            }) : Yup.string().notRequired(),
        resultDeclareBy: (resultDateAdded || resultByAdded) ? Yup.mixed().required("Please select a value for 'Declare Results' field") : Yup.mixed().notRequired(),
        resultDeclareDate: (resultDateAdded || resultByAdded) ? Yup.date().required("Please select result declaration date").test("result_Date_test_before", "Result date cannot be before competition end date", function (value) {
            const { resultDeclareBy, date, endDate } = this.parent;
            if (endDate === AppConstants.emptyString || endDate === AppConstants.nullText || endDate === undefined) {
                return true;
            }
            if (resultDeclareBy === "On") {
                // return true if result declare date is same or after end date
                return moment(value).isSameOrAfter(moment(endDate));
            } else if (resultDeclareBy === "Before") {
                // return true if result declare date is after the competition end date
                return moment(value).isAfter(endDate);
            } else if (endDate !== AppConstants.emptyString) {
                return moment(value).isSameOrAfter(endDate);
            } else {
                return true;
            }
        }) : Yup.date().notRequired(),
        competitionType: Yup.string().required(`${ErrorMessages.pleaseSelectText}${AppConstants.space}${AppConstants.competitionTypeText}`).oneOf(competitionTypes),
        geoLocation: Yup.string().when(formLabels.competitionType.name, {
            is: (val) => val === AppConstants.competitionTypeOffline,
            then: () => Yup.string().test("url_validation", `${ErrorMessages.enterValidUrl} for Venue Link`,
                function (value = "") { return validator.isURL(value) })
        }),
        venueLocation: Yup.string().when(formLabels.competitionType.name, {
            is: (val) => val === AppConstants.competitionTypeOffline,
            then: () => Yup.string().required(`${ErrorMessages.pleaseEnterText}${AppConstants.space}${formLabels.venueLocation.label}`)
        }),
        vcLink: Yup.string().test("url_validation_vclink", `${ErrorMessages.enterValidUrl} for VC Link`,
            function (value = "") { return value === AppConstants.emptyString ? true : validator.isURL(value) }).notRequired(),
        allowedNumberOfParticipants: Yup.number().required(`${ErrorMessages.pleaseEnterText}${AppConstants.space}Participant limit`).min(1, ErrorMessages.invalidParticipationLimit).integer(`Participants ${ErrorMessages.invalidDecimalNumber}`),
        minAge: Yup.number().notRequired().min(minimumSignupAge, StringUtils.getParsedMessage(ErrorMessages.invalidAge, { minimumSignupAge })).integer(`Age ${ErrorMessages.invalidDecimalNumber}`),
        maxAge: Yup.number().notRequired().integer(`Age ${ErrorMessages.invalidDecimalNumber}`).min(minimumSignupAge, StringUtils.getParsedMessage(ErrorMessages.invalidAge, { minimumSignupAge })).when("minAge", {
            is: (val) => val > minimumSignupAge - 1,
            then: () => Yup.number().notRequired().min(Yup.ref(formLabels.minAge.name), ErrorMessages.invalidMaxAge)
        }),
        submission: Yup.mixed().required(ErrorMessages.requiredField),
        thumbnailImage: Yup.mixed().notRequired().test('fileSize', `Thumbnail image ${ErrorMessages.fileSizeMoreThan5mbError}`, (value) => { return value ? (value.size <= 5000000) : true }).test('fileType', `Thumbnail image ${ErrorMessages.fileTypeOtherThanJpgOrPngError}`, (value) => { return value ? (supportedImageFormats.includes(value.type)) : true }),
        promotionImageForMobile: Yup.mixed().notRequired().test('fileSize', `Promotion image for mobile ${ErrorMessages.fileSizeMoreThan5mbError}`, (value) => { return value ? (value.size <= 5000000) : true }).test('fileType', `Promotion image for mobile ${ErrorMessages.fileTypeOtherThanJpgOrPngError}`, (value) => { return value ? (supportedImageFormats.includes(value.type)) : true }),
        promotionImageForTablet: Yup.mixed().notRequired().test('fileSize', `Promotion image for tablet ${ErrorMessages.fileSizeMoreThan5mbError}`, (value) => { return value ? (value.size <= 5000000) : true }).test('fileType', `Promotion image for tablet ${ErrorMessages.fileTypeOtherThanJpgOrPngError}`, (value) => { return value ? (supportedImageFormats.includes(value.type)) : true }),
        promotionImageForWeb: Yup.mixed().notRequired().test('fileSize', `Promotion image for web ${ErrorMessages.fileSizeMoreThan5mbError}`, (value) => { return value ? (value.size <= 5000000) : true }).test('fileType', `Promotion image for web ${ErrorMessages.fileTypeOtherThanJpgOrPngError}`, (value) => { return value ? (supportedImageFormats.includes(value.type)) : true }),
    });

    const { handleSubmit, values, touched, errors, setFieldValue, handleBlur, handleChange, validateForm } = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        handleSubmit: () => { }
    });

    useEffect(() => {
        if (skills?.length === 1) {
            setFieldValue(formLabels.skill.name, skills[0]?.skillId)
        }
    }, [skills]);

    const checkCharacterCount = (event) => {
        const editor = reactQuillRef.current.unprivilegedEditor;
        if (editor.getLength() > 500 && event.key !== 'Backspace') {
            event.preventDefault();
        }
    };

    useEffect(() => {
        validateForm();
    }, [resultDateAdded, resultByAdded, values.resultDeclareDate, values.resultDeclareBy, props.clubQrenciaFlg, props.feeWaiverFlag]);

    useEffect(() => {
        if (guidelines === AppConstants.emptyEditorString && guidelinesPdf !== null && guidelinesPdf !== undefined && guidelinesPdf.name?.length !== 0) {
            if (guidelinesPdf.type === "application/pdf") {
                if (guidelinesPdf.size > 5000000) {
                    setGuidelinesError(`Guidelines file ${ErrorMessages.fileSizeMoreThan5mbError}`);
                } else {
                    setGuidelinesError(AppConstants.emptyString);
                }
            } else if (guidelinesPdf?.size) {
                setGuidelinesError(ErrorMessages.fileTypeOtherThanPdfError);
            }
        } else {
            setGuidelinesError(AppConstants.emptyString);
        }
    }, [guidelinesPdf, guidelines]);

    useEffect(() => {
        const newValues = {
            ...values,
            guidelinesText: guidelines,
            guidelinesFile: guidelinesPdf,
            description: description
        };

        changeBasicFormDetailsData(newValues);
    }, [values, guidelines, guidelinesPdf, description]);

    useEffect(() => {
        // if the previous competition details are copied
        if (!values.date && selectedCompetitionDetails.id && values.title) {
            validateForm();
        }
    }, [values.date, selectedCompetitionDetails.id, values.title]);


    useEffect(() => {
        const changeParentState = () => {
            changeBasicDetailsFormError(errors, guidelinesError);

            if (description?.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
                if (errors == {} && values.title === AppConstants.emptyString) {
                    changeBasicDetailsFormError(errors, guidelinesError);
                } else if (values.title === AppConstants.emptyString || values.title?.includes(`"`)) {
                    changeBasicDetailsFormError(errors, guidelinesError);
                } else {
                    changeBasicDetailsFormError({ description: "Please enter a description", ...errors }, guidelinesError);
                }
            } else {
                changeBasicDetailsFormError(errors, guidelinesError);
            }
        }
        changeParentState();
    }, [errors, guidelinesError, description]);

    useEffect(() => {
        if (!props.rubricsConfigured) {
            changeResultDeclareRowStatus(AppConstants.falseText);
        }
    }, [props.rubricsConfigured]);

    useEffect(() => {
        const getUploadedFiles = async () => {
            if (selectedCompetitionDetails.guidelinesDocumentPath) {
                let fileData = await FileOperationsUtil.fetchFileBinaryData(selectedCompetitionDetails.guidelinesDocumentPath, "Guidelines");
                setGuidelinesPdf(fileData ? fileData.dataTransfer.files.item(0) : AppConstants.emptyString);
            }
            if (selectedCompetitionDetails.thumbnailImagePath) {
                let fileData = await FileOperationsUtil.fetchFileBinaryData(selectedCompetitionDetails.thumbnailImagePath, formLabels.thumbnailImage.name);
                setFieldValue(formLabels.thumbnailImage.name, fileData ? fileData.dataTransfer.files.item(0) : AppConstants.emptyString);
            }
            if (selectedCompetitionDetails.promotionImagePathMobile) {
                let fileData = await FileOperationsUtil.fetchFileBinaryData(selectedCompetitionDetails.promotionImagePathMobile, formLabels.promotionImageForMobile.name);
                setFieldValue(formLabels.promotionImageForMobile.name, fileData ? fileData.dataTransfer.files.item(0) : AppConstants.emptyString);
            }
            if (selectedCompetitionDetails.promotionImagePathTablet) {
                let fileData = await FileOperationsUtil.fetchFileBinaryData(selectedCompetitionDetails.promotionImagePathTablet, formLabels.promotionImageForTablet.name);
                setFieldValue(formLabels.promotionImageForTablet.name, fileData ? fileData.dataTransfer.files.item(0) : AppConstants.emptyString);
            }
            if (selectedCompetitionDetails.promotionImagePathWeb) {
                let fileData = await FileOperationsUtil.fetchFileBinaryData(selectedCompetitionDetails.promotionImagePathWeb, formLabels.promotionImageForWeb.name);
                setFieldValue(formLabels.promotionImageForWeb.name, fileData ? fileData.dataTransfer.files.item(0) : AppConstants.emptyString);
            }
        }
        if (selectedCompetitionDetails.id) {
            getUploadedFiles();
            setGuidelines((selectedCompetitionDetails.guidelinesText !== AppConstants.nullText || selectedCompetitionDetails.guidelines !== undefined) ? selectedCompetitionDetails.guidelinesText : AppConstants.emptyEditorString);
            setDescription(selectedCompetitionDetails.description ? selectedCompetitionDetails.description : AppConstants.emptyEditorString);
        } else {
            setDescription(AppConstants.emptyEditorString);
            setGuidelines(AppConstants.emptyEditorString);
            setGuidelinesPdf({});
        }
    }, [selectedCompetitionDetails]);

    const removeFile = (id) => {
        const input = document.getElementById(id);
        input.value = AppConstants.emptyString;
        input.type = AppConstants.inputFieldTypes.file;
        if (id === "guidelinesPdfInput") {
            setGuidelinesPdf({});
        } else {
            setFieldValue(id, AppConstants.emptyString)
        }
    };

    const changeCompetitionType = (event) => {
        setFieldValue(formLabels.competitionType.name, event.target.value);
        if (event.target.value) {
            if (event.target.value === AppConstants.competitionTypeOffline) {
                setFieldValue(formLabels.vcLink.name, AppConstants.emptyString);
            } else if (event.target.value === AppConstants.competitionTypeOnline) {
                setFieldValue(formLabels.venueLocation.name, AppConstants.emptyString);
                setFieldValue(formLabels.geoLocation.name, AppConstants.emptyString);
            }
        } else {
            setFieldValue(formLabels.vcLink.name, AppConstants.emptyString);
            setFieldValue(formLabels.venueLocation.name, AppConstants.emptyString);
            setFieldValue(formLabels.geoLocation.name, AppConstants.emptyString);
        }
    }

    const changeRegistrationClosingDate = (e) => {
        setFieldValue(formLabels.registrationClosingDate.name, e.target.value)
        if (e.target.value) {
            setRegistrationDateAdded(true);
        } else {
            setRegistrationDateAdded(false);
        }
    }

    const changeRegistrationClosingTime = (e) => {
        setFieldValue(formLabels.registrationClosingTime.name, e.target.value);
        if (e.target.value) {
            setRegistrationTimeAdded(true)
        } else {
            setRegistrationTimeAdded(false)
        }
    };

    const handleFormSubmit = () => {
        saveCurrentStep({ step: stages.indexOf(stage) });
    };

    const changeResultDeclareBy = (e) => {
        setFieldValue(formLabels.resultDeclareBy.name, e.target.value);
        setResultByAdded(AppConstants.trueText);
        validateForm();
    };

    const changeResultDeclareDate = (e) => {
        setFieldValue(formLabels.resultDeclareDate.name, e.target.value);
        if (e.target.value) {
            setResultDateAdded(AppConstants.trueText);
        } else {
            setResultDateAdded(AppConstants.falseText);
        }
        validateForm();
    };

    const changeResultDeclareRowStatus = (value) => {
        setResultDeclareCheckbox(value);
        setShowResultDeclareRow(value);
        setResultDateAdded(AppConstants.falseText);
        setResultByAdded(AppConstants.falseText);
        setFieldValue(formLabels.resultDeclareDate.name, AppConstants.emptyString);
        setFieldValue(formLabels.resultDeclareBy.name, AppConstants.emptyString);
        validateForm();
    };

    const disableImageGeneration = () => {
        if (values.title.trim() !== AppConstants.emptyString && values.skill !== AppConstants.emptyString) {
            if (values.title.trim() === imageGeneratedArgs.competitionName && values.skill === imageGeneratedArgs.skillName) {
                return true;
            }
            return false;
        } else {
            return true;
        }
    };

    const selectPreviewImage = (selectedImage, name) => {
        setFieldValue(name, selectedImage.dataTransfer.files.item(0));
    };

    const getAiImages = (imageData) => {
        closeModal();
        if (imageData) {
            setAiErrorMessage(AppConstants.emptyString);
            setAiGeneratedImages(imageData);

            setWebBannerPreview(imageData[0].imageURL);
            selectPreviewImage(imageData[0], formLabels.promotionImageForWeb.name);

            setTabletBannerPreview(imageData[1].imageURL);
            selectPreviewImage(imageData[1], formLabels.promotionImageForTablet.name);

            setMobileBannerPreview(imageData[2].imageURL);
            selectPreviewImage(imageData[2], formLabels.promotionImageForMobile.name);

            setThumbnailPreview(imageData[3].imageURL);
            selectPreviewImage(imageData[3], formLabels.thumbnailImage.name);
        } else {
            setAiErrorMessage(ErrorMessages.aiImageGenerationError);
            let timer = setTimeout(() => {
                setAiErrorMessage(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    };

    const closeModal = () => {
        setShowModal(AppConstants.falseText);
    };

    const handleShowModal = () => {
        setShowModal(AppConstants.trueText);
    }


    const checkValidCost = (event) => {
        let excludedKeywords = ["-", "e", "+", "E", AppConstants.emptyString];
        if (excludedKeywords.includes(event.key)) event.preventDefault();
    }

    const checkValidNumber = (event) => {
        let excludedKeywords = [".", "-", "e", "+", "E", AppConstants.emptyString];
        if (excludedKeywords.includes(event.key)) event.preventDefault();
    };

    const handleGuidelinesChange = (value) => {
        let regex = /^<p\s+class="[^"]*"><br><\/p>$/;
        let htmlText = value.replace(regex, AppConstants.emptyEditorString);
        setGuidelines(htmlText);
    };

    const getFileExtension = (fileUrl) => {
        return fileUrl?.split(".").pop();
    }

    const downloadFile = async (fileUrl, name, relativePath) => {
        let resp = await FileOperationsUtil.downloadFileWithLoader(fileUrl, `${selectedCompetitionDetails.title}-${name}${AppConstants.dot}${getFileExtension(relativePath)}`);
        if (resp) {
            if (name === "Guidelines") {
                setGuidelinesDocumentSpinner(AppConstants.falseText);
            } else if (name === formLabels.thumbnailImage.name) {
                setThumbnailSpinner(AppConstants.falseText);
            } else if (name === formLabels.promotionImageForWeb.name) {
                setPromotionImageWebSpinner(AppConstants.falseText);
            } else if (name === formLabels.promotionImageForTablet.name) {
                setPromotionImageTabletSpinner(AppConstants.falseText);
            } else if (name === formLabels.promotionImageForMobile.name) {
                setPromotionImageMobileSpinner(AppConstants.falseText);
            }
        } else {
            if (name === "Guidelines") {
                setGuidelinesDocumentSpinner(AppConstants.falseText);
            } else if (name === formLabels.thumbnailImage.name) {
                setThumbnailSpinner(AppConstants.falseText);
            } else if (name === formLabels.promotionImageForWeb.name) {
                setPromotionImageWebSpinner(AppConstants.falseText);
            } else if (name === formLabels.promotionImageForTablet.name) {
                setPromotionImageTabletSpinner(AppConstants.falseText);
            } else if (name === formLabels.promotionImageForMobile.name) {
                setPromotionImageMobileSpinner(AppConstants.falseText);
            }
        }
    };

    return (
        <>
            <div className={classes.formContainer}>
                <Form noValidate validated={validated} onSubmit={handleSubmit} >
                    <Row className="mb-2" >
                        <Form.Group className="mb-2" controlId="basicDetailsTitle">
                            <Form.Label className={formClass.formLabel}>{formLabels.title.label}<span className="redAstrisk">*</span></Form.Label>
                            <Form.Control className={formClass.formInput}
                                required
                                placeholder="Title for your Competition"
                                type="text" maxLength={50}
                                name={formLabels.title.name}
                                value={values.title} onBlur={handleBlur}
                                onChange={handleChange}
                                isInvalid={touched.title && errors.title}
                            />
                            <Form.Control.Feedback className={classes.errorText} type="invalid" >{errors.title}</Form.Control.Feedback>
                            {values.title?.length > 0 && <span className={classes.remainingChars}>{getRemainingLengthText(50, values.title)}</span>}
                        </Form.Group>
                    </Row>

                    <Row className={`mb-12 mt-2 ${classes.textEditorContainer}`}>
                        <Form.Group className={classes.textEditorContainer} controlId="basicDetailsDescriptionText">
                            <Form.Label className={`${formClass.formLabel} ${classes.textEditorLabel}`}>{formLabels.description.label}<span className="redAstrisk">*</span></Form.Label>
                            <ReactQuill
                                ref={reactQuillRef}
                                onKeyDown={checkCharacterCount}
                                formats={AppConstants.richTextEditorFormats}
                                onChange={setDescription}
                                value={description} preserveWhitespace={AppConstants.trueText}
                                modules={AppConstants.richTextEditorModules} className={classes.textEditor} theme="snow" placeholder="Description of your competition"
                            />
                            {reactQuillRef && reactQuillRef.current && reactQuillRef.current.unprivilegedEditor && reactQuillRef.current.unprivilegedEditor.getLength() > 1 && 501 - reactQuillRef.current.unprivilegedEditor.getLength() >= 0 && <span className={classes.remainingChars}>{501 - reactQuillRef.current.unprivilegedEditor.getLength()} out of 500 characters remaining</span>}
                            {reactQuillRef && reactQuillRef.current && reactQuillRef.current.unprivilegedEditor && reactQuillRef.current.unprivilegedEditor.getLength() > 1 && 501 - reactQuillRef.current.unprivilegedEditor.getLength() < 0 && <span className={classes.remainingChars}>0 out of 500 characters remaining</span>}
                        </Form.Group>
                    </Row>
                    <Row className="mb-2 mt-3">
                        {skills?.length > 1 && <Form.Group className="mb-2" as={Col} controlId="basicDetailsSkill">
                            <Form.Label className={formClass.formLabel}>{formLabels.skill.label}<span className="redAstrisk">*</span></Form.Label>
                            <Form.Select className={formClass.selectInput}
                                name={formLabels.skill.name}
                                value={values.skill} onBlur={handleBlur}
                                onChange={handleChange}
                                isInvalid={touched.skill && errors.skill}
                            ><option value={AppConstants.emptyString} defaultValue>Select Skill</option>
                                {skills?.map(option => (
                                    <option key={option.skillId} value={option.skillId}>
                                        {option.skillName}
                                    </option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback className={classes.errorText} type="invalid">{errors.skill}</Form.Control.Feedback>
                        </Form.Group>}
                        {!props.clubQrenciaFlg && !props.feeWaiverFlag && <Form.Group className="mb-2" as={Col} controlId="basicDetailsCost">
                            <Form.Label className={formClass.formLabel}>Cost (INR) <span className="redAstrisk">*</span></Form.Label>
                            <Form.Control className={formClass.formInput}
                                type="number"
                                placeholder="Competition Cost"
                                name={formLabels.cost.name}
                                value={values.cost} onBlur={handleBlur}
                                onChange={handleChange}
                                onKeyDown={checkValidCost}
                                isInvalid={touched.cost && errors.cost}
                            />
                            <Form.Control.Feedback className="errorText" type="invalid">{errors.cost}</Form.Control.Feedback>
                        </Form.Group>}
                    </Row>

                    <Row xs={1} sm={2} md={1} lg={2} xl={2} className="mb-2">
                        <Form.Group className={values.date === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col} controlId="basicDetailsDate">
                            <Form.Label className={formClass.formLabel}>{formLabels.date.label}<span className="redAstrisk">*</span></Form.Label>
                            <Form.Control className={formClass.formInput}
                                name={formLabels.date.name}
                                type="date"
                                min={new Date().toISOString().split('T')[0]}
                                isInvalid={touched.date && errors.date}
                                value={values.date} onBlur={handleBlur}
                                onChange={handleChange}
                            />
                            <Form.Control.Feedback className={classes.errorText} type="invalid">{errors.date}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className={values.startTime === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col} controlId="basicDetailsStarttime">
                            <Form.Label className={formClass.formLabel}>{formLabels.startTime.label}<span className="redAstrisk">*</span></Form.Label>
                            <Form.Control className={formClass.formInput}
                                name={formLabels.startTime.name}
                                type="time"
                                value={values.startTime} onBlur={handleBlur}
                                onChange={handleChange}
                                isInvalid={touched.startTime && errors.startTime}
                            />
                            <Form.Control.Feedback className={classes.errorText} type="invalid">{errors.startTime}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <Row xs={1} sm={2} md={1} lg={2} xl={2} className="mb-2">
                        <Form.Group className={values.endDate === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col} controlId="basicDetailsDate">
                            <Form.Label className={formClass.formLabel}>{formLabels.endDate.label}<span className="redAstrisk">*</span></Form.Label>
                            <Form.Control className={formClass.formInput}
                                name={formLabels.endDate.name}
                                type="date"
                                min={new Date().toISOString().split('T')[0]}
                                isInvalid={touched.endDate && errors.endDate}
                                value={values.endDate} onBlur={handleBlur}
                                onChange={handleChange}
                            />
                            <Form.Control.Feedback className={classes.errorText} type="invalid">{errors.endDate}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className={values.endTime === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col} controlId="basicDetailsEndTime">
                            <Form.Label className={formClass.formLabel}>{formLabels.endTime.label}<span className="redAstrisk">*</span></Form.Label>
                            <Form.Control className={formClass.formInput}
                                name={formLabels.endTime.name}
                                type="time"
                                value={values.endTime} onBlur={handleBlur}
                                onChange={handleChange}
                                isInvalid={touched.endTime && errors.endTime}
                            />
                            <Form.Control.Feedback className={classes.errorText} type="invalid">{errors.endTime}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className="mb-2 mt-2">
                        <Form.Group controlId="basicDetailsTitle" className={`${classes.registrationDetails} mb-2 mt-2`}>
                            <Form.Label className={`${formClass.formLabel} ${classes.registrationDetailsLabel}`}>Registration window <RiInformationFill className={classes.infoButton} data-tooltip-id="registrationEndInfoIcon" /></Form.Label>
                            <Tooltip className={classes.tooltipMessage} id="registrationEndInfoIcon"><span className={classes.tooltipMessage}>{AppConstants.competitionRegistrationDeadlineInfo}</span></Tooltip>
                        </Form.Group>
                    </Row>

                    <Row xs={1} sm={2} md={2} lg={2} xl={2} className="mb-2">
                        <Form.Group className={values.registrationClosingDate === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col} controlId="basicDetailsRegistrationDate">
                            <Form.Label className={formClass.formLabel}>{formLabels.registrationClosingDate.label}{(registrationTimeAdded || registrationDateAdded) && <span className="redAstrisk">*</span>}</Form.Label>
                            <Form.Control className={formClass.formInput}
                                name={formLabels.registrationClosingDate.name}
                                type="date"
                                min={new Date().toISOString().split('T')[0]}
                                isInvalid={touched.registrationClosingDate && errors.registrationClosingDate}
                                value={values.registrationClosingDate} onBlur={handleBlur}
                                onChange={(e) => changeRegistrationClosingDate(e)}
                            />
                            <Form.Control.Feedback className={classes.errorText} type="invalid">{errors.registrationClosingDate}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className={values.registrationClosingTime === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col} controlId="registrationClosingTime">
                            <Form.Label className={formClass.formLabel}>{formLabels.registrationClosingTime.label}{(registrationTimeAdded || registrationDateAdded) && <span className="redAstrisk">*</span>}</Form.Label>
                            <Form.Control className={formClass.formInput}
                                name={formLabels.registrationClosingTime.name}
                                type="time"
                                value={values.registrationClosingTime} onBlur={handleBlur}
                                onChange={(e) => changeRegistrationClosingTime(e)}
                                isInvalid={touched.registrationClosingTime && errors.registrationClosingTime}
                            />
                            <Form.Control.Feedback className={classes.errorText} type="invalid">{errors.registrationClosingTime}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <div className={classes.switchContainer}>
                        <div className={classes.switchText}>Select result declaration date <RiInformationFill className={classes.infoButton} data-tooltip-id="resultDeclareInfo" /><span><input className={classes.switchInput} disabled={!props.rubricsConfigured} checked={resultDeclareCheckbox} type="checkbox" onChange={() => changeResultDeclareRowStatus(!showResultDeclareRow)} /></span>
                        </div>
                        <Tooltip className={classes.tooltipMessage} id="resultDeclareInfo"><span className={classes.tooltipMessage}>{AppConstants.resultDeclareInfoText}</span></Tooltip>
                    </div>

                    {showResultDeclareRow && <Row xs={1} sm={2} md={2} lg={2} xl={2} className="mb-2">
                        <Form.Group as={Col} className="mb-2" controlId="basicDetailsSubmissions">
                            <Form.Label className={` ${classes.radioLabel}`}>{formLabels.resultDeclareBy.label}{(resultDateAdded || resultByAdded) && <span className="redAstrisk">*</span>}</Form.Label>
                            <Row>
                                <Form.Check
                                    className={`${classes.radioLabelButton} ${classes.formcheck}`}
                                    type="radio"
                                    label="On"
                                    name={formLabels.resultDeclareBy.name}
                                    id="result_declare_on"
                                    value="On"
                                    checked={values.resultDeclareBy === "On"}
                                    onChange={changeResultDeclareBy}
                                />
                                <Form.Check
                                    className={`${classes.radioLabelButton} ${classes.formcheck}`}
                                    type="radio"
                                    label="Before"
                                    name={formLabels.resultDeclareBy.name}
                                    id="result_declare_before"
                                    value="Before"
                                    checked={values.resultDeclareBy === "Before"}
                                    onChange={changeResultDeclareBy}
                                />
                            </Row>
                        </Form.Group>
                        <Form.Group className={values.resultDeclareDate === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col} controlId="basicDetailsResultDeclareDate">
                            <Form.Label className={formClass.formLabel}>{formLabels.resultDeclareDate.label}{(resultDateAdded || resultByAdded) && <span className="redAstrisk">*</span>}</Form.Label>
                            <Form.Control className={formClass.formInput}
                                name={formLabels.resultDeclareDate.name}
                                type="date"
                                min={new Date().toISOString().split('T')[0]}
                                isInvalid={touched.resultDeclareDate && errors.resultDeclareDate}
                                value={values.resultDeclareDate} onBlur={handleBlur}
                                onChange={changeResultDeclareDate}
                            />
                            <Form.Control.Feedback className={classes.errorText} type="invalid">{errors.resultDeclareDate}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>}


                    <Row className="mb-2">
                        <Form.Group className="mb-2" as={Col} controlId="basicDetailsType">
                            <Form.Label className={formClass.formLabel}>{formLabels.competitionType.label}<span className="redAstrisk">*</span></Form.Label>
                            <Form.Select className={formClass.selectInput}
                                name={formLabels.competitionType.name}
                                value={values.competitionType} onBlur={handleBlur}
                                onChange={(event) => changeCompetitionType(event)}
                                isInvalid={touched.competitionType && errors.competitionType}
                            ><option value={AppConstants.emptyString} defaultValue>Select Type</option>
                                {competitionTypes?.map((type, i) => (
                                    <option key={i} value={type}>
                                        {type}
                                    </option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback className={classes.errorText} type="invalid">{errors.competitionType}</Form.Control.Feedback>
                        </Form.Group>

                        {values.competitionType === competitionTypes[1] && <Form.Group className="mb-2" as={Col} controlId="basicDetailVenue">
                            <Form.Label className={formClass.formLabel}>{formLabels.geoLocation.label}<span className="redAstrisk">*</span></Form.Label>
                            <Form.Control className={formClass.formInput}
                                type="url"
                                placeholder="Competition Venue Location URL"
                                name={formLabels.geoLocation.name}
                                value={values.geoLocation} onBlur={handleBlur}
                                onChange={handleChange}
                                isInvalid={touched.geoLocation && errors.geoLocation} />
                            <Form.Control.Feedback className={classes.errorText} type="invalid">{errors.geoLocation}</Form.Control.Feedback>
                        </Form.Group>}
                        {values.competitionType === competitionTypes[0] && <Form.Group className="mb-2" as={Col} controlId="basicDetailsVcLink">
                            <Form.Label className={formClass.formLabel}>{formLabels.vcLink.label}</Form.Label>
                            <Form.Control className={formClass.formInput}
                                type="url"
                                placeholder="Video Conferencing link"
                                name={formLabels.vcLink.name}
                                value={values.vcLink} onBlur={handleBlur}
                                onChange={handleChange}
                                isInvalid={touched.vcLink && errors.vcLink} />
                            <Form.Control.Feedback className={classes.errorText} type="invalid">{errors.vcLink}</Form.Control.Feedback>
                        </Form.Group>}
                    </Row>
                    {values.competitionType === competitionTypes[1] && <Row>
                        <Form.Group className="mb-2" controlId="basicDetailsVenueLocation">
                            <Form.Label className={formClass.formLabel}>{formLabels.venueLocation.label}<span className="redAstrisk">*</span></Form.Label>
                            <Form.Control className={formClass.formInput}
                                name={formLabels.venueLocation.name}
                                as="textarea"
                                placeholder="Address"
                                isInvalid={touched.venueLocation && errors.venueLocation}
                                value={values.venueLocation} onBlur={handleBlur}
                                onChange={handleChange} />
                            <Form.Control.Feedback className={classes.errorText} type="invalid"> {errors.venueLocation} </Form.Control.Feedback>
                        </Form.Group>
                    </Row>}

                    <Row className="mb-2">
                        <Form.Group className="mb-2" as={Col} controlId="basicDetailsMaxLimit">
                            <Form.Label className={formClass.formLabel}>{formLabels.allowedNumberOfParticipants.label}<span className="redAstrisk">*</span></Form.Label>
                            <Form.Control className={formClass.formInput}
                                type="number"
                                placeholder="Max Limit"
                                name={formLabels.allowedNumberOfParticipants.name}
                                value={values.allowedNumberOfParticipants} onBlur={handleBlur}
                                onKeyDown={checkValidNumber}
                                onChange={handleChange}
                                isInvalid={touched.allowedNumberOfParticipants && errors.allowedNumberOfParticipants}
                            />
                            <Form.Control.Feedback className={classes.errorText} type="invalid">{errors.allowedNumberOfParticipants}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <Row className="mb-2" >
                        <Form.Group className="mb-2" as={Col} controlId="basicDetailsMinAge">
                            <Form.Label className={formClass.formLabel}>{formLabels.minAge.label}</Form.Label>
                            <Form.Control type="number" className={formClass.formInput}
                                placeholder="Min Age"
                                name={formLabels.minAge.name}
                                value={values.minAge} onBlur={handleBlur}
                                onKeyDown={checkValidNumber}
                                onChange={handleChange}
                                isInvalid={touched.minAge && errors.minAge}
                            />
                            <Form.Control.Feedback className={classes.errorText} type="invalid">{errors.minAge}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-2" as={Col} controlId="basicDetailsMaxAge">
                            <Form.Label className={formClass.formLabel}>{formLabels.maxAge.label}</Form.Label>
                            <Form.Control className={formClass.formInput}
                                type="number"
                                placeholder="Max Age"
                                name={formLabels.maxAge.name}
                                onKeyDown={checkValidNumber}
                                value={values.maxAge} onBlur={handleBlur}
                                onChange={handleChange}
                                isInvalid={touched.maxAge && errors.maxAge}
                            />
                            <Form.Control.Feedback className={classes.errorText} type="invalid">{errors.maxAge}</Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row className={classes.radioDiv}>
                        <Form.Group as={Col} className="mb-2" controlId="basicDetailsSubmissions">
                            <Form.Label className={` ${classes.radioLabel}`}>{formLabels.submission.label}</Form.Label>
                            <Row>
                                <Form.Check
                                    className={`${classes.radioLabelButton} ${classes.formcheck}`}
                                    type="radio"
                                    label="No"
                                    name={formLabels.submission.name}
                                    id="submission_falg_no"
                                    value="N"
                                    checked={values.submission === "N"}
                                    onChange={handleChange}
                                />
                                <Form.Check
                                    className={`${classes.radioLabelButton} ${classes.formcheck}`}
                                    type="radio"
                                    label="Yes"
                                    name={formLabels.submission.name}
                                    id="submission_falg_yes"
                                    value="Y"
                                    checked={values.submission === "Y"}
                                    onChange={handleChange}
                                />
                            </Row>
                        </Form.Group>
                    </Row>
                    <Row className={`mb-12 mt-2 ${classes.textEditorContainer} ${guidelinesPdf && guidelinesPdf.name?.length && "textEditorInactive"}`}>
                        <Form.Group className={classes.textEditorContainer} controlId="basicDetailsGuidelinesText">
                            <Form.Label className={`${formClass.formLabel} ${classes.textEditorLabel}`}>{formLabels.guidelines.label}</Form.Label>
                            <ReactQuill formats={AppConstants.richTextEditorFormats} modules={AppConstants.richTextEditorModules} readOnly={guidelinesPdf && guidelinesPdf.name?.length > 0} className={classes.textEditor} theme="snow" placeholder="Enter competition Guidelines"
                                value={guidelines} preserveWhitespace={AppConstants.trueText}
                                onChange={handleGuidelinesChange} />
                        </Form.Group>
                    </Row>
                    {guidelines === AppConstants.emptyEditorString && !guidelinesPdf?.size && <h4 className={classes.separatorLine}>OR</h4>}
                    <Row className='mt-2 mb-5'>
                        <div className={classes.uploadFileRow}>
                            <FileUploadInput id="guidelinesPdfInput" name="guidelines_file" disabled={guidelines !== AppConstants.emptyEditorString} accept=".pdf" label={formLabels.uploadGuidelines.label} handleFileChange={e => setGuidelinesPdf(e.target.files[0])} inputFile={guidelinesPdf} />
                            {selectedCompetitionDetails.guidelinesDocumentPath && <button type="button" onClick={() => { downloadFile(selectedCompetitionDetails.guidelinesDocumentPath, "Guidelines", selectedCompetitionDetails.guidelinesDocumentRelativePath); setGuidelinesDocumentSpinner(AppConstants.trueText) }} data-tooltip-id="downloadGuidelines" className={guidelinesError ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}>{guidelinesDocumentSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                            <button type="button" onClick={() => removeFile("guidelinesPdfInput")} data-tooltip-id="removeGuideline" className={classes.removeBtn}><MdDelete /></button>
                            <Tooltip className={classes.tooltipMessage} id="removeGuideline"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                        </div>
                        {guidelinesError && <div className={classes.errorText}>{guidelinesError}</div>}
                    </Row>

                    <Row className="mb-2">
                        <div className={classes.uploadFileRow}>
                            <div>
                                <FileUploadInput id={formLabels.thumbnailImage.name} name={formLabels.thumbnailImage.name} accept="image/png,image/jpeg,image/jpg" label={formLabels.thumbnailImage.label} handleFileChange={(e) => setFieldValue("thumbnailImage", e.target.files[0])} inputFile={values.thumbnailImage} infoMessage={AppConstants.expertCreateCompetitionsThumbnailMessage} />
                                {errors.thumbnailImage && <div className="errorText">{errors.thumbnailImage}</div>}
                            </div>
                            {selectedCompetitionDetails.thumbnailImagePath && <button type="button" onClick={() => { downloadFile(selectedCompetitionDetails.thumbnailImagePath, formLabels.thumbnailImage.name, selectedCompetitionDetails.thumbnailImageRelativePath); setThumbnailSpinner(AppConstants.trueText) }} data-tooltip-id="downloadThumbnail" className={errors.thumbnailImage ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}>{thumbnailSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                            <button type="button" onClick={() => removeFile(formLabels.thumbnailImage.name)} data-tooltip-id="removeThumbnail" className={classes.removeBtn}><MdDelete /></button>
                            <Tooltip className={classes.tooltipMessage} id="removeThumbnail"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                        </div>
                        {thumbnailPreview && <div className={classes.previewContainer}>
                            <img data-tooltip-id="selectThumbnailImage" onClick={() => selectPreviewImage(aiGeneratedImages[3], formLabels.thumbnailImage.name)} className={classes.thumbnailPreviewImage} src={thumbnailPreview} alt="thumbnailImagePreview" />
                        </div>}
                        {(thumbnailPreview && thumbnailImageInput?.files !== aiGeneratedImages[3].dataTransfer.files) && <Tooltip className={classes.tooltipMessage} id="selectThumbnailImage"><span className={classes.tooltipMessage}>Click to select this image</span></Tooltip>}
                    </Row>
                    <Row className="mb-2">
                        <div className={classes.uploadFileRow}>
                            <Form.Group className={`${formClass.fileUpload} mb-2`} as={Col} >
                                <FileUploadInput id={formLabels.promotionImageForWeb.name} name={formLabels.promotionImageForWeb.name} accept="image/png,image/jpeg,image/jpg" label={formLabels.promotionImageForWeb.label} handleFileChange={(e) => setFieldValue(formLabels.promotionImageForWeb.name, e.target.files[0])} inputFile={values.promotionImageForWeb} infoMessage={AppConstants.expertCreateCompetitionsWebBannerMessage} />
                                {errors.promotionImageForWeb && <div className="errorText">{errors.promotionImageForWeb}</div>}
                            </Form.Group>
                            {selectedCompetitionDetails.promotionImagePathWeb && <button type="button" onClick={() => { downloadFile(selectedCompetitionDetails.promotionImagePathWeb, formLabels.promotionImageForWeb.name, selectedCompetitionDetails.promotionImageWebRelativePath); setPromotionImageWebSpinner(AppConstants.trueText) }} data-tooltip-id="downloadWebImage" className={errors.promotionImageForWeb ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}>{promotionImageWebSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                            <button type="button" onClick={() => removeFile(formLabels.promotionImageForWeb.name)} data-tooltip-id="removeWebImage" className={classes.removeBtn}><MdDelete /></button>
                            <Tooltip className={classes.tooltipMessage} id="removeWebImage"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                        </div>
                        {webBannerPreview && <div className={classes.previewContainer}>
                            <img data-tooltip-id="selectWebImage" onClick={() => selectPreviewImage(aiGeneratedImages[0], formLabels.promotionImageForWeb.name)} className={classes.webPreviewImage} src={webBannerPreview} alt="webImagePreview" />
                        </div>}
                        {(webBannerPreview && webBannerInput?.files !== aiGeneratedImages[0].dataTransfer.files) && <Tooltip className={classes.tooltipMessage} id="selectWebImage"><span className={classes.tooltipMessage}>Click to select this image</span></Tooltip>}
                    </Row>
                    <Row className="mb-2">
                        <div className={classes.uploadFileRow}>
                            <Form.Group className={`${formClass.fileUpload} mb-2`} as={Col}>
                                <FileUploadInput id={formLabels.promotionImageForTablet.name} name={formLabels.promotionImageForTablet.name} accept="image/png,image/jpeg,image/jpg" label={formLabels.promotionImageForTablet.label} handleFileChange={(e) => setFieldValue(formLabels.promotionImageForTablet.name, e.target.files[0])} inputFile={values.promotionImageForTablet} infoMessage={AppConstants.expertCreateCompetitionsTabletBannerMessage} />
                                {errors.promotionImageForTablet && <div className="errorText">{errors.promotionImageForTablet}</div>}
                            </Form.Group>
                            {selectedCompetitionDetails.promotionImagePathTablet && <button type="button" onClick={() => { downloadFile(selectedCompetitionDetails.promotionImagePathTablet, formLabels.promotionImageForTablet.name, selectedCompetitionDetails.promotionImageTabletRelativePath); setPromotionImageTabletSpinner(AppConstants.trueText) }} data-tooltip-id="downloadTabletImage" className={errors.promotionImageForTablet ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}>{promotionImageTabletSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                            <button type="button" onClick={() => removeFile(formLabels.promotionImageForTablet.name)} data-tooltip-id="removeTabletImage" className={classes.removeBtn}><MdDelete /></button>
                            <Tooltip className={classes.tooltipMessage} id="removeTabletImage"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                        </div>


                        {tabletBannerPreview && <div data-tooltip-id="selectThumbnail" className={classes.previewContainer}>
                            <img data-tooltip-id="selectTabletImage" onClick={(e) => selectPreviewImage(aiGeneratedImages[1], formLabels.promotionImageForTablet.name)} className={classes.tabPreviewImage} src={tabletBannerPreview} alt="tabletImagePreview" />
                        </div>}
                        {tabletBannerPreview && tabletBannerInput?.files !== aiGeneratedImages[1].dataTransfer.files && <Tooltip className={classes.tooltipMessage} id="selectTabletImage"><span className={classes.tooltipMessage}>Click to select this image</span></Tooltip>}
                    </Row>
                    <Row className="mb-2">
                        <div className={classes.uploadFileRow}>
                            <Form.Group className={`${formClass.fileUpload} mb-2`} as={Col}>
                                <FileUploadInput id={formLabels.promotionImageForMobile.name} name={formLabels.promotionImageForMobile.name} accept="image/png,image/jpeg,image/jpg" label={formLabels.promotionImageForMobile.label} handleFileChange={(e) => setFieldValue(formLabels.promotionImageForMobile.name, e.target.files[0])} inputFile={values.promotionImageForMobile} infoMessage={AppConstants.expertCreateCompetitionsMobileBannerMessage} />
                                {errors.promotionImageForMobile && <div className="errorText">{errors.promotionImageForMobile}</div>}
                            </Form.Group>
                            {selectedCompetitionDetails.promotionImagePathMobile && <button type="button" onClick={() => { downloadFile(selectedCompetitionDetails.promotionImagePathMobile, formLabels.promotionImageForMobile.name, selectedCompetitionDetails.promotionImageMobileRelativePath); setPromotionImageMobileSpinner(AppConstants.trueText); }} data-tooltip-id="downloadMoblieImage" className={errors.promotionImageForMobile ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}>{promotionImageMobileSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                            <button type="button" onClick={() => removeFile(formLabels.promotionImageForMobile.name)} data-tooltip-id="removeMobileImage" className={classes.removeBtn}><MdDelete /></button>
                            <Tooltip className={classes.tooltipMessage} id="removeMobileImage"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                        </div>
                        {mobileBannerPreview && <div className={classes.previewContainer}>
                            <img data-tooltip-id="selectMobileImage" onClick={() => selectPreviewImage(aiGeneratedImages[2], formLabels.promotionImageForMobile.name)} className={classes.mobilePreviewImage} src={mobileBannerPreview} alt="mobileImagePreview" />
                        </div>}
                        {(mobileBannerPreview && mobileBannerInput?.files !== aiGeneratedImages[2].dataTransfer.files) && <Tooltip className={classes.tooltipMessage} id="selectMobileImage"><span className={classes.tooltipMessage}>Click to select this image</span></Tooltip>}
                    </Row>

                    {((values.title?.trim() !== imageGeneratedArgs.competitionName || values.skill !== imageGeneratedArgs.skillName) && Auth.getLoggedInUserDetails()?.generativeAiAccessDetails?.competitionAiAccess === AppConstants.trueText) && <Row className="mb-2">
                        {<h4 className={classes.separatorLine}>OR</h4>}
                        <Form.Group>
                            <Form.Label className={classes.aiGenerateImageText}>Let AI generate the promotional images for you!</Form.Label>
                        </Form.Group>
                        <div className={classes.defaultAiImageState}>
                            <img src={defaultImagePath} />
                        </div>
                        <Tooltip className={classes.tooltipMessage} id="generateAiImageInfo"><span className={classes.tooltipMessage}></span></Tooltip>
                        <div data-tooltip-id="generateImageButtonTooltip" className={classes.generateImageBtn}>
                            <GenerateAiImagesComponent disabled={disableImageGeneration()} getAiImages={getAiImages} payload={{ competitionName: values.title?.trim(), skillName: values.skill }} skills={skills} setImageGeneratedArgs={setImageGeneratedArgs} handleShowModal={handleShowModal} />
                        </div>
                        {(disableImageGeneration()) && <Tooltip className={classes.tooltipMessage} id="generateImageButtonTooltip"><span className={classes.tooltipMessage}>Enter a competition title and select a skill to generate the images</span></Tooltip>}
                    </Row>}
                    {aiErrorMessage && <ErrorSuccessAlertMessage message={aiErrorMessage} varient={AppConstants.alertVarient[1]} />}


                    <div className={classes.nextBtn}>
                        <Button type="submit" onClick={() => handleFormSubmit(errors)} disabled={formSubmitting}>{AppConstants.nextText}</Button>
                    </div>
                </Form>

                <Modal show={showModal} onHide={closeModal} backdropClassName={classes.modal} className="aiGenerateImageModal" backdrop="static" keyboard={false} centered>
                    <Modal.Body className={classes.modalBody}>
                        <div className={classes.preLoaderContainer}>
                            <div className={classes.preLoaderDiv}>
                                <div className={classes.loader}></div>
                                <img className={classes.qrenciaGlobe} src={qrenciaGlobeImage} alt="Qrencia globe" />
                                <p className={classes.loaderText}>Please wait while we are generating the creatives for you..</p>
                            </div>
                        </div>
                        <div className={classes.modalText}>
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
        </>
    )
};
