import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Tooltip } from 'react-tooltip';
import { BiCalendarCheck, BiCalendarX, BiWallet } from "react-icons/bi";
import { CgPin } from "react-icons/cg";
import { DateTime } from "luxon";
import { AppConstants } from "../../constants/AppConstants";
import Moment from 'react-moment';
import 'moment-timezone';
import './CompetitionCards.css';
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { EnrolmentModal } from "../modal/EnrolmentModal";
import { RiGitRepositoryPrivateFill } from 'react-icons/ri';
import { PaymentsUtil } from "../../utils/PaymentsUtil.js";
import { ErrorMessages } from "../../constants/ErrorMessages";
import { PostStartEnrolmentModal } from "../modal/PostStartEnrolmentModal.js";
import { PayOfflineConfirmationModal } from "../modal/PayOfflineConfirmationModal.js";
import { useEnrolment } from "../../hooks/useEnrolment.js";
import useWarnOnLeave from "../../hooks/useWarnOnLeave.js";

export const StudentRecentlyPublishedCompetitionCards = (props) => {
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [cardCount, setCardCount] = useState(0);
    const [viewMoreCardsCount, setViewMoreCardsCount] = useState(0);
    const [cardDetails, setCardDetails] = useState([]);
    const [message, setMessage] = useState(AppConstants.emptyString);
    const [modalTypeError, setModalTypeError] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [paymentParam, setPaymentParam] = useState(searchParams.get(AppConstants.paymentUrlParam));
    const [paramData, setParamData] = useState({});
    const [paymentStatus, setPaymentStatus] = useState(AppConstants.emptyString);
    const [transactionId, setTransactionId] = useState(AppConstants.emptyString);
    const learningActivitiesCardsCount = AppConstants.cardCounts;
    const [showConfirmationModal, setShowConfirmationModal] = useState(AppConstants.falseText);
    const [confirmationPopupActivityDetails, setConfirmationPopupActivityDetails] = useState({});
    const [showOfflinePaymentModal, setShowOfflinePaymentModal] = useState(AppConstants.falseText);
    const [displayNetworkError, setDisplayNetworkError] = useState(AppConstants.falseText);
    const [paymentInitiateResponse, setPaymentInitiateResponse] = useState({});
    const [shouldWarn, setShouldWarn] = useState(false);
    const { enrolInLearningActivity } = useEnrolment();
    useWarnOnLeave(shouldWarn);

    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const defaultCompetitionThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.competitionDefaultThumbnailImage}`;

    const handleResize = () => {
        const viewportWidth = window.innerWidth;
        if (viewportWidth >= 992) {
            setCardCount(learningActivitiesCardsCount.learningActivityDesktopCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.learningActivityDesktopCount);
        }
        if (viewportWidth >= 576 && viewportWidth <= 991) {
            setCardCount(learningActivitiesCardsCount.learningActivityTabCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.learningActivityTabCount);
        }
        if (viewportWidth <= 575) {
            setCardCount(learningActivitiesCardsCount.learningActivityMobileCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.learningActivityMobileCount);
        }
    };

    //call handleResize() on screen width change
    useEffect(() => {
        handleResize();
        window.addEventListener('orientationchange', handleResize);
        return () => {
            window.removeEventListener('orientationchange', handleResize);
        };
    }, []);

    useEffect(() => {
        setCardDetails(props.cardDetails);
    }, [props.cardDetails]);


    // verify method for phonepe payments
    useEffect(() => {
        const verifyPayment = async (data, competition) => {
            try {
                if (competition && competition?.uniqueId) {
                    PaymentsUtil.verifyPayment(data).then((response) => {
                        if (response.verificationStatus === AppConstants.paymentStatus.verified) {
                            setModalTypeError(AppConstants.falseText);
                            setPaymentStatus(AppConstants.paymentStatus.verified);
                            setTransactionId(response.transactionId);
                            enrolInCompetition(competition.competitionId, competition.title, competition.uniqueId, AppConstants.paymentStatus.verified);
                        } else {
                            setModalTypeError(AppConstants.trueText);
                            setMessage(response.message);
                            setTransactionId(response.transactionId);
                            setPaymentStatus(AppConstants.emptyString);
                            handleModalShow();
                        }
                    }).catch((error) => {
                        setModalTypeError(AppConstants.trueText);
                        setMessage(ErrorMessages.verifyPaymentFailedError);
                        setPaymentStatus(AppConstants.emptyString);
                        handleModalShow();
                    });
                }
            } catch (error) {
                setPaymentStatus(AppConstants.emptyString);
            }
        }

        const decodeBase64EncodedString = (encodedString) => {
            try {
                return JSON.parse(window.atob(encodedString));
            } catch (error) {
                return null;
            }
        };

        if (paymentParam && cardDetails.length > 0) {
            const data = decodeBase64EncodedString(paymentParam);
            if (data !== null && data.category === props.category) {
                const competition = cardDetails.filter(card => card.uniqueId === data.uniqueId)[0];
                if (!competition) {
                    setParamData({});
                    setPaymentStatus(AppConstants.emptyString);
                    return;
                }
                setConfirmationPopupActivityDetails({ id: competition.competitionId, title: competition.title, uniqueId: competition.uniqueId, startTime: competition.startDateTimeUTC, payOfflineFlag: competition.payOfflineFlag, creatorName: competition.organizedBy, creatorRole: competition.userRole });
                data.learningActivityName = competition.title;
                setParamData(data);
                setPaymentStatus(AppConstants.paymentStatus.pending);

                const timer = setTimeout(() => {
                    verifyPayment(data, competition);
                }, AppConstants.paymentVerificationTimeout);

                return () => clearTimeout(timer);
            }
        };
    }, [paymentParam, cardDetails]);


    const confirmBeforeEnrolment = ({ id, title, uniqueId, startTime, payOfflineFlag, creatorName, creatorRole }) => {
        const startDateTime = DateTime.fromISO(startTime?.replace(' ', 'T') + 'Z').setZone(timeZone);
        const currentDateTime = DateTime.local().setZone(timeZone);
        setConfirmationPopupActivityDetails({ id, title, uniqueId, type: AppConstants.learningActivitiesTypes.competition, payOfflineFlag, creatorName, creatorRole });
        // check if the learning activity has started.
        if (currentDateTime > startDateTime) {
            // show confirmation modal to the user.
            setShowConfirmationModal(AppConstants.trueText);
        } else {
            if (payOfflineFlag === AppConstants.yText) {
                setShowOfflinePaymentModal(AppConstants.trueText);
            } else {
                enrolInCompetition(id, title, uniqueId);
            }
        }
    };

    const updateConfirmationStatus = (status) => {
        setShowConfirmationModal(AppConstants.falseText);
        // if user selects yes -> 
        if (status) {
            if (confirmationPopupActivityDetails.payOfflineFlag === AppConstants.yText) {
                setShowOfflinePaymentModal(AppConstants.trueText);
            } else {
                enrolInCompetition(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
            }
        } else {
            setConfirmationPopupActivityDetails({});
        }
    };

    const getLearnerPaymentMethod = (paymentType) => {
        setShowOfflinePaymentModal(AppConstants.falseText);
        if (paymentType === AppConstants.paymentMethod.online) {
            enrolInCompetition(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
        } else {
            setConfirmationPopupActivityDetails({});
        }
    };

    const enrolInCompetition = async (competitionId, competitionTitle, uniqueId, status) => {
        const cost = PaymentsUtil.calculateCost({ cost: cardDetails.filter(card => card.uniqueId === uniqueId)[0].cost, discount: 0 });
        let response = await enrolInLearningActivity({ uniqueId, cost, learningActivityName: competitionTitle, learningActivityId: competitionId, learningActivityType: AppConstants.learningActivitiesTypes.competition }, status);

        setModalTypeError(response.modalTypeError);
        setPaymentStatus(response.paymentStatus);
        setMessage(response.message);
        setShowModal(response.showModal);
        setDisplayNetworkError(response.networkError);
        setShouldWarn(response.shouldWarn);
        if (response.showRazorpayPopup) {
            setTimeout(() => {
                showRazorpayPopup(response);
            }, AppConstants.paymentGatewayRedirectTimeout);
        }
    };

    const onClickRetry = () => {
        setPaymentStatus(AppConstants.paymentStatus.pending);
        enrolInCompetition(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
        PaymentsUtil.verifyRazorpayPaymentStatus(paymentInitiateResponse.response, paymentInitiateResponse.learningActivityDetails);
    }

    const showRazorpayPopup = ({ options, learningActivityDetails }) => {
        setShowModal(AppConstants.falseText);
        const rzp1 = new window.Razorpay({
            ...options,
            handler: async (response) => {
                setShouldWarn(AppConstants.trueText);
                setShowModal(AppConstants.trueText);
                setTransactionId(response.razorpay_payment_id);
                setPaymentStatus(AppConstants.paymentStatus.pending);
                setPaymentInitiateResponse({ response, learningActivityDetails });
                setTimeout(async () => {
                    enrolInCompetition(learningActivityDetails.learningActivityId, learningActivityDetails.learningActivityName, learningActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
                    PaymentsUtil.verifyRazorpayPaymentStatus(response, learningActivityDetails);
                }, AppConstants.enrolmentDelayTimeout);
            }
        });

        rzp1.on(AppConstants.razorpaypaymentFailed, function (response) {
            PaymentsUtil.handleRazorpayPaymentFailure(response, learningActivityDetails);
        });

        rzp1.open();
    };

    const handleModalClose = () => {
        setShowModal(AppConstants.falseText);
        setTransactionId(AppConstants.emptyString);
        if (paymentParam) {
            window.location.replace(window.location.href.split(AppConstants.questionMark)[0]);
        } else if (transactionId) {
            window.location.reload();
        }
    }
    const handleModalShow = () => {
        setShowModal(AppConstants.trueText);
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleCardClick = (url) => {
        window.open(url, AppConstants.newPageTargetSelf);
    };

    return (
        <>
            <Row xs={1} sm={2} md={2} lg={3} xl={3} className="cardSection">
                {cardDetails.slice(0, cardCount).map((cardData, index) => (
                    <Col key={cardData.uniqueId}>
                        <Card className="cardDiv" onClick={() => handleCardClick(`${AppConstants.forwardSlash}${AppConstants.competitionsText}${AppConstants.forwardSlash}${cardData.categorySlug}${AppConstants.forwardSlash}${cardData.skillSlug}${AppConstants.forwardSlash}${cardData.competionSlug}`)}>
                            <Card.Img variant="top" className="cardImage" src={cardData.thumbnailImagePath ? cardData.thumbnailImagePath : defaultCompetitionThumbnail} />
                            <Card.Body className="cardBody">
                                <div className="subTitleContainer">
                                    <div className="cardSubTitle competitionDiffColor">{cardData.minimumAge !== 0 && cardData.maximumAge !== 0 && "AGE " + cardData.minimumAge + "-" + cardData.maximumAge} {cardData.minimumAge === 0 && cardData.maximumAge === 0 && " ALL AGES"} {cardData.minimumAge !== 0 && cardData.maximumAge === 0 && "AGE " + cardData.minimumAge + "+"} {cardData.minimumAge === 0 && cardData.maximumAge !== 0 && "AGE " + props.minimumAgeLimit + "-" + cardData.maximumAge} <span className="seperator">{AppConstants.verticalBar}</span> {(cardData.type.toUpperCase() === AppConstants.physicalText || cardData.type.toUpperCase() === AppConstants.offlineText) ? <span onClick={stopPropagation} className="modeSubtitle" data-tooltip-place="top" data-tooltip-id={cardData.uniqueId}><a href={cardData.geoLocation} target={AppConstants.openInNewTab} className="workshopLink onlineIconCompetition"><CgPin className="geoLocationIcon onlineIconCompetition" /> {cardData.type.toUpperCase()}</a></span> : <span className="onlineIconCompetition modeSubtitle">{cardData.type.toUpperCase()}</span>}</div>
                                    <Tooltip className="tooltipMessage" id={cardData.uniqueId}><span className="tooltipMessage">{cardData.venueLocation}</span></Tooltip>
                                </div>
                                <Card.Text className="cardTitle titleFixedHeight">
                                    <Link className="cardLink" data-tooltip-id={`${cardData.uniqueId}-title`}>{cardData.title}</Link>
                                    <Tooltip className="courseTitleTooltip" id={`${cardData.uniqueId}-title`}><span>{cardData.title}</span></Tooltip>
                                </Card.Text>
                                <Card.Text onClick={e => { e.stopPropagation(); window.open(`${process.env.REACT_APP_FRONT_END_URL}${cardData.userRole === AppConstants.userRoleExpert ? AppConstants.expert : AppConstants.agency}${AppConstants.forwardSlash}${cardData.userSlug}`) }} className="cardTimeAndDate">By : {cardData.organizedBy}</Card.Text>
                                <div className="cardTimeAndDate">
                                    <div><BiCalendarCheck className="calendarIcon" />&nbsp;</div>
                                    <div className="dateTimeText">
                                        Start:&nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{cardData.startDateTimeUTC.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{cardData.startDateTimeUTC.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${timeZone})`}</span>
                                    </div>
                                </div>
                                <div className="cardTimeAndDate">
                                    <div><BiCalendarX className="calendarIcon" />&nbsp;</div>
                                    <div className="dateTimeText">
                                        End:&nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{cardData.endDateTimeUTC.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{cardData.endDateTimeUTC.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${timeZone})`}</span>
                                    </div>
                                </div>
                                {cardData.disablePublicEnrolmentFlag === AppConstants.nText && < div className="buttonSection">
                                    <div className="learningActivityCostInfo"><BiWallet /> {PaymentsUtil.calculateCost({ cost: cardData.cost, discount: 0 })}</div>
                                    <Button type="button" className="cardButton" onClick={(e) => { stopPropagation(e); confirmBeforeEnrolment({ id: cardData.competitionId, title: cardData.title, uniqueId: cardData.uniqueId, startTime: cardData.startDateTimeUTC, payOfflineFlag: cardData.payOfflineFlag, creatorName: cardData.organizedBy, creatorRole: cardData.userRole }) }}>Enrol Now</Button>
                                </div>}
                                {cardData.disablePublicEnrolmentFlag === AppConstants.yText && <div className="buttonSection">
                                    <div className="learningActivityCostInfo"><BiWallet /> {PaymentsUtil.calculateCost({ cost: cardData.cost, discount: 0 })}</div>
                                    <span data-tooltip-id={`${cardData.learningActivitySlug}-private`}><RiGitRepositoryPrivateFill /></span>
                                    <Tooltip className="tooltipMessagePrivate" id={`${cardData.learningActivitySlug}-private`}><span>This is a private competition. Public enrolments are disabled for this competition</span></Tooltip>
                                </div>}
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row >
            <Row xs={1} sm={1} md={1} lg={1} xl={1} className="loadMore">
                <Col className="loadMoreButtonSection">
                    {cardCount < cardDetails.length && <Button className="loadMoreButton" onClick={() => setCardCount(cardCount + viewMoreCardsCount)}>Load more</Button>}
                </Col>
            </Row>
            {(message !== AppConstants.emptyString || paramData.category === props.category) && (showModal || paramData.category === props.category) && <EnrolmentModal onShow={handleModalShow} message={message} modalTypeError={modalTypeError} onClose={handleModalClose} paymentStatus={paymentStatus} transactionId={transactionId} enrolmentDetails={confirmationPopupActivityDetails} displayNetworkError={displayNetworkError} onClickRetry={onClickRetry}></EnrolmentModal>}
            {showConfirmationModal && <PostStartEnrolmentModal updateConfirmationStatus={updateConfirmationStatus} learningActivityDetails={confirmationPopupActivityDetails} />}
            {showOfflinePaymentModal && <PayOfflineConfirmationModal getLearnerPaymentMethod={getLearnerPaymentMethod} learningActivityDetails={confirmationPopupActivityDetails} />}
        </>
    )
};