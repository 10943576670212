import React, { useCallback, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { ExpertMicrositeLayout } from "../../component/expertMicrosite/ExpertMicrositeLayout";
import { LandingPageFooter } from '../../layout/LandingPageLayout/LandingPageFooter';
import { AppConstants } from "../../constants/AppConstants";
import { BrandingPageTopNavigation } from "../../component/expertMicrosite/BrandingPageTopNavigation";
export const ExpertMicrositePage = () => {
    const landingPageData = useLoaderData();

    const [topNavigationItems, setTopNavigationItems] = useState({
        competitions: AppConstants.falseText,
        courses: AppConstants.falseText,
        workshops: AppConstants.falseText,
        reviews: AppConstants.falseText
    });

    const updateTopNavigationItems = useCallback((updatedValue) => {
        setTopNavigationItems({ ...updatedValue });
    }, []);

    return (
        <>
            <BrandingPageTopNavigation landingPageData={landingPageData} topNavigationItems={topNavigationItems} />
            <ExpertMicrositeLayout updateTopNavigationItems={updateTopNavigationItems} />
            <LandingPageFooter landingPageData={landingPageData} />
        </>
    );

}
