import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { AppConstants } from "../../constants/AppConstants";
import { LandingPageTopNavigation } from "../../layout/LandingPageLayout/LandingPageTopNavigation";
import Auth from "../../utils/Auth";
import classes from "./ShareCourseFeedback.module.css";
import { useEffect, useState } from "react";
import { QuizAuthenticationForm } from "../quizEventComponent/QuizAuthenticationForm";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import { BiLoaderAlt } from "react-icons/bi";
import { Logout } from "../../common/Logout";
import { LandingPageFooter } from "../../layout/LandingPageLayout/LandingPageFooter";
import { LearningActivityListDropdown } from "../learningActivityListDropdown/LearningActivityListDropdown";
import { StringUtils } from "../../utils/StringUtils";
import { FeedbackPageLearnerCards } from "../cards/feedbackPageLearnerCards/FeedbackPageLearnerCards";
import { Button, Col, Row } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { CourseFeedbackModal } from "../modal/CourseFeedbackModal";
import { SubmitFeedbackTextEditor } from "./SubmitFeedbackTextEditor";
import { ErrorMessages } from "../../constants/ErrorMessages";
import { ErrorSuccessAlertMessage } from "../errorSuccessMessage/ErrorSuccessAlertMessage";

export const ShareCourseFeedback = () => {
    const [landingPageData] = useLoaderData();
    const [showPopupForLogin, setShowPopupForLogin] = useState(AppConstants.falseText);
    const [learningActivityList, setLearningActivityList] = useState([]);
    const [logoutMessage, setLogoutMessage] = useState(AppConstants.falseText);
    const [pageLoaderSpinner, setPageLoaderSpinner] = useState(AppConstants.trueText);
    const [feedbackModalLearnerDetails, setFeedbackModalLearnerDetails] = useState(AppConstants.emptyString);
    const [learnersList, setLearnersList] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState({});
    const [errorMessage, setErrorMessage] = useState(AppConstants.emptyString);
    const [specificSelectedCourse, setSpecificSelectedCourse] = useState(AppConstants.emptyString);
    const navigate = useNavigate();
    const { courseUniqueId } = useParams();

    useEffect(() => {
        const fetchCoursesForFeedback = async () => {
            const url = AppConstants.getActiveCoursesForFeedbackAPI;
            const response = await AuthorizedDataService.getRequestWithResponseCode(url, AppConstants.emptyString, AppConstants.emptyString);

            if (response.status === AppConstants.httpResponseCodes.responseCode403) {
                setPageLoaderSpinner(AppConstants.falseText);
                setLogoutMessage(AppConstants.trueText);
                setLearningActivityList([]);
            } else if (response.status === AppConstants.httpResponseCodes.responseCode500) {
                setLearningActivityList([]);
                setPageLoaderSpinner(AppConstants.falseText);
            } else {
                let allLearningActivities = response.filter(resp => (resp.eventStatus !== AppConstants.courseStatus.upcoming));
                if (courseUniqueId) {
                    allLearningActivities.filter(resp => resp.learningActivityUniqueId === courseUniqueId).map(resp => {
                        let data = {
                            key: `${resp.learningActivityId} | ${resp.learningActivityTitle}`,
                            learningActivityTitle: resp.learningActivityTitle,
                            learningActivityUniqueId: resp.learningActivityUniqueId,
                            learningActivityId: resp.learningActivityId
                        }
                        setSpecificSelectedCourse(data);
                        return data;
                    })
                } else {
                    allLearningActivities.map(resp => {
                        let data = {
                            key: `${resp.learningActivityId} | ${resp.learningActivityTitle}`,
                            learningActivityTitle: resp.learningActivityTitle,
                            learningActivityUniqueId: resp.learningActivityUniqueId,
                            learningActivityId: resp.learningActivityId
                        }

                        return data;
                    })
                }
                setLearningActivityList(response.filter(resp => (resp.eventStatus !== AppConstants.courseStatus.upcoming)).map(resp => {
                    let data = {
                        key: `${resp.learningActivityId} | ${resp.learningActivityTitle}`,
                        learningActivityTitle: resp.learningActivityTitle,
                        learningActivityUniqueId: resp.learningActivityUniqueId,
                        learningActivityId: resp.learningActivityId
                    }
                    if (resp.learningActivityUniqueId === courseUniqueId) {
                        setSpecificSelectedCourse(data);
                    }
                    return data;
                }));

                setPageLoaderSpinner(AppConstants.falseText);
            }
        };

        if (Auth.isLogin()) {
            if (Auth.getUserRole() !== AppConstants.userRoleExpert && Auth.getUserRole() !== AppConstants.userRoleAgency) {
                navigate(AppConstants.asterisk);
                setShowPopupForLogin(AppConstants.falseText);
            } else {
                fetchCoursesForFeedback();
            }
        } else {
            setShowPopupForLogin(AppConstants.trueText);
        }
    }, [navigate]);

    const closeAuthenticationForm = () => {
        window.location.reload();
    };

    const handleSelect = async (value) => {
        if (courseUniqueId && value.length === 0) {
            window.location.href = `${process.env.REACT_APP_FRONT_END_URL}course/share-feedback`;
        } else {
            const params = {
                learningActivityUniqueId: value.learningActivityUniqueId,
                learningActivityType: AppConstants.learningActivitiesTypes.course
            }

            let url = StringUtils.createURLWithParams(AppConstants.fetchEnrolledLearnerRecordsAPI, params);
            let response = await AuthorizedDataService.getRequest(url, AppConstants.emptyString, AppConstants.emptyString);

            if (response) {
                setLearnersList(response);
                setSelectedCourse(value);
            } else {
                setErrorMessage(ErrorMessages.feedbackStudentsFetchError);
                let timer = setTimeout(() => {
                    setErrorMessage(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        }
    };

    const openFeedbackPopup = (learnerDetails) => {
        setFeedbackModalLearnerDetails([{ ...learnerDetails }])
    };

    const learnerColumnDefs = [{
        headerName: "Learner Id",
        field: "userQrenciaId",
        maxWidth: 100,
        tooltipValueGetter: ({ data }) => data.userQrenciaId,
        valueGetter: ({ data }) => {
            return data.userQrenciaId
        },
        filter: 'agTextColumnFilter'
    },
    {
        headerName: "Name",
        field: "studentName",
        width: 300,
        tooltipValueGetter: ({ data }) => data.studentName,
        valueGetter: ({ data }) => {
            return data.studentName
        },
        filter: 'agTextColumnFilter'
    },
    {
        headerName: "Phone",
        field: "mobileNumberWithCallingCode",
        tooltipField: "mobileNumberWithCallingCode",
        filter: 'agTextColumnFilter',
        tooltipValueGetter: ({ data }) => data.mobileNumberWithCallingCode,
        valueGetter: ({ data }) => {
            return data.mobileNumberWithCallingCode;
        }
    },
    {
        headerName: "Action",
        field: "action",
        cellRendererFramework: (params) => <div className={classes.obrGridActionBox}>
            <Button onClick={() => openFeedbackPopup(params.data)} className={`${classes.actionButton} ${params.data.attendance === "Present" && classes.presentButton}`} >Feedback</Button>
        </div>,
        minWidth: 200,
    }];

    const closeFeedbackModal = () => {
        setFeedbackModalLearnerDetails(AppConstants.emptyString);
    }

    return (
        <div>
            {Auth.isLogin() && <LandingPageTopNavigation landingPageData={landingPageData} />}
            {showPopupForLogin ?
                <div className={classes.loginSection}>
                    <QuizAuthenticationForm disableSignup={AppConstants.trueText} closeAuthenticationForm={closeAuthenticationForm} signupHeading="Signup" loginHeading="Login" />
                </div>
                : (pageLoaderSpinner || logoutMessage) ? <div className={classes.loaderContainer}>
                    {pageLoaderSpinner && <p className={classes.loaderIcon}><BiLoaderAlt className="spinner" /></p>}
                    {logoutMessage && <p className={classes.sessionExpiredText}>Your Session has been expired. Please <span onClick={Logout.logout}>login</span> to continue.</p>}
                </div> : <>
                    <div className={classes.pageContainer}>
                        <Row className="mb-4 mt-2">
                            <h1 className={classes.heading}>Give Feedback</h1>
                            <LearningActivityListDropdown selectedLearningActivity={specificSelectedCourse} placeholder="Search Course" labelKey="key" label="Course" learningActivityList={learningActivityList} handleSelect={handleSelect} filterByKeys={['learningActivityId', 'learningActivityTitle']} />
                            {errorMessage && <ErrorSuccessAlertMessage message={errorMessage} varient={AppConstants.alertVarient[1]} />}
                        </Row>
                        <div className={classes.tableContainer}>
                            {
                                (learnersList?.length > 0 && selectedCourse.key) ? <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <div className="ag-theme-alpine">
                                            <AgGridReact
                                                columnDefs={learnerColumnDefs}
                                                rowData={learnersList}
                                                pagination={true}
                                                paginationPageSize={20}
                                                enableBrowserTooltips={true}
                                                domLayout='autoHeight'
                                                suppressRowHoverHighlight={true}
                                                defaultColDef={{
                                                    filter: true,
                                                    sortable: true,
                                                    flex: 1,
                                                    minWidth: 100,
                                                    resizable: true,
                                                    suppressMenu: true,
                                                    suppressHorizontalScroll: true,
                                                    floatingFilterComponentParams: { suppressFilterButton: true },
                                                    floatingFilter: true,
                                                }}
                                                enableFilter={true}
                                                enableSorting={true}
                                                enableSearching={true}
                                                headerClass={classes.obrGridHeader}
                                                rowClass={classes.obrGridTable}
                                            />
                                        </div>
                                    </Col>
                                </Row> : <p className={`noDataAvailableError ${classes.errorBox}`}>{selectedCourse.key ? ErrorMessages.noLearnersForFeedbackMessage : ErrorMessages.selectCourseForFeedbackMessage}</p>
                            }
                        </div>
                        <div className={classes.studentCardContainer}>
                            {
                                (learnersList?.length > 0 && selectedCourse.key) ? <Row>
                                    {learnersList.map(learner => (
                                        <div key={learner.uniqueId}>
                                            <FeedbackPageLearnerCards learnerDetails={learner} openFeedbackPopup={openFeedbackPopup} />
                                        </div>
                                    ))}
                                </Row> : <p className={`noDataAvailableError ${classes.errorBox}`}>{selectedCourse.key ? ErrorMessages.noLearnersForFeedbackMessage : ErrorMessages.selectCourseForFeedbackMessage}</p>
                            }
                        </div>
                        {feedbackModalLearnerDetails && <CourseFeedbackModal modalHeading={`Write feedback for ${feedbackModalLearnerDetails[0].studentName}`} learningActivityDetails={selectedCourse} closeFeedbackModal={closeFeedbackModal} learnerDetails={feedbackModalLearnerDetails} feedbackHistoryHeading="Previous Feedback" />}
                        {learnersList?.length > 0 && selectedCourse.key && <>
                            <Row>
                                <h1 className={classes.postFeedbackHeading}>Give Feedback to all Learners</h1>
                            </Row>
                            <SubmitFeedbackTextEditor learnerDetails={learnersList?.filter(learner => learner.subscriptionStatus === AppConstants.subscriptionStatus.active)} placeholder="Type Your Feedback" learningActivityDetails={selectedCourse} />
                        </>}


                    </div>
                    <LandingPageFooter landingPageData={landingPageData} />
                </>}
        </div>
    )
}