import { useState, useEffect } from "react";
import { AppConstants } from "../../constants/AppConstants";
import { FaEdit } from "react-icons/fa";
import { TbShare3 } from 'react-icons/tb';
import { BiCheckCircle, BiLoaderAlt } from "react-icons/bi";
import { RxDotFilled } from "react-icons/rx";
import { BsLinkedin, BsFacebook, BsFillEnvelopeAtFill, BsFillCheckCircleFill } from 'react-icons/bs';
import { AiOutlineCopy, AiOutlineExclamationCircle } from 'react-icons/ai';
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';
import { Row, Col, Button } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { ErrorMessages } from "../../constants/ErrorMessages";
import { SuccessMessage } from "../../constants/SuccessMessage";
import { ErrorSuccessAlertMessage } from "../errorSuccessMessage/ErrorSuccessAlertMessage";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import { FileOperationsUtil } from "../../utils/FileOperationsUtil";
import { MathUtil } from "../../utils/MathUtil";
import classes from "./viewCompetitionDetailsAccordion.module.css"
import './viewCompetitionDetailsAccordion.css';
import { ExpertViewCompetitionsResult } from "./expertViewCompetitionResult";
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import Moment from 'react-moment';
import parse from 'html-react-parser';
import 'moment-timezone';
import { Tooltip } from "react-tooltip";
import { ViewJudgeCard } from "../cards/viewJudgesCard/ViewJudgeCard";
import { RiTwitterXLine } from "react-icons/ri";
import { StudentQueryCard } from "../cards/studentQueryCards/StudentQueryCard";
import { PaymentsUtil } from "../../utils/PaymentsUtil";
import { DataService } from "../../services/DataService";

export const ViewCompetitionDetailsAccordion = (props) => {
    const [selectedCompetitionData, setSelectedCompetitionData] = useState(props.selectedCompData);
    const [showSocialMediaPannel, setShowSocialMediaPannel] = useState(AppConstants.falseText);
    const [showLinkCopied, setShowLinkCopied] = useState(AppConstants.falseText);
    const [linkToShare, setLinkToShare] = useState(AppConstants.emptyString);
    const [resultDetails, setResultDetails] = useState([]);
    const [rubricsSpinner, setRubricsSpinner] = useState(AppConstants.falseText);
    const [certificateTemplateSpinner, setCertificateTemplateSpinner] = useState(AppConstants.falseText);
    const [completeCompetitionSpinner, setCompleteCompetitionSpinner] = useState(AppConstants.falseText);
    const [completeCompetitionError, setCompleteCompetitionError] = useState(AppConstants.falseText);
    const [completeCompetitionMessage, setCompleteCompetitionMessage] = useState(AppConstants.emptyString);
    const [completeCompetitionErrorVarient, setCompleteCompetitionErrorVarient] = useState(AppConstants.emptyString);
    const [basicDetailsError, setBasicDetailsError] = useState(AppConstants.falseText);
    const [basicDetailsErrorMessage, setBasicDetailsErrorMessage] = useState(AppConstants.emptyString);
    const [participantsDetailsError, setParticipantsDetailsError] = useState(AppConstants.falseText);
    const [participantsDetailsErrorMessage, setParticipantsDetailsErrorMessage] = useState(AppConstants.emptyString);
    const [downloadParticipantsSpinner, setDownloadParticipantsSpinner] = useState(AppConstants.falseText);
    const [showModal, setShowModal] = useState(AppConstants.falseText);
    const [modalData, setModalData] = useState(AppConstants.nullText);
    const [enableJudgingSpinner, setEnableJudgingSpinner] = useState(AppConstants.falseText);
    const [enableJudgingError, setEnableJudgingError] = useState(AppConstants.falseText);
    const [enableJudgingErrorVarient, setEnableJudgingErrorVarient] = useState(AppConstants.emptyString);
    const [enableJudgingErrorMessage, setEnableJudgingErrorMessage] = useState(AppConstants.falseText);
    const [timeZone, setTimeZone] = useState(DateTimeUtil.getPreferredTimeZoneForUser());
    const [sendLinkSpinner, setSendLinkSpinner] = useState(AppConstants.falseText);
    const [sendIndividualJudgingLinkError, setSendIndividualJudgingLinkError] = useState(false);
    const [sendIndividualJudgingLinkMessage, setSendIndividualJudgingLinkMessage] = useState(AppConstants.emptyString);
    const [sendIndividualJudgingLinkVarient, setSendIndividualJudgingLinkVarient] = useState(AppConstants.emptyString);
    const [completeCompetitionErrorMessage, setCompleteCompetitionErrorMessage] = useState(AppConstants.emptyString);
    const [respondedQueries, setRespondedQueries] = useState([]);
    const [participationSummarySpinner, setParticipationSummarySpinner] = useState(AppConstants.falseText);
    const [openQueries, setOpenQueries] = useState([]);
    const [signupMinimumAge, setSignupMinimumAge] = useState(AppConstants.emptyString);
    const [fetchQueryFlag, setFetchQueryFlag] = useState(0);

    useEffect(() => {
        const timer = setTimeout(() => {
            setSendIndividualJudgingLinkError(false);
            setSendIndividualJudgingLinkMessage(AppConstants.emptyString);
            setSendIndividualJudgingLinkVarient(AppConstants.emptyString);
        }, AppConstants.messageDisappearTime);

        return () => clearTimeout(timer);
    }, [sendIndividualJudgingLinkError]);

    useEffect(() => {
        async function getResultData() {
            const url = `${AppConstants.getCompetitionResultAPIWithParameter}${props.selectedCompData.id}&competitionUniqueId=${props.selectedCompData.uniqueId}`;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response !== undefined) {
                setResultDetails(response);
            } else {
                setResultDetails([]);
            }
        }
        const fetchConfigurableKeys = async () => {
            const url = AppConstants.fetchConfigurableKeysAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setSignupMinimumAge(parseInt(response.find(item => item.keyName === AppConstants.signupMinimumAgeKey).keyValue));
            }
        };

        fetchConfigurableKeys();
        getResultData();
    }, [props.selectedCompData]);

    useEffect(() => {
        const fetchStudentQueries = async () => {
            const url = `${AppConstants.getStudentQueriesAPI}${selectedCompetitionData.id}&learningActivityType=${AppConstants.learningActivitiesTypes.competition}`;

            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response !== undefined) {
                setRespondedQueries(response.filter(query => query.respondedOn !== null));
                setOpenQueries(response.filter(query => query.respondedOn === null));
            } else {
                setRespondedQueries([]);
                setOpenQueries([]);
            }
        };
        if (selectedCompetitionData.id) {
            fetchStudentQueries();
        }
    }, [selectedCompetitionData, fetchQueryFlag]);

    const updateFetchDataFlag = () => {
        setFetchQueryFlag(fetchQueryFlag + 1);
    };

    //Share on social media 
    function shareOnSocialMedia(shareData) {
        setShowSocialMediaPannel(!showSocialMediaPannel);
        setLinkToShare(`${process.env.REACT_APP_FRONT_END_URL}${AppConstants.competitionsText}${AppConstants.forwardSlash}${shareData.categorySlug}${AppConstants.forwardSlash}${shareData.skillSlug}${AppConstants.forwardSlash}${shareData.competionSlug}`);
    }
    //function to copy to clipboard
    function copyToClipBoard(event) {
        event.preventDefault();
        navigator.clipboard.writeText(linkToShare);
        setShowLinkCopied(AppConstants.trueText);
        var timer = setTimeout(() => {
            setShowLinkCopied(AppConstants.falseText);
        }, 3000);
    }
    //function Send on Mail
    function sendOnMail() {
        window.open(`${AppConstants.mailToURI}` + `${linkToShare}`, AppConstants.openInNewTab);
    }
    //Function to Share on facebook
    function shareOnFacebook() {
        window.open(`${AppConstants.facebookShareURI}` + `${linkToShare}`, AppConstants.openInNewTab);
    }
    //Function to share on Linkedin
    function shareOnLinkedIn() {
        window.open(`${AppConstants.linkedInShareURI}` + `${encodeURIComponent(linkToShare)}`, AppConstants.openInNewTab);
    }
    //function to share on twitter
    function shareOnTwitter() {
        window.open(`${AppConstants.twitterShareURI}` + `${linkToShare}`, AppConstants.openInNewTab);
    }

    //download rubrics
    async function downloadRubrics(competitionData) {
        setRubricsSpinner(AppConstants.trueText);
        const url = `${AppConstants.downloadRubricsWithParameterAPI}${competitionData.uniqueId}`;
        const name = `${competitionData.title}${AppConstants.hyphen}${AppConstants.rubricsFileName}`
        const fileName = name.replace(/\s+/g, AppConstants.hyphen);
        const response = await FileOperationsUtil.downloadFile(
            url,
            fileName,
            AppConstants.emptyString,
            AppConstants.emptyString
        );
        if (response !== undefined) {
            if (response.ok) {
                setRubricsSpinner(AppConstants.falseText);
            } else {
                setRubricsSpinner(AppConstants.falseText);
                setBasicDetailsError(AppConstants.trueText);
                setBasicDetailsErrorMessage(`${ErrorMessages.fileDownloadError}${AppConstants.rubricsText}${ErrorMessages.fileDownloadErrorPartTwo}`);
                let timer = setTimeout(() => {
                    setBasicDetailsError(AppConstants.falseText);
                    setBasicDetailsErrorMessage(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } else {
            setRubricsSpinner(AppConstants.falseText);
            setBasicDetailsError(AppConstants.trueText);
            setBasicDetailsErrorMessage(`${ErrorMessages.fileDownloadError}${AppConstants.rubricsText}${ErrorMessages.fileDownloadErrorPartTwo}`);
            let timer = setTimeout(() => {
                setBasicDetailsError(AppConstants.falseText);
                setBasicDetailsErrorMessage(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    }

    //download certificate template
    async function downloadCertificateTemplate(competitionData) {
        setCertificateTemplateSpinner(AppConstants.trueText);
        const url = `${AppConstants.downloadCertificateTemplateWithParamAPI}${competitionData.uniqueId}`;
        const name = `${competitionData.title}${AppConstants.hyphen}${AppConstants.certificateFileNameWithPDFExtension}`
        const fileName = name.replace(/\s+/g, AppConstants.hyphen);
        const response = await FileOperationsUtil.downloadFile(
            url,
            fileName,
            AppConstants.emptyString,
            AppConstants.emptyString
        );
        if (response !== undefined) {
            if (response.ok) {
                setCertificateTemplateSpinner(AppConstants.falseText);
            } else {
                setCertificateTemplateSpinner(AppConstants.falseText);
                setBasicDetailsError(AppConstants.trueText);
                setBasicDetailsErrorMessage(`${ErrorMessages.fileDownloadError}${AppConstants.certificateTest}${ErrorMessages.fileDownloadErrorPartTwo}`);
                let timer = setTimeout(() => {
                    setBasicDetailsError(AppConstants.falseText);
                    setBasicDetailsErrorMessage(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } else {
            setCertificateTemplateSpinner(AppConstants.falseText);
            setBasicDetailsError(AppConstants.trueText);
            setBasicDetailsErrorMessage(`${ErrorMessages.fileDownloadError}${AppConstants.certificateTest}${ErrorMessages.fileDownloadErrorPartTwo}`);
            let timer = setTimeout(() => {
                setBasicDetailsError(AppConstants.falseText);
                setBasicDetailsErrorMessage(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    }

    //download registered candidates
    async function downloadRegisteredCandidates(competitionData) {
        setDownloadParticipantsSpinner(AppConstants.trueText);
        const url = `${AppConstants.downloadParticipantsWithParameterAPI}${competitionData.id}`;
        const name = `${competitionData.title}${AppConstants.hyphen}${AppConstants.registeredParticipantsFileText}`
        const fileName = name.replace(/\s+/g, AppConstants.hyphen);
        const response = await FileOperationsUtil.downloadFile(
            url,
            fileName,
            AppConstants.emptyString,
            AppConstants.emptyString,
            { preferredTimezone: DateTimeUtil.getPreferredTimeZoneForUser() }
        );
        if (response !== undefined) {
            if (response.ok) {
                setDownloadParticipantsSpinner(AppConstants.falseText);
            } else {
                setDownloadParticipantsSpinner(AppConstants.falseText);
                setParticipantsDetailsError(AppConstants.trueText);
                setParticipantsDetailsErrorMessage(`${ErrorMessages.fileDownloadError}${AppConstants.participantsListText}${ErrorMessages.fileDownloadErrorPartTwo}`);
                let timer = setTimeout(() => {
                    setBasicDetailsError(AppConstants.falseText);
                    setBasicDetailsErrorMessage(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } else {
            setDownloadParticipantsSpinner(AppConstants.falseText);
            setParticipantsDetailsError(AppConstants.trueText);
            setParticipantsDetailsErrorMessage(`${ErrorMessages.fileDownloadError}${AppConstants.participantsListText}${ErrorMessages.fileDownloadErrorPartTwo}`);
            let timer = setTimeout(() => {
                setBasicDetailsError(AppConstants.falseText);
                setBasicDetailsErrorMessage(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    }

    //download registered candidates
    async function downloadParticipationSummary(competitionData) {
        setParticipationSummarySpinner(AppConstants.trueText);
        const url = `${AppConstants.downloadParticipantsWithParameterAPI}${competitionData.id}`;
        const name = `${competitionData.title}${AppConstants.hyphen}${AppConstants.registeredParticipantsFileText}`
        const fileName = name.replace(/\s+/g, AppConstants.hyphen);
        const response = await FileOperationsUtil.downloadFile(
            url,
            fileName,
            AppConstants.emptyString,
            AppConstants.emptyString,
            { preferredTimezone: DateTimeUtil.getPreferredTimeZoneForUser() }
        );
        if (response !== undefined) {
            if (response.ok) {
                setParticipationSummarySpinner(AppConstants.falseText);
            } else {
                setParticipationSummarySpinner(AppConstants.falseText);
                setBasicDetailsError(AppConstants.trueText);
                setBasicDetailsErrorMessage(ErrorMessages.participationSummaryDownloadError);
                let timer = setTimeout(() => {
                    setBasicDetailsError(AppConstants.falseText);
                    setBasicDetailsErrorMessage(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } else {
            setParticipationSummarySpinner(AppConstants.falseText);
            setBasicDetailsError(AppConstants.trueText);
            setBasicDetailsErrorMessage(ErrorMessages.participationSummaryDownloadError);
            let timer = setTimeout(() => {
                setBasicDetailsError(AppConstants.falseText);
                setBasicDetailsErrorMessage(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    }

    //show/hide modal
    const handleModalClose = () => {
        setShowModal(AppConstants.falseText);
    }
    const handleModalShow = (data) => {
        setShowModal(AppConstants.trueText);
        setModalData(data)
    }

    async function fetchMarkingCompleteFlag() {
        const url = `${AppConstants.ApiToFetchStudentsMarkingStatus}${selectedCompetitionData.uniqueId}`;
        const response = await AuthorizedDataService.getRequest(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        );

        return response
    }

    //check judging is enabled
    async function checkIsJudgingEnabled(compData) {
        if (selectedCompetitionData.checkCompetitionRubrics === 1) {
            const markingFlag = await fetchMarkingCompleteFlag();
            if (markingFlag.markingCompleteFlag === AppConstants.yText.toUpperCase()) {
                completeCompetition(compData);
            } else {
                let judges = selectedCompetitionData.judgeDetails?.length;
                if (judges === AppConstants.zeroIndex) {
                    handleModalShow(compData);
                    setCompleteCompetitionErrorMessage(ErrorMessages.expertMarkCompetitionCompleteWarning)
                } else {
                    let studentsSubmitted = selectedCompetitionData.participantsDetails?.filter(participant => participant.solutionSubmissionFlag === AppConstants.yText).map(studentDetails => studentDetails.studentId);
                    let studentsJudged = resultDetails.filter(result => result.judgesMarked > 0).map(student => student.studentId);
                    if (studentsSubmitted.every(element => studentsJudged.includes(element))) {
                        if (selectedCompetitionData.allowSubmissionFlag === AppConstants.yText) {
                            setCompleteCompetitionErrorMessage(ErrorMessages.expertMarkCompetitionCompleteWarning)
                        } else {
                            setCompleteCompetitionErrorMessage(ErrorMessages.expertCompleteCompetitionsAlertMessage)
                        }
                        handleModalShow(compData);
                    } else {
                        handleModalShow(compData);
                        setCompleteCompetitionErrorMessage(ErrorMessages.submittedStudentsNotMarkedError);
                    }
                }
            }
        } else {
            completeCompetition(compData);
        }
    }

    //mark competition as complete
    async function completeCompetition(compData) {
        setShowModal(AppConstants.falseText);
        setCompleteCompetitionSpinner(AppConstants.trueText);
        const url = `${AppConstants.completeCompetitionWithParameterAPI}${compData.uniqueId}`;
        const response = await AuthorizedDataService.put(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        );
        if (response !== undefined && response.ok) {
            setCompleteCompetitionSpinner(AppConstants.falseText);
            fetchCompetitionDetails(compData);
            setCompleteCompetitionErrorVarient(AppConstants.alertVarient[0]);
            setCompleteCompetitionError(AppConstants.trueText);
            setCompleteCompetitionMessage(SuccessMessage.expertCompetitionCompletedSuccess);
            let timer = setTimeout(() => {
                setCompleteCompetitionError(AppConstants.falseText);
                setCompleteCompetitionMessage(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        } else {
            setCompleteCompetitionSpinner(AppConstants.falseText);
            setCompleteCompetitionErrorVarient(AppConstants.alertVarient[1]);
            setCompleteCompetitionError(AppConstants.trueText);
            setCompleteCompetitionMessage(ErrorMessages.expertCompleteCompetitionError);
            let timer = setTimeout(() => {
                setCompleteCompetitionError(AppConstants.falseText);
                setCompleteCompetitionMessage(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    }

    //mark competition as complete
    async function enableDisableJudging(compData, status) {
        setEnableJudgingSpinner(AppConstants.trueText);
        setEnableJudgingError(AppConstants.falseText);
        const url = `${AppConstants.expertEnableDisableJudgeAPI}${compData.uniqueId}${AppConstants.expertEnableDisableJudgeAPIStatusParameter}${status}`;
        const response = await AuthorizedDataService.put(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        );

        if (response !== undefined && response.ok) {
            if (status === AppConstants.trueText) {
                setEnableJudgingErrorMessage(SuccessMessage.expertEnableJudgingSuccess);
            } else if (status === AppConstants.falseText) {
                setEnableJudgingErrorMessage(SuccessMessage.expertDisableJudgingSuccess);
            }
            setEnableJudgingSpinner(AppConstants.falseText);
            fetchCompetitionDetails(compData);
            setEnableJudgingErrorVarient(AppConstants.alertVarient[0]);
            setEnableJudgingError(AppConstants.trueText);
            let timer = setTimeout(() => {
                setEnableJudgingError(AppConstants.falseText);
                setEnableJudgingErrorMessage(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        } else {
            if (status === AppConstants.trueText) {
                setEnableJudgingErrorMessage(ErrorMessages.expertEnableJudgingError);
            } else if (status === AppConstants.falseText) {
                setEnableJudgingErrorMessage(ErrorMessages.expertDisableJudgingError);
            }
            setEnableJudgingSpinner(AppConstants.falseText);
            setEnableJudgingErrorVarient(AppConstants.alertVarient[1]);
            setEnableJudgingError(AppConstants.trueText);
            setEnableJudgingErrorMessage(ErrorMessages.expertCompleteCompetitionError);
            let timer = setTimeout(() => {
                setEnableJudgingError(AppConstants.falseText);
                setEnableJudgingErrorMessage(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    }

    //send judging link
    async function sendJudgingLink(compData) {
        setSendLinkSpinner(AppConstants.trueText);
        setEnableJudgingError(AppConstants.falseText);
        const url = `${AppConstants.senJudgingLinkAPIWithParameter}${compData.uniqueId}`;
        const response = await AuthorizedDataService.getRequest(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        );
        if (response === 1) {
            setSendLinkSpinner(AppConstants.falseText);
            setEnableJudgingErrorVarient(AppConstants.alertVarient[0]);
            setEnableJudgingError(AppConstants.trueText);
            setEnableJudgingErrorMessage(SuccessMessage.sendJudgingLinkSuccess);
            let timer = setTimeout(() => {
                setEnableJudgingError(AppConstants.falseText);
                setEnableJudgingErrorMessage(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        } else {
            setSendLinkSpinner(AppConstants.falseText);
            setEnableJudgingErrorVarient(AppConstants.alertVarient[1]);
            setEnableJudgingError(AppConstants.trueText);
            setEnableJudgingErrorMessage(ErrorMessages.sendJudgingLinkError);
            let timer = setTimeout(() => {
                setEnableJudgingError(AppConstants.falseText);
                setEnableJudgingErrorMessage(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    }

    // send link to individual judge
    const sendLinkToJudge = async (judgeDetails) => {
        // TODO: Change Url
        const url = `${AppConstants.sendLinkToIndividualJudgeAPI}${judgeDetails.judgeUniqueId}${AppConstants.sendLinkToJudgeSecondParam}${judgeDetails.competitionId}`
        const response = await AuthorizedDataService.getRequest(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        );

        if (response !== undefined) {
            setSendIndividualJudgingLinkError(false);
            setSendIndividualJudgingLinkMessage(AppConstants.emptyString);
            setSendIndividualJudgingLinkVarient(AppConstants.alertVarient[0]);
            setSendIndividualJudgingLinkMessage(SuccessMessage.individualJudgingLinkSuccess);
            return true;
        } else {
            setSendIndividualJudgingLinkError(true);
            setSendIndividualJudgingLinkVarient(AppConstants.alertVarient[1]);
            setSendIndividualJudgingLinkMessage(ErrorMessages.individualJudgingLinkFailure);
            return false;
        }
    }

    //show selected competition detail
    async function fetchCompetitionDetails(compData) {
        const url = `${AppConstants.fetchCompetitionDetailsWithParameterAPI}${compData.uniqueId}`;

        const response = await AuthorizedDataService.get(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        );
        if (response !== undefined) {
            setSelectedCompetitionData(response);
        } else {
            setSelectedCompetitionData([]);
        }
    };

    return (
        <div className="expertCompDetailsAccordion">
            <Accordion defaultActiveKey={['0']} alwaysOpen>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{AppConstants.viewCompetitionBasicDetailsText}</Accordion.Header>
                    <Accordion.Body>
                        <div className="row expertAccordionContentSection">
                            {completeCompetitionError && <div className={classes.errorSuccessContainer}><ErrorSuccessAlertMessage message={completeCompetitionMessage} varient={completeCompetitionErrorVarient} /></div>}
                            <div className="expertCompleteCompButtonCnt">
                                {((selectedCompetitionData.completionStatus !== AppConstants.completitionStatus.complete && !(selectedCompetitionData.competitionStatus === AppConstants.competitionStatus.past && selectedCompetitionData.participantsDetails?.length === 0)) && selectedCompetitionData.status !== AppConstants.competitionStatus.cancelled) && <FaEdit data-tooltip-id="editInfo" onClick={() => props.showEditCompetition(true)} className="expertEditCompetitionsIcon" />}
                                {(selectedCompetitionData.completionStatus === AppConstants.completitionStatus.incomplete && new Date(DateTimeUtil.utcToPreferredTimezone(selectedCompetitionData.startDateTimeUTC)) < new Date() && selectedCompetitionData.status === AppConstants.publishedText) &&
                                    <Button className={classes.downloadBtn} onClick={() => { checkIsJudgingEnabled(selectedCompetitionData) }}>Complete Competition {completeCompetitionSpinner && <BiLoaderAlt className="expertSpinnerIcon" />}</Button>}
                                {(selectedCompetitionData.completionStatus !== AppConstants.completitionStatus.incomplete && new Date(DateTimeUtil.utcToPreferredTimezone(selectedCompetitionData.startDateTimeUTC)) < new Date() && selectedCompetitionData.status === AppConstants.publishedText) &&
                                    <Button className={`${classes.disabledButton} ${classes.downloadBtn}`}><BiCheckCircle /> Closed</Button>}
                            </div>
                            <Tooltip className={classes.tooltipMessage} id="editInfo"><span className={classes.tooltipMessage}>Edit</span></Tooltip>
                            <Table striped bordered>
                                <tbody>
                                    <tr>
                                        <td className={classes.tableLabelColumn}>Title</td>
                                        <td className={classes.tableDescriptionColumn}>{selectedCompetitionData.title !== AppConstants.nullText ? selectedCompetitionData.title : AppConstants.notAvailableText}
                                            <TbShare3 className={classes.shareIcon} onClick={() => { shareOnSocialMedia(selectedCompetitionData) }} />
                                            {showSocialMediaPannel && <div className={classes.shareIconPannelContainerParent}>
                                                <div className={classes.shareIconPannelContainer}>
                                                    <BsLinkedin className={classes.shareOnLinkedIn} onClick={shareOnLinkedIn} />
                                                    <BsFacebook className={classes.shareOnFacebook} onClick={shareOnFacebook} />
                                                    <RiTwitterXLine className={classes.shareOnTwitter} onClick={shareOnTwitter} />
                                                    <BsFillEnvelopeAtFill className={classes.shareOnMailButton} onClick={sendOnMail} />
                                                    {!showLinkCopied && <AiOutlineCopy className={classes.copyToClipboard} onClick={copyToClipBoard} />}
                                                    {showLinkCopied && <BsFillCheckCircleFill className={classes.showLinkCopiedIcon} />}
                                                </div>
                                            </div>}
                                        </td>
                                    </tr>
                                    {selectedCompetitionData.competitionId && <tr>
                                        <td className={classes.tableLabelColumn}>Competition Id</td>
                                        <td className={classes.tableDescriptionColumn}>{selectedCompetitionData.competitionId}</td>
                                    </tr>}
                                    <tr>
                                        <td className={classes.tableLabelColumn}>Description</td>
                                        <td className={classes.tableDescriptionColumn}><div className={`${classes.guidelinesText} quillListIndent ql-editor`}>{selectedCompetitionData.description !== AppConstants.nullText ? parse(selectedCompetitionData.description) : AppConstants.notAvailableText}</div></td>
                                    </tr>
                                    {selectedCompetitionData.minimumAge !== 0 && selectedCompetitionData.maximumAge !== 0 && <tr>
                                        <td className={classes.tableLabelColumn}>Age</td>
                                        <td className={classes.tableDescriptionColumn}>{selectedCompetitionData.minimumAge + "-" + selectedCompetitionData.maximumAge} {AppConstants.yearText}</td>
                                    </tr>}
                                    {selectedCompetitionData.minimumAge === 0 && selectedCompetitionData.maximumAge === 0 && <tr>
                                        <td className={classes.tableLabelColumn}>Age</td>
                                        <td className={classes.tableDescriptionColumn}>{AppConstants.allAgeGroups}</td>
                                    </tr>}
                                    {selectedCompetitionData.minimumAge !== 0 && selectedCompetitionData.maximumAge === 0 && <tr>
                                        <td className={classes.tableLabelColumn}>Age</td>
                                        <td className={classes.tableDescriptionColumn}>{AppConstants.aboveText} {selectedCompetitionData.minimumAge} {AppConstants.yearText}</td>
                                    </tr>}
                                    {selectedCompetitionData.minimumAge === 0 && selectedCompetitionData.maximumAge !== 0 && <tr>
                                        <td className={classes.tableLabelColumn}>Age</td>
                                        <td className={classes.tableDescriptionColumn}>{signupMinimumAge} - {selectedCompetitionData.maximumAge} {AppConstants.yearText}</td>
                                    </tr>}
                                    <tr>
                                        <td className={classes.tableLabelColumn}>Type</td>
                                        <td className={classes.tableDescriptionColumn}>{selectedCompetitionData.type !== AppConstants.nullText ? selectedCompetitionData.type : AppConstants.notAvailableText}</td>
                                    </tr>
                                    {selectedCompetitionData.type.toUpperCase() === "ONLINE" && selectedCompetitionData.vcLink !== null && <tr>
                                        <td className={classes.tableLabelColumn}>VC Link</td>
                                        <td className={classes.tableDescriptionColumn}><a target={AppConstants.openInNewTab} href={selectedCompetitionData.vcLink}>Click Here</a></td>
                                    </tr>}
                                    {selectedCompetitionData.type.toUpperCase() === "OFFLINE" && selectedCompetitionData.address !== null && selectedCompetitionData.venueLocation !== null && <tr>
                                        <td className={classes.tableLabelColumn}>Address</td>
                                        <td className={classes.tableDescriptionColumn}><a target={AppConstants.openInNewTab} href={selectedCompetitionData.geoLocation}>{selectedCompetitionData.venueLocation}</a></td>
                                    </tr>}
                                    <tr>
                                        <td className={classes.tableLabelColumn}>Start Time</td>
                                        <td className={classes.tableDescriptionColumn}><Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{selectedCompetitionData.startDateTimeUTC.replace(' ', 'T') + 'Z'}</Moment> | <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{selectedCompetitionData.startDateTimeUTC.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${selectedCompetitionData.preferredTimezone})`}</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.tableLabelColumn}>End Time</td>
                                        <td className={classes.tableDescriptionColumn}><Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{selectedCompetitionData.endDateTimeUTC.replace(' ', 'T') + 'Z'}</Moment> | <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{selectedCompetitionData.endDateTimeUTC.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${selectedCompetitionData.preferredTimezone})`}</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.tableLabelColumn}>Registration Deadline</td>
                                        <td className={classes.tableDescriptionColumn}><Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{selectedCompetitionData.registrationClosingDateUTC.replace(' ', 'T') + 'Z'}</Moment> | <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{selectedCompetitionData.registrationClosingDateUTC.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${selectedCompetitionData.preferredTimezone})`}</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.tableLabelColumn}>Cost</td>
                                        <td className={classes.tableDescriptionColumn}>{(selectedCompetitionData.cost !== null && selectedCompetitionData.cost !== 0) ? PaymentsUtil.calculateCost({ cost: selectedCompetitionData.cost, discount: 0 }) : AppConstants.freeText}</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.tableLabelColumn}>Offline Payment Enabled</td>
                                        <td className={classes.tableDescriptionColumn}>{selectedCompetitionData.payOfflineFlag === AppConstants.yText ? "Yes" : "No"}</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.tableLabelColumn}>Enrolled Students</td>
                                        <td className={classes.tableDescriptionColumn}>{selectedCompetitionData.enrolledParticipants !== AppConstants.nullText ? MathUtil.formatNumberToLocaleString(selectedCompetitionData.participantsDetails?.length) : AppConstants.notAvailableText}</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.tableLabelColumn}>Participant Limit</td>
                                        <td className={classes.tableDescriptionColumn}>{selectedCompetitionData.allowedNumberOfParticipants !== AppConstants.nullText ? MathUtil.formatNumberToLocaleString(selectedCompetitionData.allowedNumberOfParticipants) : AppConstants.notAvailableText}</td>
                                    </tr>
                                    <tr>
                                        <td className={classes.tableLabelColumn}>Created On</td>
                                        <td className={classes.tableDescriptionColumn}><Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{selectedCompetitionData.competitionCreatedOn.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${selectedCompetitionData.preferredTimezone})`}</td>
                                    </tr>
                                    {!(selectedCompetitionData.guidelinesDocumentPath !== AppConstants.nullText && selectedCompetitionData.guidelinesText === AppConstants.nullText)
                                        && <tr>
                                            <td className={classes.tableLabelColumn}>Guidelines</td>
                                            {selectedCompetitionData.guidelinesText !== AppConstants.nullText && <td className={classes.tableDescriptionColumn}><div className={`${classes.guidelinesText} quillListIndent ql-editor`}>{selectedCompetitionData.guidelinesText !== AppConstants.nullText ? parse(selectedCompetitionData.guidelinesText) : AppConstants.notAvailableText}</div></td>}
                                            {selectedCompetitionData.guidelinesText === AppConstants.nullText && <td className={classes.tableDescriptionColumn}><div>{selectedCompetitionData.guidelinesText !== AppConstants.nullText ? parse(selectedCompetitionData.guidelinesText) : AppConstants.notAvailableText}</div></td>}
                                        </tr>}
                                    {(selectedCompetitionData.guidelinesDocumentPath !== AppConstants.nullText && selectedCompetitionData.guidelinesText === AppConstants.nullText) && <tr>
                                        <td className={classes.tableLabelColumn}>Guidelines File</td>                                        {selectedCompetitionData.guidelinesDocumentPath !== AppConstants.nullText && <td className={classes.tableDescriptionColumn}><Button target={AppConstants.openInNewTab} className={classes.downloadBtn} href={selectedCompetitionData.guidelinesDocumentPath}>Download</Button></td>}
                                        {selectedCompetitionData.guidelinesDocumentPath === AppConstants.nullText && <td className={classes.tableDescriptionColumn}>{AppConstants.notAvailableText}</td>}
                                    </tr>}
                                    <tr>
                                        <td className={classes.tableLabelColumn}>Problem Statement File</td>
                                        {selectedCompetitionData.questionPaperFilePath !== AppConstants.nullText && <td className={classes.tableDescriptionColumn}><Button target={AppConstants.openInNewTab} className={classes.downloadBtn} href={selectedCompetitionData.questionPaperFilePath} >Download</Button></td>}
                                        {selectedCompetitionData.questionPaperFilePath === AppConstants.nullText && <td className={classes.tableDescriptionColumn}>N/A</td>}
                                    </tr>
                                    <tr>
                                        <td className={classes.tableLabelColumn}>Rubrics</td>
                                        {selectedCompetitionData.checkCompetitionRubrics === 1 && <td className={classes.tableDescriptionColumn}><Button className={classes.downloadBtn} onClick={() => { downloadRubrics(selectedCompetitionData) }}>Download {rubricsSpinner && <BiLoaderAlt className="expertSpinnerIcon" />}</Button></td>}
                                        {selectedCompetitionData.checkCompetitionRubrics === 0 && <td className={classes.tableDescriptionColumn}>N/A</td>}
                                    </tr>

                                    {selectedCompetitionData.templateId !== 0 && selectedCompetitionData.templateId !== null && <tr>
                                        <td className={classes.tableLabelColumn}>Certificate Template</td>
                                        <td className={classes.tableDescriptionColumn}><Button className={classes.downloadBtn} onClick={() => { downloadCertificateTemplate(selectedCompetitionData) }}>Download {certificateTemplateSpinner && <BiLoaderAlt className="expertSpinnerIcon" />}</Button></td>
                                    </tr>}
                                    {selectedCompetitionData.schoolId !== 0 && <tr>
                                        <td className={classes.tableLabelColumn}>Classes</td>
                                        <td className={classes.tableDescriptionColumn}>{selectedCompetitionData.selectedClasses.replace(/\-+/g, '').replace(/,+/g, ', ')}</td>
                                    </tr>}
                                    {selectedCompetitionData.participantsDetails?.length !== 0 && <tr>
                                        <td className={classes.tableLabelColumn}>Participation Summary</td>
                                        <td className={classes.tableDescriptionColumn}><Button className={classes.downloadBtn} onClick={() => { downloadParticipationSummary(selectedCompetitionData) }}>Download {participationSummarySpinner && <BiLoaderAlt className="expertSpinnerIcon" />}</Button></td>
                                    </tr>}
                                </tbody>
                            </Table>
                            {basicDetailsError && <div className={classes.errorSuccessContainer}><ErrorSuccessAlertMessage message={basicDetailsErrorMessage} varient={AppConstants.alertVarient[1]} /></div>}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>{AppConstants.viewCompetitionJudgesDetailsText}</Accordion.Header>
                    <Accordion.Body>
                        {selectedCompetitionData.judgeDetails !== AppConstants.nullText && selectedCompetitionData.judgeDetails.length !== 0 ?
                            <div className="expertAccordionContentSection">
                                <div className={classes.judgesCountCntSection}>
                                    <div><p className={classes.judgesCountText}>Judges ({selectedCompetitionData.judgeDetails !== AppConstants.nullText && selectedCompetitionData.judgeDetails.length})</p></div>
                                    <div>
                                        {selectedCompetitionData.status === AppConstants.publishedText && selectedCompetitionData.completionStatus !== AppConstants.completitionStatus.complete && selectedCompetitionData.judgingEnabledFlag === AppConstants.falseFlag && <Button className={classes.judgeEnableButton}
                                            onClick={() => { enableDisableJudging(selectedCompetitionData, AppConstants.trueText) }}>Enable Judging {enableJudgingSpinner && <BiLoaderAlt className="expertSpinnerIcon" />}</Button>}
                                        {selectedCompetitionData.status === AppConstants.publishedText && selectedCompetitionData.completionStatus !== AppConstants.completitionStatus.complete && selectedCompetitionData.judgingEnabledFlag === AppConstants.trueFlag && <Button className={classes.sendLinkButton}
                                            onClick={() => { sendJudgingLink(selectedCompetitionData) }}>Send Link{sendLinkSpinner && <BiLoaderAlt className="expertSpinnerIcon" />}</Button>}
                                        {selectedCompetitionData.status === AppConstants.publishedText && selectedCompetitionData.completionStatus !== AppConstants.completitionStatus.complete && selectedCompetitionData.judgingEnabledFlag === AppConstants.trueFlag && <Button className={classes.judgeDisableButton}
                                            onClick={() => { enableDisableJudging(selectedCompetitionData, AppConstants.falseText) }}>Disable Judging {enableJudgingSpinner && <BiLoaderAlt className="expertSpinnerIcon" />}</Button>}
                                    </div>
                                </div>
                                {enableJudgingError && <div className={classes.errorSuccessContainer}><ErrorSuccessAlertMessage message={enableJudgingErrorMessage} varient={enableJudgingErrorVarient} /></div>}
                                {sendIndividualJudgingLinkError && <div className={classes.errorSuccessContainer}><ErrorSuccessAlertMessage message={sendIndividualJudgingLinkMessage} varient={sendIndividualJudgingLinkVarient} /></div>}
                                <Row xs={1} sm={2} md={2} lg={2} xl={3}>
                                    {selectedCompetitionData.judgeDetails.map((judge, index) => (
                                        <Col key={judge.judgeEmail}>
                                            <ViewJudgeCard
                                                key={judge.judgeEmail} judgeDetails={{ ...judge, competitionId: selectedCompetitionData.competitionId }}
                                                showSendMailButton={false}
                                                sendLinkToJudge={sendLinkToJudge}
                                            />
                                        </Col>))}
                                </Row>
                            </div> : <div><p className={classes.noJudgesErrorMessage}>{ErrorMessages.expertNoJudgesErrorMessage}</p></div>}
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>{AppConstants.viewCompetitionParticipantsDetailsText}</Accordion.Header>
                    <Accordion.Body>
                        <div className="expertAccordionContentSection">
                            {selectedCompetitionData.participantsDetails !== AppConstants.nullText && selectedCompetitionData.participantsDetails.length !== 0 ?
                                <div className={classes.participantDetailsTable}>
                                    <Table striped bordered>
                                        <thead>
                                            <tr>
                                                <th className={classes.tableHeaderOne}>Qrencia ID</th>
                                                <th className={classes.tableHeaderEnrolledDate}>User Name</th>
                                                <th className={classes.tableHeaderTwo}>Email</th>
                                                <th className={classes.tableHeaderTwo}>Phone</th>
                                                <th className={classes.tableHeaderTwo}>Gender</th>
                                                <th className={classes.tableHeaderEnrolledDate}>Enrolled On</th>
                                                {(selectedCompetitionData.cost !== null && selectedCompetitionData.cost !== 0) && <th className={classes.tableHeaderEnrolledDate}>Last Payment Method</th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedCompetitionData.participantsDetails.map((participants) => (
                                                <tr key={participants.id}>
                                                    <td className={classes.tableDescriptionColumnOne}>{participants.userQrenciaId}</td>
                                                    <td className={classes.tableDescriptionEnrolledDate}>{participants.studentName}{participants.isQuestionPaperDownloaded === AppConstants.yText && <RxDotFilled className={classes.liveDot}></RxDotFilled>}</td>
                                                    <td className={classes.tableDescriptionColumnOne}>{participants.studentEmail}</td>
                                                    <td className={classes.tableDescriptionColumnOne}>{participants.contryMobileNumberCode}{participants.mobileNumber}</td>
                                                    <td className={classes.tableDescriptionColumnOne}>{participants.gender ? participants.gender : "N/A"}</td>
                                                    <td className={classes.tableDescriptionEnrolledDate}><Moment format="hh:mm A" tz={timeZone}>{participants.formattedEnrolledOn?.replace(' ', 'T') + 'Z'}</Moment>, <Moment format="DD MMM YYYY" tz={timeZone}>{participants.formattedEnrolledOn?.replace(' ', 'T') + 'Z'}</Moment></td>
                                                    {(selectedCompetitionData.cost !== null && selectedCompetitionData.cost !== 0) && <td className={classes.tableDescriptionColumnOne}>{participants.paymentMode}</td>}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                                : <div><p className="expertNoParticipantsErroMessage">{ErrorMessages.expertNoParticipantsError}</p></div>}
                            {(selectedCompetitionData.participantsDetails !== AppConstants.nullText && selectedCompetitionData.participantsDetails.length !== 0) && <div className="expertCompleteCompButtonCnt">
                                <Button className={classes.participantBtn} onClick={() => { downloadRegisteredCandidates(selectedCompetitionData) }}>Participants List{downloadParticipantsSpinner && <BiLoaderAlt className="expertSpinnerIcon" />}</Button></div>}
                            {participantsDetailsError && <div className={classes.errorSuccessContainer}><ErrorSuccessAlertMessage message={participantsDetailsErrorMessage} varient={AppConstants.alertVarient[1]} /></div>}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>Queries</Accordion.Header>
                    <Accordion.Body>
                        {(openQueries.length > 0 || respondedQueries.length > 0) && <div>
                            {
                                openQueries.map((query, i) => {
                                    return <StudentQueryCard key={query.queryUniqueId} query={{ ...query, title: selectedCompetitionData.title, learningActivityType: AppConstants.learningActivitiesTypes.competition }} updateFetchDataFlag={updateFetchDataFlag} />
                                })
                            }
                            {
                                respondedQueries.map((query, i) => {
                                    return <StudentQueryCard key={query.queryUniqueId} query={{ ...query, title: selectedCompetitionData.title, learningActivityType: AppConstants.learningActivitiesTypes.competition }} updateFetchDataFlag={updateFetchDataFlag} />
                                })
                            }
                        </div>}
                        {(openQueries.length === 0 && respondedQueries.length === 0) && <div className="expertAccordionContentSection"><p className="expertNoParticipantsErroMessage">{ErrorMessages.noQuestionAskedFallbackMessage}</p></div>}
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>{AppConstants.viewCompetitionResultText}</Accordion.Header>
                    <Accordion.Body>
                        {selectedCompetitionData.checkCompetitionRubrics === 1 && selectedCompetitionData.completionStatus !== AppConstants.completeText && props.competitionTab !== AppConstants.competitionTabHeading[0] && props.competitionTab !== AppConstants.competitionTabHeading[3] && <div className={classes.judgingEnabledWarningMessage}><p><AiOutlineExclamationCircle /> {ErrorMessages.resultErrorJudgingInProgress}</p></div>}
                        {selectedCompetitionData.checkCompetitionRubrics === 0 && props.competitionTab !== AppConstants.competitionTabHeading[0] && props.competitionTab !== AppConstants.competitionTabHeading[3] && <div className={classes.judgingEnabledWarningMessage}><p><AiOutlineExclamationCircle /> {ErrorMessages.resultErrorJudgingDisabled}</p></div>}
                        <div>
                            <ExpertViewCompetitionsResult resultDetails={resultDetails} competitionCompletionStatus={selectedCompetitionData.completionStatus} competitionDetails={selectedCompetitionData} competitionTab={props.competitionTab} fetchCompetitionDetails={fetchCompetitionDetails} />
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Modal show={showModal} onHide={handleModalClose} backdrop="static" keyboard={AppConstants.falseText} centered>
                <Modal.Body className={classes.modalBody}>
                    <div className={classes.modalAlertIcon}><AiOutlineExclamationCircle /></div>
                    <div className={classes.modalAlertText}>{completeCompetitionErrorMessage}</div>
                </Modal.Body>
                {(completeCompetitionErrorMessage === ErrorMessages.expertCompleteCompetitionsAlertMessage || completeCompetitionErrorMessage === ErrorMessages.expertMarkCompetitionCompleteWarning) && <Modal.Footer className={classes.modalFooter}>
                    <Button className={classes.modalNoButton} onClick={handleModalClose}>No</Button>
                    <Button className={classes.modalYesButton} onClick={() => completeCompetition(modalData)}>Yes</Button>
                </Modal.Footer>}
                {completeCompetitionErrorMessage === ErrorMessages.submittedStudentsNotMarkedError && <Modal.Footer className={classes.modalFooter}>
                    <Button className={classes.modalCloseButton} onClick={handleModalClose}>Close</Button>
                </Modal.Footer>}
            </Modal>
        </div>
    );
}