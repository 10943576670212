import { LandingPageFooter } from "../../layout/LandingPageLayout/LandingPageFooter";
import { useLoaderData } from 'react-router-dom';
import { LoginPageTopNavigation } from "../../layout/LoginPageLayout/LoginPageTopNavigation";
import classes from "./ExpressionOfInterestPage.module.css";
import { ExpressionOfInterestForm } from "../../component/formControls/ExpressionOfInterestForm";
import { ExpressionOfInterestDetailsSection } from "../../component/eoiPageComponents/expressionOfInterestDetailsSection/ExpressionOfInterestDetailsSection";
import { ExpressionOfInterestVideoSection } from "../../component/eoiPageComponents/expressionOfInterestDetailsVideoSection/ExpressionOfInterestVideoSection";
import { ExpressionOfInterestPageBanner } from "../../component/eoiPageComponents/expressionOfInterestPageBanner/ExpressionOfInterestPageBanner";
import { ExpresionOfInterestCarouselSection } from "../../component/eoiPageComponents/expressionOfInterestMobileCarousel/ExpressionOfInterestCarouselSection";
import { ExpertWelcomePageVideo } from "../../component/expertWelcomePage/ExpertWelcomePageVideo";
import { AppConstants } from "../../constants/AppConstants";
import welcomeExpertClasses from "../../component/expertWelcomePage/expertWelcomePage.module.css";
import { Col, Row } from "react-bootstrap";
import parse from 'html-react-parser';

export const ExpressionOfIntrestPage = () => {
    const [landingPageData, eoiImageData, eoiCarouselData] = useLoaderData();
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const blueTriangleImageUrl = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.blueTriangleImagePath}`;
    const pinkBgOvalImage = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.pinkBgOvalImagePath}`;
    const blueBgOvalImage = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.blueBgOvalImagePath}`;
    const purpleBgOvalImage = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.purpleBgOvalImagePath}`;
    const lightBlueTriangleImageUrl = `${process.env.REACT_APP_MEDIA_CONTENT}${AppConstants.designImagesS3Path}/${AppConstants.lightBlueTriangleImagePath}`;
    const imageSources = [pinkBgOvalImage, blueBgOvalImage, purpleBgOvalImage];

    return (
        <>
            <LoginPageTopNavigation landingPageData={landingPageData} />
            <div className="row">
                <ExpressionOfInterestPageBanner eoiCarouselData={eoiCarouselData} />
            </div>
            <div className={`row mb-3 ${classes.section}`}>
                <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                    <ExpressionOfInterestDetailsSection />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <ExpressionOfInterestVideoSection />
                </div>
            </div>
            <div className="row">
                <ExpresionOfInterestCarouselSection eoiImageData={eoiImageData} />
            </div>
            <div className={`row mt-4 mb-3 ${classes.section}`}>
                <ExpressionOfInterestForm />
            </div>
            {landingPageData?.welcomeExpertsSuccessfulExperts?.active && <div className={`row mt-4 mb-4 ${classes.section}`}>
                <Row>
                    <div className={welcomeExpertClasses.successfulExpertsSectionEOI}>
                        <h2 className={`${welcomeExpertClasses.titleText} ${welcomeExpertClasses.successfulExpertTitle}`}>{parse(landingPageData.welcomeExpertsSuccessfulExperts.title)}</h2>
                        <img
                            className={welcomeExpertClasses.blueTriangleImage}
                            src={lightBlueTriangleImageUrl}
                            alt="Blue Triangle"
                        />
                        <h1 className={welcomeExpertClasses.subTitle}>{parse(landingPageData.welcomeExpertsSuccessfulExperts.subTitle)}</h1>

                        <Row className={welcomeExpertClasses.videoContainer} xs={1} sm={2} md={2} lg={4} xl={4}>
                            {landingPageData.welcomeExpertsSuccessfulExperts.cards?.map((video, index) => (
                                <Col key={index}>
                                    <ExpertWelcomePageVideo video={video} />
                                </Col>
                            ))}
                        </Row>
                    </div>
                </Row>
            </div>}

            {landingPageData?.welcomeExpertsQrenciaForExperts?.active && <div className={`row mt-4 mb-4 ${classes.section}`}>
                <Row>
                    <div className={welcomeExpertClasses.qrenciaForExpertsSectionEOI}>
                        <div className={welcomeExpertClasses.qrenciaForExpertsContentSection}>
                            <h2 className={`${welcomeExpertClasses.titleText} ${welcomeExpertClasses.qrenciaForExpertsTitle}`}>{landingPageData.welcomeExpertsQrenciaForExperts.title}</h2>
                            <img
                                className={welcomeExpertClasses.blueTriangleImage}
                                src={blueTriangleImageUrl}
                                alt="Blue Triangle"
                            />
                            <h1 className={welcomeExpertClasses.subTitle}>{parse(landingPageData.welcomeExpertsQrenciaForExperts.subTitle)}</h1>
                            <Row xs={2} sm={2} md={3} lg={2} xl={3} className={welcomeExpertClasses.welcomeExpertsQrenciaForExpertsCardsSection}>
                                {landingPageData.welcomeExpertsQrenciaForExperts.cards?.map((card, index) => (
                                    <div className={welcomeExpertClasses.welcomeExpertsNumberSection}>
                                        <Col key={index}>
                                            <div className={welcomeExpertClasses.welcomExpertNumberContainer}>
                                                <h1 className={welcomeExpertClasses.qrenciaForExpertsCardTitle}>{card.title}{card.description ? (<span className={welcomeExpertClasses.unicodeArrow} style={{ fontWeight: 'bold', fontFamily: 'Arial, sans-serif' }}>
                                                    {String.fromCodePoint(parseInt(card.description.replace('U+', ''), 16))}
                                                </span>
                                                ) : ""}</h1>
                                                <p className={welcomeExpertClasses.qrenciaForExpertsCardSubTitle}>{card.subTitle}</p>
                                            </div>
                                            <img
                                                className={welcomeExpertClasses.qrenciaForExpertsCardsBackgroundImage}
                                                src={imageSources[index % 3]}
                                                alt="Oval cover"
                                            />
                                        </Col>
                                    </div>
                                ))}
                            </Row>
                        </div>
                        <div className={welcomeExpertClasses.qrenciaForExpertsVideoSection}>
                            <ExpertWelcomePageVideo video={landingPageData.welcomeExpertsQrenciaForExperts.video} />
                        </div>
                    </div>
                </Row>

            </div>}
            <LandingPageFooter landingPageData={landingPageData} />
        </>
    )
}