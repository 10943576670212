import React, { useState, useEffect, useCallback, useRef } from 'react';
import { IoMdClose } from 'react-icons/io';
import { BiLoaderAlt, BiMap } from 'react-icons/bi';
import WorldFlag from 'react-world-flags';
import { FaUserGraduate } from 'react-icons/fa';
import { BsBuilding } from 'react-icons/bs';
import { FiMail, FiSmartphone, } from 'react-icons/fi';
import { TfiLinkedin, TfiFacebook } from 'react-icons/tfi';
import { AiFillClockCircle, AiFillStar, AiOutlineStar, AiFillInstagram, AiOutlineCheckCircle, AiOutlineExclamationCircle } from 'react-icons/ai';
import { FaAward, FaGlobeAsia, FaYoutube } from 'react-icons/fa';
import Auth from "../../utils/Auth";
import { AppConstants } from '../../constants/AppConstants';
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import { DataService } from '../../services/DataService';
import { MicrositeCoursesSection } from '../expertMicrosite/MicrositeCoursesSection';
import { MicrositeCompetitionsSection } from '../expertMicrosite/MicrositeCompetitionsSection';
import leftSectionStyles from '../expertMicrosite/MicrositePageLeftSection.module.css';
import rightSectionStyles from '../expertMicrosite/MicrositePageRightSection.module.css';
import { DateTimeUtil } from '../../utils/DateTimeUtil';
import { MicrositeWorkshopSection } from '../expertMicrosite/MicrositeWorkshopSection';
import { Button, Modal, Row } from 'react-bootstrap';
import modalClass from "../cards/CompetitionsCards.module.css";
import { MdInsertComment, MdPeopleAlt } from "react-icons/md";
import { MicrositeAssociatedExpertCards } from '../cards/agencyMicrositeCards/MicrositeAssociatedExpertCards';
import { LoginModal } from '../modal/LoginModal';
import { ErrorMessages } from '../../constants/ErrorMessages';
import { MicrositeReviewComponent } from '../micrositeReviewComponent/MicrositeReviewComponent';
import { GoPencil } from 'react-icons/go';
import { SubmitReviewModal } from '../modal/SubmitReviewModal';
import { MathUtil } from '../../utils/MathUtil';
import { ImageGalleryModal } from '../modal/ImageGalleryModal';
import { Element } from 'react-scroll';
import { StudentRaiseNudgeForm } from '../formControls/StudentRaiseNudgeForm';

export const AgencyMicrositePageLeftSection = (props) => {
    const [micrositeDetails, setMicrositeDetails] = useState(props.micrositeData);
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const videoPlayIconUrl = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.videoPlayIconImagePath}`;
    const videoMessageThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.expertMicroSiteS3ImagesPath}/${AppConstants.expertVideoMessageThumbnailPath}`;
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [showPlayIcon, setShowPlayIcon] = useState(true);
    const [zeroText, setZeroText] = useState(0);
    const preferredTimezone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [showModal, setShowModal] = useState(AppConstants.falseText);
    const [modalAlertText, setModalAlertText] = useState(AppConstants.falseText);
    const [modalTypeError, setModalTypeError] = useState(AppConstants.falseText);
    const [followUnfollowStatus, setFollowUnfollowStatus] = useState(AppConstants.falseText);
    const [followSpinner, setFollowSpinner] = useState(AppConstants.falseText);
    const [associatedExperts, setAssociatedExperts] = useState([]);
    const [minimumFollowersCount, setMinimumFollowersCount] = useState([]);
    const [showLoginModal, setShowLoginModal] = useState(AppConstants.emptyString);
    const [loginFollowFlag, setLoginFollowFlag] = useState(AppConstants.falseText);
    const [loggedInAgencyType, setLoggedInAgencyType] = useState(AppConstants.nullText);
    const [uploadedVideoMessageThumbnailImage, setUploadedVideoMessageThumbnailImage] = useState(props.micrositeData.videoMessageThumbnailAbsolutePath);
    const [showReviewModal, setShowReviewModal] = useState(AppConstants.falseText);
    const [canStudentPostReview, setCanStudentPostReview] = useState(AppConstants.trueText);
    const [hasStudentProvidedReview, setHasStudentProvidedReview] = useState(AppConstants.falseText);
    const [micrositeReviews, setMicrositeReviews] = useState([]);
    const [showMediaGallery, setShowMediaGallery] = useState(AppConstants.falseText);
    const [selectedMedia, setSelectedMedia] = useState(AppConstants.emptyString);
    const sectionNames = AppConstants.micrositeTopNavigationSections;
    const [showRaiseNudgeLoginModal, setShowRaiseNudgeLoginModal] = useState(AppConstants.falseText);
    const [isUserLoggedInFromModal, setIsUserLoggedInFromModal] = useState(AppConstants.falseText);
    const [showNudgeModal, setShowNudgeModal] = useState(AppConstants.falseText);
    const [displayNudgeBackground, setDisplayNudgeBackground] = useState(AppConstants.trueText);
    const [signupMinimumAge, setSignupMinimumAge] = useState(AppConstants.emptyString);
    const reviewsRef = useRef(null);

    const playVideo = () => {
        setIsVideoPlaying(true);
        setShowPlayIcon(false);
    };

    const handleClose = () => {
        setIsVideoPlaying(false);
        setShowPlayIcon(true);
    };

    useEffect(() => {
        const fetchConfigurableKeys = async () => {
            const url = AppConstants.fetchConfigurableKeysAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setMinimumFollowersCount(parseInt(response.find(item => item.keyName === AppConstants.minimumFollowersCountConfigurableKey).keyValue));
                setSignupMinimumAge(parseInt(response.find(item => item.keyName === AppConstants.signupMinimumAgeKey).keyValue));
            }
        };
        fetchConfigurableKeys();

        const fetchFollowUnfollowStatus = async () => {
            const url = `${AppConstants.checkUserFollowStatusAPI}${micrositeDetails?.agencyUserUniqueId}`;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString,
            );

            if (response) {
                setFollowUnfollowStatus(AppConstants.trueText);
            } else {
                setFollowUnfollowStatus(AppConstants.falseText);
            }
        }
        if (micrositeDetails.agencyUserUniqueId && Auth.isLogin() && (Auth.getUserRole() === AppConstants.userRoleStudent || Auth.getUserRole() === AppConstants.userRoleAgency)) {
            fetchFollowUnfollowStatus();
        }

    }, [micrositeDetails]);

    useEffect(() => {
        const fetchExperts = setTimeout(() => {
            async function getExpertDetailsUnAuthorized() {
                const url = `${AppConstants.getUsersFollowedByAgencyAPI}${micrositeDetails?.agencyUserUniqueId}`;
                const response = await DataService.get(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString,
                );
                if (response !== undefined) {
                    setAssociatedExperts(response);
                } else {
                    setAssociatedExperts([]);
                }
            };
            async function getExpertDetailsAuthorized() {
                const url = `${AppConstants.getUsersFollowedByAgencyAPI}${micrositeDetails?.agencyUserUniqueId}`;
                const response = await AuthorizedDataService.getRequest(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString,
                );
                if (response !== undefined) {
                    setAssociatedExperts(response);
                } else {
                    setAssociatedExperts([]);
                }
            };
            if (Auth.isLogin()) {
                getExpertDetailsAuthorized();
            } else {
                getExpertDetailsUnAuthorized();
            }
        }, 1000);

        const fetchLoggedInAgencyType = async () => {
            const url = AppConstants.getLoggedInAgencyTypeAPI;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setLoggedInAgencyType(response.agencyType);
            }
        }

        if (Auth.isLogin() && Auth.getUserRole() === AppConstants.userRoleAgency) {
            fetchLoggedInAgencyType();
        } else {
            setLoggedInAgencyType(AppConstants.emptyString);
        }
    }, []);

    const fetchReviewForUser = useCallback(async () => {
        const url = `${AppConstants.getCreatorReviewsForMicrositeAPI}${props.micrositeData.agencyUserUniqueId}`;
        const response = await DataService.get(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        );

        if (response) {
            if (Auth.isLogin() && Auth.getUserRole() === AppConstants.userRoleStudent) {
                setHasStudentProvidedReview(response.some(review => review.reviewerUserQrenciaId === Auth.getLoggedInUserDetails().userId));
            }
            setMicrositeReviews(response)
        } else {
            setMicrositeReviews([]);
        }
    }, [props.micrositeData.agencyUserUniqueId]);

    useEffect(() => {
        const checkStudentReviewEligiblity = async () => {
            const url = `${AppConstants.checkStudentEligibilityToRateCreatorAPI}${props.micrositeData.userId}`;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response === 1) {
                setCanStudentPostReview(AppConstants.trueText);
            } else {
                setCanStudentPostReview(AppConstants.falseText);
            }
        }


        if (props.micrositeData?.userId) {
            checkStudentReviewEligiblity();
        }

    }, [props.micrositeData?.userId]);

    useEffect(() => {
        setMicrositeReviews(props.micrositeReviews);
        if (props.micrositeReviews?.length > 0) {
            if (Auth.isLogin() && Auth.getUserRole() === AppConstants.userRoleStudent) {
                setHasStudentProvidedReview(props.micrositeReviews.some(review => review.reviewerUserQrenciaId === Auth.getLoggedInUserDetails().userId));
            }
        }
    }, [props.micrositeReviews]);

    const updateFollowStatus = async (followParam) => {
        setFollowSpinner(AppConstants.trueText);
        let status = followParam ? followParam : followUnfollowStatus ? AppConstants.followUnfollowStatus.unfollow : AppConstants.followUnfollowStatus.follow;
        const url = `${AppConstants.followUnfollowUserAPI}${micrositeDetails.agencyUserUniqueId}&status=${status}`;
        const response = await AuthorizedDataService.postRequestWithResponseCode(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString,
        );
        if (response?.status === AppConstants.httpResponseCodes.responseCode409) {
            status === AppConstants.followUnfollowStatus.follow ? setModalAlertText(`${ErrorMessages.youHaveAlreadyFollowedText} ${micrositeDetails?.agencyName}`) : setModalAlertText(`${ErrorMessages.youHaveAlreadyUnfollowedText} ${micrositeDetails?.agencyName}`)
            setModalTypeError(AppConstants.trueText);
            setShowModal(AppConstants.trueText);
            setFollowSpinner(AppConstants.falseText);
        } else if (response?.status === AppConstants.httpResponseCodes.responseCode400) { // Acadmey follows academy
            const body = await response.json();
            if (body === -22) {
                // Agency cannot follow Agency (School, Skill Org, Housing Society)
                setModalAlertText(ErrorMessages.agencyFollowAgencyError);
            } else if (body === -21) {
                // academy cannot follow other agency
                setModalAlertText(ErrorMessages.academyFollowAgencyError);
            }
            setModalTypeError(AppConstants.trueText);
            setShowModal(AppConstants.trueText);
            setFollowSpinner(AppConstants.falseText);
        } else if (response && response.status === AppConstants.httpResponseCodes.responseCode200) {
            status === AppConstants.followUnfollowStatus.follow ? setModalAlertText(`You have started following ${micrositeDetails?.agencyName}`) : setModalAlertText(`You have unfollowed ${micrositeDetails?.agencyName}`)
            setModalTypeError(AppConstants.falseText);
            status === AppConstants.followUnfollowStatus.follow ? setFollowUnfollowStatus(AppConstants.trueText) : setFollowUnfollowStatus(AppConstants.falseText);
            setFollowSpinner(AppConstants.falseText);
            if (followParam) {
                window.location.reload();
            }
        } else if (response?.status === AppConstants.httpResponseCodes.responseCode403) {
            setModalAlertText(ErrorMessages.sessionExpiredFollowError);
            setModalTypeError(AppConstants.trueText);
            setShowModal(AppConstants.trueText);
            setFollowSpinner(AppConstants.falseText);
        } else {
            status === AppConstants.followUnfollowStatus.follow ? setModalAlertText(`${ErrorMessages.followingErrorMessage} ${micrositeDetails?.agencyName}. ${ErrorMessages.pleaseTryAgainText}`) : setModalAlertText(`${ErrorMessages.unfollowingErrorMessage} ${micrositeDetails?.agencyName}. ${ErrorMessages.pleaseTryAgainText}`)
            setModalTypeError(AppConstants.trueText);
            setShowModal(AppConstants.trueText);
            setFollowSpinner(AppConstants.falseText);
        }
    };

    const handleModalClose = () => {
        if (loginFollowFlag) {
            window.location.reload();
        }
        setShowModal(false);
        setLoginFollowFlag(AppConstants.falseText);
    };

    const followUserWithLogin = () => {
        setLoginFollowFlag(AppConstants.trueText);
        setShowLoginModal(AppConstants.trueText);
    };

    const hideLoginModal = () => {
        if (Auth.isLogin()) {
            if (Auth.getUserRole() === AppConstants.userRoleStudent || Auth.getUserRole() === AppConstants.userRoleAgency) {
                updateFollowStatus(AppConstants.followUnfollowStatus.follow);
            } else {
                const userDetails = Auth.getLoggedInUserDetails();
                setModalAlertText(`Hi ${userDetails.firstName}, ${ErrorMessages.expertFollowErrorMessage}`);
                setModalTypeError(AppConstants.trueText);
                setShowModal(AppConstants.trueText);
            }
        }
        setShowLoginModal(AppConstants.falseText);
    };

    const goToReviewSection = () => {
        const scrollToChallenge = () => {
            window.scrollTo({
                top: reviewsRef?.current?.offsetTop,
                behavior: 'smooth'
            })
        }
        if (micrositeReviews.length > 0) {
            scrollToChallenge();
        }
    }

    const closeReviewModal = () => {
        setShowReviewModal(AppConstants.falseText);
    };

    const handleMediaGalleryShow = (value) => {
        setShowMediaGallery(AppConstants.trueText);
        setSelectedMedia(value);
    };

    const hanldeGalleryClose = () => {
        setSelectedMedia(AppConstants.emptyString);
        setShowMediaGallery(AppConstants.falseText);
    };

    const initiateRaiseNudge = () => {
        if (Auth.isLogin()) {
            setShowNudgeModal(AppConstants.trueText);
        } else {
            setShowRaiseNudgeLoginModal(AppConstants.trueText);
        }
    };

    const handleNudgeModalClose = () => {
        if (isUserLoggedInFromModal) {
            window.location.reload();
        } else {
            setShowNudgeModal(AppConstants.falseText);
        }
    };


    const hideNudgeFormBackground = () => {
        setDisplayNudgeBackground(AppConstants.falseText);
    };

    const closeRaiseNudgeLoginModal = () => {
        if (Auth.isLogin()) {
            setIsUserLoggedInFromModal(AppConstants.trueText);
            setShowNudgeModal(AppConstants.trueText);
        }
        setShowRaiseNudgeLoginModal(AppConstants.falseText);
    };

    return (
        <>
            <div className={leftSectionStyles.leftSectionMainCnt}>
                <div className={leftSectionStyles.profilePicMainCnt}>
                    <div className={leftSectionStyles.profilePicPreviewCnt}>
                        {micrositeDetails?.userImageAbsolutePath !== null && micrositeDetails.isAgencyAPartOfQrencia === AppConstants.yText && <img alt='Profile Avtar' className={leftSectionStyles.previewProfilePic} src={micrositeDetails?.userImageAbsolutePath}></img>}
                        {micrositeDetails.isAgencyAPartOfQrencia === AppConstants.nText && <div className={leftSectionStyles.initialsSection}>
                            {micrositeDetails.initials}
                        </div>}
                    </div>
                    <div className={leftSectionStyles.profilePicPreviewDetails}>
                        {<p className={leftSectionStyles.expertName}>{micrositeDetails?.agencyName} <WorldFlag className={leftSectionStyles.expertCountryFlag} code={micrositeDetails?.agencyCountryCode} /></p>}
                        {!Auth.isLogin() && micrositeDetails.isAgencyAPartOfQrencia === AppConstants.yText && <Button className={`${leftSectionStyles.followUnfollowButton} ${leftSectionStyles.follow}`} onClick={followUserWithLogin}>{`+ ${AppConstants.followUnfollowStatus.follow}`} </Button>}
                        {(Auth.isLogin() && micrositeDetails.isAgencyAPartOfQrencia === AppConstants.yText && (Auth.getUserRole() === AppConstants.userRoleStudent || (Auth.getUserRole() === AppConstants.userRoleAgency && micrositeDetails.agencyType === AppConstants.agencyTypes.academy && loggedInAgencyType !== null && loggedInAgencyType !== AppConstants.agencyTypes.academy)) && followUnfollowStatus) ? <Button className={`${leftSectionStyles.followUnfollowButton} ${leftSectionStyles.following}`} onClick={() => updateFollowStatus()}>{AppConstants.followUnfollowStatus.following} {followSpinner && <BiLoaderAlt className='spinner' />}</Button> : null}
                        {(Auth.isLogin() && micrositeDetails.isAgencyAPartOfQrencia === AppConstants.yText && (Auth.getUserRole() === AppConstants.userRoleStudent || (Auth.getUserRole() === AppConstants.userRoleAgency && micrositeDetails.agencyType === AppConstants.agencyTypes.academy && loggedInAgencyType !== null && loggedInAgencyType !== AppConstants.agencyTypes.academy)) && !followUnfollowStatus) ? <Button className={`${leftSectionStyles.followUnfollowButton} ${leftSectionStyles.follow}`} onClick={() => updateFollowStatus()}>{followSpinner ? `${AppConstants.followUnfollowStatus.follow}` : `+ ${AppConstants.followUnfollowStatus.follow}`} {followSpinner && <BiLoaderAlt className='spinner' />}</Button> : null}
                        {micrositeDetails.isAgencyAPartOfQrencia === AppConstants.yText && (!Auth.isLogin() || (Auth.isLogin() && Auth.getUserRole() === AppConstants.userRoleStudent)) && micrositeDetails.agencyType === AppConstants.agencyTypes.academy && <Button className={`${leftSectionStyles.followUnfollowButton} ${leftSectionStyles.writeReview}`} onClick={initiateRaiseNudge}><MdInsertComment /> Raise a Nudge</Button>}
                        {Auth.isLogin() && micrositeDetails.isAgencyAPartOfQrencia === AppConstants.yText && canStudentPostReview && !hasStudentProvidedReview && <Button className={`${leftSectionStyles.followUnfollowButton} ${leftSectionStyles.writeReview}`} onClick={() => setShowReviewModal(AppConstants.trueText)}><GoPencil /> Write a review</Button>}
                    </div>
                </div>
                {(micrositeDetails?.agencyRating ||
                    micrositeDetails?.studentsEngagedCount ||
                    micrositeDetails?.yearsOfExperience ||
                    micrositeDetails.topAgencyFlag === AppConstants.yText) &&
                    <div className={leftSectionStyles.infoBarContainer}>
                        {micrositeDetails?.agencyRating && <div onClick={goToReviewSection} className={leftSectionStyles.ratingCnt}>
                            <div>
                                {micrositeDetails?.agencyRating && AppConstants.rateNudge5starArray.map((value) => (
                                    <span
                                        key={value}
                                        className={leftSectionStyles.ratingStars}>
                                        {value <= (micrositeDetails?.agencyRating?.substr(0, 1) || 0) ? <AiFillStar /> : <AiOutlineStar />}
                                    </span>
                                ))}
                                <span className={leftSectionStyles.ratingText}>{`${micrositeDetails.reviewCount > 0 ? `(${micrositeDetails.reviewCount} ${micrositeDetails.reviewCount > 1 ? "Reviews" : "Review"})` : ""}`}</span>
                            </div>
                        </div>}
                        {micrositeDetails?.studentsEngagedCount > 0 && <div className={leftSectionStyles.studentEngagedCnt}>
                            <FaUserGraduate />
                            <p>{MathUtil.formatNumberToLocaleString(micrositeDetails?.studentsEngagedCount)}{AppConstants.expertMicroSiteStaticText.studentsEngagedText}</p>
                        </div>}
                        {micrositeDetails?.yearsOfExperience && <div className={leftSectionStyles.studentEngagedCnt}>
                            <AiFillClockCircle />
                            <p>{micrositeDetails?.yearsOfExperience}{AppConstants.expertMicroSiteStaticText.yearsOfExperienceText}</p>
                        </div>}
                        {micrositeDetails.topAgencyFlag === AppConstants.yText && <div className={leftSectionStyles.studentEngagedCnt}>
                            <FaAward />
                            <p>Top Agency</p>
                        </div>}
                        {micrositeDetails.followersCount >= minimumFollowersCount && <div className={leftSectionStyles.studentEngagedCnt}>
                            <MdPeopleAlt />
                            <p>{MathUtil.formatNumberToLocaleString(micrositeDetails.followersCount)} {micrositeDetails.followersCount > 1 ? "Followers" : "Follower"}</p>
                        </div>}
                    </div>}

                <div className={`${leftSectionStyles.orgNameContainer} mt-3`}>
                    <p className={leftSectionStyles.aboutExpertHeading}>About {micrositeDetails?.agencyName}</p>
                </div>

                {<div>
                    <div className={leftSectionStyles.aboutExpertSection}>
                        <p className={leftSectionStyles.aboutExpertSubHeading}>{micrositeDetails?.agencyDescription}</p>
                        <div className={leftSectionStyles.organizationContactContainer}>
                            <div className={leftSectionStyles.organizationContactInnerCnt}>
                                <span><BsBuilding /></span>
                                {micrositeDetails?.agencyCity !== null && micrositeDetails?.agencyState !== null && micrositeDetails?.agencyCountryName !== null && <p>{micrositeDetails?.agencyCity}, {micrositeDetails?.agencyState}, {micrositeDetails?.agencyCountryName}</p>}
                                {micrositeDetails?.agencyCity == null && micrositeDetails?.agencyState !== null && micrositeDetails?.agencyCountryName !== null && <p>{micrositeDetails?.agencyCity} {micrositeDetails?.agencyState}, {micrositeDetails?.agencyCountryName}</p>}
                                {micrositeDetails?.agencyCity == null && micrositeDetails?.agencyState == null && micrositeDetails?.agencyCountryName !== null && <p>{micrositeDetails?.agencyCity} {micrositeDetails?.agencyState} {micrositeDetails?.agencyCountryName}</p>}
                                {micrositeDetails?.agencyCity !== null && micrositeDetails?.agencyState == null && micrositeDetails?.agencyCountryName !== null && <p>{micrositeDetails?.agencyCity}, {micrositeDetails?.agencyState} {micrositeDetails?.agencyCountryName}</p>}
                            </div>
                            {micrositeDetails?.agencyContactNumber && <div className={leftSectionStyles.organizationContactInnerCnt}>
                                <span><FiSmartphone /></span>
                                <p>{micrositeDetails?.agencyCountryMobileNumberCode}-{micrositeDetails?.agencyContactNumber}</p>
                            </div>}
                            {micrositeDetails?.agencyEmail && <div className={leftSectionStyles.organizationContactInnerCnt}>
                                <span> <FiMail /></span>
                                <p>{micrositeDetails?.agencyEmail}</p>
                            </div>}
                            {(micrositeDetails?.agencyGeoLocation || micrositeDetails?.agencyAddress) && <div className={leftSectionStyles.organizationContactInnerCnt}>
                                {<a href={micrositeDetails?.agencyGeoLocation} target={AppConstants.openInNewTab} className={leftSectionStyles.locationIcon}><BiMap /></a>}
                                {<p href={micrositeDetails?.agencyGeoLocation} target={AppConstants.openInNewTab}>{micrositeDetails?.agencyAddress}</p>}
                            </div>}
                        </div>

                    </div>
                </div>}
                {micrositeDetails.agencyCentresDetails !== null && micrositeDetails?.agencyCentresDetails?.length !== zeroText &&
                    <p className={`${leftSectionStyles.aboutExpertHeading} mb-2 mt-3`}>Our Centres</p>}
                {micrositeDetails.agencyCentresDetails !== null && micrositeDetails?.agencyCentresDetails?.length !== zeroText && <div>
                    {micrositeDetails.agencyCentresDetails !== null && micrositeDetails?.agencyCentresDetails?.map((center, index) => (
                        <div key={index} className={leftSectionStyles.aboutExpertSection}>
                            <div className={leftSectionStyles.orgNameContainer}>
                                <p className={leftSectionStyles.aboutExpertHeading}>{center?.centreName}</p>
                            </div>
                            <div className={leftSectionStyles.organizationContactContainer}>
                                {(center?.centreCity || center?.centreState || center?.centreCountryName) &&
                                    <div className={leftSectionStyles.organizationContactInnerCnt}>
                                        <span><BsBuilding /></span>
                                        {center?.centreCity !== null && center?.centreState !== null && center?.centreCountryName !== null && <p>{center?.centreCity}, {center?.centreState}, {center?.centreCountryName}</p>}
                                        {center?.centreCity == null && center?.centreState == null && center?.centreCountryName !== null && <p>{center?.centreCity} {center?.centreState} {center?.centreCountryName}</p>}
                                        {center?.centreCity != null && center?.centreState == null && center?.centreCountryName !== null && <p>{center?.centreCity}, {center?.centreState} {center?.centreCountryName}</p>}
                                        {center?.centreCity == null && center?.centreState != null && center?.centreCountryName !== null && <p>{center?.centreCity} {center?.centreState}, {center?.centreCountryName}</p>}
                                    </div>}
                                {center?.centreSpocPhoneNumber && <div className={leftSectionStyles.organizationContactInnerCnt}>
                                    <span><FiSmartphone /></span>
                                    <p>{center.countryMobileNumberCode}-{center?.centreSpocPhoneNumber}</p>
                                </div>}
                                {center?.centreSpocEmail && <div className={leftSectionStyles.organizationContactInnerCnt}>
                                    <span> <FiMail /></span>
                                    <p>{center?.centreSpocEmail}</p>
                                </div>}
                                {(center?.centreGeoLocation || center?.centreAddress) && <div className={leftSectionStyles.organizationContactInnerCnt}>
                                    {<a href={center?.centreGeoLocation} target={AppConstants.openInNewTab} className={leftSectionStyles.locationIcon}><BiMap /></a>}
                                    {<p href={center?.centreGeoLocation} target={AppConstants.openInNewTab}>{center?.centreAddress}</p>}
                                </div>}
                            </div>
                        </div>))}
                </div>}
                {micrositeDetails && <div className={rightSectionStyles.showOnMobileScreen}>
                    {(micrositeDetails?.agencyFacebookLink ||
                        micrositeDetails?.agencyLinkedinLink ||
                        micrositeDetails?.agencyWebsiteLink ||
                        micrositeDetails?.agencyYoutubeLink ||
                        micrositeDetails?.agencyInstagramLink || micrositeDetails.selectedSkills?.length > 0) ?
                        <div className={rightSectionStyles.aboutExpertContainer}>
                            {micrositeDetails.agencyType === AppConstants.agencyTypes.academy && micrositeDetails?.selectedSkills?.length > 0 && <p className={rightSectionStyles.aboutExpertHeading}>Skills</p>}
                            {micrositeDetails.agencyType === AppConstants.agencyTypes.academy && micrositeDetails?.selectedSkills?.length > 0 && <div className={`${rightSectionStyles.skillsContainer} mb-3`}>
                                {micrositeDetails?.selectedSkills?.map((skill, index) => (
                                    <p key={index} className={rightSectionStyles.skillsText}>
                                        {skill.skillName}
                                    </p>))}
                            </div>}
                            {(micrositeDetails?.agencyFacebookLink ||
                                micrositeDetails?.agencyLinkedinLink ||
                                micrositeDetails?.agencyWebsiteLink ||
                                micrositeDetails?.agencyYoutubeLink ||
                                micrositeDetails?.agencyInstagramLink) && <>
                                    <p className={rightSectionStyles.aboutExpertHeading}>{AppConstants.expertMicroSiteStaticText.socialLinksText}</p>
                                    <div className={rightSectionStyles.skillsContainer}>
                                        {micrositeDetails?.agencyFacebookLink && <a href={micrositeDetails?.agencyFacebookLink} target={AppConstants.openInNewTab}><TfiFacebook /></a>}
                                        {micrositeDetails?.agencyLinkedinLink && <a href={micrositeDetails?.agencyLinkedinLink} target={AppConstants.openInNewTab}><TfiLinkedin /></a>}
                                        {micrositeDetails?.agencyWebsiteLink && <a href={micrositeDetails?.agencyWebsiteLink} target={AppConstants.openInNewTab}><FaGlobeAsia /></a>}
                                        {micrositeDetails?.agencyYoutubeLink && <a href={micrositeDetails?.agencyYoutubeLink} target={AppConstants.openInNewTab}><FaYoutube /></a>}
                                        {micrositeDetails?.agencyInstagramLink && <a href={micrositeDetails?.agencyInstagramLink} target={AppConstants.openInNewTab}><AiFillInstagram /></a>}
                                    </div>
                                </>}
                        </div> : <div className='mt-4'></div>}
                    {micrositeDetails.videoMessageAbsolutePath !== null && <div className={rightSectionStyles.videoReviewSection}>
                        <p className={leftSectionStyles.aboutExpertHeading}>Video Message</p>
                        <div className={rightSectionStyles.promotionVideoSection}>
                            {isVideoPlaying ? (<div>
                                <video controls autoPlay className={rightSectionStyles.videoContent}>
                                    <source src={micrositeDetails.videoMessageAbsolutePath} type={AppConstants.contentTypeMap.mp4} />
                                </video>
                                <IoMdClose className={rightSectionStyles.closeButton} onClick={handleClose} />
                            </div>
                            ) : <img src={uploadedVideoMessageThumbnailImage || videoMessageThumbnail} className={rightSectionStyles.videoContent} alt="Video Message Thumbnail" />}
                            {showPlayIcon && <img src={videoPlayIconUrl} onClick={playVideo} alt="Video Play Button" className={rightSectionStyles.videoIcon} />}
                            <div>

                            </div>
                        </div>
                    </div>}
                </div>}

                {props.creatorGalleryContent.length > 0 && <div className={leftSectionStyles.coursesSection}>
                    <p className={`${leftSectionStyles.aboutExpertHeading} mb-2`}>Spotlight</p>
                    <div className={leftSectionStyles.mediaContentContainer}>
                        {props.creatorGalleryContent.slice(0, 4).map((value, index) => (
                            value.type === AppConstants.mediaTypes.image ? (
                                <div className={leftSectionStyles.imageBox} key={index}>
                                    <img className={`${leftSectionStyles.gallaryImage} ${props.creatorGalleryContent.length > AppConstants.micrositeGalleryImagesCount && index === AppConstants.micrositeGalleryImagesCount - 1 && leftSectionStyles.fadeImageBackground}`} onClick={() => handleMediaGalleryShow(value)} src={value.url} alt="Gallery" />
                                    {props.creatorGalleryContent.length > AppConstants.micrositeGalleryImagesCount && index === AppConstants.micrositeGalleryImagesCount - 1 && <div onClick={() => handleMediaGalleryShow(value)} className={leftSectionStyles.overLay}>+ {props.creatorGalleryContent.length - AppConstants.micrositeGalleryImagesCount}</div>}
                                    {props.creatorGalleryContent.length > AppConstants.micrositeGalleryImagesCount && index === AppConstants.micrositeGalleryImagesCount - 1 && <div onClick={() => handleMediaGalleryShow(value)} className={leftSectionStyles.fadeImageBackground}></div>}
                                </div>
                            ) : (
                                <div className={leftSectionStyles.imageBox} key={index}>
                                    <video controls autoPlay={false} className={`${leftSectionStyles.gallaryImage} ${props.creatorGalleryContent.length > AppConstants.micrositeGalleryImagesCount && index === AppConstants.micrositeGalleryImagesCount - 1 && leftSectionStyles.fadeImageBackground}`} onClick={() => handleMediaGalleryShow(value)}>
                                        <source src={value.url} />
                                    </video>
                                    <img onClick={() => handleMediaGalleryShow(value)}
                                        className={leftSectionStyles.gallaryVideoPlayIcon}
                                        src={videoPlayIconUrl}
                                        alt="Video Play Button"
                                    />
                                    {props.creatorGalleryContent.length > AppConstants.micrositeGalleryImagesCount && index === AppConstants.micrositeGalleryImagesCount - 1 && <div onClick={() => handleMediaGalleryShow(value)} className={leftSectionStyles.overLay}>+ {props.creatorGalleryContent.length - AppConstants.micrositeGalleryImagesCount}</div>}
                                    {props.creatorGalleryContent.length > AppConstants.micrositeGalleryImagesCount && index === AppConstants.micrositeGalleryImagesCount - 1 && <div onClick={() => handleMediaGalleryShow(value)} className={leftSectionStyles.fadeImageBackground}></div>}
                                </div>
                            )
                        ))}
                    </div>
                </div>}
                {showMediaGallery && <ImageGalleryModal creatorGalleryContent={props.creatorGalleryContent} selectedMediaContent={selectedMedia} hanldeGalleryClose={hanldeGalleryClose} creatorName={micrositeDetails?.agencyName} />}

                {associatedExperts && associatedExperts.length !== zeroText && <div className={leftSectionStyles.coursesSection}>
                    <p className={`${leftSectionStyles.aboutExpertHeading} mb-2`}>Associated Experts</p>
                    <MicrositeAssociatedExpertCards loggedInAgencyType={loggedInAgencyType} associatedExperts={associatedExperts} />
                </div>}
                {props.courseDetails && props.courseDetails?.length !== zeroText && <Element id={AppConstants.micrositeTopNavigationSections.courses}>
                    <div className={leftSectionStyles.coursesSection}>
                        <p className={`${leftSectionStyles.aboutExpertHeading} mb-2`}>{sectionNames.courses}</p>
                        <MicrositeCoursesSection category="Course" courseData={props.courseDetails} userRoleForMicrosite={AppConstants.userRoleAgency} />
                    </div>
                </Element>}
                {props.competitionDetails && props.competitionDetails?.length !== zeroText && <Element id={AppConstants.micrositeTopNavigationSections.competitions}>
                    <div className={leftSectionStyles.coursesSection}>
                        <p className={`${leftSectionStyles.aboutExpertHeading} mb-2`}>{sectionNames.competitions}</p>
                        <MicrositeCompetitionsSection signupMinimumAge={signupMinimumAge} competitionsData={props.competitionDetails} userRoleForMicrosite={AppConstants.userRoleAgency} />
                    </div>
                </Element>}

                {props.workshopDetails && props.workshopDetails?.length !== zeroText && <Element id={AppConstants.micrositeTopNavigationSections.workshops}>
                    <div className={leftSectionStyles.coursesSection}>
                        <p className={`${leftSectionStyles.aboutExpertHeading} mb-2`}>{sectionNames.workshops}</p>
                        <MicrositeWorkshopSection workshopsData={props.workshopDetails} userRoleForMicrosite={AppConstants.userRoleAgency} />
                    </div>
                </Element>}
                {micrositeReviews && micrositeReviews.length > 0 && <Element id={AppConstants.micrositeTopNavigationSections.reviews}>
                    <div ref={reviewsRef} className={leftSectionStyles.coursesSection}>
                        <p className={`${leftSectionStyles.aboutExpertHeading} mb-2`}>{sectionNames.reviews}</p>
                        <MicrositeReviewComponent micrositeReviews={micrositeReviews} />
                    </div>
                </Element>
                }
                {micrositeDetails && <div className={rightSectionStyles.showOnMobileScreen}>
                    {micrositeDetails.promotionImagePathOne !== null && <div className={`${rightSectionStyles.advertismentSection} mb-4 mt-3`}>
                        <div className={rightSectionStyles.promotionVideoSection}>
                            <img src={micrositeDetails.promotionImagePathOne} className={rightSectionStyles.advertismentImage} alt="Advertisment" />
                        </div>
                    </div>}
                </div>}
            </div>
            {showReviewModal && <SubmitReviewModal fetchReviewForUser={fetchReviewForUser} closeReviewModal={closeReviewModal} micrositeUserId={micrositeDetails.userId} micrositeUserRole={AppConstants.userRoleAgency} micrositeUserName={micrositeDetails.agencyName} />}
            <Modal show={showModal} onHide={handleModalClose} backdrop="static" keyboard={false} centered>
                {showModal === true && <Modal.Body className={modalClass.modalBody}>
                    {(modalTypeError === AppConstants.falseText) && <div className={modalClass.modalSuccessIcon}><AiOutlineCheckCircle /></div>}
                    {(modalTypeError === AppConstants.trueText) && <div className={modalClass.modalErrorIcon}><AiOutlineExclamationCircle /></div>}
                    <div className={modalClass.modalAlertText}>{modalAlertText}</div>
                </Modal.Body>}
                {showModal === true && <Modal.Footer className={modalClass.modalFooter}>
                    {<Button className={modalClass.modalCloseButton} onClick={handleModalClose}>Close</Button>}
                </Modal.Footer>}
            </Modal>
            {showNudgeModal && <Modal size={displayNudgeBackground ? "lg" : "sm"} show={showNudgeModal} onHide={handleNudgeModalClose} className={!displayNudgeBackground && leftSectionStyles.nudgePopup} backdrop="static" keyboard={false} centered>
                {displayNudgeBackground && <Modal.Header closeButton>
                    <Modal.Title className={leftSectionStyles.modalTitle}>Raise Nudge to  {micrositeDetails?.agencyName}</Modal.Title>
                </Modal.Header>}
                <Modal.Body className={modalClass.modalBody}>
                    {Auth.getUserRole() === AppConstants.userRoleStudent ?
                        <StudentRaiseNudgeForm category={AppConstants.learningActivitiesTypes.nudge} expertSkills={micrositeDetails?.selectedSkills} expertUniqueId={micrositeDetails.agencyUserUniqueId} hideNudgeFormBackground={hideNudgeFormBackground} /> :
                        <p className={leftSectionStyles.errorContainer}>Only Students can raise nudge!</p>}
                </Modal.Body>
            </Modal>}
            {showRaiseNudgeLoginModal && <LoginModal signupMessage="Signup to raise nudge" loginMessage="Login to raise nudge" onShow={() => setShowLoginModal(AppConstants.trueText)} onClose={closeRaiseNudgeLoginModal} />}
            {showLoginModal && <LoginModal signupMessage={AppConstants.signupFollowMessage} loginMessage={AppConstants.loginFollowMessage} onShow={() => setShowLoginModal(AppConstants.trueText)} onClose={hideLoginModal} />}
        </>
    );
}