import React, { useState, useEffect, useRef, useCallback } from 'react';
import leftSectionStyles from './MicrositePageLeftSection.module.css';
import rightSectionStyles from './MicrositePageRightSection.module.css';
import { AppConstants } from '../../constants/AppConstants';
import { FaUserGraduate } from 'react-icons/fa';
import { BsBuilding } from 'react-icons/bs';
import { FiMail, FiSmartphone, } from 'react-icons/fi';
import { TfiLinkedin, TfiFacebook } from 'react-icons/tfi';
import { AiFillClockCircle, AiFillStar, AiOutlineStar, AiFillInstagram, AiOutlineCheckCircle, AiOutlineExclamationCircle } from 'react-icons/ai';
import { FaAward, FaGlobeAsia, FaYoutube } from 'react-icons/fa';
import { MicrositeCoursesSection } from './MicrositeCoursesSection';
import { MicrositeCompetitionsSection } from './MicrositeCompetitionsSection';
import Auth from "../../utils/Auth";
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import { DataService } from '../../services/DataService';
import { IoMdClose } from 'react-icons/io';
import { MicrositeWorkshopSection } from './MicrositeWorkshopSection';
import { BiLoaderAlt, BiMap } from 'react-icons/bi';
import WorldFlag from 'react-world-flags';
import { DateTimeUtil } from '../../utils/DateTimeUtil';
import { Modal, Button } from 'react-bootstrap';
import modalClass from "../cards/CompetitionsCards.module.css";
import { MdInsertComment, MdPeopleAlt } from 'react-icons/md';
import { LoginModal } from '../modal/LoginModal';
import { ErrorMessages } from '../../constants/ErrorMessages';
import { MicrositeReviewComponent } from '../micrositeReviewComponent/MicrositeReviewComponent';
import { GoPencil } from "react-icons/go";
import { SubmitReviewModal } from '../modal/SubmitReviewModal';
import { MathUtil } from '../../utils/MathUtil';
import { ImageGalleryModal } from '../modal/ImageGalleryModal';
import { StudentRaiseNudgeForm } from '../formControls/StudentRaiseNudgeForm';
import { Element } from 'react-scroll';

export const MicrositePageLeftSection = (props) => {
    const [micrositeDetails, setMicrositeDetails] = useState(props.micrositeData);
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const videoPlayIconUrl = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.videoPlayIconImagePath}`;
    const videoMessageThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.expertMicroSiteS3ImagesPath}/${AppConstants.expertVideoMessageThumbnailPath}`;
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [showPlayIcon, setShowPlayIcon] = useState(true);
    const [zeroText, setZeroText] = useState(0);
    const preferredTimezone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [showModal, setShowModal] = useState(AppConstants.falseText);
    const [modalAlertText, setModalAlertText] = useState(AppConstants.falseText);
    const [modalTypeError, setModalTypeError] = useState(AppConstants.falseText);
    const [minimumFollowersCount, setMinimumFollowersCount] = useState([]);
    const [uploadedVideoMessageThumbnailImage, setUploadedVideoMessageThumbnailImage] = useState(props.micrositeData.videoMessageThumbnailPath);
    const [followUnfollowStatus, setFollowUnfollowStatus] = useState(AppConstants.falseText);
    const [followSpinner, setFollowSpinner] = useState(AppConstants.falseText);
    const [showLoginModal, setShowLoginModal] = useState(AppConstants.emptyString);
    const [loginFollowFlag, setLoginFollowFlag] = useState(AppConstants.falseText);
    const [showReviewModal, setShowReviewModal] = useState(AppConstants.falseText);
    const [canStudentPostReview, setCanStudentPostReview] = useState(AppConstants.trueText);
    const [hasStudentProvidedReview, setHasStudentProvidedReview] = useState(AppConstants.falseText);
    const [micrositeReviews, setMicrositeReviews] = useState([]);
    const [showMediaGallery, setShowMediaGallery] = useState(AppConstants.falseText);
    const [selectedMedia, setSelectedMedia] = useState(AppConstants.emptyString);
    const [showRaiseNudgeLoginModal, setShowRaiseNudgeLoginModal] = useState(AppConstants.falseText);
    const [showNudgeModal, setShowNudgeModal] = useState(AppConstants.falseText);
    const [displayNudgeBackground, setDisplayNudgeBackground] = useState(AppConstants.trueText);
    const [isUserLoggedInFromModal, setIsUserLoggedInFromModal] = useState(AppConstants.falseText);
    const [signupMinimumAge, setSignupMinimumAge] = useState(AppConstants.emptyString);
    const sectionNames = AppConstants.micrositeTopNavigationSections;

    const reviewsRef = useRef(null);

    const playVideo = () => {
        setIsVideoPlaying(true);
        setShowPlayIcon(false);
    };


    const handleClose = () => {
        setIsVideoPlaying(false);
        setShowPlayIcon(true);
    };

    useEffect(() => {
        const fetchConfigurableKeys = async () => {
            const url = AppConstants.fetchConfigurableKeysAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setMinimumFollowersCount(parseInt(response.find(item => item.keyName === AppConstants.minimumFollowersCountConfigurableKey).keyValue));
                setSignupMinimumAge(parseInt(response.find(item => item.keyName === AppConstants.signupMinimumAgeKey).keyValue));
            }
        };
        fetchConfigurableKeys();
        const fetchFollowUnfollowStatus = async () => {
            const url = `${AppConstants.checkUserFollowStatusAPI}${micrositeDetails?.uniqueId}`;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString,
            );

            if (response) {
                setFollowUnfollowStatus(AppConstants.trueText);
            } else {
                setFollowUnfollowStatus(AppConstants.falseText);
            }
        }
        if (micrositeDetails.uniqueId && Auth.isLogin() && (Auth.getUserRole() === AppConstants.userRoleAgency || Auth.getUserRole() === AppConstants.userRoleStudent)) {
            fetchFollowUnfollowStatus();
        }
    }, [micrositeDetails]);

    const fetchReviewForUser = useCallback(async () => {
        const url = `${AppConstants.getCreatorReviewsForMicrositeAPI}${props.micrositeData.uniqueId}`;
        const response = await DataService.get(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        );

        if (response) {
            if (Auth.isLogin() && Auth.getUserRole() === AppConstants.userRoleStudent) {
                setHasStudentProvidedReview(response.some(review => review.reviewerUserQrenciaId === Auth.getLoggedInUserDetails().userId));
            }
            setMicrositeReviews(response)
        } else {
            setMicrositeReviews([]);
        }
    }, [props.micrositeData?.uniqueId]);

    useEffect(() => {
        const checkStudentReviewEligiblity = async () => {
            const url = `${AppConstants.checkStudentEligibilityToRateCreatorAPI}${props.micrositeData.id}`;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response === 1) {
                setCanStudentPostReview(AppConstants.trueText);
            } else {
                setCanStudentPostReview(AppConstants.falseText);
            }
        }

        if (props.micrositeData?.id) {
            checkStudentReviewEligiblity();
        }

    }, [props.micrositeData?.id]);

    useEffect(() => {
        setMicrositeReviews(props.micrositeReviews);
        if (props.micrositeReviews?.length > 0) {
            if (Auth.isLogin() && Auth.getUserRole() === AppConstants.userRoleStudent) {
                setHasStudentProvidedReview(props.micrositeReviews.some(review => review.reviewerUserQrenciaId === Auth.getLoggedInUserDetails().userId));
            }
        }
    }, [props.micrositeReviews]);

    const updateFollowStatus = async (followParam) => {
        setFollowSpinner(AppConstants.trueText);
        let status = followParam ? followParam : followUnfollowStatus ? AppConstants.followUnfollowStatus.unfollow : AppConstants.followUnfollowStatus.follow;
        const url = `${AppConstants.followUnfollowUserAPI}${micrositeDetails.uniqueId}&status=${status}`;
        const response = await AuthorizedDataService.postRequestWithResponseCode(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString,
        );
        if (response?.status === AppConstants.httpResponseCodes.responseCode409) {
            status === AppConstants.followUnfollowStatus.follow ? setModalAlertText(`You have already followed ${micrositeDetails?.firstName} ${micrositeDetails?.lastName}`) : setModalAlertText(`You have already unfollowed ${micrositeDetails?.firstName} ${micrositeDetails?.lastName}`)
            setModalTypeError(AppConstants.trueText);
            setShowModal(AppConstants.trueText);
            setFollowSpinner(AppConstants.falseText);
        } else if (response && response.status === AppConstants.httpResponseCodes.responseCode200) {
            status === AppConstants.followUnfollowStatus.follow ? setModalAlertText(`You have started following ${micrositeDetails?.firstName} ${micrositeDetails?.lastName}`) : setModalAlertText(`You have unfollowed ${micrositeDetails?.firstName} ${micrositeDetails?.lastName}`)
            setModalTypeError(AppConstants.falseText);
            status === AppConstants.followUnfollowStatus.follow ? setFollowUnfollowStatus(AppConstants.trueText) : setFollowUnfollowStatus(AppConstants.falseText);
            setFollowSpinner(AppConstants.falseText);
            if (followParam) {
                window.location.reload();
            }
        } else if (response?.status === AppConstants.httpResponseCodes.responseCode403) {
            setModalAlertText(ErrorMessages.sessionExpiredFollowError);
            setModalTypeError(AppConstants.trueText);
            setShowModal(AppConstants.trueText);
            setFollowSpinner(AppConstants.falseText);
        } else {
            status === AppConstants.followUnfollowStatus.follow ? setModalAlertText(`Something went wrong while following ${micrositeDetails?.firstName} ${micrositeDetails?.lastName}. Please try again.`) : setModalAlertText(`Something went wrong while unfollowing ${micrositeDetails?.firstName} ${micrositeDetails?.lastName}. Please try again.`)
            setModalTypeError(AppConstants.trueText);
            setShowModal(AppConstants.trueText);
            setFollowSpinner(AppConstants.falseText);
        }
    }

    const handleModalClose = () => {
        if (loginFollowFlag) {
            window.location.reload();
        }
        setShowModal(false);
        setLoginFollowFlag(AppConstants.falseText);
    };

    const followUserWithLogin = () => {
        setLoginFollowFlag(AppConstants.trueText);
        setShowLoginModal(AppConstants.trueText);
    };

    const hideLoginModal = () => {
        if (Auth.isLogin()) {
            if (Auth.getUserRole() === AppConstants.userRoleStudent || Auth.getUserRole() === AppConstants.userRoleAgency) {
                updateFollowStatus(AppConstants.followUnfollowStatus.follow);
            } else {
                const userDetails = Auth.getLoggedInUserDetails();
                setModalAlertText(`Hi ${userDetails.firstName}, ${Auth.getUserRole() === AppConstants.userRoleExpert ? ErrorMessages.expertFollowErrorMessage : ErrorMessages.adminFollowErrorMessage}`);
                setModalTypeError(AppConstants.trueText);
                setShowModal(AppConstants.trueText);
            }
        }
        setShowLoginModal(AppConstants.falseText);
    };

    const goToReviewSection = () => {
        const scrollToChallenge = () => {
            window.scrollTo({
                top: reviewsRef?.current?.offsetTop,
                behavior: 'smooth'
            })
        }
        if (micrositeReviews && micrositeReviews.length > 0) {
            scrollToChallenge();
        }
    }

    const closeReviewModal = () => {
        setShowReviewModal(AppConstants.falseText);
    };

    const handleMediaGalleryShow = (value) => {
        setShowMediaGallery(AppConstants.trueText);
        setSelectedMedia(value);
    };

    const hanldeGalleryClose = () => {
        setSelectedMedia(AppConstants.emptyString);
        setShowMediaGallery(AppConstants.falseText);
    };

    const initiateRaiseNudge = () => {
        if (Auth.isLogin()) {
            setShowNudgeModal(AppConstants.trueText);
        } else {
            setShowRaiseNudgeLoginModal(AppConstants.trueText);
        }
    };

    const closeRaiseNudgeLoginModal = () => {
        if (Auth.isLogin()) {
            setIsUserLoggedInFromModal(AppConstants.trueText);
            setShowNudgeModal(AppConstants.trueText);
        }
        setShowRaiseNudgeLoginModal(AppConstants.falseText);
    };

    const handleNudgeModalClose = () => {
        if (isUserLoggedInFromModal) {
            window.location.reload();
        } else {
            setShowNudgeModal(AppConstants.falseText);
        }
    };

    const hideNudgeFormBackground = () => {
        setDisplayNudgeBackground(AppConstants.falseText);
    };


    return (
        <>
            <div className={leftSectionStyles.leftSectionMainCnt}>
                <div className={leftSectionStyles.profilePicMainCnt}>
                    <div className={leftSectionStyles.profilePicPreviewCnt}>
                        {micrositeDetails?.expertProfilePicPath !== null && micrositeDetails.isCreatorAPartOfQrencia === AppConstants.yText && <img alt='Profile Avtar' className={leftSectionStyles.previewProfilePic} src={micrositeDetails?.userImagePath}></img>}
                        {micrositeDetails.isCreatorAPartOfQrencia === AppConstants.nText && <div className={leftSectionStyles.initialsSection}>
                            {micrositeDetails.initials}
                        </div>}
                    </div>
                    <div className={leftSectionStyles.profilePicPreviewDetails}>
                        <p className={leftSectionStyles.expertName}>{micrositeDetails?.firstName} {micrositeDetails?.lastName} <WorldFlag className={leftSectionStyles.expertCountryFlag} code={micrositeDetails?.countryCode} /></p>
                        {micrositeDetails?.organizationDetails !== null && <p className={leftSectionStyles.expertSchoolName}>{micrositeDetails?.organizationDetails?.organizationDesignation}, {micrositeDetails?.organizationDetails?.organizationName}</p>}
                        {!Auth.isLogin() && micrositeDetails.isCreatorAPartOfQrencia === AppConstants.yText && <Button className={`${leftSectionStyles.followUnfollowButton} ${leftSectionStyles.follow}`} onClick={followUserWithLogin}>{`+ ${AppConstants.followUnfollowStatus.follow}`} </Button>}
                        {(Auth.isLogin() && micrositeDetails.isCreatorAPartOfQrencia === AppConstants.yText && (Auth.getUserRole() === AppConstants.userRoleStudent || Auth.getUserRole() === AppConstants.userRoleAgency) && followUnfollowStatus) ? <Button className={`${leftSectionStyles.followUnfollowButton} ${leftSectionStyles.following}`} onClick={() => updateFollowStatus()}>{AppConstants.followUnfollowStatus.following} {followSpinner && <BiLoaderAlt className='spinner' />}</Button> : null}
                        {(Auth.isLogin() && micrositeDetails.isCreatorAPartOfQrencia === AppConstants.yText && (Auth.getUserRole() === AppConstants.userRoleStudent || Auth.getUserRole() === AppConstants.userRoleAgency) && !followUnfollowStatus) ? <Button className={`${leftSectionStyles.followUnfollowButton} ${leftSectionStyles.follow}`} onClick={() => updateFollowStatus()}>{followSpinner ? `${AppConstants.followUnfollowStatus.follow}` : `+ ${AppConstants.followUnfollowStatus.follow}`} {followSpinner && <BiLoaderAlt className='spinner' />}</Button> : null}
                        {micrositeDetails.isCreatorAPartOfQrencia === AppConstants.yText && (!Auth.isLogin() || (Auth.isLogin() && Auth.getUserRole() === AppConstants.userRoleStudent)) && <Button className={`${leftSectionStyles.followUnfollowButton} ${leftSectionStyles.writeReview}`} onClick={initiateRaiseNudge}><MdInsertComment /> Raise a Nudge</Button>}
                        {Auth.isLogin() && micrositeDetails.isCreatorAPartOfQrencia === AppConstants.yText && canStudentPostReview && !hasStudentProvidedReview && <Button className={`${leftSectionStyles.followUnfollowButton} ${leftSectionStyles.writeReview}`} onClick={() => setShowReviewModal(AppConstants.trueText)}><GoPencil /> Write a review</Button>}
                    </div>
                </div>
                {(micrositeDetails?.rating ||
                    micrositeDetails?.studentsEngagedCount ||
                    micrositeDetails?.yearsOfExperience ||
                    micrositeDetails.topExpertFlag === AppConstants.yText) &&
                    <div className={leftSectionStyles.infoBarContainer}>
                        {micrositeDetails?.rating && <div onClick={goToReviewSection} className={leftSectionStyles.ratingCnt}>
                            <div>
                                {micrositeDetails?.rating && AppConstants.rateNudge5starArray.map((value) => (
                                    <span
                                        key={value}
                                        className={leftSectionStyles.ratingStars}>
                                        {value <= (micrositeDetails?.rating?.substr(0, 1) || 0) ? <AiFillStar /> : <AiOutlineStar />}
                                    </span>
                                ))}
                                <span className={leftSectionStyles.ratingText}>{`${micrositeDetails.reviewCount > 0 ? `(${micrositeDetails.reviewCount} ${micrositeDetails.reviewCount > 1 ? "Reviews" : "Review"})` : ""}`}</span>
                            </div>
                        </div>}
                        {micrositeDetails?.studentsEngagedCount > 0 && <div className={leftSectionStyles.studentEngagedCnt}>
                            <FaUserGraduate />
                            <p>{MathUtil.formatNumberToLocaleString(micrositeDetails?.studentsEngagedCount)}{AppConstants.expertMicroSiteStaticText.studentsEngagedText}</p>
                        </div>}
                        {micrositeDetails?.yearsOfExperience && <div className={leftSectionStyles.studentEngagedCnt}>
                            <AiFillClockCircle />
                            <p>{micrositeDetails?.yearsOfExperience}{AppConstants.expertMicroSiteStaticText.yearsOfExperienceText}</p>
                        </div>}
                        {micrositeDetails.topExpertFlag === AppConstants.yText && <div className={leftSectionStyles.studentEngagedCnt}>
                            <FaAward />
                            <p>Top Expert</p>
                        </div>}
                        {micrositeDetails.followersCount >= minimumFollowersCount && <div className={leftSectionStyles.studentEngagedCnt}>
                            <MdPeopleAlt />
                            <p>{MathUtil.formatNumberToLocaleString(micrositeDetails.followersCount)} {micrositeDetails.followersCount > 1 ? "Followers" : "Follower"}</p>
                        </div>}
                    </div>}
                {micrositeDetails?.isCreatorAPartOfQrencia === AppConstants.yText && <p className={`${leftSectionStyles.aboutExpertHeading} mt-3`}>About Me</p>}
                {micrositeDetails?.isCreatorAPartOfQrencia === AppConstants.nText && <p className={`${leftSectionStyles.aboutExpertHeading} mt-3`}>About the Expert</p>}
                {micrositeDetails?.isCreatorAPartOfQrencia === AppConstants.yText && <div className={leftSectionStyles.aboutExpertSection}>
                    {micrositeDetails.aboutUser && <p className={leftSectionStyles.aboutExpertSubHeading}>{micrositeDetails?.aboutUser}</p>}
                    <div className={leftSectionStyles.organizationContactContainer}>
                        <div className={leftSectionStyles.organizationContactInnerCnt}>
                            <span><BsBuilding /></span>
                            {micrositeDetails?.city !== null && micrositeDetails?.state !== null && micrositeDetails?.countryName !== null && <p>{micrositeDetails?.city}, {micrositeDetails?.state}, {micrositeDetails?.countryName}</p>}
                            {micrositeDetails?.city == null && micrositeDetails?.state !== null && micrositeDetails?.countryName !== null && <p>{micrositeDetails?.city} {micrositeDetails?.state}, {micrositeDetails?.countryName}</p>}
                            {micrositeDetails?.city == null && micrositeDetails?.state == null && micrositeDetails?.countryName !== null && <p>{micrositeDetails?.city} {micrositeDetails?.state} {micrositeDetails?.countryName}</p>}
                            {micrositeDetails?.city !== null && micrositeDetails?.state == null && micrositeDetails?.countryName !== null && <p>{micrositeDetails?.city}, {micrositeDetails?.state} {micrositeDetails?.countryName}</p>}
                        </div>
                        {micrositeDetails?.mobileNumber && <div className={leftSectionStyles.organizationContactInnerCnt}>
                            <span><FiSmartphone /></span>
                            <p>{micrositeDetails?.countryMobileCode}-{micrositeDetails?.mobileNumber}</p>
                        </div>}
                        {micrositeDetails?.email && <div className={leftSectionStyles.organizationContactInnerCnt}>
                            <span><FiMail /></span>
                            <p>{micrositeDetails?.email}</p>
                        </div>}
                    </div>
                </div>}
                {micrositeDetails?.organizationDetails && micrositeDetails?.isCreatorAPartOfQrencia === AppConstants.yText &&
                    <div className={`${leftSectionStyles.orgNameContainer} mt-3`}>
                        <p className={leftSectionStyles.aboutExpertHeading}>About {micrositeDetails?.organizationDetails?.organizationName}</p>
                        <div className={leftSectionStyles.shareIconPannelContainer}>
                            {micrositeDetails?.organizationDetails?.organizationFacebookLink && <a href={micrositeDetails?.organizationDetails?.organizationFacebookLink} target={AppConstants.openInNewTab}><TfiFacebook /></a>}
                            {micrositeDetails?.organizationDetails?.organizationLinkedinLink && <a href={micrositeDetails?.organizationDetails?.organizationLinkedinLink} target={AppConstants.openInNewTab}><TfiLinkedin /></a>}
                            {micrositeDetails?.organizationDetails?.organizationWebsiteLink && <a href={micrositeDetails?.organizationDetails?.organizationWebsiteLink} target={AppConstants.openInNewTab}><FaGlobeAsia /></a>}
                            {micrositeDetails?.organizationDetails?.organizationYoutubeLink && <a href={micrositeDetails?.organizationDetails?.organizationYoutubeLink} target={AppConstants.openInNewTab}><FaYoutube /></a>}
                            {micrositeDetails?.organizationDetails?.organizationInstagramLink && <a href={micrositeDetails?.organizationDetails?.organizationInstagramLink} target={AppConstants.openInNewTab}><AiFillInstagram /></a>}
                        </div>
                    </div>
                }
                {micrositeDetails?.organizationDetails && <div>
                    <div className={leftSectionStyles.aboutExpertSection}>
                        <p className={leftSectionStyles.aboutExpertSubHeading}>{micrositeDetails?.organizationDetails?.organizationDescription}</p>
                        <div className={leftSectionStyles.organizationContactContainer}>
                            <div className={leftSectionStyles.organizationContactInnerCnt}>
                                <span><BsBuilding /></span>
                                {micrositeDetails?.organizationDetails?.organizationCity !== null && micrositeDetails?.organizationDetails?.organizationState !== null && micrositeDetails?.organizationDetails?.organizationCountryName !== null && <p>{micrositeDetails?.organizationDetails?.organizationCity}, {micrositeDetails?.organizationDetails?.organizationState}, {micrositeDetails?.organizationDetails?.organizationCountryName}</p>}
                                {micrositeDetails?.organizationDetails?.organizationCity == null && micrositeDetails?.organizationDetails?.organizationState !== null && micrositeDetails?.organizationDetails?.organizationCountryName !== null && <p>{micrositeDetails?.organizationDetails?.organizationCity} {micrositeDetails?.organizationDetails?.organizationState}, {micrositeDetails?.organizationDetails?.organizationCountryName}</p>}
                                {micrositeDetails?.organizationDetails?.organizationCity == null && micrositeDetails?.organizationDetails?.organizationState == null && micrositeDetails?.organizationDetails?.organizationCountryName !== null && <p>{micrositeDetails?.organizationDetails?.organizationCity} {micrositeDetails?.organizationDetails?.organizationState} {micrositeDetails?.organizationDetails?.organizationCountryName}</p>}
                                {micrositeDetails?.organizationDetails?.organizationCity !== null && micrositeDetails?.organizationDetails?.organizationState == null && micrositeDetails?.organizationDetails?.organizationCountryName !== null && <p>{micrositeDetails?.organizationDetails?.organizationCity}, {micrositeDetails?.organizationDetails?.organizationState} {micrositeDetails?.organizationDetails?.organizationCountryName}</p>}
                            </div>
                            {micrositeDetails?.organizationDetails?.expertOrganizationContactNumber && <div className={leftSectionStyles.organizationContactInnerCnt}>
                                <span><FiSmartphone /></span>
                                <p>{micrositeDetails?.organizationDetails?.expertOrganizationContactNumber}</p>
                            </div>}
                            {micrositeDetails?.organizationDetails?.expertOrganizationEmail && <div className={leftSectionStyles.organizationContactInnerCnt}>
                                <span><FiMail /></span>
                                <p>{micrositeDetails?.organizationDetails?.expertOrganizationEmail}</p>
                            </div>}
                            {(micrositeDetails?.organizationDetails?.organizationGeoLocation || micrositeDetails?.organizationDetails?.organizationAddress) && <div className={leftSectionStyles.organizationContactInnerCnt}>
                                {<a href={micrositeDetails?.organizationDetails?.organizationGeoLocation} target={AppConstants.openInNewTab} className={leftSectionStyles.locationIcon}><BiMap /></a>}
                                {<p href={micrositeDetails?.organizationDetails?.organizationGeoLocation} target={AppConstants.openInNewTab}>{micrositeDetails?.organizationDetails?.organizationAddress}</p>}
                            </div>}
                        </div>
                    </div>
                </div>}
                {micrositeDetails?.isCreatorAPartOfQrencia === AppConstants.nText && <div>
                    <div className={leftSectionStyles.aboutExpertSection}>
                        <div className={leftSectionStyles.organizationContactContainer}>
                            <div className={leftSectionStyles.organizationContactInnerCnt}>
                                <BsBuilding />
                                {micrositeDetails?.city !== null && micrositeDetails?.state !== null && micrositeDetails?.countryName !== null && <p>{micrositeDetails?.city}, {micrositeDetails?.state}, {micrositeDetails?.countryName}</p>}
                                {micrositeDetails?.city == null && micrositeDetails?.state !== null && micrositeDetails?.countryName !== null && <p>{micrositeDetails?.city} {micrositeDetails?.state}, {micrositeDetails?.countryName}</p>}
                                {micrositeDetails?.city == null && micrositeDetails?.state == null && micrositeDetails?.countryName !== null && <p>{micrositeDetails?.city} {micrositeDetails?.state} {micrositeDetails?.countryName}</p>}
                                {micrositeDetails?.city !== null && micrositeDetails?.state == null && micrositeDetails?.countryName !== null && <p>{micrositeDetails?.city}, {micrositeDetails?.state} {micrositeDetails?.countryName}</p>}
                            </div>
                            {micrositeDetails?.mobileNumber && <div className={leftSectionStyles.organizationContactInnerCnt}>
                                <FiSmartphone />
                                <p>{micrositeDetails?.countryMobileCode}-{micrositeDetails?.mobileNumber}</p>
                            </div>}
                            {micrositeDetails?.email && <div className={leftSectionStyles.organizationContactInnerCnt}>
                                <FiMail />
                                <p>{micrositeDetails?.email}</p>
                            </div>}
                        </div>
                    </div>
                </div>}
                {micrositeDetails?.organizationDetails && micrositeDetails?.organizationDetails?.organizationCentreDetails?.length !== zeroText &&
                    <p className={`${leftSectionStyles.aboutExpertHeading} mb-2 mt-3`}>Our Centres</p>}
                {micrositeDetails?.organizationDetails?.organizationCentreDetails?.length !== zeroText && <div>
                    {micrositeDetails?.organizationDetails?.organizationCentreDetails?.map((center, index) => (
                        <div key={index} className={leftSectionStyles.aboutExpertSection}>
                            <div className={leftSectionStyles.orgNameContainer}>
                                <p className={leftSectionStyles.aboutExpertHeading}>{center?.centreName}</p>
                                <div className={leftSectionStyles.shareIconPannelContainer}>
                                    {center?.organizationFacebookLink && <a href={center?.organizationFacebookLink} target={AppConstants.openInNewTab}><TfiFacebook /></a>}
                                    {center?.organizationLinkedinLink && <a href={center?.organizationLinkedinLink} target={AppConstants.openInNewTab}><TfiLinkedin /></a>}
                                    {center?.organizationWebsiteLink && <a href={center?.organizationWebsiteLink} target={AppConstants.openInNewTab}><FaGlobeAsia /></a>}
                                    {center?.organizationYoutubeLink && <a href={center?.organizationYoutubeLink} target={AppConstants.openInNewTab}><FaYoutube /></a>}
                                    {center?.organizationInstagramLink && <a href={center?.organizationInstagramLink} target={AppConstants.openInNewTab}><AiFillInstagram /></a>}
                                </div>
                            </div>
                            <p className={leftSectionStyles.aboutExpertSubHeading}>{center?.organizationDescription}</p>
                            <div className={leftSectionStyles.organizationContactContainer}>
                                {(center?.centreCity || center?.centreState || center?.centreCountryName) &&
                                    <div className={leftSectionStyles.organizationContactInnerCnt}>
                                        <span><BsBuilding /></span>
                                        {center?.centreCity !== null && center?.centreState !== null && center?.centreCountryName !== null && <p>{center?.centreCity}, {center?.centreState}, {center?.centreCountryName}</p>}
                                        {center?.centreCity == null && center?.centreState == null && center?.centreCountryName !== null && <p>{center?.centreCity} {center?.centreState} {center?.centreCountryName}</p>}
                                        {center?.centreCity != null && center?.centreState == null && center?.centreCountryName !== null && <p>{center?.centreCity}, {center?.centreState} {center?.centreCountryName}</p>}
                                        {center?.centreCity == null && center?.centreState != null && center?.centreCountryName !== null && <p>{center?.centreCity} {center?.centreState}, {center?.centreCountryName}</p>}
                                    </div>}
                                {center?.centreSpocPhoneNumber && <div className={leftSectionStyles.organizationContactInnerCnt}>
                                    <span><FiSmartphone /></span>
                                    <p>{center?.centreSpocPhoneNumber}</p>
                                </div>}
                                {center?.centreSpocEmail && <div className={leftSectionStyles.organizationContactInnerCnt}>
                                    <span><FiMail /></span>
                                    <p>{center?.centreSpocEmail}</p>
                                </div>}
                                {(center?.centreGeoLocation || center?.centreAddress) && <div className={leftSectionStyles.organizationContactInnerCnt}>
                                    {<a href={center?.centreGeoLocation} target={AppConstants.openInNewTab} className={leftSectionStyles.locationIcon}><BiMap /></a>}
                                    {<p href={center?.centreGeoLocation} target={AppConstants.openInNewTab}>{center?.centreAddress}</p>}
                                </div>}
                            </div>
                        </div>))}
                </div>}
                {micrositeDetails && <div className={rightSectionStyles.showOnMobileScreen}>
                    <div className={rightSectionStyles.aboutExpertContainer}>
                        {micrositeDetails?.skillDetails?.length !== zeroText && <p className={rightSectionStyles.aboutExpertHeading}>{AppConstants.expertMicroSiteStaticText.expertSkillsText}</p>}
                        {micrositeDetails?.skillDetails?.length !== zeroText && <div className={`${rightSectionStyles.skillsContainer} mb-3`}>
                            {micrositeDetails?.skillDetails?.map((skill, index) => (
                                <p key={index} className={rightSectionStyles.skillsText}>
                                    {skill.skillName}
                                </p>))}
                        </div>}
                        {(micrositeDetails?.facebookLink ||
                            micrositeDetails?.linkedinLink ||
                            micrositeDetails?.websiteLink ||
                            micrositeDetails?.youtubeLink ||
                            micrositeDetails?.instagramLink) && <p className={rightSectionStyles.aboutExpertHeading}>{AppConstants.expertMicroSiteStaticText.socialLinksText}</p>}
                        {(micrositeDetails?.facebookLink ||
                            micrositeDetails?.linkedinLink ||
                            micrositeDetails?.websiteLink ||
                            micrositeDetails?.youtubeLink ||
                            micrositeDetails?.instagramLink) && <div className={rightSectionStyles.skillsContainer}>
                                {micrositeDetails?.facebookLink && <a href={micrositeDetails?.facebookLink} target={AppConstants.openInNewTab}><TfiFacebook /></a>}
                                {micrositeDetails?.linkedinLink && <a href={micrositeDetails?.linkedinLink} target={AppConstants.openInNewTab}><TfiLinkedin /></a>}
                                {micrositeDetails?.websiteLink && <a href={micrositeDetails?.websiteLink} target={AppConstants.openInNewTab}><FaGlobeAsia /></a>}
                                {micrositeDetails?.youtubeLink && <a href={micrositeDetails?.youtubeLink} target={AppConstants.openInNewTab}><FaYoutube /></a>}
                                {micrositeDetails?.instagramLink && <a href={micrositeDetails?.instagramLink} target={AppConstants.openInNewTab}><AiFillInstagram /></a>}
                            </div>}
                    </div>
                    {micrositeDetails.videoMessagePath !== null && <div className={rightSectionStyles.videoReviewSection}>
                        <p className={leftSectionStyles.aboutExpertHeading}>Video Message</p>
                        <div className={rightSectionStyles.promotionVideoSection}>
                            {isVideoPlaying ? (<div>
                                <video controls autoPlay className={rightSectionStyles.videoContent}>
                                    <source src={micrositeDetails.videoMessagePath} type={AppConstants.contentTypeMap.mp4} />
                                </video>
                                <IoMdClose className={rightSectionStyles.closeButton} onClick={handleClose} />
                            </div>
                            ) : <img src={uploadedVideoMessageThumbnailImage || videoMessageThumbnail} className={rightSectionStyles.videoContent} alt="Video Message Thumbnail" />}
                            {showPlayIcon && <img src={videoPlayIconUrl} onClick={playVideo} alt="Video Play Button" className={rightSectionStyles.videoIcon} />}
                            <div>
                            </div>
                        </div>
                    </div>}
                </div>}

                {props.creatorGalleryContent.length > 0 && <div className={leftSectionStyles.coursesSection}>
                    <p className={`${leftSectionStyles.aboutExpertHeading} mb-2`}>Spotlight</p>
                    <div className={leftSectionStyles.mediaContentContainer}>

                        {props.creatorGalleryContent.slice(0, 4).map((value, index) => (
                            value.type === "Image" ? (
                                <div className={leftSectionStyles.imageBox} key={index}>
                                    <img className={`${leftSectionStyles.gallaryImage} ${props.creatorGalleryContent.length > AppConstants.micrositeGalleryImagesCount && index === AppConstants.micrositeGalleryImagesCount - 1 && leftSectionStyles.fadeImageBackground}`} onClick={() => handleMediaGalleryShow(value)} src={value.url} alt="Gallery" />
                                    {props.creatorGalleryContent.length > AppConstants.micrositeGalleryImagesCount && index === AppConstants.micrositeGalleryImagesCount - 1 && <div onClick={() => handleMediaGalleryShow(value)} className={leftSectionStyles.overLay}>+ {props.creatorGalleryContent.length - AppConstants.micrositeGalleryImagesCount}</div>}
                                    {props.creatorGalleryContent.length > AppConstants.micrositeGalleryImagesCount && index === AppConstants.micrositeGalleryImagesCount - 1 && <div onClick={() => handleMediaGalleryShow(value)} className={leftSectionStyles.fadeImageBackground}></div>}
                                </div>
                            ) : (
                                <div className={leftSectionStyles.imageBox} key={index}>
                                    <video controls={false} autoPlay={false} className={`${leftSectionStyles.gallaryImage} ${props.creatorGalleryContent.length > AppConstants.micrositeGalleryImagesCount && index === AppConstants.micrositeGalleryImagesCount - 1 && leftSectionStyles.fadeImageBackground}`} onClick={() => handleMediaGalleryShow(value)}>
                                        <source src={value.url} />
                                    </video>
                                    <img onClick={() => handleMediaGalleryShow(value)}
                                        className={leftSectionStyles.gallaryVideoPlayIcon}
                                        src={videoPlayIconUrl}
                                        alt="Video Play Button"
                                    />
                                    {props.creatorGalleryContent.length > AppConstants.micrositeGalleryImagesCount && index === AppConstants.micrositeGalleryImagesCount - 1 && <div onClick={() => handleMediaGalleryShow(value)} className={leftSectionStyles.overLay}>+ {props.creatorGalleryContent.length - AppConstants.micrositeGalleryImagesCount}</div>}
                                    {props.creatorGalleryContent.length > AppConstants.micrositeGalleryImagesCount && index === AppConstants.micrositeGalleryImagesCount - 1 && <div onClick={() => handleMediaGalleryShow(value)} className={leftSectionStyles.fadeImageBackground}></div>}
                                </div>
                            )
                        ))}

                    </div>
                </div>}
                {showMediaGallery && <ImageGalleryModal creatorGalleryContent={props.creatorGalleryContent} selectedMediaContent={selectedMedia} hanldeGalleryClose={hanldeGalleryClose} creatorName={`${micrositeDetails?.firstName} ${micrositeDetails?.lastName}`} />}
                {props.courseDetails && props.courseDetails?.length !== zeroText && <Element id={AppConstants.micrositeTopNavigationSections.courses}>
                    <div className={leftSectionStyles.coursesSection}>
                        <p className={`${leftSectionStyles.aboutExpertHeading} mb-2`}>{sectionNames.courses}</p>
                        <MicrositeCoursesSection category="Course" courseData={props.courseDetails} />
                    </div>
                </Element>}
                {props.competitionDetails && props.competitionDetails?.length !== zeroText && <Element id={AppConstants.micrositeTopNavigationSections.competitions}>
                    <div className={leftSectionStyles.coursesSection}>
                        <p className={`${leftSectionStyles.aboutExpertHeading} mb-2`}>{sectionNames.competitions}</p>
                        <MicrositeCompetitionsSection signupMinimumAge={signupMinimumAge} competitionsData={props.competitionDetails} />
                    </div>
                </Element>}
                {props.workshopDetails && props.workshopDetails?.length !== zeroText &&
                    <Element id={AppConstants.micrositeTopNavigationSections.workshops}>
                        <div className={leftSectionStyles.coursesSection}>
                            <p className={`${leftSectionStyles.aboutExpertHeading} mb-2`}>{sectionNames.workshops}</p>
                            <MicrositeWorkshopSection workshopsData={props.workshopDetails} />
                        </div>
                    </Element>}
                {micrositeReviews && micrositeReviews.length > 0 && <Element id={AppConstants.micrositeTopNavigationSections.reviews}>
                    <div ref={reviewsRef} className={leftSectionStyles.coursesSection}>
                        <p className={`${leftSectionStyles.aboutExpertHeading} mb-2`}>{sectionNames.reviews}</p>
                        <MicrositeReviewComponent micrositeReviews={micrositeReviews} />
                    </div></Element>}
                {micrositeDetails && <div className={rightSectionStyles.showOnMobileScreen}>
                    {micrositeDetails.promotionImageOnePath !== null && <div className={`${rightSectionStyles.advertismentSection} mb-4 mt-3`}>
                        <div className={rightSectionStyles.promotionVideoSection}>
                            <img src={micrositeDetails.promotionImageOnePath} className={rightSectionStyles.advertismentImage} alt="Advertisment" />
                        </div>
                    </div>}
                </div>}
            </div>
            {showReviewModal && <SubmitReviewModal fetchReviewForUser={fetchReviewForUser} closeReviewModal={closeReviewModal} micrositeUserId={micrositeDetails.id} micrositeUserRole={AppConstants.userRoleExpert} micrositeUniqueId={micrositeDetails.uniqueId} micrositeUserName={`${micrositeDetails?.firstName} ${micrositeDetails?.lastName}`} />}
            <Modal show={showModal} onHide={handleModalClose} backdrop="static" keyboard={false} centered>
                {showModal === true && <Modal.Body className={modalClass.modalBody}>
                    {(modalTypeError === AppConstants.falseText) && <div className={modalClass.modalSuccessIcon}><AiOutlineCheckCircle /></div>}
                    {(modalTypeError === AppConstants.trueText) && <div className={modalClass.modalErrorIcon}><AiOutlineExclamationCircle /></div>}
                    <div className={modalClass.modalAlertText}>{modalAlertText}</div>
                </Modal.Body>}
                {showModal === true && <Modal.Footer className={modalClass.modalFooter}>
                    {<Button className={modalClass.modalCloseButton} onClick={handleModalClose}>Close</Button>}
                </Modal.Footer>}
            </Modal>

            {showNudgeModal && <Modal size={displayNudgeBackground ? "lg" : "sm"} show={showNudgeModal} onHide={handleNudgeModalClose} className={!displayNudgeBackground && leftSectionStyles.nudgePopup} backdrop="static" keyboard={false} centered>
                {displayNudgeBackground && <Modal.Header closeButton>
                    <Modal.Title className={leftSectionStyles.modalTitle}>Raise Nudge to  {micrositeDetails?.firstName} {micrositeDetails?.lastName}</Modal.Title>
                </Modal.Header>}
                <Modal.Body className={modalClass.modalBody}>
                    {Auth.getUserRole() === AppConstants.userRoleStudent ?
                        <StudentRaiseNudgeForm category={AppConstants.learningActivitiesTypes.nudge} expertSkills={micrositeDetails?.skillDetails} expertUniqueId={micrositeDetails.uniqueId} hideNudgeFormBackground={hideNudgeFormBackground} /> :
                        <p className={leftSectionStyles.errorContainer}>Only Students can raise nudge!</p>}
                </Modal.Body>
            </Modal>}
            {showRaiseNudgeLoginModal && <LoginModal signupMessage="Signup to raise nudge" loginMessage="Login to raise nudge" onShow={() => setShowLoginModal(AppConstants.trueText)} onClose={closeRaiseNudgeLoginModal} />}
            {showLoginModal && <LoginModal signupMessage={AppConstants.signupFollowMessage} loginMessage={AppConstants.loginFollowMessage} onShow={() => setShowLoginModal(AppConstants.trueText)} onClose={hideLoginModal} />}
        </>
    );
}