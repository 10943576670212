import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Tooltip } from 'react-tooltip';
import { DateTime } from "luxon";
import { BiCalendarCheck, BiCalendarX, BiWallet, BiLoaderAlt } from "react-icons/bi";
import { CgPin } from "react-icons/cg";
import { AppConstants } from "../../../constants/AppConstants";
import Moment from 'react-moment';
import 'moment-timezone';
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import { TbShare3 } from 'react-icons/tb';
import { EnrolmentModal } from "../../modal/EnrolmentModal";
import classes from "./CompetitionListingPageCards.module.scss"
import { RiGitRepositoryPrivateFill } from 'react-icons/ri';
import { PaymentsUtil } from "../../../utils/PaymentsUtil";
import { ShareLinkToSocialMediaUtil } from "../../../utils/ShareLinkToSocialMediaUtil";
import { PostStartEnrolmentModal } from "../../modal/PostStartEnrolmentModal.js";
import { PayOfflineConfirmationModal } from "../../modal/PayOfflineConfirmationModal.js";
import { useEnrolment } from "../../../hooks/useEnrolment.js";
import useInfiniteScroll from "../../../hooks/useInfiniteScroll";
import Auth from "../../../utils/Auth.js";
import { ErrorMessages } from "../../../constants/ErrorMessages.js";
import useWarnOnLeave from "../../../hooks/useWarnOnLeave.js";

export const CompetitionsListingPageCards = (props) => {
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [cardCount, setCardCount] = useState(0);
    const [viewMoreCardsCount, setViewMoreCardsCount] = useState(0);
    const [editIndex, setEditIndex] = useState(null);
    const [cardDetails, setCardDetails] = useState(props.competitionsOfCategory || []);
    const [message, setMessage] = useState(AppConstants.emptyString);
    const [linkToShare, setLinkToShare] = useState(AppConstants.emptyString);
    const [showSocialMediaPannel, setShowSocialMediaPannel] = useState(AppConstants.falseText);
    const [modalTypeError, setModalTypeError] = useState(AppConstants.falseText);
    const [showModal, setShowModal] = useState(AppConstants.falseText);
    const [paymentStatus, setPaymentStatus] = useState(AppConstants.emptyString);
    const [transactionId, setTransactionId] = useState(AppConstants.emptyString);
    const learningActivitiesCardsCount = AppConstants.cardCounts;
    const [showConfirmationModal, setShowConfirmationModal] = useState(AppConstants.falseText);
    const [confirmationPopupActivityDetails, setConfirmationPopupActivityDetails] = useState({});
    const [showOfflinePaymentModal, setShowOfflinePaymentModal] = useState(AppConstants.falseText);
    const { enrolInLearningActivity } = useEnrolment();
    const [displayNetworkError, setDisplayNetworkError] = useState(AppConstants.falseText);
    const [shouldWarn, setShouldWarn] = useState(AppConstants.falseText);
    const [userLoggedInAndEnroled, setUserLoggedInAndEnroled] = useState(AppConstants.falseText);
    const [paymentInitiateResponse, setPaymentInitiateResponse] = useState({});
    useWarnOnLeave(shouldWarn);

    const { lastElementRef, loading } = useInfiniteScroll(() => {
        setCardCount(prevCount => prevCount + viewMoreCardsCount);
    }, [cardDetails]);

    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const defaultCompetitionThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.competitionDefaultThumbnailImage}`;

    const handleResize = () => {
        const viewportWidth = window.innerWidth;
        if (viewportWidth >= 992) {
            setCardCount(learningActivitiesCardsCount.competitionsPageCardCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.competitionsPageCardCount);
        }
        if (viewportWidth >= 576 && viewportWidth <= 991) {
            setCardCount(learningActivitiesCardsCount.learningActivityTabCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.learningActivityTabCount);
        }
        if (viewportWidth <= 575) {
            setCardCount(learningActivitiesCardsCount.learningActivityMobileCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.learningActivityMobileCount);
        }
    };

    //call handleResize() on screen width change
    useEffect(() => {
        handleResize();
        window.addEventListener('orientationchange', handleResize);
        return () => {
            window.removeEventListener('orientationchange', handleResize);
        };
    }, []);

    useEffect(() => {
        if (props.competitionsOfCategory !== cardDetails) {
            setCardDetails(props.competitionsOfCategory);
        }
    }, [props.competitionsOfCategory]);


    const confirmBeforeEnrolment = ({ id, title, uniqueId, startTime, payOfflineFlag, creatorName, creatorRole }) => {
        setConfirmationPopupActivityDetails({ id, title, uniqueId, type: AppConstants.learningActivitiesTypes.competition, payOfflineFlag, creatorName, creatorRole });
        if (Auth.isLogin() && Auth.getUserRole() === AppConstants.userRoleStudent) {
            const startDateTime = DateTime.fromISO(startTime?.replace(' ', 'T') + 'Z').setZone(timeZone);
            const currentDateTime = DateTime.local().setZone(timeZone);
            // check if the learning activity has started.
            if (currentDateTime > startDateTime) {
                // show confirmation modal to the user.
                setShowConfirmationModal(AppConstants.trueText);
            } else {
                if (payOfflineFlag === AppConstants.yText) {
                    setShowOfflinePaymentModal(AppConstants.trueText);
                } else {
                    enrolInCompetition(id, title, uniqueId);
                }
            }
        } else {
            enrolInCompetition(id, title, uniqueId);
        }

    };

    const updateConfirmationStatus = (status) => {
        setShowConfirmationModal(AppConstants.falseText);
        // if user selects yes -> 
        if (status) {
            if (confirmationPopupActivityDetails.payOfflineFlag === AppConstants.yText) {
                setShowOfflinePaymentModal(AppConstants.trueText);
            } else {
                enrolInCompetition(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
            }
        } else {
            setConfirmationPopupActivityDetails({});
        }
    };

    const getLearnerPaymentMethod = (paymentType) => {
        setShowOfflinePaymentModal(AppConstants.falseText);
        if (paymentType === AppConstants.paymentMethod.online) {
            enrolInCompetition(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
        } else {
            setConfirmationPopupActivityDetails({});
        }
    };

    const enrolInCompetition = async (competitionId, competitionTitle, uniqueId, status) => {
        if (Auth.isLogin() && Auth.getUserRole() !== AppConstants.userRoleStudent) {
            setModalTypeError(true);
            setMessage(ErrorMessages.enrollmentUserNotStudentError);
            setPaymentStatus(AppConstants.emptyString);
            handleModalShow();
            return;
        }
        const cost = PaymentsUtil.calculateCost({ cost: cardDetails.filter(card => card.uniqueId === uniqueId)[0].cost, discount: 0 });
        let response = await enrolInLearningActivity({ uniqueId, cost, learningActivityName: competitionTitle, learningActivityId: competitionId, learningActivityType: AppConstants.learningActivitiesTypes.competition }, status);

        setModalTypeError(response.modalTypeError);
        setPaymentStatus(response.paymentStatus);
        setMessage(response.message);
        setShowModal(response.showModal);
        setShouldWarn(response.shouldWarn);
        setDisplayNetworkError(response.networkError);
        if (response.showRazorpayPopup) {
            setTimeout(() => {
                showRazorpayPopup(response);
            }, AppConstants.paymentGatewayRedirectTimeout);
        }
    };

    const showRazorpayPopup = ({ options, learningActivityDetails }) => {
        setShowModal(AppConstants.falseText);
        const rzp1 = new window.Razorpay({
            ...options,
            handler: async (response) => {
                setShowModal(AppConstants.trueText);
                setTransactionId(response.razorpay_payment_id);
                setPaymentStatus(AppConstants.paymentStatus.pending);
                setShouldWarn(AppConstants.trueText);
                setPaymentInitiateResponse({ response, learningActivityDetails });
                setTimeout(async () => {
                    enrolInCompetition(learningActivityDetails.learningActivityId, learningActivityDetails.learningActivityName, learningActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
                    PaymentsUtil.verifyRazorpayPaymentStatus(response, learningActivityDetails);
                }, AppConstants.enrolmentDelayTimeout);
            }

        });

        rzp1.on(AppConstants.razorpaypaymentFailed, function (response) {
            PaymentsUtil.handleRazorpayPaymentFailure(response, learningActivityDetails);
        });

        rzp1.open();
    };

    const handleModalClose = () => {
        if (transactionId) {
            window.location.reload();
        } else {
            if (userLoggedInAndEnroled) {
                window.location.reload();
            } else {
                setShowModal(AppConstants.falseText);
                setConfirmationPopupActivityDetails({});
            }
        }
    }

    const handleModalShow = () => {
        setShowModal(AppConstants.trueText);
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleCardClick = (url) => {
        window.open(url, AppConstants.newPageTargetSelf);
    };

    //Share on social media 
    function shareOnSocialMedia(shareData) {
        setShowSocialMediaPannel(!showSocialMediaPannel);
        setLinkToShare(`${process.env.REACT_APP_FRONT_END_URL}${AppConstants.competitionsText}${AppConstants.forwardSlash}${shareData.categorySlug}${AppConstants.forwardSlash}${shareData.skillSlug}${AppConstants.forwardSlash}${shareData.competionSlug}`);
    };

    const getAgeGroups = (competiton) => {
        if (competiton.minimumAge !== 0 && competiton.maximumAge !== 0) {
            return `AGE ${competiton.minimumAge}-${competiton.maximumAge}`;
        } else if (competiton.minimumAge === 0 && competiton.maximumAge !== 0) {
            return `AGE ${props.signupMinimumAge}-${competiton.maximumAge}`;
        } else if (competiton.minimumAge !== 0 && competiton.maximumAge === 0) {
            return `AGE ${competiton.minimumAge}+`;
        } else {
            return "ALL AGES";
        }
    };

    const getActionButtonText = (competition) => {
        if (competition.isOfflinePaymentRequested === AppConstants.yText) {
            return "Requested";
        }
        if (competition.enrolledOn === null) {
            return "Enrol Now";
        } else {
            return "Enrolled";
        }
    };


    const onClickRetry = () => {
        setPaymentStatus(AppConstants.paymentStatus.pending);
        enrolInCompetition(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
        PaymentsUtil.verifyRazorpayPaymentStatus(paymentInitiateResponse.response, paymentInitiateResponse.learningActivityDetails);
    };

    return (
        <>
            <Row xs={1} sm={2} md={3} lg={4} xl={4} className={classes.cardSection}>
                {cardDetails.slice(0, cardCount).map((cardData, index) => (
                    <Col key={cardData.uniqueId} ref={index === cardCount - 1 ? lastElementRef : null}>
                        <Card className={classes.cardDiv} onClick={() => handleCardClick(`${AppConstants.forwardSlash}${AppConstants.competitionsText}${AppConstants.forwardSlash}${cardData.categorySlug}${AppConstants.forwardSlash}${cardData.skillSlug}${AppConstants.forwardSlash}${cardData.competionSlug}`)}>
                            <Card.Img variant="top" className={classes.cardImage} src={cardData.thumbnailImagePath ? cardData.thumbnailImagePath : defaultCompetitionThumbnail} />
                            <TbShare3 className={classes.shareIconUpcomingCards} onClick={(e) => { stopPropagation(e); setEditIndex(editIndex => editIndex === index ? index : index); shareOnSocialMedia(cardData) }} />
                            <Card.Body className={classes.cardBody}>
                                <div className={classes.subTitleContainer}>
                                    <Tooltip className={classes.tooltipMessage} id={cardData.uniqueId}><span className={classes.tooltipMessage}>{cardData.venueLocation}</span></Tooltip>
                                </div>
                                <div className={`${classes.cardSubTitle} ${classes.competitionDiffColor}`}>{getAgeGroups(cardData)} <span className="seperator">{AppConstants.verticalBar}</span> {(cardData.type.toUpperCase() === AppConstants.physicalText || cardData.type.toUpperCase() === AppConstants.offlineText) ? <span onClick={stopPropagation} className="modeSubtitle" data-tooltip-place="top" data-tooltip-id={cardData.uniqueId}><a href={cardData.geoLocation} target={AppConstants.openInNewTab} className="workshopLink onlineIconCompetition"><CgPin className="geoLocationIcon onlineIconCompetition" /> {cardData.type.toUpperCase()}</a></span> : <span className="onlineIconCompetition modeSubtitle">{cardData.type.toUpperCase()}</span>}</div>
                                <Card.Text className={`${classes.cardTitle} ${classes.titleFixedHeight}`}>
                                    <Link className={classes.cardLink} data-tooltip-id={`${cardData.uniqueId}-title`}>{cardData.title}</Link>
                                    <Tooltip className="courseTitleTooltip" id={`${cardData.uniqueId}-title`}><span>{cardData.title}</span></Tooltip>
                                </Card.Text>
                                {editIndex === index && showSocialMediaPannel && <div onClick={stopPropagation} className={classes.shareIconPannelContainerUpcomingCards}>
                                    <ShareLinkToSocialMediaUtil linkToShare={linkToShare} className={classes} useIcons={AppConstants.trueText} />
                                </div>}
                                <Card.Text onClick={e => { e.stopPropagation(); window.open(`${process.env.REACT_APP_FRONT_END_URL}${cardData.userRole === AppConstants.userRoleExpert ? AppConstants.expert : AppConstants.agency}${AppConstants.forwardSlash}${cardData.userSlug}`) }} className={`${classes.createdBy} ${classes.cardTimeAndDate}`}>By : {cardData.organizedBy}</Card.Text>
                                <div className={classes.cardTimeAndDate}>
                                    <div><BiCalendarCheck className={classes.calendarIcon} />&nbsp;</div>
                                    <div className={classes.dateTimeText}>
                                        Start:&nbsp;<span className={classes.competitionDateTimeFormat}><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{cardData.startDateTimeUTC.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{cardData.startDateTimeUTC.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${timeZone})`}</span>
                                    </div>
                                </div>
                                <div className={classes.cardTimeAndDate}>
                                    <div><BiCalendarX className={classes.calendarIcon} />&nbsp;</div>
                                    <div className={classes.dateTimeText}>
                                        End:&nbsp;<span className={classes.competitionDateTimeFormat}><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{cardData.endDateTimeUTC.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{cardData.endDateTimeUTC.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${timeZone})`}</span>
                                    </div>
                                </div>
                                {cardData.disablePublicEnrolmentFlag === AppConstants.nText && < div className={classes.buttonSection}>
                                    <div className={classes.learningActivityCostInfo}><BiWallet /> {PaymentsUtil.calculateCost({ cost: cardData.cost, discount: 0 })}</div>
                                    <Button type="button" disabled={cardData.isOfflinePaymentRequested === AppConstants.yText || cardData.enrolledOn !== AppConstants.nullText} className={classes.cardButton} onClick={(e) => { stopPropagation(e); confirmBeforeEnrolment({ id: cardData.competitionId, title: cardData.title, uniqueId: cardData.uniqueId, startTime: cardData.startDateTimeUTC, payOfflineFlag: cardData.payOfflineFlag, creatorName: cardData.organizedBy, creatorRole: cardData.userRole }) }}>{getActionButtonText(cardData)}</Button>
                                </div>}
                                {cardData.disablePublicEnrolmentFlag === AppConstants.yText && <div className={classes.buttonSection}>
                                    <div className={classes.learningActivityCostInfo}><BiWallet /> {PaymentsUtil.calculateCost({ cost: cardData.cost, discount: 0 })}</div>
                                    <span data-tooltip-id={`${cardData.learningActivitySlug}-private`}><RiGitRepositoryPrivateFill /></span>
                                    <Tooltip className={classes.tooltipMessagePrivate} id={`${cardData.learningActivitySlug}-private`}><span>This is a private competition. Public enrolments are disabled for this competition</span></Tooltip>
                                </div>}
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row >
            <Row xs={1} sm={1} md={1} lg={1} xl={1} className="loadMore">
                {loading && (
                    <div className="pageLoader"><BiLoaderAlt className="spinner" /></div>
                )}
            </Row>
            {(message !== AppConstants.emptyString) && (showModal) && <EnrolmentModal onShow={handleModalShow} message={message} modalTypeError={modalTypeError} onClose={handleModalClose} userLoggedInAndEnroled={userLoggedInAndEnroled} setUserLoggedInAndEnroled={setUserLoggedInAndEnroled} paymentStatus={paymentStatus} transactionId={transactionId} confirmBeforeEnrolment={confirmBeforeEnrolment} enrolmentDetails={confirmationPopupActivityDetails} displayNetworkError={displayNetworkError} onClickRetry={onClickRetry}></EnrolmentModal>}
            {showConfirmationModal && <PostStartEnrolmentModal updateConfirmationStatus={updateConfirmationStatus} learningActivityDetails={confirmationPopupActivityDetails} />}
            {showOfflinePaymentModal && <PayOfflineConfirmationModal getLearnerPaymentMethod={getLearnerPaymentMethod} learningActivityDetails={confirmationPopupActivityDetails} />}
        </>
    )
}