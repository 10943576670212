import { useEffect, useState } from "react";
import { AppConstants } from "../../constants/AppConstants";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import classes from './StudentHomePage.module.css';
import { StudentHomeRecentlyPublishedLearningActivities } from "../../component/studentPageComponents/StudentHomeRecentlyPublishedLearningActivities";
import { StudentHomeFeaturedLearningActivities } from "../../component/studentPageComponents/StudentHomeFeaturedLearningActivities";
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { StudentHomeTodaysActivities } from "../../component/studentPageComponents/StudentHomeTodaysActivities";
import { DataService } from "../../services/DataService";
import Auth from "../../utils/Auth";
import { Button, Modal } from "react-bootstrap";

export const StudentHomePage = () => {
    const [featuredLearningActivitiesData, setFeaturedLearningActivitiesData] = useState();
    const [recentlyPublishedLearningActivitiesData, setRecentlyPublishedLearningActivitiesData] = useState([]);
    const preferredTimeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [minimumReviewCount, setMinimumReviewCount] = useState(AppConstants.emptyString);
    const [nudgeAdImage, setNudgeAdImage] = useState({});
    const [skipsAvailable, setSkipsAvailable] = useState()
    const [profileCompletion, setProfileCompletion] = useState(AppConstants.falseFlag)
    const [minimumAgeLimit, setMinimumAgeLimit] = useState(AppConstants.emptyString);
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const defaultProfilePicture = `${mediaContent}${AppConstants.profileImageS3Path}`;
    const [updateProfileDialogBox, setUpdateProfileDialog] = useState(AppConstants.trueText);
    const backdropElementMobile = `${mediaContent}${AppConstants.studentProfileBackgroundMobile}`;

    useEffect(() => {
        async function fetchFeaturedLearningActivitiesData() {
            const url = `${AppConstants.studentFeaturedLearningActivitiesAPI}${preferredTimeZone}`;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            setFeaturedLearningActivitiesData(response);
            return null;
        }
        fetchFeaturedLearningActivitiesData();

        openUpdateProfileDialog();

        async function fetchRecentlyPublishedLearningActivitiesData() {
            const url = `${AppConstants.studentRecentlyPublishedLearningActivitiesAPI}${preferredTimeZone}`;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            setRecentlyPublishedLearningActivitiesData(response);
            return null;
        };
        fetchRecentlyPublishedLearningActivitiesData();

        async function fetchNudgeAdvertisementData() {
            const url = `${AppConstants.pageSpecificContentAPI}${AppConstants.studentHomePageName}`
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            setNudgeAdImage(response);
        };
        fetchNudgeAdvertisementData();

        const fetchConfigurableKeys = async () => {
            const url = AppConstants.fetchConfigurableKeysAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setMinimumReviewCount(parseInt(response.find(item => item.keyName === AppConstants.minimumCourseReviewsCardDisplay).keyValue));
                setMinimumAgeLimit(parseInt(response.find(item => item.keyName === AppConstants.signupMinimumAgeKey).keyValue))
            }
        };

        fetchConfigurableKeys();

        async function fetchUserInfo() {
            const url = AppConstants.fetchLoggedInStudentInfoAPI + Auth.getLoggedInUserDetails().userId;
            const response = await AuthorizedDataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            const loggedUserInfo = response;
            setSkipsAvailable(loggedUserInfo.profileCompletionSkips)
            response.profileCompletionFlag === AppConstants.yText ? setProfileCompletion(true) : setProfileCompletion(false)
        }
        fetchUserInfo();
    }, []);

    const openUpdateProfileDialog = () => {
        setUpdateProfileDialog(AppConstants.trueText);
    };

    const closeUpdateProfileDialog = () => {
        setUpdateProfileDialog(AppConstants.falseText);
    };

    const skipProfileCompletion = async () => {
        const userQrenciaId = Auth.getLoggedInUserDetails().userId;
        const url = `${AppConstants.skipCompleteStudentProfile}?userQrenciaId=${userQrenciaId}`
        const response = await AuthorizedDataService.putRequest(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        );
        closeUpdateProfileDialog()

    }

    const updateProfile = () => {
        window.open(`${AppConstants.StudentCompleteProfilePagePath}`, AppConstants.openInNewTab);
        closeUpdateProfileDialog()
    }


    return (
        <div className="studentContentSection">
            <div>
                <StudentHomeTodaysActivities minimumReviewCount={minimumReviewCount} />
                {nudgeAdImage?.bannerImage?.active && <div className={classes.adImageSection}>
                    <a target={AppConstants.newPageTargetSelf} href={nudgeAdImage.bannerImage?.link}>
                        <img alt="advertisement" src={nudgeAdImage.bannerImage?.imagePath} className="templateHomeBannerImage" />
                    </a>
                </div>}
                {featuredLearningActivitiesData !== null && featuredLearningActivitiesData !== undefined && featuredLearningActivitiesData.length !== 0 && <StudentHomeFeaturedLearningActivities minimumAgeLimit={minimumAgeLimit} minimumReviewCount={minimumReviewCount} featuredLearningActivitiesData={featuredLearningActivitiesData} />}
            </div>
            <div className={classes.sectionDiv}>
                {recentlyPublishedLearningActivitiesData != null && recentlyPublishedLearningActivitiesData !== undefined && recentlyPublishedLearningActivitiesData.length !== 0 && <StudentHomeRecentlyPublishedLearningActivities minimumAgeLimit={minimumAgeLimit} minimumReviewCount={minimumReviewCount} recentlyPublishedActivitiesDetails={recentlyPublishedLearningActivitiesData} />}
            </div>

            <Modal centered
                show={updateProfileDialogBox && !profileCompletion}
                onRequestClose={closeUpdateProfileDialog}
                contentLabel="Update Profile Popup"
                className={classes.updateProfilePopup}
                overlayClassName={classes.overlay}>
                <div className={classes.updateProfileDialogContainer}>
                    <div className={classes.greetingsContainer}>
                        <div className={classes.dialogProfileGifBackground}>
                            <img src={backdropElementMobile} alt="backdrop" className={classes.backdropImage} />
                            <div className={classes.avatarImage}>
                                <img src={defaultProfilePicture} className={classes.dialogProfileGif} alt="Profile" />
                            </div>
                        </div>
                        <div className={classes.dialogContent}>
                            <p className={classes.welcomeText}>Welcome Onboard!</p>
                            <div className={classes.dialogContentText}>
                                <p>{`Hi ${JSON.parse(localStorage.getItem(AppConstants.sessionKeyUserDetails))?.firstName}`}</p>
                                <p>Enhance your profile for a better experience</p>
                            </div>
                        </div>
                    </div>
                    <div className={classes.buttonContainer}>
                        {skipsAvailable > 0 && <div className={classes.dialogButton} onClick={skipProfileCompletion}><span className={classes.skipButton}>Skip for now</span></div>}
                        <Button className={classes.dialogButton} onClick={updateProfile}>Update profile</Button>
                    </div>
                </div>
            </Modal >
        </div >
    )
}