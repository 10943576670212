import React, { useState, useEffect } from "react";
import { Button, Collapse, Modal, Toast, ToastContainer } from "react-bootstrap";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io"
import { BiLoaderAlt } from 'react-icons/bi';
import { AddCertificate } from "../../component/createCompetitionComponents/AddCertificate/AddCertificate";
import { AddChallenge } from "../../component/createCompetitionComponents/AddChallenge/AddChallenge";
import { AddCompetitionDetails } from "../../component/createCompetitionComponents/AddCompetitionDetails/AddCompetitionDetails";
import { AddJudgementParameters } from "../../component/createCompetitionComponents/AddJudgementParameters/AddJudgementParameters";
import { AddViewJudges } from "../../component/createCompetitionComponents/AddViewJudges/AddViewJudges";
import { AdvancedCompetitionSettings } from "../../component/createCompetitionComponents/AdvancedCompetitionSettings/AdvancedCompetitionSettings";
import { DateTime } from 'luxon'
import Auth from "../../utils/Auth";
import classes from "./ExpertCreateCompetition.module.css";
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { FileOperationsUtil } from "../../utils/FileOperationsUtil";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import { AppConstants } from "../../constants/AppConstants";
import { ErrorMessages } from "../../constants/ErrorMessages";
import { SuccessMessage } from "../../constants/SuccessMessage";
import { DataService } from "../../services/DataService";
import modalClass from "../../component/cards/CompetitionsCards.module.css";
import { AiOutlineCheckCircle, AiOutlineExclamationCircle } from "react-icons/ai";
import { CopyLearningActivityInput } from "../../component/copyLearningActivityInput/CopyLearningActivityInput";

export const ExpertCreateCompetitionsPage = () => {

    const stages = AppConstants.createCompetitionActions;
    const basicDetailsFormLabels = AppConstants.addCompetitionDetailsLabels;
    const certificateFromLabels = AppConstants.addCertificateFormLabels;

    const [basicDetailsFormData, setBasicDetailsFormData] = useState({});
    const [problemStatementData, setProblemStatementData] = useState({});
    const [rubricsData, setRubricsData] = useState([]);
    const [certificateData, setCertificateData] = useState({});
    const [judgesData, setJudgesData] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [toastHeading, setToastHeading] = useState(AppConstants.emptyString);
    const [toastBody, setToastBody] = useState(AppConstants.emptyString);
    const [open, setOpen] = useState([true, false, false, false, false, false]);
    const [basicDetailsFormError, setBasicDetailsFormError] = useState({});
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [scrollOffset, setScrollOffset] = useState(0);
    const [skills, setSkills] = useState([]);
    const [userDetails, setUserDetails] = useState({});
    const [showSpinner, setShowSpinner] = useState(false);
    const [guidelinesError, setGuidelinesError] = useState(AppConstants.emptyString);
    const [toastBackground, setToastBackground] = useState(AppConstants.alertVarient[0]);
    const [template, setTemplate] = useState({});
    const [competitionLink, setCompetitionLink] = useState(AppConstants.emptyString);
    const [formErrors, setFormErrors] = useState([]);
    const [formValid, setFormValid] = useState(false);
    const [certificateValid, setCertificateValid] = useState(true);
    const [allowedParticipants, setAllowedParticipants] = useState(AppConstants.emptyString);
    const [advancedSettingsData, setAdvancedSettingsData] = useState({});
    const [rubricsConfigured, setRubricsConfigured] = useState(AppConstants.trueText);
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [isExpertAssociatedWithClubQrencia, setIsExpertAssociatedWithClubQrencia] = useState(AppConstants.falseText);
    const [schoolId, setSchoolId] = useState(AppConstants.emptyString);
    const [clubQrenciaValidation, setClubQrenciaValidation] = useState(AppConstants.emptyString);
    const [competitionMinimumCost, setCompetitionMinimumCost] = useState(AppConstants.emptyString);
    const [showModal, setShowModal] = useState(AppConstants.falseText);
    const [modalTypeError, setModalTypeError] = useState(AppConstants.falseText);
    const [modalMessage, setModalMessage] = useState(AppConstants.emptyString);
    const [selectedCompetitionDetails, setSelectedCompetitionDetails] = useState({});
    const [previousRubricsDetails, setPreviousRubricsDetails] = useState([]);
    const [previousCertificateDetails, setPreviousCertificateDetails] = useState({});
    const [previousJudgeDetails, setPreviousJudgeDetails] = useState([]);
    const [minimumSignupAge, setMinimumSignupAge] = useState(AppConstants.emptyString);

    const numberInputs = document.querySelectorAll('input[type=number]');
    numberInputs.forEach(input => {
        input.addEventListener('wheel', (e) => e.preventDefault());
    });

    useEffect(() => {
        const fetchSkillsForExpert = async () => {
            const url = AppConstants.fetchExpertSkillsApi;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {

                // Sort the skills alphabetically based on skillName
                const sortedSkills = response.sort((a, b) =>
                    a.skillName.localeCompare(b.skillName)
                );

                // Set the sorted skills array in the state
                setSkills(sortedSkills);
            }

        };

        const fetchConfigurableKeys = async () => {
            const url = AppConstants.fetchConfigurableKeysAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setCompetitionMinimumCost(parseInt(response.find(item => item.keyName === AppConstants.competitionMinCostKey).keyValue));
                setMinimumSignupAge(parseInt(response.find(item => item.keyName === AppConstants.signupMinimumAgeKey).keyValue));
            }
        };

        async function fetchSkillsForAgency() {
            const url = AppConstants.getSkillsofAgencyAPI;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response) {
                // Sort the skills alphabetically based on skillName
                setSkills(response.sort((a, b) =>
                    a.skillName.localeCompare(b.skillName)
                ));
            }
        };

        async function getClubQrenciaAssociationStatus() {
            let url = AppConstants.expertAssociationWithSchoolAPI;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setIsExpertAssociatedWithClubQrencia(response.associationFlag);
                setSchoolId(response.schoolId);
            } else {
                setIsExpertAssociatedWithClubQrencia(AppConstants.nText);
            }

        };

        getClubQrenciaAssociationStatus();
        fetchConfigurableKeys();
        const details = Auth.getLoggedInUserDetails();
        if (details) setUserDetails(details);

        if (details.userRole === AppConstants.userRoleAgency) {
            fetchSkillsForAgency();
        } else {
            fetchSkillsForExpert();
        }
    }, []);

    useEffect(() => {
        const getCertificateTemplate = async () => {
            const url = AppConstants.fetchCertificateTemplateApi;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            setTemplate(response);
        }
        getCertificateTemplate();
    }, []);

    useEffect(() => {
        let errorValues = Object.values(basicDetailsFormError);
        setFormErrors(errorValues);

        if (errorValues?.length === 0 && basicDetailsFormData.title?.trim() !== AppConstants.emptyString && guidelinesError === AppConstants.emptyString && basicDetailsFormData.description?.replace(/<(.|\n)*?>/g, '').trim().length !== 0) {
            setFormValid(true);
        } else {
            setFormValid(false);
        }
    }, [basicDetailsFormError, guidelinesError, basicDetailsFormData.description]);

    // function to capture basic details
    const changeBasicFormDetailsData = (newData) => {
        setBasicDetailsFormData({ ...newData });
        setAllowedParticipants(newData.allowedNumberOfParticipants);
        setShowToast(false);
    }

    // function to capture basic details form errors
    const changeBasicDetailsFormError = (errors, guidelines_error) => {
        setBasicDetailsFormError(errors);
        setGuidelinesError(guidelines_error);
    }

    // function to capture problem statement data
    const changeProblemStatementData = (newData) => {
        setProblemStatementData({ ...problemStatementData, ...newData })
        setShowToast(false);
    }

    // function to capture rubrics data
    const changeRubricsData = (newData) => {
        setRubricsData(newData.rubrics);
        setRubricsConfigured(newData.rubricsConfigured);
        setShowToast(false);
    };

    const formatRichTextContent = (content) => {
        var regex = /^(<p\s+class="[^"]*"><br><\/p>)+|(<p\s+class="[^"]*"><br><\/p>)+$/gm;
        let htmlText = content.replace(regex, AppConstants.emptyEditorString);
        const modifiedText = htmlText.replace(/^(<p><br><\/p>)+|(<p><br><\/p>)+$/g, '');
        if (modifiedText === AppConstants.emptyString) {
            return AppConstants.emptyEditorString
        } else {
            return modifiedText;
        }
    }

    // function to capture certificate data
    const changeCertificateData = (newData) => {
        if (newData.logoUpdated && newData.messageUpdated) {
            if (newData.signatoryOneUpdated === AppConstants.incompleteText || newData.signatoryTwoUpdated === AppConstants.incompleteText || newData.signatoryThreeUpdated === AppConstants.incompleteText) {
                setCertificateValid(false);
            } else if (newData.signatoryOneUpdated === AppConstants.emptyString && newData.signatoryTwoUpdated === AppConstants.emptyString && newData.signatoryThreeUpdated === AppConstants.emptyString) {
                setCertificateValid(false);
            } else {
                setCertificateValid(true);
            }
        } else if (newData.logoUpdated || newData.messageUpdated) {
            setCertificateValid(false);
        } else {
            if (newData.signatoryOneUpdated === AppConstants.emptyString && newData.signatoryTwoUpdated === AppConstants.emptyString && newData.signatoryThreeUpdated === AppConstants.emptyString) {
                setCertificateValid(true);
            } else {
                setCertificateValid(false);
            }
        }

        let certificateErrorMessages = Object.values(newData.certificateDataErrors);
        for (let i = 0; i < certificateErrorMessages.length; i++) {
            if (certificateErrorMessages[i] !== AppConstants.emptyString) {
                setCertificateValid(false);
            }
        }

        setCertificateData({ ...newData });
        setShowToast(false);
    };

    // function to capture judges data
    const changeJudgesData = (newData) => setJudgesData([...newData]);

    // function to capture advanced settings data
    const changeAdvancedSettings = (newData) => {
        setAdvancedSettingsData(newData);
        if (newData.onlyForClubQrencia) {
            const classLength = newData.selectedClasses.filter(cls => cls.schoolClass === AppConstants.emptyString).length;
            if (classLength > 0) {
                setClubQrenciaValidation("Please select a class in the added class dropdown");
            } else {
                setClubQrenciaValidation(AppConstants.emptyString);
            }
        } else {
            setClubQrenciaValidation(AppConstants.emptyString);
        }
    };

    const getSelectedClassesForSchool = () => {
        let selectedClassString = AppConstants.emptyString;
        advancedSettingsData.selectedClasses.forEach((cls) => {
            cls.allSections.forEach((sec) => {
                if (sec.isActive) {
                    selectedClassString = selectedClassString.length ? `${selectedClassString},${cls.schoolClass}-${sec.section}` : `${cls.schoolClass}-${sec.section}`
                }
            })
        });
        return selectedClassString;
    };

    const createDateTimeObject = (date, time) => {
        if (!date || !time) {
            return null;
        }
        const dateArr = date.split(AppConstants.hyphen);
        const timeArr = time.split(AppConstants.colon);
        const dateTime = DateTime.fromObject({
            year: dateArr[0],
            month: dateArr[1],
            day: dateArr[2],
            hour: timeArr[0],
            minute: timeArr[1],
            second: 0
        }, { zone: timeZone }
        );

        return dateTime;
    };

    const formatDateTime = (dateTime) => {
        return dateTime.toFormat(`EEE MMM dd yyyy HH:mm:ss \'GMT\'ZZZ \'(${DateTimeUtil.getFullZoneName(timeZone)})\'`);
    };

    // Function to build request object for competitionDetails
    const buildBasicDetailsProps = (filePaths) => {
        const start_date_time = formatDateTime(createDateTimeObject(basicDetailsFormData.date, basicDetailsFormData.startTime));
        const end_date_time = formatDateTime(createDateTimeObject(basicDetailsFormData.endDate, basicDetailsFormData.endTime));

        let registration_close_time;

        if (basicDetailsFormData.registrationClosingDate !== AppConstants.emptyString && basicDetailsFormData.registrationClosingTime !== AppConstants.emptyString) {
            registration_close_time = formatDateTime(createDateTimeObject(basicDetailsFormData.registrationClosingDate, basicDetailsFormData.registrationClosingTime));
        } else {
            // Setting default value of registration closing time to 1 minute before competition end time
            let date = createDateTimeObject(basicDetailsFormData.endDate, basicDetailsFormData.endTime);
            registration_close_time = formatDateTime(date.minus({ minutes: 1 })); // Subtracting 1 minute from Competition end time.
        }

        const selectedClasses = (isExpertAssociatedWithClubQrencia && advancedSettingsData.onlyForClubQrencia) ? getSelectedClassesForSchool() : null

        let competitionBasicDetails = {
            title: basicDetailsFormData.title.trim(),
            description: formatRichTextContent(basicDetailsFormData.description),
            stringStartTime: start_date_time,
            stringEndTime: end_date_time,
            registrationClosesOn: registration_close_time,
            thumbnailImagePath: filePaths.thumbnailImagePath,
            competitionType: basicDetailsFormData.competitionType,
            vcLink: basicDetailsFormData.vcLink?.trim() || null,
            geoLocation: basicDetailsFormData.competitionType === AppConstants.competitionTypeOffline ? basicDetailsFormData.geoLocation?.trim() : null,
            venueLocation: basicDetailsFormData.competitionType === AppConstants.competitionTypeOffline ? basicDetailsFormData.venueLocation.replace(/\n/g, ' ')?.trim() : null,
            skillId: skills.length === 1 ? skills[0].skillId : basicDetailsFormData.skill,
            promotionImagePathMobile: filePaths.promotionImagePathMobile || null,
            promotionImagePathTablet: filePaths.promotionImagePathTablet || null,
            promotionImagePathWeb: filePaths.promotionImagePathWeb,
            allowedNumberOfParticipants: basicDetailsFormData.allowedNumberOfParticipants,
            maxAge: basicDetailsFormData.maxAge || null,
            minAge: basicDetailsFormData.minAge || null,
            resultDeclared: basicDetailsFormData.resultDeclareBy ? basicDetailsFormData.resultDeclareBy : null,
            resultDate: basicDetailsFormData.resultDeclareDate ? basicDetailsFormData.resultDeclareDate : null,
            allowSubmissionFlag: basicDetailsFormData.submission,
            clubQrenciaFlag: (advancedSettingsData.onlyForClubQrencia && selectedClasses) ? AppConstants.yText : AppConstants.nText,
            guidelinesDocumentPath: filePaths.guidelinesDocumentPath || null,
            guidelinesText: formatRichTextContent(basicDetailsFormData.guidelinesText) === AppConstants.emptyEditorString ? null : formatRichTextContent(basicDetailsFormData.guidelinesText),
            schoolId: selectedClasses ? schoolId : null,
            competitionFeeWaiverFlag: advancedSettingsData.feeWaiver === AppConstants.falseText ? AppConstants.nText : AppConstants.yText,
            disablePublicEnrolmentFlag: advancedSettingsData.publicEnrolments === AppConstants.falseText ? AppConstants.nText : AppConstants.yText,
            disablePerformanceResultFlag: advancedSettingsData.performanceReport === AppConstants.falseText ? AppConstants.nText : AppConstants.yText,
            selectedClasses: (selectedClasses === AppConstants.emptyString || selectedClasses === null) ? null : selectedClasses,
            cost: (advancedSettingsData.feeWaiver === AppConstants.trueText || advancedSettingsData.onlyForClubQrencia === AppConstants.trueText) ? 0 : basicDetailsFormData.cost,
            payOfflineFlag: (advancedSettingsData.feeWaiver === AppConstants.trueText || advancedSettingsData.onlyForClubQrencia === AppConstants.trueText) ? AppConstants.nText : advancedSettingsData.enableOfflinePayment ? AppConstants.yText : AppConstants.nText
        }

        return competitionBasicDetails;
    };

    // Function to build request object for competition challenge
    const buildCompetitionChallengeProps = (filepaths) => {
        let isChallengeTextAdded = problemStatementData.problemStatement?.replace(/<(.|\n)*?>/g, '').trim().length === 0 ? AppConstants.falseText : AppConstants.trueText;

        let competitionChallenge = {
            challengeText: !isChallengeTextAdded ? null : formatRichTextContent(problemStatementData.problemStatement),
            challengePath: filepaths.challengePath,
            challengeRubrics: []
        };

        rubricsData.forEach(rubrics => {
            if (rubrics.included && rubrics.aspectDescription?.trim().length) {
                competitionChallenge.challengeRubrics.push({
                    criteriaId: rubrics.criterionId,
                    aspectDescription: rubrics.aspectDescription,
                    aspectMarks: rubrics.marks
                });
            }
        });

        return competitionChallenge;
    };

    // Function to build request object for competition certificate
    const buildCertificateDataProps = (filePaths) => {
        let competitionCertificate = {
            title: certificateData.certificateMessage?.trim() ? "Certificate of Participation" : null,
            description: null,
            certificateMessage: certificateData.certificateMessage.trim() || null,
            numberOfMeritParticipants: (certificateData.meritRange === AppConstants.emptyString || certificateData.meritRange === null) ? 3 : certificateData.meritRange,
            logoOnePath: filePaths.logoOnePath || null,
            logoTwoPath: filePaths.logoTwoPath || null,
            logoThreePath: filePaths.logoThreePath || null,
            logoFourPath: null,
            signatoryOneName: certificateData.signatoryOneName || null,
            signatoryTwoName: certificateData.signatoryTwoName || null,
            signatoryThreeName: certificateData.signatoryThreeName || null,
            signatoryOneDesignation: certificateData.signatoryOneDesignation || null,
            signatoryTwoDesignation: certificateData.signatoryTwoDesignation || null,
            signatoryThreeDesignation: certificateData.signatoryThreeDesignation || null,
            signatoryImageOnePath: filePaths.signatoryImageOnePath || null,
            signatoryImageTwoPath: filePaths.signatoryImageTwoPath || null,
            signatoryImageThreePath: filePaths.signatoryImageThreePath || null,
            signatoryOneOrganization: certificateData.signatoryOneOrganization || null,
            signatoryTwoOrganization: certificateData.signatoryTwoOrganization || null,
            signatoryThreeOrganization: certificateData.signatoryThreeOrganization || null,
            signatoryFourName: null,
            signatoryFourDesignation: null,
            signatoryFourOrganization: null,
            signatoryImageFourPath: null,
            templateId: certificateData.certificateMessage?.trim() ? template[0]?.id : null
        };

        return competitionCertificate;
    }

    // scroll functions
    const changeScrollOffset = () => {
        const size = window.screen.availWidth;
        if (size <= 990) {
            setScrollOffset(-1000)
        } else {
            setScrollOffset(0);
        }
    }

    const scrollToSection = (offset = 0) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const closeModal = () => {
        setShowModal(false);
        if (competitionLink.length) {
            window.location.reload();
            setCompetitionLink(AppConstants.emptyString);
        }
    };

    const setCollapseContainerState = (step) => {
        setOpen(prevState => prevState.map((state, i) => {
            if (i <= step) state = false;
            if (i === step + 1) state = true;
            return state;
        }));
    };


    const saveCurrentStep = ({ step }) => {
        changeScrollOffset();
        setCollapseContainerState(step);
        scrollToSection(0);
    };

    // function to manage error handling operations
    const performAllErrorHandling = (errorMsg) => {
        setToastBackground(AppConstants.alertVarient[1]);
        setToastBody(errorMsg);
        setToastHeading(ErrorMessages.error);
        setShowToast(true);
        setShowSpinner(false);
        setFormSubmitting(false);
    }

    const uploadFileInFolder = async (file, path, id, access) => {
        let response;
        if (access === AppConstants.publicText) {
            response = await FileOperationsUtil.uploadFileThroughPreSignedURL(file, path, AppConstants.s3filePermissionPublic, AppConstants.s3FileContentDispositionInline);
        } else {
            response = await FileOperationsUtil.uploadFileThroughPreSignedURL(file, path, AppConstants.s3filePermissionPrivate, AppConstants.s3FileContentDispositionInline);
        }

        return {
            id: id,
            response
        }
    };

    const uploadFiles = async () => {
        try {
            let filePaths = {
                thumbnailImagePath: null,
                promotionImagePathMobile: null,
                promotionImagePathTablet: null,
                promotionImagePathWeb: null,
                guidelinesDocumentPath: null,
                challengePath: null,
                logoOnePath: null,
                logoTwoPath: null,
                logoThreePath: null,
                signatoryImageOnePath: null,
                signatoryImageTwoPath: null,
                signatoryImageThreePath: null
            };

            // mandatory files
            let params = [];

            // other files
            if (basicDetailsFormData.thumbnailImage?.size) params.push({ file: basicDetailsFormData.thumbnailImage, folder: AppConstants.competitionDetailsUploadFolder, id: basicDetailsFormLabels.thumbnailImage.name, access: AppConstants.publicText })
            if (basicDetailsFormData.promotionImageForWeb?.size) params.push({ file: basicDetailsFormData.promotionImageForWeb, folder: AppConstants.competitionDetailsUploadFolder, id: basicDetailsFormLabels.promotionImageForWeb.name, access: AppConstants.publicText })
            if (basicDetailsFormData.promotionImageForMobile?.size) params.push({ file: basicDetailsFormData.promotionImageForMobile, folder: AppConstants.competitionDetailsUploadFolder, id: basicDetailsFormLabels.promotionImageForMobile.name, access: AppConstants.publicText })
            if (basicDetailsFormData.promotionImageForTablet?.size) params.push({ file: basicDetailsFormData.promotionImageForTablet, folder: AppConstants.competitionDetailsUploadFolder, id: basicDetailsFormLabels.promotionImageForTablet.name, access: AppConstants.publicText })
            if (basicDetailsFormData.guidelinesFile?.size) params.push({ file: basicDetailsFormData.guidelinesFile, folder: AppConstants.competitionDetailsUploadFolder, id: "Guidelines-Document", access: AppConstants.publicText });
            if (problemStatementData.statementFile?.size) params.push({ file: problemStatementData.statementFile, folder: AppConstants.challengesUploadFolder, id: "Problem-Statement" });
            if (certificateData.logoOne?.size) params.push({ file: certificateData.logoOne, folder: AppConstants.certificateUploadFolder, id: certificateFromLabels.logoOne.name });
            if (certificateData.logoTwo?.size) params.push({ file: certificateData.logoTwo, folder: AppConstants.certificateUploadFolder, id: certificateFromLabels.logoTwo.name });
            if (certificateData.logoThree?.size) params.push({ file: certificateData.logoThree, folder: AppConstants.certificateUploadFolder, id: certificateFromLabels.logoThree.name });
            if (certificateData.signatoryImageOne?.size) params.push({ file: certificateData.signatoryImageOne, folder: AppConstants.certificateUploadFolder, id: certificateFromLabels.signatoryImageOne.name });
            if (certificateData.signatoryImageTwo?.size) params.push({ file: certificateData.signatoryImageTwo, folder: AppConstants.certificateUploadFolder, id: certificateFromLabels.signatoryImageTwo.name });
            if (certificateData.signatoryImageThree?.size) params.push({ file: certificateData.signatoryImageThree, folder: AppConstants.certificateUploadFolder, id: certificateFromLabels.signatoryImageThree.name });


            const timestamp = DateTimeUtil.fetchCurrentTimestamp();

            let responseData = [];

            for (let i = 0; i < params.length; i++) {
                let { file, folder, id, access } = params[i];
                let ext = file.name.split(".").pop();
                let path = `${AppConstants.competitionUploadFolder}${AppConstants.forwardSlash}${userDetails.userId}${AppConstants.hyphen}${timestamp}${AppConstants.forwardSlash}${folder}${AppConstants.forwardSlash}${timestamp}${i}${AppConstants.hyphen}${id}${AppConstants.dot}${ext}`;
                let response = await uploadFileInFolder(file, path, id, access)
                responseData.push(response);
            }

            responseData?.forEach((data, i) => {
                if (data.response instanceof Error) {
                    throw new Error(data.response);
                } else {
                    const { id, response } = data;
                    const location = response.objectKey;
                    if (id === basicDetailsFormLabels.thumbnailImage.name) filePaths.thumbnailImagePath = location;
                    if (id === basicDetailsFormLabels.promotionImageForMobile.name) filePaths.promotionImagePathMobile = location;
                    if (id === basicDetailsFormLabels.promotionImageForTablet.name) filePaths.promotionImagePathTablet = location;
                    if (id === basicDetailsFormLabels.promotionImageForWeb.name) filePaths.promotionImagePathWeb = location;
                    if (id === "Guidelines-Document") filePaths.guidelinesDocumentPath = location;
                    if (id === "Problem-Statement") filePaths.challengePath = location;
                    if (id === certificateFromLabels.logoOne.name) filePaths.logoOnePath = location;
                    if (id === certificateFromLabels.logoTwo.name) filePaths.logoTwoPath = location;
                    if (id === certificateFromLabels.logoThree.name) filePaths.logoThreePath = location;
                    if (id === certificateFromLabels.signatoryImageOne.name) filePaths.signatoryImageOnePath = location;
                    if (id === certificateFromLabels.signatoryImageTwo.name) filePaths.signatoryImageTwoPath = location;
                    if (id === certificateFromLabels.signatoryImageThree.name) filePaths.signatoryImageThreePath = location;
                }
            });

            return filePaths;
        } catch (error) {
            return null;
        }
    };

    // submit form data
    const handleFormSubmission = async () => {
        setFormSubmitting(true);
        setShowSpinner(true);
        setOpen([false, false, false, false, false, false])
        try {
            if (formValid && certificateValid && basicDetailsFormData.allowedNumberOfParticipants >= certificateData.meritRange && clubQrenciaValidation === AppConstants.emptyString) {
                const filePaths = await uploadFiles();
                if (filePaths === null) throw new Error(ErrorMessages.cannotCreateCompetitionText);

                const data = {
                    competitionDetails: buildBasicDetailsProps(filePaths),
                    competitionChallenge: buildCompetitionChallengeProps(filePaths),
                    certificateInformation: buildCertificateDataProps(filePaths),
                    competitionJudges: rubricsConfigured ? judgesData : [],
                    locale: DateTimeUtil.getPreferredTimeZoneForUser()
                };

                const url = AppConstants.postCompetitionApi;
                const response = await AuthorizedDataService.postRequest(
                    url,
                    data,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );

                if (response) {
                    if (response.ok && response.status === AppConstants.httpResponseCodes.responseCode201) {
                        const text = await response.text();
                        const link = `${process.env.REACT_APP_FRONT_END_URL}${AppConstants.competitionsText.toLocaleLowerCase()}${AppConstants.forwardSlash}${text}`;
                        setCompetitionLink(link);
                        if (advancedSettingsData.feeWaiver) {
                            setModalMessage(SuccessMessage.feeWaiverCompetitionCreatedSuccess);
                        } else {
                            setModalMessage(SuccessMessage.competitionCreatedSuccessfullyText);
                        }
                        setModalTypeError(AppConstants.falseText);
                        setShowSpinner(false);
                        setShowModal(true);
                        setFormSubmitting(false)
                    } else {
                        setOpen([false, false, false, false, false, false])
                        scrollToSection(0);
                        setCompetitionLink(AppConstants.emptyString);
                        setModalMessage(ErrorMessages.cannotCreateCompetitionText);
                        setModalTypeError(AppConstants.trueText);
                        setShowModal(AppConstants.trueText);
                        setFormSubmitting(false);
                        setShowSpinner(false);
                    }
                } else {
                    setOpen([false, false, false, false, false, false])
                    scrollToSection(0);
                    setCompetitionLink(AppConstants.emptyString);
                    setModalMessage(ErrorMessages.cannotCreateCompetitionText);
                    setModalTypeError(AppConstants.trueText);
                    setShowModal(AppConstants.trueText);
                    setFormSubmitting(false);
                    setShowSpinner(false);
                }
            } else {
                scrollToSection(0);
                if (!formValid) {
                    setOpen([true, false, false, false, false, false]);
                    if (formErrors.length > 0) {
                        performAllErrorHandling(formErrors[0]);
                    } else if (formErrors.length === 0 && basicDetailsFormData.title?.trim().length !== 0) {
                        performAllErrorHandling(ErrorMessages.addGuidelinesError)
                    } else {
                        performAllErrorHandling(ErrorMessages.addCompetitionDetailsText);
                    }
                } else if (!certificateValid) {
                    setOpen([false, false, false, true, false, false]);
                    let imageErrors = certificateData.certificateDataErrors;
                    if (imageErrors.logoOne !== AppConstants.emptyString) {
                        performAllErrorHandling(imageErrors.logoOne);
                    } else if (imageErrors.logoTwo !== AppConstants.emptyString) {
                        performAllErrorHandling(imageErrors.logoTwo);
                    } else if (imageErrors.logoThree !== AppConstants.emptyString) {
                        performAllErrorHandling(imageErrors.logoThree);
                    } else if (!certificateData.logoUpdated) {
                        performAllErrorHandling(ErrorMessages.atleastOneLogoError);
                    } else if (!certificateData.messageUpdated) {
                        performAllErrorHandling(ErrorMessages.certificateMessageError)
                    } else if (imageErrors.signatoryImageOne !== AppConstants.emptyString) {
                        performAllErrorHandling(imageErrors.signatoryImageOne);
                    } else if (certificateData.signatoryOneUpdated === AppConstants.incompleteText) {
                        performAllErrorHandling(ErrorMessages.signatoryOneError);
                    } else if (imageErrors.signatoryImageTwo !== AppConstants.emptyString) {
                        performAllErrorHandling(imageErrors.signatoryImageTwo);
                    } else if (certificateData.signatoryTwoUpdated === AppConstants.incompleteText) {
                        performAllErrorHandling(ErrorMessages.signatoryTwoError);
                    } else if (imageErrors.signatoryImageThree !== AppConstants.emptyString) {
                        performAllErrorHandling(imageErrors.signatoryImageThree);
                    } else if (certificateData.signatoryThreeUpdated === AppConstants.incompleteText) {
                        performAllErrorHandling(ErrorMessages.signatoryThreeError);
                    } else {
                        performAllErrorHandling(ErrorMessages.addOneSignatoryDetails);
                    }
                } else if (basicDetailsFormData.allowedNumberOfParticipants < certificateData.meritRange) {
                    setOpen([false, false, false, true, false, false]);
                    performAllErrorHandling(ErrorMessages.meritRangeLessThanParticipantLimit)
                }
                else if (clubQrenciaValidation !== AppConstants.emptyString) {
                    setOpen([false, false, false, false, false, true]);
                    performAllErrorHandling(clubQrenciaValidation);
                } else {
                    setOpen([false, false, false, false, false, false])
                    setCompetitionLink(AppConstants.emptyString);
                    performAllErrorHandling(ErrorMessages.cannotCreateCompetitionText);
                }
            }
        } catch (error) {
            setOpen([false, false, false, false, false, false])
            scrollToSection(0);
            setCompetitionLink(AppConstants.emptyString);
            performAllErrorHandling(ErrorMessages.cannotCreateCompetitionText);
        }
    };

    const setSelectedLearningActivityDetails = (competitionDetails) => {
        setSelectedCompetitionDetails(competitionDetails);

        let rubricsArray = [];
        competitionDetails.competitionRubrics.forEach((res, i) => rubricsArray.push({ ...res, editable: true, marks: res.aspectMarks, isIncludedFlag: AppConstants.yText.toUpperCase(), included: true, previousRubrics: AppConstants.trueText }));
        setPreviousRubricsDetails(rubricsArray);
        setPreviousCertificateDetails(competitionDetails.certificateDetails);
        setPreviousJudgeDetails(competitionDetails.judgeDetails);
    }

    const resetLearningActivityDetails = () => {
        setSelectedCompetitionDetails({});
        setPreviousRubricsDetails([]);
        setPreviousCertificateDetails({});
        setPreviousJudgeDetails([]);
    };

    return (
        <div className="expertContentSection">
            <div className={classes.createCompHeading}>{AppConstants.createCompetitionText}</div>
            <div className={classes.createCompContainer}>
                <div className={classes.copyLearningActivityContainer}>
                    <CopyLearningActivityInput learningActivityType={AppConstants.learningActivitiesTypes.competition} setSelectedLearningActivityDetails={setSelectedLearningActivityDetails} resetLearningActivityDetails={resetLearningActivityDetails} />
                </div>
                <div id="createCompetitionComponents" className={classes.infoComponents}>
                    {
                        stages.map((stage, idx) => {
                            return (<div key={idx} className={classes.collapseContainer}>
                                <div className={classes.headerSection}>
                                    <div className={classes.heading}>
                                        {stage}
                                    </div>
                                    <div className={classes.collapseIcons}>
                                        {!open[idx] && <IoIosArrowDown onClick={() => setOpen(prevState => prevState.map((state, i) => i === idx ? !state : state))} />}
                                        {open[idx] && <IoIosArrowUp onClick={() => setOpen(prevState => prevState.map((state, i) => i === idx ? !state : state))} />}
                                    </div>
                                </div>
                                <Collapse in={open[idx]}>
                                    <div id="competition-collapse-container">
                                        <div className={classes.collapseDiv}>
                                            {idx === 0 && <AddCompetitionDetails minimumSignupAge={minimumSignupAge} competitionMinimumCost={competitionMinimumCost} feeWaiverFlag={advancedSettingsData.feeWaiver} clubQrenciaFlg={advancedSettingsData.onlyForClubQrencia} rubricsConfigured={rubricsConfigured} saveCurrentStep={saveCurrentStep} changeBasicDetailsFormError={changeBasicDetailsFormError}
                                                changeBasicFormDetailsData={changeBasicFormDetailsData} skills={skills} stage={stage} selectedCompetitionDetails={selectedCompetitionDetails} />}
                                            {idx === 1 && <AddChallenge saveCurrentStep={saveCurrentStep} changeProblemStatementData={changeProblemStatementData} stage={stage} competitionData={selectedCompetitionDetails} />}
                                            {idx === 2 && <AddJudgementParameters saveCurrentStep={saveCurrentStep} changeRubricsData={changeRubricsData} skillId={basicDetailsFormData.skill} stage={stage} previousSkill={selectedCompetitionDetails.skillUniqueId} previousRubricsDetails={previousRubricsDetails} />}
                                            {idx === 3 && <AddCertificate saveCurrentStep={saveCurrentStep} changeCertificateData={changeCertificateData} rubricsConfigured={rubricsConfigured} template={template} stage={stage} allowedParticipants={allowedParticipants} certificateData={previousCertificateDetails} selectedCompetitionTitle={selectedCompetitionDetails.title} />}
                                            {idx === 4 && <AddViewJudges rubricsConfigured={rubricsConfigured} saveCurrentStep={saveCurrentStep} changeJudgesData={changeJudgesData} stage={stage} judgeDetails={previousJudgeDetails} />}
                                            {idx === 5 && <AdvancedCompetitionSettings cost={basicDetailsFormData.cost} rubricsConfigured={rubricsConfigured} saveCurrentStep={saveCurrentStep} stage={stage} changeAdvancedSettings={changeAdvancedSettings} isExpertAssociatedWithClubQrencia={isExpertAssociatedWithClubQrencia} schoolId={schoolId} competitionDetails={selectedCompetitionDetails} />}
                                        </div>
                                    </div>
                                </Collapse>
                            </div>);
                        })
                    }
                    <div className={classes.createButton}>
                        {<Button disabled={showSpinner} type="submit" onClick={handleFormSubmission} className={classes.createComptitionButton}>{showSpinner ? <BiLoaderAlt className="spinner" /> : "Create"}</Button>}
                    </div>
                </div>

                <ToastContainer
                    className="p-3"
                    position='middle-center'
                    style={{ zIndex: 100 }}
                >
                    <Toast show={showToast}
                        onClose={() => setShowToast(false)}
                        bg={toastBackground} animation delay={5000} autohide={false}>
                        <Toast.Header>
                            <strong className="me-auto">{toastHeading}</strong>
                        </Toast.Header>
                        <Toast.Body className="text-white">
                            {toastBody} <br></br>
                        </Toast.Body>
                    </Toast>
                </ToastContainer>

                <Modal show={showModal} onHide={closeModal} backdrop="static" keyboard={false} centered>
                    <Modal.Body className={modalClass.modalBody}>
                        {(modalTypeError === AppConstants.falseText) && <div className={modalClass.modalSuccessIcon}><AiOutlineCheckCircle /></div>}
                        {(modalTypeError === AppConstants.trueText) && <div className={modalClass.modalErrorIcon}><AiOutlineExclamationCircle /></div>}
                        <div className={modalClass.modalAlertText}>
                            {modalMessage}
                        </div>
                        <div className={modalClass.modalAlertText}>
                            {competitionLink && !advancedSettingsData.feeWaiver && <a className={classes.shareLink} href={competitionLink} target="_blank" rel="noreferrer">{AppConstants.shareCompetitionLinkText}</a>}
                        </div>
                    </Modal.Body>
                    <Modal.Footer className={modalClass.modalFooter}>
                        <Button className={modalClass.modalCloseButton} onClick={closeModal}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};