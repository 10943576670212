import { useRef, useState, useEffect } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import { Modal } from "react-bootstrap";
import { useSearchParams } from 'react-router-dom';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { AppConstants } from "../../../constants/AppConstants";
import { ErrorMessages } from "../../../constants/ErrorMessages";
import { SuccessMessage } from "../../../constants/SuccessMessage";
import formClass from "../../../common/FormStyling.module.css";
import "./IndividualSkillsPageRaiseNudge.css";
import { DataService } from "../../../services/DataService";
import ReactQuill, { Quill } from "react-quill";
import { BiLoaderAlt } from "react-icons/bi";
import { AiOutlineCheckCircle, AiOutlineExclamationCircle } from "react-icons/ai";
import { AuthorizedDataService } from "../../../services/AuthorizedDataService";
import { FileOperationsUtil } from "../../../utils/FileOperationsUtil";
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import Auth from "../../../utils/Auth";
import { ErrorSuccessAlertMessage } from "../../errorSuccessMessage/ErrorSuccessAlertMessage";
import { PaymentsUtil } from "../../../utils/PaymentsUtil";
import modalClass from "../../cards/CompetitionsCards.module.css";
import { LoginModal } from "../../modal/LoginModal";
import parse from 'html-react-parser';
import { FileUploadInput } from "../../fileUploadInput/FileUploadInput";
import { useOnlineStatus } from "../../../hooks/useOnlineStatus";
import useWarnOnLeave from "../../../hooks/useWarnOnLeave";
import { IoMdCopy } from "react-icons/io";
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { StringUtils } from "../../../utils/StringUtils";

export const IndividualSkillsPageRaiseNudge = (props) => {
    const [showLinkCopied, setShowLinkCopied] = useState(AppConstants.falseText);
    const [allSkillsList, setAllSkillsList] = useState([]);
    const reactQuillRef = useRef();
    //formValues
    const [selectedSkill, setSelectedSkill] = useState([]);
    const [summary, setSummary] = useState(AppConstants.emptyString);
    const [description, setDescription] = useState(AppConstants.emptyEditorString);
    const [nudgeFile, setNudgeFile] = useState(null);
    const [submitSpinner, setSubmitSpinner] = useState(false);
    const [skillError, setSkillError] = useState(false);
    const [skillErrorMessage, setSkillErrorMessage] = useState(AppConstants.emptyString);
    const [summaryError, setSummaryError] = useState(false);
    const [summaryErrorMessage, setSummaryErrorMessage] = useState(AppConstants.emptyString);
    const [descriptionError, setDescriptionError] = useState(false);
    const [descriptionErrorMessage, setDescriptionErrorMessage] = useState(AppConstants.emptyString);
    const [nudgeFileError, setNudgeFileError] = useState(false);
    const [nudgeFileErrorMessage, setNudgeFileErrorMessage] = useState(AppConstants.emptyString);
    const [submitError, setSubmitError] = useState(false);
    const [submitErrorMessage, setSubmitErrorMessage] = useState(AppConstants.emptyString);
    const [submitErrorVarient, setSubmitErrorVarient] = useState(AppConstants.emptyString);
    const [modalTypeError, setModalTypeError] = useState(AppConstants.falseText);
    const [showModal, setShowModal] = useState(AppConstants.falseText);
    const [nudgeCost, setNudgeCost] = useState(AppConstants.emptyString);
    const [searchParams, setSearchParams] = useSearchParams();
    const [paymentParam, setPaymentParam] = useState(searchParams.get(AppConstants.paymentUrlParam));
    const [paymentStatus, setPaymentStatus] = useState(AppConstants.emptyString);
    const [transactionId, setTransactionId] = useState(AppConstants.emptyString);
    const [raisedNudge, setRaisedNudge] = useState({});
    const [message, setMessage] = useState(AppConstants.emptyString);
    // const [expertsList, setExpertsList] = useState(null);
    const [isNudgeActivated, setIsNudgeActivated] = useState(AppConstants.falseText);
    const [showLoginModal, setShowLoginModal] = useState(AppConstants.falseText);
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const [showFreeNudgeModal, setShowFreeNudgeModal] = useState(AppConstants.falseText);
    const [displayNetworkError, setDisplayNetworkError] = useState(AppConstants.falseText);
    const [paymentInitiateResponse, setPaymentInitiateResponse] = useState({});
    const isOnlineRef = useOnlineStatus();
    const [shouldWarn, setShouldWarn] = useState(false);
    const [selectedSkillCost, setSelectedSkillCost] = useState(AppConstants.emptyString);
    const [responseTime, setResponseTime] = useState(AppConstants.emptyString);
    useWarnOnLeave(shouldWarn);
    const verifyGif = `${mediaContent}${AppConstants.designImagesS3Path}${AppConstants.forwardSlash}${AppConstants.greenVerificationGif}`;

    const Link = Quill.import('formats/link');
    Link.sanitize = function (url) {
        try {
            // quill by default creates relative links if scheme is missing.
            if (!url.startsWith(`${AppConstants.internetProtocols.http}://`) && !url.startsWith(`${AppConstants.internetProtocols.https}://`)) {
                return `${AppConstants.internetProtocols.http}://${url}`
            }
            return url;
        } catch (e) {
            return url;
        }
    }

    //fetch all skills
    useEffect(() => {
        fetchSkills();
        async function fetchSkills() {
            const url = AppConstants.getSkillsDataAPI;
            const response = await DataService.get(url, AppConstants.emptyString, AppConstants.emptyString);
            setSelectedSkillCost(response.filter(skill => skill.skillId === props.skillId)[0].nudgeCost);
        }
    }, []);

    //handle description change
    const handleTextChange = (value) => {
        setDescription(value);
    }


    const removeFile = () => {
        let input = document.getElementById("nudgeFile");
        input.value = "";
        input.type = "";
        input.type = "file";
        setNudgeFile(null)
    }

    //handle file change
    const handleUploadFileChange = (e) => {
        var fileTypeArray = AppConstants.executableFileTypes;
        if (e.target.files[0] !== undefined) {
            const name = e.target.files[0].name;
            const lastDot = name.lastIndexOf('.');
            const ext = name.substring(lastDot + 1);

            if ((fileTypeArray.includes(ext.toUpperCase()))) {
                document.getElementById("nudgeFile").value = null;
                setNudgeFile(null);
                setNudgeFileError(true);
                setNudgeFileErrorMessage(ErrorMessages.executableFileUploadError);
                // setTimeout(() => setNudgeFileError(false), AppConstants.messageDisappearTime);
            } else {
                setNudgeFile(e.target.files[0]);
                setNudgeFileError(false);
                setNudgeFileErrorMessage(AppConstants.emptyString);
            }
        } else {
            setNudgeFile(null);
            setNudgeFileError(false);
            setNudgeFileErrorMessage(AppConstants.emptyString);
        }
    };

    const activateNudge = async (uniqueId, nudgeCost) => {
        try {
            const url = `${AppConstants.activateNudgeAPI}${uniqueId}`;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            setShouldWarn(AppConstants.falseText);
            if (response === undefined || response?.status === AppConstants.httpResponseCodes.responseCode500) {
                setModalTypeError(AppConstants.trueText);
                if (nudgeCost === AppConstants.freeText) {
                    setShowFreeNudgeModal(AppConstants.trueText);
                } else {
                    handleModalShow();
                    if (!isOnlineRef?.current) {
                        setDisplayNetworkError(AppConstants.trueText);
                        setMessage(ErrorMessages.networkIssueRaiseNudgeError);
                    } else {
                        setDisplayNetworkError(AppConstants.falseText);
                        setMessage(ErrorMessages.paymentDeductedRaiseNudgeError);
                    }
                    setPaymentStatus(AppConstants.paymentStatus.failed);
                }
            } else {
                if (nudgeCost === AppConstants.freeText) {
                    setShowFreeNudgeModal(AppConstants.trueText);
                } else {
                    setPaymentStatus(AppConstants.paymentStatus.verified);
                    handleModalShow();
                    setMessage(SuccessMessage.nudgeRaisedSuccessfullyMessage);
                    setIsNudgeActivated(AppConstants.trueText);
                }
                setModalTypeError(AppConstants.falseText);
            }
        } catch (error) {
            setModalTypeError(AppConstants.trueText);
            if (nudgeCost === AppConstants.freeText) {
                setShowFreeNudgeModal(AppConstants.trueText);
            } else {
                setPaymentStatus(AppConstants.paymentStatus.failed);
                handleModalShow();
            }
        }
    };

    useEffect(() => {
        const fetchConfigurableKeys = async () => {
            const url = AppConstants.fetchConfigurableKeysAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                const responseTime = parseInt(response.find(item => item.keyName === AppConstants.nudgeResponseTime).keyValue);
                setResponseTime(responseTime > 1 ? `${responseTime} hours` : `${responseTime} hour`);
            }
        };

        fetchConfigurableKeys();
    }, []);

    const initiatePayment = async (uniqueId) => {
        try {
            setPaymentStatus(AppConstants.paymentStatus.initiated);
            const response = await PaymentsUtil.initiatePaymentWithRazorpay({
                uniqueId: raisedNudge.uniqueId || uniqueId,
                learningActivityType: AppConstants.learningActivitiesTypes.nudge,
            });

            if (response.message === SuccessMessage.paymentInitiatedMessage) {
                setModalTypeError(AppConstants.falseText);
                setMessage(response.message);
                setPaymentStatus(AppConstants.paymentStatus.initiated);
                handleModalShow();
                setTimeout(() => {
                    showRazorpayPopup(response);
                }, AppConstants.paymentGatewayRedirectTimeout);
            } else {
                setModalTypeError(AppConstants.trueText);
                setMessage(response.message);
                setPaymentStatus(AppConstants.emptyString);
                handleModalShow();
            }

            return;
        } catch (error) {
            setModalTypeError(AppConstants.trueText);
            setMessage(ErrorMessages.initiatePaymentFailedError);
            setPaymentStatus(AppConstants.emptyString);
            handleModalShow();
        }
    };

    const showRazorpayPopup = ({ options, learningActivityDetails }) => {
        setShowModal(AppConstants.falseText);
        const rzp1 = new window.Razorpay({
            ...options,
            handler: async (response) => {
                setRaisedNudge({});
                setTransactionId(response.razorpay_payment_id);
                setShowModal(AppConstants.trueText);
                setPaymentStatus(AppConstants.paymentStatus.pending);
                setPaymentInitiateResponse({ response, learningActivityDetails });
                setShouldWarn(AppConstants.trueText);
                setTimeout(async () => {
                    activateNudge(learningActivityDetails.uniqueId);
                    PaymentsUtil.verifyRazorpayPaymentStatus(response, learningActivityDetails);
                }, AppConstants.enrolmentDelayTimeout);
            }
        });

        rzp1.on(AppConstants.razorpaypaymentFailed, function (response) {
            PaymentsUtil.handleRazorpayPaymentFailure(response, learningActivityDetails);
        });

        rzp1.open();
    };

    const retryNudgeActivation = () => {
        setPaymentStatus(AppConstants.paymentStatus.pending);
        activateNudge(paymentInitiateResponse.learningActivityDetails.uniqueId);
        PaymentsUtil.verifyRazorpayPaymentStatus(paymentInitiateResponse.response, paymentInitiateResponse.learningActivityDetails);
    }

    const handleModalClose = () => {
        setShowModal(false);
        setNudgeCost(AppConstants.emptyString);
        if (paymentParam) {
            window.location.replace(window.location.href.split("?")[0]);
        } else {
            window.location.reload();
        }
    };

    const handleModalShow = () => {
        setShowModal(true);
    };

    //submit nudge request
    async function submitNudgeRequest(e) {
        if (!Auth.isLogin()) {
            setShowLoginModal(AppConstants.trueText);
            return;
        }
        setNudgeFileError(false);
        setSkillError(false);
        setSummaryError(false);
        setDescriptionError(false);
        let isSubmit = true;
        setSubmitSpinner(true);

        if (summary === undefined || summary === null || summary.trim() === "") {
            setSubmitSpinner(false);
            setSummaryError(true);
            setSummaryErrorMessage(ErrorMessages.studentRaiseNudgeInvalidQuestionError);
            isSubmit = false;
        } else {
            setSummaryErrorMessage(AppConstants.emptyString);
        }
        if (description === undefined || description === null || description.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
            setSubmitSpinner(false);
            setDescriptionError(true);
            setDescriptionErrorMessage(ErrorMessages.studentRaiseNudgeInvalidDescriptionError);
            isSubmit = false;
        } else {
            setDescriptionErrorMessage(AppConstants.emptyString);
        }
        if (nudgeFile !== null) {
            let fileName = nudgeFile.name.replace(/\s+/g, AppConstants.emptyString);
            const contentDisposition = `attachment;filename="${fileName}"`
            const path = `${AppConstants.nudgesTextForUploadFilePath}${AppConstants.forwardSlash}${DateTimeUtil.fetchCurrentTimestamp()}${AppConstants.hyphen}${Auth.getLoggedInUserDetails().userId}${AppConstants.hyphen}${fileName}`;
            const result = await FileOperationsUtil.uploadFileThroughPreSignedURL(nudgeFile, path, AppConstants.s3filePermissionPrivate, contentDisposition);
            if (result.objectKey !== undefined) {
                var nudgeFilePath = result.objectKey;
            } else {
                nudgeFilePath = null;
            }
        }

        if (isSubmit) {
            const formValues = {
                summary: summary,
                description: description,
                filePath: nudgeFilePath,
                skillUniqueId: props.skillId,
                expertUniqueId: AppConstants.nullText
            }
            const url = AppConstants.studentraiseNudgeAPI;
            const data = formValues;
            const response = await AuthorizedDataService.postRequestWithResponseCode(
                url,
                data,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response !== undefined) {
                isSubmit = false;
                setSubmitSpinner(AppConstants.falseText);
                if (response.status === AppConstants.httpResponseCodes.responseCode201) {
                    const data = await response.json();
                    if (data !== undefined) {
                        //clear form values
                        document.getElementById("raiseNudgeForm").reset();
                        setSummary(null);
                        setDescription(null);
                        setNudgeFile(null);
                        nudgeFilePath = null;
                        setSelectedSkill([]);
                        setRaisedNudge(data);
                        if (data.nudgeCost > 0) {
                            handleModalShow();
                            initiatePayment(data.uniqueId);
                            setNudgeCost(data.nudgeCost);
                            setModalTypeError(AppConstants.falseText);
                        } else {
                            activateNudge(data.uniqueId, AppConstants.freeText);
                        }
                    } else {
                        setSubmitError(true);
                        setSubmitErrorVarient(AppConstants.alertVarient[1]);
                        setModalTypeError(AppConstants.trueText);
                        setSubmitErrorMessage(ErrorMessages.studentRaiseNudgeError);
                        return setTimeout(() => setSubmitError(false), AppConstants.messageDisappearTime);
                    }
                } else if (response.status === AppConstants.httpResponseCodes.responseCode404) {
                    setSubmitError(true);
                    setSubmitErrorVarient(AppConstants.alertVarient[1]);
                    setSubmitErrorMessage(ErrorMessages.studentRaiseNudgeNoExpertError);
                    return setTimeout(() => setSubmitError(false), AppConstants.messageDisappearTime);
                } else if (response.status === AppConstants.httpResponseCodes.responseCode403) {
                    setSubmitError(true);
                    setSubmitErrorVarient(AppConstants.alertVarient[1]);
                    setSubmitErrorMessage(ErrorMessages.onlyStudentRaiseNudgeError);
                    return setTimeout(() => setSubmitError(false), AppConstants.messageDisappearTime);
                } else {
                    setSubmitError(true);
                    setSubmitErrorVarient(AppConstants.alertVarient[1]);
                    setSubmitErrorMessage(ErrorMessages.studentRaiseNudgeError);
                    return setTimeout(() => setSubmitError(false), AppConstants.messageDisappearTime);
                }
            } else {
                isSubmit = false;
                setSubmitSpinner(AppConstants.falseText);
                setSubmitError(true);
                setSubmitErrorVarient(AppConstants.alertVarient[1]);
                setSubmitErrorMessage(ErrorMessages.studentRaiseNudgeError);
                return setTimeout(() => setSubmitError(false), AppConstants.messageDisappearTime);
            }
        }
    };

    const handleFreeNudgeModalClose = () => {
        setShowFreeNudgeModal(AppConstants.falseText);
        setRaisedNudge({});
        window.location.reload();
    }


    const getParsedMessage = (message, obj) => {
        let replacedStr = message;
        for (const [key, value] of Object.entries(obj)) {
            replacedStr = replacedStr.replace(`{{${key}}}`, `${value}`);
        };

        return parse(replacedStr);
    }

    const hideLoginModal = () => {
        if (Auth.isLogin()) {
            submitNudgeRequest();
        }
        setShowLoginModal(AppConstants.falseText);
    }
    function copyToClipBoard(event) {
        event.preventDefault();
        navigator.clipboard.writeText(transactionId);
        setShowLinkCopied(AppConstants.trueText);
        var timer = setTimeout(() => {
            setShowLinkCopied(AppConstants.falseText);
        }, 3000);
        return () => { clearTimeout(timer) }
    };

    return (
        <>
            <div className="skillPageRaiseNudgeForm">
                <form id="raiseNudgeForm" onSubmit={(e) => { submitNudgeRequest(e) }}>
                    <Row>
                        <Col className="skillPageRaiseNudgeFormInputBox" xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Label className={`${formClass.formLabel}`}>Skill</Form.Label>
                            <Form.Control className={`${formClass.formInput} disabledSkillInput`} name="skill" type="text" value={props.skill} disabled />
                            {skillError && <p className="formErrorMessage"><AiOutlineExclamationCircle /> {skillErrorMessage}</p>}
                        </Col>
                        <Col className="skillPageRaiseNudgeFormInputBox" xs={12} sm={12} md={6} lg={6} xl={6}>
                            <div className={modalClass.nudgeCost}>{selectedSkillCost ? `INR ${selectedSkillCost}` : AppConstants.freeText}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="skillPageRaiseNudgeFormInputBox mt-3" xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Form.Group className="raiseNudgeFileUpload">
                                <FileUploadInput id="nudgeFile" name="nudgeFile" label="Upload File (Optional)" handleFileChange={event => handleUploadFileChange(event)} inputFile={nudgeFile} />
                                {nudgeFile && <p onClick={removeFile} className={`removeFileText removeFileOption`}>Remove file</p>}
                                {nudgeFileError && <p className="formErrorMessage"><AiOutlineExclamationCircle /> {nudgeFileErrorMessage}</p>}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="skillPageRaiseNudgeFormInputBox" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Label className={`${formClass.formLabel}`}>Question</Form.Label>
                            <Form.Control className={`${formClass.formInput}`} name="summary" placeholder="Enter the question you want to ask" value={summary} onInput={(e) => { setSummary(e.target.value) }} minLength={3} type="text" />
                            {summaryError && <p className="formErrorMessage"><AiOutlineExclamationCircle /> {summaryErrorMessage}</p>}
                        </Col>

                        <Col className="skillPageRaiseNudgeFormInputBox" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div>
                                <Form.Label className="descriptionLabel">Description</Form.Label>
                                <ReactQuill
                                    className="skillPageRaiseNudgeQuillInputBox"
                                    placeholder="Please provide context and specific details for a personalized response from the expert"
                                    value={description}
                                    ref={reactQuillRef}
                                    formats={AppConstants.richTextEditorFormats}
                                    modules={AppConstants.richTextEditorModules}
                                    onChange={handleTextChange} />
                            </div>
                            {descriptionError && <p className="formErrorMessage"><AiOutlineExclamationCircle /> {descriptionErrorMessage}</p>}
                        </Col>
                    </Row>

                    <div className="submitButtonContainer">
                        <Button onClick={submitNudgeRequest} disabled={submitSpinner} className="submitButton">{Auth.isLogin() ? "Raise Nudge" : "Login to Raise Nudge"} {submitSpinner && <BiLoaderAlt className="spinner" />}</Button>
                        {submitError && <ErrorSuccessAlertMessage message={submitErrorMessage} varient={submitErrorVarient} />}
                    </div>
                    {props.category === AppConstants.learningActivitiesTypes.nudge && showModal && Auth.isLogin() && <Modal show={showModal} onHide={handleModalClose} backdrop="static" keyboard={false} centered>
                        <Modal.Body className={modalClass.modalBody}>

                            {(raisedNudge.uniqueId && paymentStatus !== AppConstants.paymentStatus.initiated && modalTypeError === AppConstants.falseText) ? <div className={modalClass.modalSuccessIcon}><AiOutlineExclamationCircle /></div> : null}
                            {(raisedNudge.uniqueId && paymentStatus === AppConstants.emptyString && modalTypeError === AppConstants.trueText) ? <div className={modalClass.modalErrorIcon}><AiOutlineExclamationCircle /></div> : null}
                            {raisedNudge.uniqueId && paymentStatus === AppConstants.paymentStatus.initiated ? <div className={modalClass.modalSuccessIcon}><BiLoaderAlt className='spinner' /><br /></div> : null}
                            {(!raisedNudge.uniqueId && paymentStatus === AppConstants.paymentStatus.pending) ? <div className={modalClass.modalSuccessIcon}><BiLoaderAlt className='spinner' /></div> : null}
                            {(!raisedNudge.uniqueId && paymentStatus === AppConstants.paymentStatus.failed && modalTypeError === AppConstants.trueText) ? <div className={modalClass.modalErrorIcon}><AiOutlineExclamationCircle /></div> : null}
                            {(!raisedNudge.uniqueId && paymentStatus === AppConstants.paymentStatus.verified) ? <>
                                {(modalTypeError === AppConstants.falseText && message !== SuccessMessage.paymentInitiatedMessage) && <div className={modalClass.modalSuccessIcon}><img className={modalClass.verifyCheckImg} src={verifyGif} /></div>}
                                {(modalTypeError === AppConstants.trueText) && <div className={modalClass.modalErrorIcon}><AiOutlineExclamationCircle /></div>}
                                {(message === SuccessMessage.paymentInitiatedMessage) && <div className={modalClass.modalSuccessIcon}><BiLoaderAlt className='spinner' /><br /></div>}
                            </> : null}
                            {/* {(raisedNudge.uniqueId && paymentStatus === AppConstants.emptyString && modalTypeError === AppConstants.falseText) ? <div className={modalClass.modalAlertText}>
                                Please complete the payment of <span>INR {nudgeCost}</span> to raise Nudge.<br></br>
                            </div> : null} */}
                            {(paymentStatus === AppConstants.paymentStatus.initiated) && <div className={modalClass.modalAlertText}>
                                Initiating your payment of <span>INR {nudgeCost}</span>. Please wait while we open the checkout window for you.  <br></br>
                            </div>}
                            {(!raisedNudge.uniqueId && paymentStatus === AppConstants.paymentStatus.pending) ? <div className={modalClass.modalAlertText}>
                                Processing your payment. Please do not reload or refresh this page.<br></br>
                            </div> : null}
                            {(raisedNudge.uniqueId && paymentStatus === AppConstants.emptyString && modalTypeError === AppConstants.trueText) ? <div className={modalClass.modalAlertText}>
                                {parse(message)}<br></br>
                            </div> : null}
                            {(!raisedNudge.uniqueId && paymentStatus === AppConstants.paymentStatus.failed) ? <div className={modalClass.modalAlertText}>
                                {message ? getParsedMessage(message, { transactionId }) : getParsedMessage(ErrorMessages.nudgePaymentVerificationError, { transactionId })}<br></br>
                            </div> : null}
                            {(!raisedNudge.uniqueId && paymentStatus === AppConstants.paymentStatus.verified && !isNudgeActivated && modalTypeError === AppConstants.trueText) ? <div className={modalClass.modalAlertText}>
                                {getParsedMessage(ErrorMessages.paymentDeductedRaiseNudgeError, { transactionId })}<br></br>
                            </div> : null}
                            {(!raisedNudge.uniqueId && paymentStatus === AppConstants.paymentStatus.verified && isNudgeActivated) ? <div className={modalClass.modalAlertText}>
                                <b>Payment Complete!</b> <br></br>
                                {transactionId && <span className={modalClass.transactionId}>Payment Id: {transactionId}{!showLinkCopied && <IoMdCopy className={modalClass.copyCodeIcon} onClick={copyToClipBoard} data-tooltip-id="clickToCopyMessage" />}{showLinkCopied && <BsFillCheckCircleFill className={modalClass.copyCodeTickIcon} />}</span>}<br />
                                {modalTypeError === AppConstants.falseText && message !== SuccessMessage.paymentInitiatedMessage && StringUtils.getParsedMessage(SuccessMessage.nudgeRaisedSuccessfullyMessage, { responseTime })}
                            </div> : null}
                        </Modal.Body>
                        {/* {(raisedNudge.uniqueId && paymentStatus === AppConstants.emptyString && modalTypeError === AppConstants.falseText) && <Modal.Footer className={modalClass.modalFooter}>
                            <Button className={modalClass.modalCloseButton} onClick={initiatePayment}>Pay now</Button>
                        </Modal.Footer>} */}
                        {(raisedNudge.uniqueId && paymentStatus === AppConstants.emptyString && modalTypeError === AppConstants.trueText) && <Modal.Footer className={modalClass.modalFooter}>
                            <Button className={modalClass.modalCloseButton} onClick={handleModalClose}>Close</Button>
                        </Modal.Footer>}
                        {(paymentStatus === AppConstants.paymentStatus.verified || paymentStatus === AppConstants.paymentStatus.failed) && (message !== SuccessMessage.paymentInitiatedMessage) && <Modal.Footer className={modalClass.modalFooter}>
                            {!displayNetworkError && <Button className={modalClass.modalCloseButton} onClick={handleModalClose}>Close</Button>}
                            {displayNetworkError && <Button className={modalClass.modalCloseButton} onClick={retryNudgeActivation}>Retry</Button>}
                        </Modal.Footer>}
                    </Modal>}
                    {props.category === AppConstants.learningActivitiesTypes.nudge && showFreeNudgeModal && Auth.isLogin() && <Modal show={showFreeNudgeModal} onHide={handleFreeNudgeModalClose} backdrop="static" keyboard={false} centered>
                        <Modal.Body className={modalClass.modalBody}>
                            {(modalTypeError === AppConstants.trueText) ? <div className={modalClass.modalErrorIcon}><AiOutlineExclamationCircle /></div> : <div className={modalClass.modalSuccessIcon}><AiOutlineCheckCircle /></div>}
                            <div className={modalClass.modalAlertText}>
                                {modalTypeError === AppConstants.falseText ? StringUtils.getParsedMessage(SuccessMessage.nudgeRaisedSuccessfullyMessage, { responseTime }) : ErrorMessages.studentRaiseNudgeError}
                            </div>
                        </Modal.Body>
                        <Modal.Footer className={modalClass.modalFooter}>
                            <Button className={modalClass.modalCloseButton} onClick={handleFreeNudgeModalClose}>Close</Button>
                        </Modal.Footer>
                    </Modal>}
                    {showLoginModal && <LoginModal onShow={() => setShowLoginModal(AppConstants.trueText)} onClose={hideLoginModal} />}
                </form>
            </div >
        </>
    )
}
