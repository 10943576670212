import { Link } from "react-router-dom";
import { React, useState } from "react";
import 'moment-timezone';
import { AiOutlineClockCircle } from "react-icons/ai";
import { IoPeopleSharp } from "react-icons/io5";
import { Row, Col, Card, Button } from "react-bootstrap";
import { FaRegCalendarCheck } from 'react-icons/fa';
import { BiCalendarX } from 'react-icons/bi';
import classes from './MarkAttendanceWidgetCards.module.css';
import { AppConstants } from "../../constants/AppConstants";
import { MathUtil } from "../../utils/MathUtil";
import { DateTimeUtil } from "../../utils/DateTimeUtil";

export const MarkAttendanceWidgetCards = (props) => {
    const [cardCount, setCardCount] = useState(2);

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleCardClick = (url) => {
        window.open(url, AppConstants.newPageTargetSelf);
    };

    const navigateToAttendancePage = (uniqueId) => {
        const url = `${AppConstants.markAttendancePath}${AppConstants.forwardSlash}${uniqueId}`;
        window.open(url, AppConstants.openInNewTab);
    }

    return (
        <>
            <Row xs={1} sm={1} md={1} lg={1} xl={1} className={classes.courseCardContainer}>
                {props.cardDetails?.slice(0, cardCount).map((course) => (
                    <Col className={classes.courseCardSection} key={course.id}>
                        <Card className={classes.courseCard} onClick={() => handleCardClick(`${AppConstants.forwardSlash}${AppConstants.coursesText}${AppConstants.forwardSlash}${course.skillCategorySlug}${AppConstants.forwardSlash}${course.skillSlug}${AppConstants.forwardSlash}${course.courseSlug}`)}>
                            <Card.Body className={classes.courseCardBody}>
                                <Card.Title className={classes.courseTitle}><Link>{course.courseTitle}</Link></Card.Title>
                                {course.assignedBy && <div onClick={e => { e.stopPropagation(); window.open(`${process.env.REACT_APP_FRONT_END_URL}${AppConstants.agency}${AppConstants.forwardSlash}${course.assignedBySlug}`) }} className={classes.expertNameText}>By : {course.assignedBy}</div>}
                                {course.courseType === AppConstants.flexibleCourseType && <div className={classes.dateTimeSection}>
                                    <AiOutlineClockCircle className={classes.blueCalendarIcon} /> <div className={classes.dateTimeText}>
                                        <span className="competitionDateTimeFormat">{`${DateTimeUtil.convertUtcTimeToLocalTime(course.windowStartTime)} - ${DateTimeUtil.convertUtcTimeToLocalTime(course.windowEndTime)}`} {course.preferredTimezone === AppConstants.utcTimezone && `(${course.preferredTimezone})`}</span>
                                    </div>
                                </div>}
                                {course.courseType === AppConstants.structuredCourseType && <div className={classes.dateTimeSection}>
                                    <FaRegCalendarCheck className={classes.blueCalendarIcon} /> <div className={classes.dateTimeText}>
                                        Start:&nbsp;<span className="competitionDateTimeFormat">{DateTimeUtil.getDateTimeForCourses(course.startTime)} {course.preferredTimezone === AppConstants.utcTimezone && `(${course.preferredTimezone})`}</span>
                                    </div>
                                </div>}
                                {course.courseType === AppConstants.structuredCourseType && <div className={classes.dateTimeSection}>
                                    <BiCalendarX className={`${classes.blueCalendarIcon} ${classes.endDateIcon}`} /> <div className={classes.dateTimeText}>
                                        End:&nbsp;<span className="competitionDateTimeFormat">{DateTimeUtil.getDateTimeForCourses(course.endTime)} {course.preferredTimezone === AppConstants.utcTimezone && `(${course.preferredTimezone})`}</span>
                                    </div>
                                </div>}
                                <div className={`${classes.dateTimeSection}`}>
                                    <IoPeopleSharp className={classes.blueCalendarIcon} /> <div className={classes.dateTimeText}>
                                        <span className="competitionDateTimeFormat">{MathUtil.formatNumberToLocaleString(course.participantsDetails?.length)} Enrolments</span>
                                    </div>
                                </div>
                                {course.isAttendanceEnabledFlag === AppConstants.yText && <Button onClick={(e) => { stopPropagation(e); navigateToAttendancePage(course.uniqueId) }} className={classes.markAttendanceButton}>Mark Attendance</Button>}
                                {course.trackingType === AppConstants.classBasedTrackingText && course.isAttendanceEnabledFlag === AppConstants.nText && <Button onClick={(e) => { stopPropagation(e); navigateToAttendancePage(course.uniqueId) }} className={classes.markAttendanceButton}>Track Classes</Button>}
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Row xs={1} sm={1} md={1} lg={1} xl={1} className="loadMore">
                <Col className="loadMoreButtonSection">
                    {cardCount < props.cardDetails?.length && <button className="loadMoreButton" onClick={() => setCardCount(cardCount + 2)}>Load more</button>}
                </Col>
            </Row>
        </>
    );
}