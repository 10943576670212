import { useLoaderData, useNavigate } from "react-router-dom";
import classes from "./RespondNudgesPage.module.css";
import { useEffect, useState } from "react";
import { AppConstants } from "../../constants/AppConstants";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import Auth from "../../utils/Auth";
import { LandingPageTopNavigation } from "../../layout/LandingPageLayout/LandingPageTopNavigation";
import { QuizAuthenticationForm } from "../../component/quizEventComponent/QuizAuthenticationForm";
import { BiLoaderAlt } from "react-icons/bi";
import { LandingPageFooter } from "../../layout/LandingPageLayout/LandingPageFooter";
import { NudgesPageBanner } from "../../component/nudgesPage/NudgesPageBanner";
import { ExpertViewNudgesCards } from "../../component/cards/expertNudgesCards/ExpertViewNudgesCards";
import { FaArrowLeft } from "react-icons/fa";
import { ExpertNudgeDetailsSection } from "../../component/cards/expertNudgesCards/ExpertNudgeDetailsSection";

export const RespondNudgesPage = () => {
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const [landingPageData] = useLoaderData();
    const [showPopupForLogin, setShowPopupForLogin] = useState(AppConstants.falseText);
    const [pageLoaderSpinner, setPageLoaderSpinner] = useState(AppConstants.trueText);
    const [activeNudgesArray, setActiveNudgesArray] = useState([]);
    const [closedNudgesArray, setClosedNudgesArray] = useState([]);
    const [viewDetailsSection, setViewDetailsSection] = useState(false);
    const [selectedNudgeData, setSelectedNudgeData] = useState([]);
    const [isUserEligibleToRespond, setIsUserEligibleToRespond] = useState(AppConstants.emptyString);
    const [refreshFlag, setRefreshFlag] = useState(AppConstants.falseText);
    const navigate = useNavigate();

    useEffect(() => {
        const getActiveNudges = async () => {
            const url = AppConstants.getActiveNudgesAssignedToExpertAPIWithParam;
            const response = await AuthorizedDataService.getRequest(url, AppConstants.emptyString, AppConstants.emptyString);
            return response;
        };

        const getClosedNudges = async () => {
            const url = AppConstants.getClosedNudgesAssignedToExpertAPIWithParam;
            const response = await AuthorizedDataService.getRequest(url, AppConstants.emptyString, AppConstants.emptyString);
            return response;
        };

        const fetchNudges = async () => {
            try {
                const [activeNudges, closedNudges] = await Promise.all([
                    getActiveNudges(),
                    getClosedNudges()
                ]);
                setActiveNudgesArray(activeNudges || []);
                setClosedNudgesArray(closedNudges || []);
            } finally {
                setPageLoaderSpinner(AppConstants.falseText);
            }
        };


        const checkUserEligibility = async () => {
            const url = AppConstants.checkUserEligibilityForNudgesAPI;
            const response = await AuthorizedDataService.getRequest(url, AppConstants.emptyString, AppConstants.emptyString);
            return response;
        };

        const handleUserEligibility = async () => {
            if (Auth.isLogin()) {
                const userRole = Auth.getUserRole();
                if (userRole === AppConstants.userRoleStudent) {
                    setIsUserEligibleToRespond(AppConstants.falseText);
                    navigate(AppConstants.asterisk);
                    setShowPopupForLogin(AppConstants.falseText);
                } else if (userRole === AppConstants.userRoleExpert) {
                    setIsUserEligibleToRespond(AppConstants.trueText);
                    fetchNudges();
                } else {
                    const eligibility = await checkUserEligibility();
                    if (eligibility) {
                        setIsUserEligibleToRespond(AppConstants.trueText);
                        fetchNudges();
                    } else {
                        navigate(AppConstants.asterisk);
                        setShowPopupForLogin(AppConstants.falseText);
                    }
                }
            } else {
                setShowPopupForLogin(AppConstants.trueText);
            }
        };

        if (isUserEligibleToRespond === AppConstants.emptyString) {
            handleUserEligibility();
        } else if (isUserEligibleToRespond === AppConstants.trueText) {
            fetchNudges();
        }
    }, [navigate, refreshFlag]);



    const closeAuthenticationForm = () => {
        window.location.reload();
    };

    const viewNudgeDetails = (options) => {
        setViewDetailsSection(true);
        setSelectedNudgeData(options);
    }

    const backToRaiseNudgeSection = () => {
        setViewDetailsSection(false);
    }

    const bannerImages = {
        webBanner: `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.expertNudgeWebBannerImage}`,
        tabBanner: `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.expertNudgeTabBannerImage}`,
        mobileBanner: `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.expertNudgeMobileBannerImage}`
    };

    return (
        <div>
            {Auth.isLogin() && <LandingPageTopNavigation landingPageData={landingPageData} />}
            {showPopupForLogin ?
                <div className={classes.loginSection}>
                    <QuizAuthenticationForm disableSignup={AppConstants.trueText} closeAuthenticationForm={closeAuthenticationForm} loginHeading="Login" />
                </div>
                : (pageLoaderSpinner) ? <div className={classes.loaderContainer}>
                    {pageLoaderSpinner && <p className={classes.loaderIcon}><BiLoaderAlt className="spinner" /></p>}
                </div>
                    : <>
                        <NudgesPageBanner bannerImages={bannerImages} />
                        <div className={classes.pageContainer}>
                            <div className="expertContentSection">
                                {!viewDetailsSection && <div>
                                    <h1 className={classes.nudgesHeading}>Active Nudges</h1>
                                    <ExpertViewNudgesCards nudgesData={activeNudgesArray} closedNudges={false} onViewClick={viewNudgeDetails} />
                                    <h1 className={classes.nudgesHeading}>Responded Nudges</h1>
                                    <ExpertViewNudgesCards nudgesData={closedNudgesArray} closedNudges={true} onViewClick={viewNudgeDetails} />
                                </div>}
                                {viewDetailsSection && <div className={classes.viewDetailsContainer}>
                                    <div className={classes.backButtonContainer}><FaArrowLeft onClick={() => { setRefreshFlag(!refreshFlag); backToRaiseNudgeSection() }} className={classes.backArrowbutton} /></div>
                                    <ExpertNudgeDetailsSection nudgesData={selectedNudgeData} onBackButtonClick={backToRaiseNudgeSection} />
                                </div>}
                            </div>
                        </div>
                        <LandingPageFooter landingPageData={landingPageData} />
                    </>}
        </div>
    )
}