import React, { useEffect } from "react";
import { LandingPageHeroCarousel } from '../../component/landingPageSections/LandingPageHeroCarousel';
import { ImpactCreatedByQrenciaSection } from '../../component/landingPageSections/ImpactCreatedByQrenciaSection';
import { QrenciaNews } from '../../component/landingPageSections/QrenciaNews';
import { QrenciaSkillsSection } from '../../component/landingPageSections/QrenciaSkillsSection';
import { UpcomingQTalksSection } from '../../component/landingPageSections/UpcomingQTalksSection';
import { LandingPageFooter } from '../../layout/LandingPageLayout/LandingPageFooter';
import { useLoaderData } from 'react-router-dom';
import { AppConstants } from "../../constants/AppConstants";
import { LandingPageDedicatedNavbar } from "../../layout/LandingPageLayout/LandingPageDedicatedNavbar";
import { Element } from 'react-scroll';
import { DiscoverYourPassionUpdatedSection } from "../../component/landingPageSections/DiscoverYourPassionUpdatedSection";
import { QrenciaForExpertsSection } from "../../component/landingPageSections/QrenciaForExpertsSection";

export const LandingPage = () => {
  useEffect(() => {
    scrollToTop()
    async function scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'instant'
      });
    };

  }, []);
  const [landingPageSectionDetails, qtalksData, activitiesList, activitiesDropdownOptions] = useLoaderData();
  const upcomingQtalkDetails = qtalksData.filter((qtalk) => qtalk.showOnLandingPage.toLowerCase() === AppConstants.trueFlag.toLowerCase());

  return (
    <>
      {landingPageSectionDetails && qtalksData && activitiesList && <div>
        <LandingPageDedicatedNavbar learningActivities={activitiesDropdownOptions} />
        <LandingPageHeroCarousel landingPageData={landingPageSectionDetails} />
        <Element id="skillSection"><QrenciaSkillsSection landingPageData={landingPageSectionDetails} activitiesList={activitiesList} /></Element>
        <Element id="yourPassionSection"><DiscoverYourPassionUpdatedSection landingPageData={landingPageSectionDetails} /></Element>
        {upcomingQtalkDetails.length !== 0 && <UpcomingQTalksSection landingPageData={landingPageSectionDetails} qtalksData={qtalksData} />}
        {landingPageSectionDetails?.qrenciaForExpert && landingPageSectionDetails?.qrenciaForExpert?.active && <QrenciaForExpertsSection qernciaForExperts={landingPageSectionDetails?.qrenciaForExpert} />}
        <ImpactCreatedByQrenciaSection landingPageData={landingPageSectionDetails} />
        <QrenciaNews landingPageData={landingPageSectionDetails} />
        <LandingPageFooter landingPageData={landingPageSectionDetails} /></div>
      }
    </>
  );
}