import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Tooltip } from 'react-tooltip';
import { BiLoaderAlt, BiCalendarCheck, BiWallet } from "react-icons/bi";
import { TbShare3 } from "react-icons/tb";
import { AppConstants } from "../../../constants/AppConstants";
import classes from './ViewCourseCards.module.css';
import 'moment-timezone';
import '../CourseCards.css';
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import { ErrorMessages } from "../../../constants/ErrorMessages";
import { ShareLinkToSocialMediaUtil } from "../../../utils/ShareLinkToSocialMediaUtil";
import { PaymentsUtil } from "../../../utils/PaymentsUtil";
import { CgPin } from "react-icons/cg";
import { AiOutlineClockCircle } from "react-icons/ai";
import { FaChalkboardTeacher } from "react-icons/fa";
import { MathUtil } from "../../../utils/MathUtil";
import useInfiniteScroll from "../../../hooks/useInfiniteScroll";
import { ViewSelectedWeekDays } from "../../calendar/ViewSelectedWeekDays";

export const ViewCourseCards = (props) => {
    const [courseCards, setCourseCards] = useState([]);
    const [cardCount, setCardCount] = useState(0);
    const [cardDisplayedCount, setCardDisplayedCount] = useState(0);
    const [editIndex, setEditIndex] = useState(null);
    const [showSocialMediaPannel, setShowSocialMediaPannel] = useState(AppConstants.falseText);
    const [linkToShare, setLinkToShare] = useState(AppConstants.emptyString);
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const learningActivitiesCardsCount = AppConstants.cardCounts;
    const [participantsImageList, setParticipantImageList] = useState([]);

    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const defaultCourseThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.courseDefaultThumbnailImage}`;

    useEffect(() => {
        const populateParticipantImageList = () => {
            let imageArray = [];
            for (let i = 0; i < props.courseData.length; i++) {
                let partcipantsData = props.courseData[i].participantsDetails;
                let imageList = [];
                partcipantsData.map(data => {
                    if (data.studentImage) imageList.push(data.studentImage)
                });
                imageArray.push(imageList);
            }
            setParticipantImageList(imageArray);
        }
        populateParticipantImageList();
    }, [props.courseData]);

    useEffect(() => {
        setCourseCards(props.courseData);
    }, [props.courseData]);

    const handleResize = () => {
        const viewportWidth = window.innerWidth;
        if (viewportWidth >= 992) {
            setCardCount(learningActivitiesCardsCount.learningActivityDesktopCount);
            setCardDisplayedCount(learningActivitiesCardsCount.learningActivityDesktopCount);
        }
        if (viewportWidth >= 576 && viewportWidth <= 991) {
            setCardCount(learningActivitiesCardsCount.learningActivityTabCount);
            setCardDisplayedCount(learningActivitiesCardsCount.learningActivityTabCount);
        }
        if (viewportWidth <= 575) {
            setCardCount(learningActivitiesCardsCount.learningActivityMobileCount);
            setCardDisplayedCount(learningActivitiesCardsCount.learningActivityMobileCount);
        }
    };

    //call handleResize() on screen width change
    useEffect(() => {
        handleResize();
        window.addEventListener('orientationchange', handleResize);
        return () => {
            window.removeEventListener('orientationchange', handleResize);
        };
    }, []);

    // Share on social media
    function shareOnSocialMedia(shareData) {
        setShowSocialMediaPannel(!showSocialMediaPannel);
        setLinkToShare(`${process.env.REACT_APP_FRONT_END_URL}${AppConstants.coursesText}${AppConstants.forwardSlash}${shareData.skillCategorySlug}${AppConstants.forwardSlash}${shareData.skillSlug}${AppConstants.forwardSlash}${shareData.courseSlug}`);
    }

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleCardClick = (url) => {
        window.open(url, AppConstants.newPageTargetSelf);
    };

    const navigateToGeolocation = (mode, location) => {
        if (mode?.toUpperCase() === "OFFLINE") {
            window.open(location, AppConstants.openInNewTab);
        }
    }

    const getWeekTimings = (value) => {
        let options = AppConstants.weekDaysOptions;
        let arr = value.split(AppConstants.comma);
        if (arr.length === 7) {
            return AppConstants.allDaysOfWeekText;
        } else {
            let dayString = AppConstants.emptyString;
            options.forEach(opt => {
                if (value.includes(opt.name)) dayString += `${opt.key}, `
            })
            return `On ${dayString.replace(/,\s*$/, AppConstants.emptyString)}`
        }
    };

    const fetchFormattedEnrolledCount = (enrolledParticipants) => {
        if (!enrolledParticipants) return AppConstants.emptyString;

        let enrolCount = MathUtil.formatNumberToLocaleString(parseInt(enrolledParticipants.split(AppConstants.space)));
        return `${enrolCount} Enrolled`
    }

    const { lastElementRef, loading } = useInfiniteScroll(() => {
        setCardCount(prevCount => prevCount + cardDisplayedCount);
    }, [courseCards]);

    return (
        <>
            <Row xs={1} sm={2} md={2} lg={3} xl={3} className="cardSection">
                {courseCards !== undefined && courseCards.slice(0, cardCount).map((course, index) => (
                    <Col key={course.uniqueId} ref={index === cardCount - 1 ? lastElementRef : null}>
                        <Card className="cardDiv" key={index} onClick={() => handleCardClick(`${AppConstants.forwardSlash}${AppConstants.coursesText}${AppConstants.forwardSlash}${course.skillCategorySlug}${AppConstants.forwardSlash}${course.skillSlug}${AppConstants.forwardSlash}${course.courseSlug}`)}>
                            <Card.Img variant="top" className="cardImage" src={course.courseThumbnailImageAbsolutePath ? course.courseThumbnailImageAbsolutePath : defaultCourseThumbnail} />
                            <div className={classes.uniqueIdText}>{course.courseId}</div>
                            <TbShare3 className={classes.shareIcon} onClick={(e) => { stopPropagation(e); setEditIndex(editIndex => editIndex === index ? index : index); shareOnSocialMedia(course) }} />
                            <Card.Body className="cardBody">
                                <div className="subTitleContainer">
                                    <div data-tooltip-id={`${course.uniqueId}-offline`} className={`cardSubTitle courseDiffColor`} onClick={(e) => { stopPropagation(e); navigateToGeolocation(course.modeOfDelivery.toUpperCase(), course.geoLocation) }}>{course.modeOfDelivery.toUpperCase()}</div>
                                    <div className="cardsCostInfoText"><BiWallet /> {PaymentsUtil.calculateCost({ cost: course.cost, discount: course.discount })}</div>
                                </div>
                                {course.modeOfDelivery.toUpperCase() === "OFFLINE" && <Tooltip className="tooltipMessage" id={`${course.uniqueId}-offline`}><span className="tooltipMessage">{course.address}</span></Tooltip>}
                                <Card.Text className="cardTitle titleFixedHeight">
                                    <Link className="cardLink" data-tooltip-id={`${course.uniqueId}-title`}>{course.courseTitle}</Link>
                                    <Tooltip className="courseTitleTooltip" id={`${course.uniqueId}-title`}><span>{course.courseTitle}</span></Tooltip>
                                </Card.Text>
                                {editIndex === index && showSocialMediaPannel && <div onClick={stopPropagation} className={classes.shareIconPannelContainer}>
                                    <ShareLinkToSocialMediaUtil linkToShare={linkToShare} className={classes} useIcons={AppConstants.trueText} />
                                </div>}
                                {course.expertName && <Card.Text className="cardTimeAndDate">By : {course.expertName}</Card.Text>}
                                <div className="cardTimeAndDate">
                                    <div className="dateTimeText">
                                        <span className="competitionDateTimeFormat"><ViewSelectedWeekDays selectedValues={course.weekDays} /></span>
                                    </div>
                                </div>
                                {course.courseType === AppConstants.structuredCourseType && <div className="cardTimeAndDate">
                                    <div><BiCalendarCheck className="calendarIcon" />&nbsp;</div>
                                    <div className="dateTimeText"><span className="competitionDateTimeFormat">Starts {DateTimeUtil.getDateTimeForCourses(course.startTime)} {timeZone === AppConstants.utcTimezone && `(${course.preferredTimezone})`}</span>
                                    </div>
                                </div>}
                                {course.courseType === AppConstants.flexibleCourseType && <div className="cardTimeAndDate">
                                    <div><AiOutlineClockCircle className="calendarIcon" />&nbsp;</div>
                                    <div className="dateTimeText"><span data-tooltip-id={`${course.uniqueId}-weekdays`} className="competitionDateTimeFormat">{course.startTime} - {course.endTime} {timeZone === AppConstants.utcTimezone && `(${course.preferredTimezone})`}</span>
                                        {course.weekDays && <Tooltip className="courseTitleTooltip" id={`${course.uniqueId}-weekdays`}><span>{getWeekTimings(course.weekDays)}</span></Tooltip>}
                                    </div>
                                </div>}
                                {course.trackingType !== AppConstants.durationBasedTrackingText && <div className="cardTimeAndDate">
                                    <div><FaChalkboardTeacher className="durationIcon calendarIcon" />&nbsp;</div><div className="dateTimeText">
                                        <span className="competitionDateTimeFormat">&nbsp;{course.numberOfClasses} {course.numberOfClasses > 1 ? "Classes" : "Class"} <span className="durationSeparator">|</span> {course.duration} {course.duration > 1 ? "Hours" : "Hour"} </span>
                                    </div>
                                </div>}
                                {course.trackingType === AppConstants.durationBasedTrackingText && <div className="cardTimeAndDate">
                                    <div><FaChalkboardTeacher className="durationIcon calendarIcon" />&nbsp;</div><div className="dateTimeText">
                                        <span className="competitionDateTimeFormat">&nbsp;{course.packageDuration} {course.packageDuration > 1 ? "Days" : "Day"} </span>
                                    </div>
                                </div>}
                                <div className="buttonSection">
                                    <div>
                                        {props.showEditButton && <span onClick={stopPropagation} data-tooltip-id={`pastCourseEditInfo-${course.uniqueId}`}><Button type="button" disabled={props.eventStatus === AppConstants.courseStatus.past} onClick={(e) => { stopPropagation(e); props.onClickEdit(course) }} className="cardButton me-2">Edit</Button></span>}
                                        {props.showViewButton && <Button onClick={(e) => { stopPropagation(e); props.onClickView(course) }} type="button" className="cardButton">View</Button>}
                                        {props.showAssignButton && <Button onClick={(e) => { stopPropagation(e); props.onClickAssign(course) }} type="button" className="cardButton">Assign</Button>}
                                    </div>
                                    {props.eventStatus === AppConstants.courseStatus.past && < Tooltip className="courseEditTooltip" id={`pastCourseEditInfo-${course.uniqueId}`}><span>{ErrorMessages.cannotEditPastCourseInfo}</span></Tooltip>}
                                </div>
                                <div className="cardUserProfileImageSection">
                                    <Tooltip className="tooltipMessage" id={course.uniqueId}><span className="tooltipMessage">{course.address}</span></Tooltip>
                                </div>

                                <div className="cardUserProfileImageSectionExpert mt-1">
                                    <div className="cardUserProfileImageContainerExpert">
                                        {(course?.length !== 0 && course.participantsDetails !== null && course?.participantsDetails?.length !== 0 && participantsImageList?.length !== 0 && participantsImageList[index]?.length !== 0) && <div className="cardProfileImageSection">
                                            {course.length !== 0 && course.participantsDetails !== null
                                                && participantsImageList[index]?.slice(0, AppConstants.studentProfileImageLimit).map((imgageSrc, i) => (
                                                    <img src={imgageSrc} key={i} className="cardProfileImage" alt="student profile" />
                                                ))}
                                        </div>}
                                        <div className="cardEnrolledCountSection"><div className="cardText">{fetchFormattedEnrolledCount(course.registeredStudents)}</div></div>
                                    </div>
                                    <div onClick={stopPropagation}>
                                        {(course.modeOfDelivery.toUpperCase() === AppConstants.physicalText || course.modeOfDelivery.toUpperCase() === AppConstants.offlineText) && <span className="onlineIcon" data-tooltip-place="top" data-tooltip-id={course.uniqueId}><a href={course.geoLocation} target={AppConstants.openInNewTab} className="cardLink"><CgPin className="geoLocationIcon" /></a></span>}
                                    </div>
                                    <Tooltip className="tooltipMessage" id={course.uniqueId}><span className="tooltipMessage">{course.address}</span></Tooltip>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            {loading && cardCount < props.courseData?.length && (
                <Row xs={1} sm={1} md={1} lg={1} xl={1} className="loadMore">
                    <div className="pageLoader"><BiLoaderAlt className="spinner" /></div>
                </Row>
            )}
        </>
    );
}