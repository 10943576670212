import { Outlet } from 'react-router-dom';
import React from 'react';
import { Row, Col } from "react-bootstrap";
import { AdminHeader } from '../../layout/AdminPageLayout/AdminHeader';
import { AdminLeftNav } from '../../layout/AdminPageLayout/AdminLeftNav';
import { AdminFooter } from '../../layout/AdminPageLayout/AdminFooter';
import { useLoaderData } from 'react-router-dom';

export const AdminTemplate = () => {
    const [footerDetails, isUserEligibleToRespond] = useLoaderData();

    return (
        <>
            <AdminHeader isUserEligibleToRespond={isUserEligibleToRespond} />
            <Row>
                <Col lg={2}><AdminLeftNav isUserEligibleToRespond={isUserEligibleToRespond} /></Col>
                <Col xs={12} sm={12} md={12} lg={10}>
                    <Outlet />
                </Col>
            </Row>
            {footerDetails != null && <AdminFooter footerDetails={footerDetails} />}
        </>
    )
}