import { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import nudgeCardStyle from "../../../common/NudgesCardsStyle.module.css";
import { FaCalendarAlt, FaCalendarCheck } from "react-icons/fa";
import { AppConstants } from "../../../constants/AppConstants";
import Moment from "react-moment";
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import { ErrorMessages } from "../../../constants/ErrorMessages";

export const ExpertViewNudgesCards = (props) => {
    const [cardCount, setCardCount] = useState(6);
    const nudgeDetailsArray = props.nudgesData;
    const [timeZone, setTimeZone] = useState(DateTimeUtil.getPreferredTimeZoneForUser());

    return (
        <>
            {props.nudgesData?.length !== 0 && <div className={nudgeCardStyle.expertContainer}>
                <Row>
                    {nudgeDetailsArray?.slice(0, cardCount).map((nudge, index) => (<Col key={nudge.id} className={nudgeCardStyle.columnPadding} xs={12} sm={12} md={6} lg={6} xl={4}>
                        <div className={nudgeCardStyle.cardsContainer}>
                            <div className={nudgeCardStyle.responseCountContainer}>
                                <p className={nudgeCardStyle.questionHeading}>Question</p>
                                {/* {nudge.feedbackSharedStatus===AppConstants.yesText && <p className= {`${nudgeCardStyle.resolvedText} ${nudgeCardStyle.responseCountText}`}>Resolved</p>}
                                {nudge.feedbackSharedStatus===AppConstants.noText && <p className= {`${nudgeCardStyle.pendingText} ${nudgeCardStyle.responseCountText}`}>Pending</p>} */}
                            </div>
                            <div className={nudgeCardStyle.summaryContainer}>
                                <p className={nudgeCardStyle.summaryText}>{nudge.summary}</p>
                            </div>
                            <div className={nudgeCardStyle.bottomContainer}>
                                {nudge.raisedOnUtc !== null && <div className={nudgeCardStyle.detailsGroup}>
                                    <div className={nudgeCardStyle.detailsIconSection}>
                                        <FaCalendarAlt className={nudgeCardStyle.detailsIcon} />
                                    </div>
                                    <div className={nudgeCardStyle.detailsSubsection}>
                                        <p className={nudgeCardStyle.detailsSubHeading}>Raised</p>
                                        <p className={nudgeCardStyle.detailsHeading}><Moment format={AppConstants.nudgeRaisedOnTimeFormat} tz={timeZone}>{nudge.raisedOnUtc.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${nudge.preferredTimezone})`}</p>
                                    </div>
                                </div>}
                                {nudge.status === AppConstants.closedText && nudge.closedOnUtc !== null && <div className={nudgeCardStyle.detailsGroup}>
                                    <div className={nudgeCardStyle.detailsIconSection}>
                                        <FaCalendarCheck className={nudgeCardStyle.detailsIcon} />
                                    </div>
                                    <div className={nudgeCardStyle.detailsSubsection}>
                                        <p className={nudgeCardStyle.detailsSubHeading}>Closed</p>
                                        <p className={nudgeCardStyle.detailsHeading}><Moment format={AppConstants.nudgeRaisedOnTimeFormat} tz={timeZone}>{nudge.closedOnUtc.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${nudge.preferredTimezone})`}</p>
                                    </div>
                                </div>}
                                <div className={nudgeCardStyle.viewButtonContainer}>
                                    <Button className={nudgeCardStyle.viewButton} onClick={() => props.onViewClick(nudge)}>View</Button>
                                </div>
                            </div>
                        </div>
                    </Col>))}
                </Row>
                <Row xs={1} sm={1} md={1} lg={1} xl={1} className={nudgeCardStyle.loadMoreContainer}>
                    <Col>
                        {cardCount < nudgeDetailsArray?.length && <button className="loadMoreButton" onClick={() => setCardCount(cardCount + 6)}>Load more</button>}
                    </Col>
                </Row>
            </div>}
            {props.nudgesData?.length === 0 && <div className={nudgeCardStyle.containerFallback}>
                <div>
                    {!props.closedNudges && <p className={nudgeCardStyle.fallBackMessage}>{ErrorMessages.expertViewActiveNudgesFallbackMessages[0]} {!props.closedNudges && "active"} {ErrorMessages.expertViewActiveNudgesFallbackMessages[1]}</p>}
                    {props.closedNudges && <p className={nudgeCardStyle.fallBackMessage}>{ErrorMessages.expertViewClosedNudgesFallbackMessages[0]} {props.closedNudges && "responded"} {ErrorMessages.expertViewClosedNudgesFallbackMessages[1]}</p>}
                </div>
            </div>}
        </>
    )
}