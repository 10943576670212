import { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import styles from "../../../common/NudgesDetailsSectionStyle.module.css";
import { AppConstants } from "../../../constants/AppConstants";
import Moment from "react-moment";
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import parse from 'html-react-parser';
import { AuthorizedDataService } from "../../../services/AuthorizedDataService";
import { BiLoaderAlt } from "react-icons/bi";
import { IoMdSend } from "react-icons/io";
import { AiFillCheckCircle, AiFillExclamationCircle, AiFillStar, AiOutlineStar } from "react-icons/ai";
import { FaCalendarAlt, FaCalendarCheck, FaPaperPlane } from "react-icons/fa";
import { ErrorMessages } from "../../../constants/ErrorMessages";
import { NudgeOfferingsCards } from "../nudgeOfferingsCards/NudgeOfferingsCards";
import reviewClass from "../reviewCardsComponent/ReviewCardsComponent.module.css";

export const StudentNudgeDetailsSection = (props) => {
    const nudgeDetails = props.nudgesData;
    const [expertDetailsArray, setExpertDetailsArray] = useState([]);
    const [timeZone, setTimeZone] = useState(DateTimeUtil.getPreferredTimeZoneForUser());
    const [rating, setRating] = useState(AppConstants.nullText);
    const [showRatingSubmit, setShowRatingSubmit] = useState(false);
    const [showRatingSpinner, setShowRatingSpinner] = useState(false);
    const [showRatingSuccess, setShowRatingSuccess] = useState(false);
    const [showRatingError, setShowRatingError] = useState(false);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [editIndex, setEditIndex] = useState(null);

    useEffect(() => {
        //get experts responses
        async function getExpertResponses() {
            const url = `${AppConstants.getExpertResponsesForNudgeAPI}${nudgeDetails.uniqueId}`;
            const requestHeaders = {
                preferredTimezone: timeZone
            }
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString,
                requestHeaders
            );
            if (response !== undefined) {
                setExpertDetailsArray(response);
            } else {
                setExpertDetailsArray([]);
            }
        }
        getExpertResponses();
    }, [refreshFlag]);

    //handle rating
    const handleRate = (newRating) => {
        setShowRatingSuccess(false);
        setShowRatingError(false);
        setRating(newRating);
        setShowRatingSubmit(true);
    };

    //submit rating
    async function submitRating(data) {
        setShowRatingSpinner(true);
        const ratingVar = rating + " Star";
        const url = `${AppConstants.rateNudgeAPIWithParamOne}${nudgeDetails.uniqueId}${AppConstants.rateNudgeAPIWithParamTwo}${data.expertUniqueId}${AppConstants.rateNudgeAPIWithParamThree}${ratingVar}`;
        const response = await AuthorizedDataService.putRequest(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        );
        if (response !== undefined) {
            setShowRatingSpinner(false);
            if (response.status === AppConstants.httpResponseCodes.responseCode201) {
                setShowRatingSuccess(true);
                setShowRatingError(false);
                setRefreshFlag(!refreshFlag);
                setTimeout(() => {
                    setShowRatingSuccess(false)
                }, AppConstants.messageDisappearTime);
            } else {
                setShowRatingSuccess(false);
                setShowRatingSpinner(false);
                setShowRatingError(true);
                setTimeout(() => {
                    setShowRatingError(false);
                }, AppConstants.messageDisappearTime);
            }
        } else {
            setShowRatingSuccess(false);
            setShowRatingSpinner(false);
            setShowRatingError(true);
            setTimeout(() => {
                setShowRatingError(false);
            }, AppConstants.messageDisappearTime);
        }
    }
    const navigateToBrandingPage = (expert) => {
        if (expert.expertBrandingPageLink) {
            window.open(`${process.env.REACT_APP_FRONT_END_URL}${expert.expertBrandingPageLink}`, AppConstants.openInNewTab)
        }
    }
    return (
        <>
            <div className={styles.mainContainer}>
                <div className={styles.mainHeadingContainer}>
                    <h1 className={styles.mainHeading}>Nudge Details</h1>
                </div>
                <Row>
                    <div className={styles.bottomContainer}>
                        <div className={styles.detailsGroup}>
                            <div className={styles.detailsIconSection}>
                                <FaPaperPlane className={styles.detailsIcon} />
                            </div>
                            <div className={styles.detailsSubsection}>
                                <p className={styles.detailsSubHeading}>Skill</p>
                                <p className={styles.detailsHeading}>{nudgeDetails.skillName}</p>
                            </div>
                        </div>
                        {nudgeDetails.raisedOnUtc !== AppConstants.nullText && <div className={styles.detailsGroup}>
                            <div className={styles.detailsIconSection}>
                                <FaCalendarAlt className={styles.detailsIcon} />
                            </div>
                            <div className={styles.detailsSubsection}>
                                <p className={styles.detailsSubHeading}>Raised On</p>
                                <p className={styles.detailsHeading}><Moment format={AppConstants.nudgeRaisedOnTimeFormat} tz={timeZone}>{nudgeDetails.raisedOnUtc.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${nudgeDetails.preferredTimezone})`}</p>
                            </div>
                        </div>}
                        {nudgeDetails.closedOnUtc !== AppConstants.nullText && <div className={styles.detailsGroup}>
                            <div className={styles.detailsIconSection}>
                                <FaCalendarCheck className={styles.detailsIcon} />
                            </div>
                            <div className={styles.detailsSubsection}>
                                <p className={styles.detailsSubHeading}>Closed On</p>
                                <p className={styles.detailsHeading}><Moment format={AppConstants.nudgeRaisedOnTimeFormat} tz={timeZone}>{nudgeDetails.closedOnUtc.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${nudgeDetails.preferredTimezone})`}</p>
                            </div>
                        </div>}
                    </div>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <p className={styles.detailHeading}>Question</p>
                        <p className={styles.detailSubHeading}>{nudgeDetails.summary}</p>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className={styles.downloadButtonContainer}>
                            <p className={styles.detailHeading}>Description</p>
                            {nudgeDetails !== AppConstants.nullText && nudgeDetails.filePath !== AppConstants.nullText && <Button href={nudgeDetails.filePath} className={styles.downloadButton}>View My Work</Button>}
                        </div>
                        <div className={`${styles.descriptionText} ql-editor`}>{parse(nudgeDetails.description)}</div>
                    </Col>
                </Row>

                {expertDetailsArray.length !== 0 && <div>
                    {expertDetailsArray.map((expert, index) => (<div className={styles.previousResponsesContainer}>
                        <div className={styles.expertNameContainer}>
                            <div key={index} className={styles.profilePicMainCnt}>
                                <div onClick={() => navigateToBrandingPage(expert)} className={styles.profilePicPreviewCnt}>
                                    {expert !== AppConstants.nullText && expert.expertProfileImage !== AppConstants.nullText && <img alt='Profile Avtar' className={styles.previewProfilePicOnModal} src={expert !== AppConstants.nullText && expert.expertProfileImage}></img>}
                                    {expert !== AppConstants.nullText && expert.expertProfileImage === AppConstants.nullText && <p className={styles.initialProfilePreviewOnModal}>{expert !== AppConstants.nullText && expert.creatorNameInitials}</p>}
                                </div>
                                <div className={styles.profilePicPreviewDetails}>
                                    <p onClick={() => navigateToBrandingPage(expert)} className={styles.expertName}>{expert !== AppConstants.nullText && expert.creatorName}</p>
                                </div>
                            </div>
                            {expert.rating === null && <div>
                                <p className={styles.ratingTitle}>{AppConstants.studentRateNudgeMessageText}</p>
                                {AppConstants.rateNudge5starArray.map((value) => (
                                    <span
                                        key={value}
                                        className={styles.ratingStars}
                                        onClick={() => { setEditIndex(editIndex => editIndex === index ? index : index); handleRate(value) }}>
                                        {editIndex === index && value <= rating ? <AiFillStar /> : <AiOutlineStar />}
                                    </span>
                                ))}
                                {editIndex === index && showRatingSubmit && <div className={styles.submitRatingIconContainer}>
                                    {(editIndex === index && !showRatingSuccess) && (editIndex === index && !showRatingSpinner) && (editIndex === index && !showRatingError) && <IoMdSend onClick={() => { submitRating(expert) }} className={styles.submitRatingIcon} />}
                                    {editIndex === index && showRatingSpinner && <BiLoaderAlt className="spinner ms-3" />}
                                    {editIndex === index && showRatingSuccess && <AiFillCheckCircle className={styles.submitRatingIconSuccess} />}
                                    {editIndex === index && showRatingError && <AiFillExclamationCircle className={styles.submitRatingIconError} />}
                                </div>}
                            </div>}
                            {expert.rating !== null && <div>
                                {AppConstants.rateNudge5starArray.map((value) => (
                                    <span
                                        key={value}
                                        className={styles.ratingStars}>
                                        {value <= expert.rating.substr(0, 1) ? <AiFillStar /> : <AiOutlineStar />}
                                    </span>
                                ))}
                            </div>}
                        </div>
                        <div className={styles.responseContainer}>
                            {expert.expertResponses.map((response, index) => (
                                <div key={index}>
                                    <div className={styles.responseCardContainer}>
                                        <p className={styles.studentResponseDateTime}><Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{response.respondedOn.replace(' ', 'T') + 'Z'}</Moment> | <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{response.respondedOn.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${nudgeDetails.preferredTimezone})`}</p>
                                        <div className={`${styles.studentResponseCard} ql-editor`}>{parse(response.response)}
                                            {response.nudgeResponseFilePath !== null && <Button href={response.nudgeResponseFilePath} className={styles.showExpertFileButton}>{AppConstants.viewExpertNudgeAttachment}</Button>}
                                        </div>
                                        {index !== expert?.expertResponses?.length - 1 && <div className={styles.responseSeparator}></div>}
                                    </div>
                                </div>))}

                            <div className={styles.expertBrandingSection}>
                                {expert.expertOffrings?.length > 0 && <div className={styles.offeringsContainer}>
                                    <h5 className={styles.exploreOfferingsHeading}>Explore some interesting learning activities</h5>
                                    <NudgeOfferingsCards cardDetails={expert.expertOffrings} />
                                </div>}
                                {expert.expertReviews !== AppConstants.nullText && <div className={styles.offeringsContainer}>
                                    <h5 className={styles.exploreOfferingsHeading}>What learners say about me!</h5>
                                    <div className={`${reviewClass.reviewContainer} ${styles.reviews}`}>
                                        <div className={reviewClass.profileImageSection}>
                                            {expert.expertReviews.reviewerProfileImage ? <div className={reviewClass.reviewInitials}><img src={expert.expertReviews.reviewerProfileImage} className={reviewClass.profilePicture} alt="user_profile" /></div> : <div className={reviewClass.reviewInitials}>{expert.expertReviews.reviewerInitials}</div>}
                                            <div>
                                                <p className={reviewClass.reviewerName}>{expert.expertReviews.reviewedBy}</p>
                                                {expert.expertReviews.rating && <div className={reviewClass.ratingStarContainer}>
                                                    {AppConstants.rateNudge5starArray.map((value) => (
                                                        <span
                                                            key={value}
                                                            className={styles.expertRatingStarts}
                                                        >
                                                            {value <= (expert.expertReviews.rating.toString()?.substr(0, 1) || 0) ? <AiFillStar /> : <AiOutlineStar />}
                                                        </span>
                                                    ))}
                                                </div>}
                                            </div>
                                        </div>
                                        <p className={reviewClass.reviewComment}>{expert.expertReviews.review}</p>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>))}
                </div>}
                {expertDetailsArray.length === 0 && <div className="mt-2">
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <p className={styles.detailHeading}>Responses</p>
                            <p className={styles.fallBackMessage}>{ErrorMessages.noResponseFallbackMessage}</p>
                        </Col>
                    </Row>
                </div>}
            </div>
        </>
    )
}