import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Tooltip } from 'react-tooltip';
import { CgPin } from "react-icons/cg";
import { DateTime } from "luxon";
import classes from './StudentFeaturedCourseCards.module.css';
import { AppConstants } from "../../../constants/AppConstants";
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import { BiCalendarCheck, BiWallet } from "react-icons/bi";
import { EnrolmentModal } from "../../modal/EnrolmentModal";
import { PaymentsUtil } from "../../../utils/PaymentsUtil";
import { ErrorMessages } from "../../../constants/ErrorMessages";
import { FaChalkboardTeacher } from "react-icons/fa";
import { PostStartEnrolmentModal } from "../../modal/PostStartEnrolmentModal";
import { AiOutlineClockCircle } from "react-icons/ai";
import 'moment-timezone';
import '../CourseCards.css';
import { PayOfflineConfirmationModal } from "../../modal/PayOfflineConfirmationModal";
import { MathUtil } from "../../../utils/MathUtil";
import { useEnrolment } from "../../../hooks/useEnrolment";
import useWarnOnLeave from "../../../hooks/useWarnOnLeave";
import { ViewSelectedWeekDays } from "../../calendar/ViewSelectedWeekDays";

export const StudentFeaturedCourseCards = (props) => {
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [courseCards, setCourseCards] = useState([]);
    const [cardCount, setCardCount] = useState(0);
    const [viewMoreCardsCount, setViewMoreCardsCount] = useState(0);
    const [message, setMessage] = useState(AppConstants.emptyString);
    const [showModal, setShowModal] = useState(false);
    const [modalTypeError, setModalTypeError] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [paymentParam, setPaymentParam] = useState(searchParams.get(AppConstants.paymentUrlParam));
    const [paymentStatus, setPaymentStatus] = useState(AppConstants.emptyString);
    const [transactionId, setTransactionId] = useState(AppConstants.emptyString);
    const [payloadData, setPayloadData] = useState({});
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const learningActivitiesCardsCount = AppConstants.cardCounts;
    const [showOfflinePaymentModal, setShowOfflinePaymentModal] = useState(AppConstants.falseText);
    const [showConfirmationModal, setShowConfirmationModal] = useState(AppConstants.falseText);
    const [confirmationPopupActivityDetails, setConfirmationPopupActivityDetails] = useState({});
    const defaultCourseThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.courseDefaultThumbnailImage}`;
    const { enrolInLearningActivity } = useEnrolment(AppConstants.learningActivitiesTypes.course);
    const [displayNetworkError, setDisplayNetworkError] = useState(AppConstants.falseText);
    const [paymentInitiateResponse, setPaymentInitiateResponse] = useState({});
    const [shouldWarn, setShouldWarn] = useState(false);
    useWarnOnLeave(shouldWarn)

    const handleResize = () => {
        const viewportWidth = window.innerWidth;
        if (viewportWidth >= 992) {
            setCardCount(learningActivitiesCardsCount.learningActivityDesktopCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.learningActivityDesktopCount);
        }
        if (viewportWidth >= 576 && viewportWidth <= 991) {
            setCardCount(learningActivitiesCardsCount.learningActivityTabCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.learningActivityTabCount);
        }
        if (viewportWidth <= 575) {
            setCardCount(learningActivitiesCardsCount.learningActivityMobileCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.learningActivityMobileCount);
        }
    };

    const handleModalClose = () => {
        setShowModal(false);
        setTransactionId(AppConstants.emptyString);
        if (paymentParam) {
            window.location.replace(window.location.href.split(AppConstants.questionMark)[0]);
        } else if (transactionId) {
            window.location.reload();
        }
    }

    const handleModalShow = () => {
        setShowModal(true);
    };

    //call handleResize() on screen width change
    useEffect(() => {
        handleResize();
        window.addEventListener('orientationchange', handleResize);
        return () => {
            window.removeEventListener('orientationchange', handleResize);
        };
    }, []);

    useEffect(() => {
        setCourseCards(props.courseData);
    }, [props.courseData]);

    useEffect(() => {
        const verifyPayment = async (data, course) => {
            try {
                if (course && course?.uniqueId) {
                    PaymentsUtil.verifyPayment(data).then((response) => {
                        if (response.verificationStatus === AppConstants.paymentStatus.verified) {
                            setPaymentStatus(AppConstants.paymentStatus.verified);
                            setModalTypeError(AppConstants.falseText);
                            setTransactionId(response.transactionId);
                            window.history.pushState(AppConstants.nullText, AppConstants.nullText, window.location.href.split(AppConstants.questionMark)[0]);
                            enrolInCourse(course.courseId, course.courseTitle, course.uniqueId, AppConstants.paymentStatus.verified);
                        } else {
                            setModalTypeError(AppConstants.trueText);
                            setMessage(response.message);
                            setTransactionId(response.transactionId);
                            setPaymentStatus(AppConstants.emptyString);
                            window.history.pushState(AppConstants.nullText, AppConstants.nullText, window.location.href.split(AppConstants.questionMark)[0]);
                            handleModalShow();
                        }
                    }).catch((error) => {
                        setModalTypeError(AppConstants.trueText);
                        setMessage(ErrorMessages.verifyPaymentFailedError);
                        setPaymentStatus(AppConstants.emptyString);
                        window.history.pushState(AppConstants.nullText, AppConstants.nullText, window.location.href.split(AppConstants.questionMark)[0]);
                        handleModalShow();
                    });
                }
            } catch (error) {
                setPaymentStatus(AppConstants.emptyString);
            }
        }

        const decodeBase64EncodedString = (encodedString) => {
            try {
                return JSON.parse(window.atob(encodedString));
            } catch (error) {
                return null;
            }
        };

        if (paymentParam && courseCards.length > 0) {
            const data = decodeBase64EncodedString(paymentParam);
            if (data !== null && data.category === props.category) {
                const course = courseCards.filter(card => card.uniqueId === data.uniqueId)[0];
                if (!course) {
                    setPayloadData({});
                    setPaymentStatus(AppConstants.emptyString);
                    return;
                }
                setConfirmationPopupActivityDetails({ id: course.courseId, title: course.courseTitle, uniqueId: course.uniqueId, startTime: course.courseType === AppConstants.structuredCourseType ? `${course.utcStartDateObject} ${course.windowStartTimeString}` : AppConstants.nullText, payOfflineFlag: course.payOfflineFlag, creatorName: course.organizedBy, creatorRole: course.userRole });
                data.learningActivityName = course.courseTitle;
                setPayloadData(data);
                setPaymentStatus(AppConstants.paymentStatus.pending);

                const timer = setTimeout(() => {
                    verifyPayment(data, course);
                }, AppConstants.paymentVerificationTimeout);

                return () => clearTimeout(timer);
            }
        }

    }, [paymentParam, courseCards]);

    const confirmBeforeEnrolment = ({ id, title, uniqueId, startTime, payOfflineFlag, creatorName, creatorRole }) => {
        setTransactionId(AppConstants.emptyString);
        setConfirmationPopupActivityDetails({ id, title, uniqueId, type: AppConstants.learningActivitiesTypes.course, payOfflineFlag, creatorName, creatorRole, startTime });

        // flexi courses
        if (startTime === AppConstants.nullText) {
            if (payOfflineFlag === AppConstants.yText) {
                setShowOfflinePaymentModal(AppConstants.trueText);
            } else {
                enrolInCourse(id, title, uniqueId);
            }
        } else {
            const startDateTime = DateTime.fromISO(startTime?.replace(' ', 'T') + 'Z').setZone(timeZone);
            const currentDateTime = DateTime.local().setZone(timeZone);
            // check if the learning activity has started.
            if (currentDateTime > startDateTime) {
                // show confirmation modal to the user.
                setShowConfirmationModal(AppConstants.trueText);
            } else {
                if (payOfflineFlag === AppConstants.yText) {
                    setShowOfflinePaymentModal(AppConstants.trueText);
                } else {
                    enrolInCourse(id, title, uniqueId);
                }
            }
        }
    };

    const updateConfirmationStatus = (status) => {
        setShowConfirmationModal(AppConstants.falseText);
        // if user selects yes -> 
        if (status) {
            if (confirmationPopupActivityDetails.payOfflineFlag === AppConstants.yText) {
                setShowOfflinePaymentModal(AppConstants.trueText);
            } else {
                enrolInCourse(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
            }
        } else {
            setConfirmationPopupActivityDetails({});
        }
    };

    const getLearnerPaymentMethod = (paymentType) => {
        setShowOfflinePaymentModal(AppConstants.falseText);
        if (paymentType === AppConstants.paymentMethod.online) {
            enrolInCourse(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
        } else {
            setConfirmationPopupActivityDetails({});
        }
    };

    const enrolInCourse = async (courseId, courseTitle, courseUniqueId, status) => {
        const course = courseCards.filter(card => card.uniqueId === courseUniqueId)[0];
        const cost = PaymentsUtil.calculateCost({ cost: course.cost, discount: course.discount });
        let response = await enrolInLearningActivity({
            uniqueId: courseUniqueId,
            cost,
            learningActivityType: AppConstants.learningActivitiesTypes.course,
            learningActivityId: courseId,
            learningActivityName: courseTitle,
            courseType: course.courseType,
            subscriptionType: course.courseType === AppConstants.flexibleCourseType && course.courseEnrolledOnDate !== null ? course.trackingType : AppConstants.falseText
        }, status)

        setModalTypeError(response.modalTypeError);
        setPaymentStatus(response.paymentStatus);
        setMessage(response.message);
        setShowModal(response.showModal);
        setDisplayNetworkError(response.networkError);
        setShouldWarn(response.shouldWarn);
        if (response.showRazorpayPopup) {
            setTimeout(() => {
                showRazorpayPopup(response);
            }, AppConstants.paymentGatewayRedirectTimeout);
        }
    }

    const onClickRetry = () => {
        setPaymentStatus(AppConstants.paymentStatus.pending);
        enrolInCourse(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
        PaymentsUtil.verifyRazorpayPaymentStatus(paymentInitiateResponse.response, paymentInitiateResponse.learningActivityDetails);
    };

    const showRazorpayPopup = ({ options, learningActivityDetails }) => {
        setShowModal(AppConstants.falseText);
        const rzpay = new window.Razorpay({
            ...options,
            handler: async (response) => {
                setShouldWarn(AppConstants.trueText);
                setShowModal(AppConstants.trueText);
                setTransactionId(response.razorpay_payment_id);
                setPaymentStatus(AppConstants.paymentStatus.pending);
                setPaymentInitiateResponse({ response, learningActivityDetails });
                setTimeout(async () => {
                    enrolInCourse(learningActivityDetails.learningActivityId, learningActivityDetails.learningActivityId, learningActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
                    PaymentsUtil.verifyRazorpayPaymentStatus(response, learningActivityDetails);
                }, AppConstants.enrolmentDelayTimeout);
            }

        });

        rzpay.on(AppConstants.razorpaypaymentFailed, function (response) {
            PaymentsUtil.handleRazorpayPaymentFailure(response, learningActivityDetails);
        });

        rzpay.open();
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleCardClick = (url) => {
        window.open(url, AppConstants.newPageTargetSelf);
    };

    return (
        <>
            <Row xs={1} sm={2} md={2} lg={3} xl={3} className="cardSection">
                {courseCards !== undefined && courseCards.slice(AppConstants.zeroIndex, cardCount).map((course, index) => (
                    <Col key={course.uniqueId}>
                        <Card className="cardDiv" key={index} onClick={() => handleCardClick(`${AppConstants.forwardSlash}${AppConstants.coursesText}${AppConstants.forwardSlash}${course.categorySlug}${AppConstants.forwardSlash}${course.skillSlug}${AppConstants.forwardSlash}${course.courseSlug}`)}>
                            <Card.Img variant="top" className="cardImage" src={course.courseThumbnailImagePath ? course.courseThumbnailImagePath : defaultCourseThumbnail} />
                            <Card.Body className="cardBody">
                                <div className="subTitleContainer">
                                    <div className={`cardSubTitle courseDiffColor`}>{course.modeOfDelivery.toLowerCase() === AppConstants.offlineText.toLocaleLowerCase() ? <span onClick={stopPropagation} className="modeSubtitle" data-tooltip-place="top" data-tooltip-id={course.uniqueId}><a className="workshopLink onlineIconCourse" href={course.geoLocation} target={AppConstants.openInNewTab}><CgPin className="geoLocationIcon onlineIconCourse" /> {course.modeOfDelivery.toUpperCase()}</a></span> : <span className="onlineIconCourse modeSubtitle"> {course.modeOfDelivery.toUpperCase()}</span>}</div>
                                    <Tooltip className="tooltipMessage" id={course.uniqueId}><span className="tooltipMessage">{course.address}</span></Tooltip>
                                </div>
                                <Card.Text className="cardTitle titleFixedHeight">
                                    <Link className="cardLink" data-tooltip-id={`${course.uniqueId}-title`}>{course.courseTitle}</Link>
                                    <Tooltip className="courseTitleTooltip" id={`${course.uniqueId}-title`}><span>{course.courseTitle}</span></Tooltip>
                                </Card.Text>
                                <Card.Text onClick={e => { e.stopPropagation(); window.open(`${process.env.REACT_APP_FRONT_END_URL}${course.userRole === AppConstants.userRoleExpert ? AppConstants.expert : AppConstants.agency}${AppConstants.forwardSlash}${course.userSlug}`) }} className="cardTimeAndDate">By : {course.organizedBy}</Card.Text>
                                <div className="cardTimeAndDate">
                                    <div className="dateTimeText">
                                        <span className="competitionDateTimeFormat"><ViewSelectedWeekDays selectedValues={course.weekDays} /></span>
                                    </div>
                                </div>
                                {course.courseType === AppConstants.structuredCourseType && <div className="cardTimeAndDate">
                                    <div><BiCalendarCheck className="calendarIcon" />&nbsp;</div>
                                    <div className="dateTimeText">
                                        <span className="competitionDateTimeFormat">Starts {DateTimeUtil.getDateTimeForCourses(course.startTime)} {timeZone === AppConstants.utcTimezone && `(${timeZone})`}</span>
                                    </div>
                                </div>}
                                {course.courseType === AppConstants.flexibleCourseType && <div className="cardTimeAndDate">
                                    <div><AiOutlineClockCircle className="calendarIcon" />&nbsp;</div>
                                    <div className="dateTimeText">
                                        <span className="competitionDateTimeFormat">{`${DateTimeUtil.convertUtcTimeToLocalTime(course.windowStartTimeString)} - ${DateTimeUtil.convertUtcTimeToLocalTime(course.windowEndTimeString)}`} {timeZone === AppConstants.utcTimezone && `(${timeZone})`} {course.reviewCount >= props.minimumReviewCount && <><span className="durationSeparator">|</span> {`${MathUtil.formatNumberToLocaleString(course.reviewCount)} ${course.reviewCount > 1 ? "Reviews" : "Review"}`}</>}</span>
                                    </div>
                                </div>}
                                <div className="cardTimeAndDate">
                                    <div><FaChalkboardTeacher className="durationIcon calendarIcon" />&nbsp;</div><div className="dateTimeText">
                                        {course.trackingType !== AppConstants.durationBasedTrackingText && <span data-tooltip-id={`${course.uniqueId}-remainingClasses`} className="competitionDateTimeFormat">&nbsp;{course.numberOfClasses} {course.numberOfClasses > 1 ? "Classes" : "Class"} <span className="durationSeparator">|</span> {course.duration} {course.duration > 1 ? "Hours" : "Hour"} </span>}
                                        {course.trackingType === AppConstants.durationBasedTrackingText && <span className="competitionDateTimeFormat">&nbsp; {course.packageDuration} {course.packageDuration > 1 ? "Days" : "Day"}</span>}
                                    </div>
                                </div>
                                <div className="buttonSection">
                                    <div className="learningActivityCostInfo"><BiWallet /> {PaymentsUtil.calculateCost({ cost: course.cost, discount: course.discount })}</div>
                                    <Button type="button" onClick={(e) => { stopPropagation(e); confirmBeforeEnrolment({ id: course.courseId, title: course.courseTitle, uniqueId: course.uniqueId, startTime: course.courseType === AppConstants.structuredCourseType ? `${course.utcStartDateObject} ${course.windowStartTimeString}` : AppConstants.nullText, payOfflineFlag: course.payOfflineFlag, creatorName: course.organizedBy, creatorRole: course.userRole }) }} className={classes.sessionButton}>{(course.courseEnrolledOnDate === null || course.courseType === AppConstants.structuredCourseType) ? "Enrol Now" : AppConstants.renewPlanText}</Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Row xs={1} sm={1} md={1} lg={1} xl={1} className={classes.loadMore}>
                <Col className={classes.loadMoreDiv}>
                    {cardCount < courseCards?.length && <button className="loadMoreButton" onClick={() => setCardCount(cardCount + viewMoreCardsCount)}>Load more</button>}
                </Col>
            </Row>
            {(message !== AppConstants.emptyString || payloadData.category === props.category) && (showModal || payloadData.category === props.category) && <EnrolmentModal onShow={handleModalShow} message={message} modalTypeError={modalTypeError} onClose={handleModalClose} paymentStatus={paymentStatus} transactionId={transactionId} enrolmentDetails={confirmationPopupActivityDetails} displayNetworkError={displayNetworkError} onClickRetry={onClickRetry}></EnrolmentModal>}
            {showConfirmationModal && <PostStartEnrolmentModal updateConfirmationStatus={updateConfirmationStatus} learningActivityDetails={confirmationPopupActivityDetails} />}
            {showOfflinePaymentModal && <PayOfflineConfirmationModal getLearnerPaymentMethod={getLearnerPaymentMethod} learningActivityDetails={confirmationPopupActivityDetails} />}
        </>
    );
}