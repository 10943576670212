import classes from '../individualCompetition/IndividualCompetitionBanner.module.css';

export const NudgesPageBanner = ({ bannerImages }) => {

    return (
        <>
            <div className={classes.competitionMobileImgContainer}>
                <img src={bannerImages.mobileBanner} alt="Nudge Banner" className={classes.competitionBannerImage} />
            </div>
            <div className={classes.competitionTabletImgContainer}>
                <img src={bannerImages.tabBanner} alt="Nudge Banner" className={classes.competitionBannerImage} />
            </div>
            <div className={classes.competitionBannerImgContainer}>
                <img src={bannerImages.webBanner} alt="Nudge Banner" className={classes.competitionWebBannerImage} />
            </div>
        </>
    );
}