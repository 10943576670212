import React from "react";
import { useLoaderData } from "react-router-dom";
import { TopNavigationWithoutLogin } from '../../layout/ExtendedPagesLayout/TopNavigationWithoutLogin';
import { LandingPageFooter } from '../../layout/LandingPageLayout/LandingPageFooter';
import { ExpertWelcomePageBanner } from '../../component/expertWelcomePage/ExpertWelcomePageBanner';
import classes from '../../component/expertWelcomePage/expertWelcomePage.module.css';
import parse from 'html-react-parser';
import { AppConstants } from "../../constants/AppConstants";
import { ExpertWelcomePageVideo } from "../../component/expertWelcomePage/ExpertWelcomePageVideo";
import { Col, Row } from "react-bootstrap";

export const WelcomeExpertPage = () => {
    const [landingPageData, bannerSectionData] = useLoaderData();
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const blueTriangleImageUrl = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.blueTriangleImagePath}`;
    const orangeTriangleImageUrl = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.orangeTriangleImagePath}`;
    const lightBlueTriangleImageUrl = `${process.env.REACT_APP_MEDIA_CONTENT}${AppConstants.designImagesS3Path}/${AppConstants.lightBlueTriangleImagePath}`;
    const pinkBgOvalImage = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.pinkBgOvalImagePath}`;
    const blueBgOvalImage = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.blueBgOvalImagePath}`;
    const purpleBgOvalImage = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.purpleBgOvalImagePath}`;
    const imageSources = [pinkBgOvalImage, blueBgOvalImage, purpleBgOvalImage];

    // scroll function
    const scrollToSection = () => {
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        });
    };

    scrollToSection();

    return (
        <>
            <TopNavigationWithoutLogin landingPageData={landingPageData} />
            <ExpertWelcomePageBanner bannerImage={bannerSectionData} />
            {landingPageData?.welcomeExpertsJourneyAhead?.active && <div className={classes.journeyAheadSection}>
                <h2 className={`${classes.titleText} ${classes.journeryAheadTitle}`}>{landingPageData.welcomeExpertsJourneyAhead.title}</h2>
                <img
                    className={classes.blueTriangleImage}
                    src={orangeTriangleImageUrl}
                    alt="Blue Triangle"
                />
                <h1 className={classes.subTitle}>{parse(landingPageData.welcomeExpertsJourneyAhead.subTitle)}</h1>
                <img
                    className={classes.journeyAheadBanner}
                    src={landingPageData.welcomeExpertsJourneyAhead?.bannerImage.imagePath}
                    alt="Banner"
                />
                <p className={`${classes.journeySubTitle} ${classes.description}`}>{parse(landingPageData.welcomeExpertsJourneyAhead.description)}</p>
            </div>}
            {landingPageData?.welcomeExpertsSuccessfulExperts?.active && <div className={classes.successfulExpertsSection}>
                <h2 className={`${classes.titleText} ${classes.successfulExpertTitle}`}>{parse(landingPageData.welcomeExpertsSuccessfulExperts.title)}</h2>
                <img
                    className={classes.blueTriangleImage}
                    src={lightBlueTriangleImageUrl}
                    alt="Blue Triangle"
                />
                <h1 className={classes.subTitle}>{parse(landingPageData.welcomeExpertsSuccessfulExperts.subTitle)}</h1>

                <Row className={classes.videoContainer} xs={1} sm={2} md={2} lg={4} xl={4}>
                    {landingPageData.welcomeExpertsSuccessfulExperts.cards?.map((video, index) => (
                        <Col key={index}>
                            <ExpertWelcomePageVideo video={video} />
                        </Col>
                    ))}
                </Row>
            </div>}
            {/* {landingPageData?.welcomeExpertsWhatsappCommunity?.active && <div className={classes.whatsappCommunitySection}>
                <img src={landingPageData.welcomeExpertsWhatsappCommunity.bannerImage?.imagePath} alt="whatsapp icon" className={classes.whatsappIcon} />
                <h1 className={classes.whatsappCommunitySectionTitle}>{parse(landingPageData.welcomeExpertsWhatsappCommunity.title)}</h1>
                <button className={classes.whatsappCommunitySectionButton}>{landingPageData.welcomeExpertsWhatsappCommunity.buttonText}</button>
            </div>} */}
            {landingPageData?.welcomeExpertsQrenciaForExperts?.active && <div className={classes.qrenciaForExpertsSection}>
                <div className={classes.qrenciaForExpertsContentSection}>
                    <h2 className={`${classes.titleText} ${classes.qrenciaForExpertsTitle}`}>{landingPageData.welcomeExpertsQrenciaForExperts.title}</h2>
                    <img
                        className={classes.blueTriangleImage}
                        src={blueTriangleImageUrl}
                        alt="Blue Triangle"
                    />
                    <h1 className={classes.subTitle}>{parse(landingPageData.welcomeExpertsQrenciaForExperts.subTitle)}</h1>
                    <Row xs={2} sm={2} md={3} lg={2} xl={3} className={classes.welcomeExpertsQrenciaForExpertsCardsSection}>
                        {landingPageData.welcomeExpertsQrenciaForExperts.cards?.map((card, index) => (
                            <div className={classes.welcomeExpertsNumberSection}>
                                <Col key={index}>
                                    <div className={classes.welcomExpertNumberContainer}>
                                        <h1 className={classes.qrenciaForExpertsCardTitle}>{card.title}{card.description ? (<span className={classes.unicodeArrow} style={{ fontWeight: 'bold', fontFamily: 'Arial, sans-serif' }}>
                                            {String.fromCodePoint(parseInt(card.description.replace('U+', ''), 16))}
                                        </span>
                                        ) : ""}</h1>
                                        <p className={classes.qrenciaForExpertsCardSubTitle}>{card.subTitle}</p>
                                    </div>
                                    <img
                                        className={classes.qrenciaForExpertsCardsBackgroundImage}
                                        src={imageSources[index % 3]}
                                        alt="Oval cover"
                                    />
                                </Col>
                            </div>
                        ))}
                    </Row>
                </div>
                <div className={classes.qrenciaForExpertsVideoSection}>
                    <ExpertWelcomePageVideo video={landingPageData.welcomeExpertsQrenciaForExperts.video} />
                </div>
            </div>}
            <LandingPageFooter landingPageData={landingPageData} />
        </>
    );

}