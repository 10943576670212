import { useState } from "react";
import moment from "moment";
import { BiLoaderAlt } from "react-icons/bi";
import { AiOutlineCheckCircle, AiOutlineExclamationCircle } from "react-icons/ai";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { FormValidationUtils } from "../../../utils/FormValidationUtils";
import { ErrorMessages } from "../../../constants/ErrorMessages";
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import { AppConstants } from "../../../constants/AppConstants";
import { AuthorizedDataService } from "../../../services/AuthorizedDataService";
import formClass from "../../../common/FormStyling.module.css";
import modalClass from "../../cards/CompetitionsCards.module.css";
import classes from "./PendingEnrolmentRequestDetails.module.css"
import { MathUtil } from "../../../utils/MathUtil";
import { PaymentsUtil } from "../../../utils/PaymentsUtil";
export const PendingEnrolmentRequestDetails = ({ pendingEnrolmentDetails, refreshPageData }) => {
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [confirmationDetails, setConfirmationDetails] = useState({});
    const [isCourseValidityAdded, setIsCourseValidityAdded] = useState(AppConstants.falseText);
    const [editDetailsCopy, setEditDetailsCopy] = useState({});
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(AppConstants.falseText);
    const [modalAction, setModalAction] = useState(AppConstants.emptyString);
    const [approvalError, setApprovalError] = useState(AppConstants.emptyString);
    const [isStatusApproved, setIsStatusApproved] = useState(AppConstants.falseText);
    const [isStatusRejected, setIsStatusRejected] = useState(AppConstants.falseText);
    const codStatus = AppConstants.pendingCODStatus;
    const [sendingApprovalStatusRequest, setSendingApprovalStatusRequest] = useState(AppConstants.falseText);
    const [formErrors, setFormErrors] = useState({
        durationError: AppConstants.emptyString,
        costError: AppConstants.emptyString,
        discountError: AppConstants.emptyString,
        classesError: AppConstants.emptyString,
        validityError: AppConstants.emptyString
    });

    const numberInputs = document.querySelectorAll('input[type=number]');
    numberInputs.forEach(input => {
        input.addEventListener('wheel', (e) => e.preventDefault());
    });

    const getLearningActivityTextClass = ({ data }) => {
        if (data.learningActivityType === AppConstants.learningActivitiesTypes.competition) {
            return `${classes.competitionTextColor}`;
        } else if (data.learningActivityType === AppConstants.learningActivitiesTypes.course) {
            return `${classes.courseTextColor}`;
        } else if (data.learningActivityType === AppConstants.learningActivitiesTypes.workshop) {
            return `${classes.workshopTextColor}`;
        } else {
            return AppConstants.emptyString;
        }
    };

    const columnDefs = [
        {
            headerName: "Learner Id",
            field: "learnerQrenciaId",
            width: 300,
            tooltipValueGetter: ({ data }) => data.learnerQrenciaId,
            valueGetter: ({ data }) => {
                return data.learnerQrenciaId
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: "Name",
            field: "learnerName",
            width: 300,
            tooltipValueGetter: ({ data }) => data.learnerName,
            valueGetter: ({ data }) => {
                return data.learnerName
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: "Phone",
            field: "learnerMobileNumber",
            tooltipField: "learnerMobileNumber",
            filter: 'agTextColumnFilter',
            tooltipValueGetter: ({ data }) => data.learnerMobileNumber,
            valueGetter: ({ data }) => {
                return data.learnerMobileNumber;
            },
        },
        {
            headerName: "Learning Activity Id",
            field: "learningActivityId",
            tooltipField: "learningActivityId",
            filter: 'agTextColumnFilter',
            tooltipValueGetter: ({ data }) => data.learningActivityId,
            valueGetter: ({ data }) => {
                return data.learningActivityId;
            },
            headerClass: "multi-line-header"
        },
        {
            headerName: "Learning Activity Name",
            field: "learningActivityTitle",
            tooltipField: "learningActivityTitle",
            filter: 'agTextColumnFilter',
            minWidth: 130,
            tooltipValueGetter: ({ data }) => data.learningActivityTitle,
            valueGetter: ({ data }) => {
                return data.learningActivityTitle;
            },
            cellClass: getLearningActivityTextClass,
            headerClass: "multi-line-header"
        },
        {
            headerName: "Requested On",
            field: "offlinepaymentRequestedOn",
            tooltipValueGetter: ({ data }) => `${moment.utc(data.offlinepaymentRequestedOn).tz(timeZone).format('hh:mm A, D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`,
            filter: 'agTextColumnFilter',
            headerClass: 'multi-line-header',
            valueGetter: ({ data }) => {
                return `${moment.utc(data.offlinepaymentRequestedOn).tz(timeZone).format('hh:mm A, D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`;
            }
        },
        {
            headerName: "Cost",
            field: "learningActivityNetAmount",
            tooltipField: "learningActivityNetAmount",
            filter: 'agTextColumnFilter',
            maxWidth: 80,
            tooltipValueGetter: ({ data }) => MathUtil.formatNumberToLocaleString(data.learningActivityNetAmount),
            valueGetter: ({ data }) => {
                return MathUtil.formatNumberToLocaleString(data.learningActivityNetAmount);
            },
        },
        {
            headerName: "Classes",
            field: "learningActivityNumberOfClasses",
            filter: 'agTextColumnFilter',
            maxWidth: 90,
            tooltipValueGetter: ({ data }) => (data.learningActivityNumberOfClasses && data.learningActivityNumberOfClasses > 0) ? data.learningActivityNumberOfClasses : AppConstants.notAvailableText,
            valueGetter: ({ data }) => {
                return (data.learningActivityNumberOfClasses && data.learningActivityNumberOfClasses > 0) ? data.learningActivityNumberOfClasses : AppConstants.notAvailableText;
            },
        },
        {
            headerName: "Duration (Days)",
            field: "learningActivityPackageDuration",
            filter: 'agTextColumnFilter',
            maxWidth: 90,
            tooltipValueGetter: ({ data }) => (data.learningActivityPackageDuration && data.learningActivityPackageDuration > 0) ? data.learningActivityPackageDuration : AppConstants.notAvailableText,
            valueGetter: ({ data }) => {
                return (data.learningActivityPackageDuration && data.learningActivityPackageDuration > 0) ? data.learningActivityPackageDuration : AppConstants.notAvailableText;
            },
            headerClass: "multi-line-header"
        },
        {
            headerName: "Class Validity (Days)",
            field: "learningActivityValidity",
            filter: 'agTextColumnFilter',
            minWidth: 120,
            tooltipValueGetter: ({ data }) => (data.learningActivityValidity && data.learningActivityValidity > 0) ? data.learningActivityValidity : AppConstants.notAvailableText,
            valueGetter: ({ data }) => {
                return (data.learningActivityValidity && data.learningActivityValidity > 0) ? data.learningActivityValidity : AppConstants.notAvailableText;
            },
            headerClass: "multi-line-header"
        },
        {
            headerName: "Action",
            field: "action",
            cellRendererFramework: (params) => <div className={classes.obrGridActionBox}>
                <Button className={classes.actionButtons} onClick={() => showModal(AppConstants.approveText, params.data)} >{AppConstants.approveText}</Button>
                <Button className={classes.actionButtons} onClick={() => showModal(AppConstants.rejectText, params.data)}>{AppConstants.rejectText}</Button>
            </div>,
            minWidth: 150
        }
    ];

    const showModal = (action, data) => {
        setModalAction(action);
        setShowConfirmationPopup(AppConstants.trueText);
        setConfirmationDetails(data);
        setIsCourseValidityAdded(data.learningActivityValidity > 0 ? AppConstants.trueText : AppConstants.falseText);
    };

    const hideConfirmationPopup = () => {
        setConfirmationDetails({});
        setIsCourseValidityAdded(AppConstants.falseText);
        setShowConfirmationPopup(AppConstants.falseText);
        setModalAction(AppConstants.emptyString);
        setApprovalError(AppConstants.emptyString);
        setIsStatusApproved(AppConstants.falseText);
        setIsStatusRejected(AppConstants.falseText);
    };

    const getPayloadData = (approvalStatus) => {
        let data = {
            learningActivityUniqueId: confirmationDetails.learningActivityUniqueId,
            learningActivityType: confirmationDetails.learningActivityType,
            approvalStatus,
            learningActivityTitle: confirmationDetails.learningActivityTitle,
            learningActivityCost: confirmationDetails.learningActivityCost,
            learnerEmail: confirmationDetails.learnerEmail,
            netAmount: confirmationDetails.learningActivityDiscount ? confirmationDetails.learningActivityCost - confirmationDetails.learningActivityDiscount : confirmationDetails.learningActivityNetAmount
        };

        if (confirmationDetails.learningActivityType === AppConstants.learningActivitiesTypes.course) {
            if (confirmationDetails.courseType === AppConstants.flexibleCourseType) {
                data = {
                    ...data, numberOfClasses: confirmationDetails.learningActivityNumberOfClasses || AppConstants.nullText,
                    packageDuration: confirmationDetails.learningActivityPackageDuration || AppConstants.nullText,
                    validity: confirmationDetails.learningActivityValidity || AppConstants.nullText,
                    enrolmentDate: DateTimeUtil.formatDateTime(DateTimeUtil.createDateTimeObject(new Date().toISOString().split('T')[0], "00:00"), confirmationDetails.learnerPreferredTimeZone)
                }
            }
            data = {
                ...data,
                discount: confirmationDetails.learningActivityDiscount || AppConstants.nullText
            }
        }

        return data;
    }

    const updateConfirmationStatus = async (approvalStatus) => {
        setSendingApprovalStatusRequest(AppConstants.trueText);
        const url = AppConstants.updateOfflinePaymentRequestStatusAPI;
        const data = getPayloadData(approvalStatus);

        const response = await AuthorizedDataService.putRequest(
            url,
            data,
            AppConstants.emptyString,
            AppConstants.emptyString
        );

        if (response) {
            setIsStatusApproved(approvalStatus === codStatus.approve ? AppConstants.trueText : AppConstants.falseText);
            setIsStatusRejected(approvalStatus !== codStatus.approve ? AppConstants.trueText : AppConstants.falseText);
            setApprovalError(AppConstants.emptyString);
            setSendingApprovalStatusRequest(AppConstants.falseText);
            refreshPageData();
        } else {
            setIsStatusApproved(AppConstants.falseText);
            setIsStatusRejected(AppConstants.falseText);
            setApprovalError(approvalStatus === codStatus.approve ? ErrorMessages.approveCodError : ErrorMessages.rejectCodError);
            setSendingApprovalStatusRequest(AppConstants.falseText);
        }
    };

    const resetEditForm = () => {
        setEditDetailsCopy({});
        setFormErrors({ durationError: AppConstants.emptyString, costError: AppConstants.emptyString, classesError: AppConstants.emptyString, discountError: AppConstants.emptyString, validityError: AppConstants.emptyString });
        setModalAction(AppConstants.approveText);
    }

    const saveConfirmationDetails = () => {
        let isValid = AppConstants.trueText;

        if (confirmationDetails.learningActivityType === AppConstants.learningActivitiesTypes.course && confirmationDetails.courseType === AppConstants.flexibleCourseType) {
            if (parseInt(editDetailsCopy.learningActivityCost) <= 0) {
                setFormErrors({ ...formErrors, costError: ErrorMessages.minimumCostFlexibleCourseError });
                isValid = AppConstants.falseText;
            }
            if (editDetailsCopy.learningActivityDiscount && parseInt(editDetailsCopy.learningActivityCost || 0) < parseInt(editDetailsCopy.learningActivityDiscount)) {
                setFormErrors({ ...formErrors, discountError: ErrorMessages.approveCodDiscountError });
                isValid = AppConstants.falseText;
            }
            if (confirmationDetails.trackingType === AppConstants.durationBasedTrackingText && (editDetailsCopy.learningActivityPackageDuration < 0 || !editDetailsCopy.learningActivityPackageDuration)) {
                setFormErrors({ ...formErrors, durationError: ErrorMessages.approveCodDurationError });
                isValid = AppConstants.falseText;
            }
            if (confirmationDetails.trackingType === AppConstants.classBasedTrackingText && (editDetailsCopy.learningActivityNumberOfClasses < 0 || !editDetailsCopy.learningActivityNumberOfClasses)) {
                setFormErrors({ ...formErrors, classesError: ErrorMessages.approveCodClassesError });
                isValid = AppConstants.falseText;
            }
            if (confirmationDetails.trackingType === AppConstants.classBasedTrackingText && isCourseValidityAdded && (!editDetailsCopy.learningActivityValidity || parseInt(editDetailsCopy.learningActivityValidity) < 1)) {
                setFormErrors({ ...formErrors, validityError: ErrorMessages.approveCodClassValidityError });
                isValid = AppConstants.falseText;
            }
        }

        if (isValid) {
            setConfirmationDetails({ ...editDetailsCopy });
            resetEditForm();
        }
    };

    return (
        <div className={classes.container}>
            <div className="ag-theme-alpine">
                <AgGridReact
                    columnDefs={columnDefs}
                    rowData={pendingEnrolmentDetails}
                    pagination={true}
                    paginationPageSize={10}
                    enableBrowserTooltips={true}
                    domLayout='autoHeight'
                    suppressRowHoverHighlight={true}
                    defaultColDef={{
                        filter: true,
                        sortable: true,
                        flex: 1,
                        minWidth: 100,
                        resizable: true,
                        suppressMenu: true,
                        suppressHorizontalScroll: true,
                        floatingFilterComponentParams: { suppressFilterButton: true },
                        floatingFilter: true,
                    }}
                    enableFilter={true}
                    enableSorting={true}
                    enableSearching={true}
                    headerClass={classes.obrGridHeader}
                    rowClass={classes.obrGridTable}
                />
            </div>

            <Modal show={showConfirmationPopup} onHide={() => hideConfirmationPopup()} backdrop="static" keyboard={false} centered>
                {modalAction === AppConstants.approveText && <>
                    <Modal.Body className={modalClass.modalBody}>
                        {approvalError === AppConstants.emptyString && !isStatusApproved && <>
                            <p className={classes.modalHeading}><b>Please verify the below details</b></p>
                            <div className={modalClass.modalAlertText}>
                                <p className={classes.modalTextContent}>Learner Name: {confirmationDetails.learnerName}</p>
                                <p className={classes.modalTextContent}>Amount Paid: {confirmationDetails.learningActivityDiscount ? PaymentsUtil.calculateCost({ cost: confirmationDetails.learningActivityCost, discount: confirmationDetails.learningActivityDiscount }) : PaymentsUtil.calculateCost({ cost: confirmationDetails.learningActivityCost, discount: 0 })}</p>
                                {confirmationDetails.learningActivityNumberOfClasses > 0 && <p className={classes.modalTextContent}>Classes: {confirmationDetails.learningActivityNumberOfClasses}</p>}
                                {confirmationDetails.learningActivityPackageDuration > 0 && <p className={classes.modalTextContent}>Duration: {confirmationDetails.learningActivityPackageDuration} {confirmationDetails.learningActivityPackageDuration > 1 ? "Days" : "Day"}</p>}
                                {isCourseValidityAdded && <p className={classes.modalTextContent}>Class Validity: {confirmationDetails.learningActivityValidity} {confirmationDetails.learningActivityValidity > 1 ? "Days" : "Day"}</p>}
                            </div>
                        </>}
                        {approvalError !== AppConstants.emptyString && !isStatusApproved && <>
                            <div className={modalClass.modalErrorIcon}><AiOutlineExclamationCircle /></div>
                            <div className={modalClass.modalAlertText}>
                                <p className={classes.modalTextContent}>{approvalError}</p>
                            </div>
                        </>}
                        {approvalError === AppConstants.emptyString && isStatusApproved && <>
                            <div className={modalClass.modalSuccessIcon}><AiOutlineCheckCircle /></div>
                            <div className={modalClass.modalAlertText}>
                                <p className={classes.modalTextContent}>Enrolment request approved! {confirmationDetails.learnerName} has been successfully enrolled in the {confirmationDetails.learningActivityType?.toLowerCase()}.</p>
                            </div>
                        </>}
                    </Modal.Body>
                    <Modal.Footer className={modalClass.modalFooter}>
                        {approvalError === AppConstants.emptyString && !isStatusApproved && <>
                            <Button disabled={sendingApprovalStatusRequest} className={modalClass.modalYesButton} onClick={() => updateConfirmationStatus(codStatus.approve)}>Approve {sendingApprovalStatusRequest && <BiLoaderAlt className="spinner" />}</Button>
                            {confirmationDetails.courseType === AppConstants.flexibleCourseType && < Button disabled={sendingApprovalStatusRequest} className={modalClass.modalCloseButton} onClick={() => { setEditDetailsCopy({ ...confirmationDetails }); setModalAction("Edit") }}>Edit</Button>}
                            <Button disabled={sendingApprovalStatusRequest} className={modalClass.modalNoButton} onClick={hideConfirmationPopup}>Close</Button>
                        </>}
                        {(approvalError !== AppConstants.emptyString || isStatusApproved) && <>
                            <Button disabled={sendingApprovalStatusRequest} className={modalClass.modalCloseButton} onClick={hideConfirmationPopup}>Close</Button>
                        </>}
                    </Modal.Footer>
                </>}
                {modalAction === AppConstants.rejectText && <>
                    <Modal.Body className={modalClass.modalBody}>
                        {approvalError === AppConstants.emptyString && !isStatusRejected && <>
                            <p className={classes.modalHeading}><b> Reject Enrolment Request?</b></p>
                            <div className={modalClass.modalAlertText}>
                                <p className={classes.modalTextContent}>Learner Name: {confirmationDetails.learnerName}</p>
                                <p className={classes.modalTextContent}>Amount Paid: {confirmationDetails.learningActivityDiscount ? PaymentsUtil.calculateCost({ cost: confirmationDetails.learningActivityCost, discount: confirmationDetails.learningActivityDiscount }) : PaymentsUtil.calculateCost({ cost: confirmationDetails.learningActivityCost, discount: 0 })}</p>
                                {confirmationDetails.learningActivityNumberOfClasses > 0 && <p className={classes.modalTextContent}>Classes: {confirmationDetails.learningActivityNumberOfClasses}</p>}
                                {confirmationDetails.learningActivityPackageDuration > 0 && <p className={classes.modalTextContent}>Duration: {confirmationDetails.learningActivityPackageDuration} {confirmationDetails.learningActivityPackageDuration > 1 ? "Days" : "Day"}</p>}
                                {isCourseValidityAdded && <p className={classes.modalTextContent}>Class Validity: {confirmationDetails.learningActivityValidity} {confirmationDetails.learningActivityValidity > 1 ? "Days" : "Day"}</p>}
                            </div>
                        </>}
                        {approvalError !== AppConstants.emptyString && !isStatusRejected && <>
                            <div className={modalClass.modalErrorIcon}><AiOutlineExclamationCircle /></div>
                            <div className={modalClass.modalAlertText}>
                                <p className={classes.modalTextContent}>{approvalError}</p>
                            </div>
                        </>}
                        {approvalError === AppConstants.emptyString && isStatusRejected && <>
                            <div className={modalClass.modalSuccessIcon}><AiOutlineCheckCircle /></div>
                            <div className={modalClass.modalAlertText}>
                                <p className={classes.modalTextContent}>Enrolment request successfully rejected for {confirmationDetails.learnerName}.</p>
                            </div>
                        </>}
                    </Modal.Body>
                    <Modal.Footer className={modalClass.modalFooter}>
                        {approvalError === AppConstants.emptyString && !isStatusRejected && <Button className={modalClass.modalNoButton} disabled={sendingApprovalStatusRequest} onClick={() => updateConfirmationStatus(codStatus.cancel)}>Reject {sendingApprovalStatusRequest && <BiLoaderAlt className="spinner" />}</Button>}
                        <Button className={modalClass.modalCloseButton} disabled={sendingApprovalStatusRequest} onClick={hideConfirmationPopup}>Close</Button>
                    </Modal.Footer>
                </>}
                {modalAction === "Edit" && <>
                    <Modal.Body className={modalClass.modalBody}>
                        <p className={classes.modalHeading}><b>Edit details</b></p>
                        <div className={classes.modalText}>
                            <Form.Group className="mb-2" as={Col}>
                                <Form.Label className={formClass.formLabel}>Cost<span className="redAstrisk">*</span></Form.Label>
                                <Form.Control className={formClass.formInput}
                                    type="number"
                                    min={0}
                                    placeholder="Cost"
                                    value={editDetailsCopy.learningActivityCost}
                                    onChange={e => setEditDetailsCopy({ ...editDetailsCopy, learningActivityCost: e.target.value })}
                                    onKeyDown={e => FormValidationUtils.preventNumberWithSpecialCharacters(e, ["-", "+", "."])}
                                />
                                {formErrors.costError && <p className="errorText"><AiOutlineExclamationCircle /> {formErrors.costError}</p>}
                            </Form.Group>
                            <Form.Group className="mb-2" as={Col}>
                                <Form.Label className={formClass.formLabel}>Discount<span className="redAstrisk">*</span></Form.Label>
                                <Form.Control className={formClass.formInput}
                                    type="number"
                                    min={0}
                                    placeholder="Discount"
                                    value={editDetailsCopy.learningActivityDiscount}
                                    onChange={e => setEditDetailsCopy({ ...editDetailsCopy, learningActivityDiscount: e.target.value })}
                                    onKeyDown={e => FormValidationUtils.preventNumberWithSpecialCharacters(e, ["-", "+", "."])}
                                />
                                {formErrors.discountError && <p className="errorText"><AiOutlineExclamationCircle /> {formErrors.discountError}</p>}
                            </Form.Group>
                            {confirmationDetails.trackingType === AppConstants.classBasedTrackingText && <Form.Group className="mb-2" as={Col}>
                                <Form.Label className={formClass.formLabel}>Number of Classes<span className="redAstrisk">*</span></Form.Label>
                                <Form.Control className={formClass.formInput}
                                    type="number"
                                    min={0}
                                    placeholder="Number of classes"
                                    value={editDetailsCopy.learningActivityNumberOfClasses}
                                    onChange={e => setEditDetailsCopy({ ...editDetailsCopy, learningActivityNumberOfClasses: e.target.value })}
                                    onKeyDown={e => FormValidationUtils.preventNumberWithSpecialCharacters(e, ["-", "+", "."])}
                                />
                                {formErrors.classesError && <p className="errorText"><AiOutlineExclamationCircle /> {formErrors.classesError}</p>}
                            </Form.Group>}
                            {confirmationDetails.trackingType === AppConstants.durationBasedTrackingText && <Form.Group className="mb-2" as={Col}>
                                <Form.Label className={formClass.formLabel}>Package Duration<span className="redAstrisk">*</span></Form.Label>
                                <Form.Control className={formClass.formInput}
                                    type="number"
                                    min={0}
                                    placeholder="Package Duration"
                                    value={editDetailsCopy.learningActivityPackageDuration}
                                    onChange={e => setEditDetailsCopy({ ...editDetailsCopy, learningActivityPackageDuration: e.target.value })}
                                    onKeyDown={e => FormValidationUtils.preventNumberWithSpecialCharacters(e, ["-", "+", "."])}
                                />
                                {formErrors.durationError && <p className="errorText"><AiOutlineExclamationCircle /> {formErrors.durationError}</p>}
                            </Form.Group>}
                            {confirmationDetails.trackingType === AppConstants.classBasedTrackingText && isCourseValidityAdded && <Form.Group className="mb-2" as={Col}>
                                <Form.Label className={formClass.formLabel}>Class Validity<span className="redAstrisk">*</span></Form.Label>
                                <Form.Control className={formClass.formInput}
                                    type="number"
                                    min={0}
                                    placeholder="Class Validity"
                                    value={editDetailsCopy.learningActivityValidity}
                                    onChange={e => setEditDetailsCopy({ ...editDetailsCopy, learningActivityValidity: e.target.value })}
                                    onKeyDown={e => FormValidationUtils.preventNumberWithSpecialCharacters(e, ["-", "+", "."])}
                                />
                                {formErrors.validityError && <p className="errorText"><AiOutlineExclamationCircle /> {formErrors.validityError}</p>}
                            </Form.Group>}
                        </div>
                    </Modal.Body>
                    <Modal.Footer className={modalClass.modalFooter}>
                        <Button className={modalClass.modalYesButton} onClick={saveConfirmationDetails}>Confirm</Button>
                        <Button className={modalClass.modalCloseButton} onClick={resetEditForm}>Cancel</Button>
                    </Modal.Footer>
                </>}
            </Modal>
        </div >
    )
}