import { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Modal } from "react-bootstrap";
import { FaEye, FaRegCalendarCheck, FaRegEye } from 'react-icons/fa';
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { TbRefreshAlert } from 'react-icons/tb';
import { BiDownload } from 'react-icons/bi';
import { MdShareLocation } from 'react-icons/md';
import { AppConstants } from "../../constants/AppConstants";
import Moment from 'react-moment';
import studentCompetitionWidgetCards from './StudentCompetitionWidgetCards.module.css';
import nudgeCardStyles from './StudentWidgetNudgesCards.module.css';
import { FileOperationsUtil } from "../../utils/FileOperationsUtil";
import 'moment-timezone';
import { StudentLearningActivityNudgesDetails } from "./studentLearningActivityCompetitionCards/StudentLearningActivityNudgesDetails";

export const StudentWidgetNudgesCards = (props) => {
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const timeZoneAbbr = DateTimeUtil.getZoneNameForPreferredTimeZone(timeZone);
    const [downloadFailed, setDownloadFailed] = useState(false);
    const [cardCount, setCardCount] = useState(AppConstants.cardCounts.rightWidgetCardCounts);
    const [selectedNudgeData, setSelectedNudgeData] = useState([]);
    const [showModal, setShowModal] = useState(false);

    //view nudge details
    const viewNudgeDetails = (options) => {
        setShowModal(true);
        setSelectedNudgeData(options);
    }

    //hide nudge details
    const onCloseModal = () => {
        setShowModal(false);
    }

    return (
        <>
            <Row xs={1} sm={1} md={1} lg={1} xl={1} className={studentCompetitionWidgetCards.competitionCardContainer}>
                {props.cardDetails?.slice(0, cardCount).map((nudge) => (
                    <Col className={studentCompetitionWidgetCards.competitionCardSection} key={nudge.id}>
                        <Card className={studentCompetitionWidgetCards.competitionCard} onClick={() => viewNudgeDetails(nudge)}>
                            <Card.Body className={`${studentCompetitionWidgetCards.competitionCardBody} ${nudgeCardStyles.nudgeCardBody}`}>
                                <Card.Title className={`${studentCompetitionWidgetCards.nudgeTitle} ${nudgeCardStyles.nudgeSummary}`}><Link>{nudge.summary}</Link></Card.Title>
                                <div>
                                    <div className={studentCompetitionWidgetCards.dateTimeSection}>
                                        <FaRegCalendarCheck className={`${nudgeCardStyles.nudgeIconColor}`} /> <div className={studentCompetitionWidgetCards.dateTimeText}>Raised On : <span className="competitionDateTimeFormat"><Moment format={AppConstants.nudgeRaisedOnTimeFormat} tz={timeZone}>{nudge.raisedOnUtc.replace(' ', 'T') + 'Z'}</Moment> {nudge.preferredTimezone === AppConstants.utcTimezone && `(${nudge.preferredTimezone})`}</span></div>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <FaEye size={15} className={studentCompetitionWidgetCards.locationIcon} /> <p className={`${studentCompetitionWidgetCards.competitionOnlineModeText} ${nudgeCardStyles.cursorPointer}`} onClick={() => viewNudgeDetails(nudge)}>View</p> {nudge.totalResponse && <span className={studentCompetitionWidgetCards.linkSeperator}>|</span>} {nudge.totalResponse && <p className={studentCompetitionWidgetCards.competitionOnlineModeText}>{nudge.totalResponse > 1 ? `${nudge.totalResponse} Responses` : `${nudge.totalResponse} Response`}</p>}
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Row xs={1} sm={1} md={1} lg={1} xl={1} className="loadMore">
                <Col className="loadMoreButtonSection">
                    {cardCount < props.cardDetails?.length && <button className="loadMoreButton" onClick={() => setCardCount(cardCount + AppConstants.cardCounts.rightWidgetCardCounts)}>Load more</button>}
                </Col>
            </Row>
            <Modal size="lg" show={showModal} onHide={onCloseModal} backdrop="static" keyboard={false} centered>
                <Modal.Header closeButton>
                    <Modal.Title className={nudgeCardStyles.nudgeModalTitle}>Nudge Details</Modal.Title>
                </Modal.Header>
                <StudentLearningActivityNudgesDetails nudgesData={selectedNudgeData} />
            </Modal>
        </>
    );
}