import { useState, useEffect } from "react";
import { MdClear } from "react-icons/md";
import { ViewCourseCards } from "../../component/cards/viewCourseCards/ViewCourseCards";
import { AppConstants } from "../../constants/AppConstants";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import { ErrorMessages } from "../../constants/ErrorMessages";
import classes from "../expert/ExpertManageCoursesPage.module.css";
import { EventStatusNavTab } from "../../component/expertManageCourses/EventStatusNavTabs";
import { AssignCoursesComponent } from "../../component/assignCourses/AssignCoursesComponent";

export const AgencyAssignCoursesPage = () => {
    const courseStatus = AppConstants.courseStatus;
    const [selectedTab, setSelectedTab] = useState(AppConstants.courseStatus.upcoming);
    const [upcomingCoursesSection, setUpcomingCoursesSection] = useState(true);
    const [ongoingCoursesSection, setOngoingCoursesSection] = useState(false);
    const [upcomingCoursesArray, setUpcomingCoursesArray] = useState([]);
    const [ongoingCoursesArray, setOngoingCoursesArray] = useState([]);
    const [filteredCourses, setFilteredCourses] = useState([]);
    const [filterCourseText, setFilterCourseText] = useState(AppConstants.emptyString);
    const [selectedCourse, setSelectedCourse] = useState();
    const [showAssignScreen, setShowAssignScreen] = useState(false);

    useEffect(() => {
        //fetch Upcoming Courses
        async function getUpcomingCoursesData() {
            const url = `${AppConstants.fetchCoursesByStatusAPI}${courseStatus.upcoming}`;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response !== undefined) {
                setUpcomingCoursesArray(response.filter(course => (course.trackingType === AppConstants.classBasedTrackingText || course.isAttendanceEnabledFlag === AppConstants.yText)));
                setFilteredCourses(response.filter(course => (course.trackingType === AppConstants.classBasedTrackingText || course.isAttendanceEnabledFlag === AppConstants.yText)));
            } else {
                setUpcomingCoursesArray([]);
                setFilteredCourses([]);
            }
        }
        getUpcomingCoursesData();
    }, []);

    function changeStatus(status) {
        setSelectedTab(status);
        setFilteredCourses([]);
        setFilterCourseText(AppConstants.emptyString);
        setShowAssignScreen(false);
        setSelectedCourse([]);
        if (status === courseStatus.upcoming) {
            setUpcomingCoursesSection(true);
            setOngoingCoursesSection(false);
            async function getUpcomingCoursesData() {
                const url = `${AppConstants.fetchCoursesByStatusAPI}${courseStatus.upcoming}`;
                const response = await AuthorizedDataService.getRequest(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );

                if (response !== undefined) {
                    setUpcomingCoursesArray(response.filter((course => course.trackingType === AppConstants.classBasedTrackingText || course.isAttendanceEnabledFlag === AppConstants.yText)));
                    setFilteredCourses(response.filter((course => course.trackingType === AppConstants.classBasedTrackingText || course.isAttendanceEnabledFlag === AppConstants.yText)));
                } else {
                    setUpcomingCoursesArray([]);
                    setFilteredCourses([]);
                }
            }
            getUpcomingCoursesData();
        } else if (status === courseStatus.ongoing) {
            setOngoingCoursesSection(true);
            setUpcomingCoursesSection(false);
            //fetch ongoing courses
            async function getOngoingCoursesData() {
                const url = `${AppConstants.fetchCoursesByStatusAPI}${courseStatus.ongoing}`;
                const response = await AuthorizedDataService.getRequest(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );

                if (response !== undefined) {
                    setOngoingCoursesArray(response.filter((course => course.trackingType === AppConstants.classBasedTrackingText || course.isAttendanceEnabledFlag === AppConstants.yText)));
                    setFilteredCourses(response.filter((course => course.trackingType === AppConstants.classBasedTrackingText || course.isAttendanceEnabledFlag === AppConstants.yText)));
                } else {
                    setOngoingCoursesArray([]);
                    setFilteredCourses([]);
                }
            }
            getOngoingCoursesData();
        }
    };

    const searchCourse = (e) => {
        setFilterCourseText(e.target.value);
        let coursesArray = [];
        if (selectedTab === courseStatus.upcoming) {
            coursesArray = upcomingCoursesArray;
        } else if (selectedTab === courseStatus.ongoing) {
            coursesArray = ongoingCoursesArray
        }
        let filteredArray = e.target.value.toLowerCase() !== AppConstants.emptyString
            ? coursesArray?.filter((item) => ((item.courseTitle.toLowerCase()).includes(e.target.value.toLowerCase()) || (item.courseType.toLowerCase()).includes(e.target.value.toLowerCase()) || (item?.uniqueId.toLowerCase()).includes(e.target.value.toLowerCase()) || (item.courseId.toLowerCase()).includes(e.target.value.toLowerCase())))
            : coursesArray;
        setFilteredCourses(filteredArray);
    };

    const resetFilterText = () => {
        setFilterCourseText(AppConstants.emptyString);
        if (selectedTab === courseStatus.upcoming) {
            setFilteredCourses(upcomingCoursesArray);
        } else if (selectedTab === courseStatus.ongoing) {
            setFilteredCourses(ongoingCoursesArray);
        }
    };

    const onClickAssign = async (course) => {

        setSelectedCourse(course);
        setShowAssignScreen(true);
    };


    const onHideAssign = () => {
        setShowAssignScreen(false);
        setSelectedCourse([]);
    }

    return (
        <div className="expertContentSection">
            <div className={classes.manageCoursesHeading}>Assign Courses</div>
            <EventStatusNavTab changeStatus={changeStatus} tabs={AppConstants.assignCoursesTabs} />
            {!showAssignScreen && <div>
                <div className={classes.inputBoxContainer}>
                    <input type="text" className={classes.searchInputBox} value={filterCourseText} onChange={(e) => { searchCourse(e) }} placeholder="Search course" />
                    {filterCourseText.length > 0 && <MdClear className={classes.resetSearchIcon} onClick={() => resetFilterText()} />}
                </div>
                {upcomingCoursesSection &&
                    <div>
                        {filteredCourses.length !== 0 && <ViewCourseCards
                            courseData={filteredCourses}
                            onClickAssign={onClickAssign}
                            showViewButton={AppConstants.falseText} showEditButton={AppConstants.falseText} showAssignButton={AppConstants.trueText}
                            eventStatus={AppConstants.assignCoursesTabs.upcoming}
                        />}
                        {filteredCourses.length === 0 && <div>
                            <p className="noDataAvailableError">{ErrorMessages.expertNoUpcomingCoursesFallbackMessage}</p>
                        </div>}
                    </div>
                }
                {ongoingCoursesSection && <div>
                    {filteredCourses.length !== 0 && <ViewCourseCards
                        courseData={filteredCourses}
                        onClickAssign={onClickAssign}
                        showViewButton={AppConstants.falseText} showEditButton={AppConstants.falseText} showAssignButton={AppConstants.trueText}
                        eventStatus={AppConstants.assignCoursesTabs.ongoing}
                    />}
                    {filteredCourses.length === 0 && <div>
                        <p className="noDataAvailableError">{ErrorMessages.expertNoOngoingCoursesFallbackMessage}</p>
                    </div>}
                </div>}
            </div>}

            {showAssignScreen && <div className={classes.viewCourseDetailsSection}><AssignCoursesComponent selectedCourse={selectedCourse} onHideAssign={onHideAssign} /></div>}
        </div>
    )
}