import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { CgPin } from "react-icons/cg";
import { Row, Col, Card, Button } from "react-bootstrap";
import classes from '../../extendedPageComponents/skills/IndividualSkillsCards.module.css'
import { BiCalendarCheck, BiLoaderAlt } from "react-icons/bi";
import { FaChalkboardTeacher } from 'react-icons/fa';
import { AppConstants } from "../../../constants/AppConstants";
import { AiOutlineClockCircle } from "react-icons/ai";
import { DateTime } from "luxon";
import { PaymentsUtil } from "../../../utils/PaymentsUtil";
import Auth from "../../../utils/Auth";
import styles from '../competitionListingCards/CompetitionListingPageCards.module.scss'
import { ErrorMessages } from "../../../constants/ErrorMessages";
import { EnrolmentModal } from "../../modal/EnrolmentModal";
import moment from 'moment';
import { LoginModal } from "../../modal/LoginModal";
import { BiWallet } from "react-icons/bi";
import { ShareLinkToSocialMediaUtil } from "../../../utils/ShareLinkToSocialMediaUtil";
import { TbShare3 } from "react-icons/tb";
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import { PostStartEnrolmentModal } from "../../modal/PostStartEnrolmentModal";
import { PayOfflineConfirmationModal } from "../../modal/PayOfflineConfirmationModal";
import { MathUtil } from "../../../utils/MathUtil";
import { useEnrolment } from "../../../hooks/useEnrolment";
import useInfiniteScroll from "../../../hooks/useInfiniteScroll";
import useWarnOnLeave from "../../../hooks/useWarnOnLeave";
import { ViewSelectedWeekDays } from "../../calendar/ViewSelectedWeekDays";

export const CourseListingPageCards = (props) => {

    const [cardCount, setCardCount] = useState(0);
    const [viewMoreCardsCount, setViewMoreCardsCount] = useState(0);
    const [cardDetails, setCardDetails] = useState(props.coursesOfCategory);
    const [message, setMessage] = useState(AppConstants.emptyString);
    const [modalTypeError, setModalTypeError] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [userLoggedInAndEnroled, setUserLoggedInAndEnroled] = useState(AppConstants.falseText);
    const [paymentStatus, setPaymentStatus] = useState(AppConstants.emptyString);
    const [transactionId, setTransactionId] = useState(AppConstants.emptyString);
    const [showLoginModal, setShowLoginModal] = useState(AppConstants.falseText);
    const [showSocialMediaPannel, setShowSocialMediaPannel] = useState(AppConstants.falseText);
    const [linkToShare, setLinkToShare] = useState(AppConstants.emptyString);
    const [editIndex, setEditIndex] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(AppConstants.falseText);
    const [minimumReviewCount, setMinimumReviewCount] = useState(AppConstants.courseReviewCount);
    const [confirmationPopupActivityDetails, setConfirmationPopupActivityDetails] = useState({});
    const [showOfflinePaymentModal, setShowOfflinePaymentModal] = useState(AppConstants.falseText);
    const { enrolInLearningActivity } = useEnrolment();
    const [displayNetworkError, setDisplayNetworkError] = useState(AppConstants.falseText);
    const [shouldWarn, setShouldWarn] = useState(AppConstants.falseText);
    const [paymentInitiateResponse, setPaymentInitiateResponse] = useState({});
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    useWarnOnLeave(shouldWarn);

    const learningActivitiesCardsCount = AppConstants.cardCounts;
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const defaultThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.courseDefaultThumbnailImage}`;
    const navigate = useNavigate();

    const { lastElementRef, loading } = useInfiniteScroll(() => {
        setCardCount(prevCount => prevCount + viewMoreCardsCount);
    }, [cardDetails]);


    //set cardCount to 4 on tablet screen and 2 on mobile screen
    const handleResize = () => {
        const viewportWidth = window.innerWidth;
        if (viewportWidth >= 992) {
            setCardCount(learningActivitiesCardsCount.competitionsPageCardCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.competitionsPageCardCount);
        }
        if (viewportWidth >= 576 && viewportWidth <= 991) {
            setCardCount(learningActivitiesCardsCount.competitionsPageCardCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.competitionsPageCardCount);
        }
        if (viewportWidth <= 575) {
            setCardCount(learningActivitiesCardsCount.competitionsPageCardCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.competitionsPageCardCount);
        }
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('orientationchange', handleResize);
        return () => {
            window.removeEventListener('orientationchange', handleResize);
        };
    }, []);


    useEffect(() => {
        if (props.coursesOfCategory !== cardDetails) {
            setCardDetails(props.coursesOfCategory);
        }
    }, [props.coursesOfCategory]);


    const confirmBeforeEnrolment = ({ id, title, uniqueId, startTime, payOfflineFlag, creatorName, creatorRole }) => {
        setConfirmationPopupActivityDetails({ id, title, uniqueId, startTime, type: AppConstants.learningActivitiesTypes.course, payOfflineFlag, creatorName, creatorRole });

        if (Auth.isLogin() && Auth.getUserRole() === AppConstants.userRoleStudent) {
            if (startTime === AppConstants.nullText) {
                if (payOfflineFlag === AppConstants.yText) {
                    setShowOfflinePaymentModal(AppConstants.trueText);
                } else {
                    enrolInCourse(id, title, uniqueId);
                }
            } else {
                const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
                const startDateTime = DateTime.fromISO(startTime?.replace(' ', 'T') + 'Z').setZone(timeZone);
                const currentDateTime = DateTime.local().setZone(timeZone);
                // check if the learning activity has started.
                if (currentDateTime > startDateTime) {
                    // show confirmation modal to the user.
                    setShowConfirmationModal(AppConstants.trueText);
                } else {
                    if (payOfflineFlag === AppConstants.yText) {
                        setShowOfflinePaymentModal(AppConstants.trueText);
                    } else {
                        enrolInCourse(id, title, uniqueId);
                    }
                }
            }
        } else {
            enrolInCourse(id, title, uniqueId);
        }

    };

    const getCourseActionButtonText = (course) => {
        if (course.courseEnrolledOnDate === null) {
            return "Enrol Now";
        }
        if (course.courseType === AppConstants.structuredCourseType) {
            return "Enrolled";
        }
        return course.subscriptionStatus === AppConstants.subscriptionStatus.active ? "Enrolled" : "Renew Plan";
    };

    const updateConfirmationStatus = (status) => {
        setShowConfirmationModal(AppConstants.falseText);
        // if user selects yes -> 
        if (status) {
            if (confirmationPopupActivityDetails.payOfflineFlag === AppConstants.yText) {
                setShowOfflinePaymentModal(AppConstants.trueText);
            } else {
                enrolInCourse(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
            }
        } else {
            setConfirmationPopupActivityDetails({});
        }
    };

    const getLearnerPaymentMethod = (paymentType) => {
        setShowOfflinePaymentModal(AppConstants.falseText);
        if (paymentType === AppConstants.paymentMethod.online) {
            enrolInCourse(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
        } else {
            setConfirmationPopupActivityDetails({});
        }
    };

    const enrolInCourse = async (courseId, courseTitle, uniqueId, status) => {
        if (Auth.isLogin() && Auth.getUserRole() !== AppConstants.userRoleStudent) {
            setModalTypeError(AppConstants.trueText);
            setMessage(ErrorMessages.onlyStudentEnrolCourseError);
            setPaymentStatus(AppConstants.emptyString);
            handleModalShow();
            return;
        }
        const course = cardDetails.filter(card => card.uniqueId === uniqueId)[0];
        const cost = PaymentsUtil.calculateCost({ cost: course.cost, discount: course.discount });

        let response = await enrolInLearningActivity({
            uniqueId,
            cost,
            learningActivityType: AppConstants.learningActivitiesTypes.course,
            learningActivityId: courseId,
            learningActivityName: courseTitle,
            courseType: course.courseType,
            subscriptionType: course.courseType === AppConstants.flexibleCourseType && course.courseEnrolledOnDate !== null ? course.trackingType : AppConstants.falseText
        }, status)


        setModalTypeError(response.modalTypeError);
        setPaymentStatus(response.paymentStatus);
        setMessage(response.message);
        setShowModal(response.showModal);
        setShouldWarn(response.shouldWarn);
        setDisplayNetworkError(response.networkError);
        if (response.showRazorpayPopup) {
            setTimeout(() => {
                showRazorpayPopup(response);
            }, AppConstants.paymentGatewayRedirectTimeout);
        }

    };


    const showRazorpayPopup = ({ options, learningActivityDetails }) => {
        setShowModal(AppConstants.falseText);
        const rzpay = new window.Razorpay({
            ...options,
            handler: async (response) => {
                setShowModal(AppConstants.trueText);
                setTransactionId(response.razorpay_payment_id);
                setPaymentStatus(AppConstants.paymentStatus.pending);
                setShouldWarn(AppConstants.trueText);
                setPaymentInitiateResponse({ response, learningActivityDetails });
                setTimeout(async () => {
                    enrolInCourse(learningActivityDetails.learningActivityId, learningActivityDetails.learningActivityId, learningActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
                    PaymentsUtil.verifyRazorpayPaymentStatus(response, learningActivityDetails);
                }, AppConstants.enrolmentDelayTimeout);
            }
        });

        rzpay.on(AppConstants.razorpaypaymentFailed, function (response) {
            PaymentsUtil.handleRazorpayPaymentFailure(response, learningActivityDetails);
        });

        rzpay.open();
    };

    const getCourseStartTime = (startTime, endTime) => {
        const start = DateTime.fromFormat(startTime, AppConstants.courseCardsTimeDateFormat).toFormat(AppConstants.courseCardsDateFormat);
        return `${moment(start).format(AppConstants.ISPcourseDateFormat)}, ${startTime.split(AppConstants.comma)[0]}`;
    };

    const handleModalClose = () => {
        if (transactionId) {
            window.location.reload();
        } else {
            if (userLoggedInAndEnroled) {
                window.location.reload();
            } else {
                setShowModal(AppConstants.falseText);
            }
        }
    };

    const handleModalShow = () => {
        setShowModal(AppConstants.trueText);
    };

    const handleLoginModalClose = () => {
        setShowLoginModal(AppConstants.falseText);
        if (Auth.isLogin()) {
            if (Auth.getUserRole() === AppConstants.userRoleStudent) {
                navigate(AppConstants.studentCoursesPath);
            } else {
                window.location.reload();
            }
        }
        setConfirmationPopupActivityDetails({});
    };

    const handleLoginModalShow = () => {
        if (Auth.isLogin() && Auth.getUserRole() === AppConstants.userRoleStudent) {
            navigate(AppConstants.studentCoursesPath);
        } else {
            setShowLoginModal(AppConstants.trueText);
        }
    };


    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleCardClick = (url) => {
        window.open(url, AppConstants.openInNewTab);
    };

    // Share on social media
    function shareOnSocialMedia(shareData) {
        setShowSocialMediaPannel(!showSocialMediaPannel);
        setLinkToShare(`${process.env.REACT_APP_FRONT_END_URL}${AppConstants.coursesText}${AppConstants.forwardSlash}${shareData.categorySlug}${AppConstants.forwardSlash}${shareData.skillSlug}${AppConstants.forwardSlash}${shareData.courseSlug}`);
    };

    const onClickRetry = () => {
        setPaymentStatus(AppConstants.paymentStatus.pending);
        enrolInCourse(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
        PaymentsUtil.verifyRazorpayPaymentStatus(paymentInitiateResponse.response, paymentInitiateResponse.learningActivityDetails);
    };

    return (
        <>
            <Row xs={1} sm={2} md={3} lg={4} xl={4} className={styles.cardSection}>
                {cardDetails !== null && cardDetails?.slice(0, cardCount).map((courseCard, index) => (
                    <Col className={classes.courseActivityCardSection} ref={index === cardCount - 1 ? lastElementRef : null}>
                        <Card key={index} className={styles.cardDiv} onClick={() => handleCardClick(`${AppConstants.forwardSlash}${AppConstants.coursesText}${AppConstants.forwardSlash}${courseCard.categorySlug}${AppConstants.forwardSlash}${courseCard.skillSlug}${AppConstants.forwardSlash}${courseCard.courseSlug}`)}>
                            <Card.Img variant="top" src={courseCard.courseThumbnailImagePath || defaultThumbnail} className={classes.courseActivityCardImage} />
                            <TbShare3 className={styles.shareIconUpcomingCards} onClick={(e) => { stopPropagation(e); setEditIndex(editIndex => editIndex === index ? index : index); shareOnSocialMedia(courseCard) }} />
                            <Card.Body className={classes.courseActivityCardBody}>
                                <div className={`${styles.subTitleContainer} ${styles.mb - 1}`}>
                                    <div className={`cardSubTitle courseDiffColor`}>{courseCard.modeOfDelivery.toLowerCase() === AppConstants.offlineText.toLocaleLowerCase() ? <span className="modeSubtitle" data-tooltip-place="top" data-tooltip-id={courseCard.uniqueId}><a onClick={stopPropagation} href={courseCard.geoLocation} target={AppConstants.openInNewTab} className="workshopLink onlineIconCourse"><CgPin className="geoLocationIcon onlineIconCourse" /> {courseCard.modeOfDelivery.toUpperCase()}</a></span> : <span className="onlineIconCourse modeSubtitle">{courseCard.modeOfDelivery.toUpperCase()}</span>}</div>
                                    <Tooltip className={styles.tooltipMessage} id={courseCard.uniqueId}><span className={styles.tooltipMessage}>{courseCard.address}</span></Tooltip>
                                </div>
                                <Card.Text className={`${styles.cardTitle} ${styles.titleFixedHeight}`}>
                                    <Link className={styles.cardLink} data-tooltip-id={`${courseCard.uniqueId}-title`}>{courseCard.courseTitle}</Link>
                                    <Tooltip className={styles.courseTitleTooltip} id={`${courseCard.uniqueId}-title`}><span>{courseCard.courseTitle}</span></Tooltip>
                                </Card.Text>
                                {editIndex === index && showSocialMediaPannel && <div onClick={stopPropagation} className={classes.shareIconPannelContainerUpcomingCards}>
                                    <ShareLinkToSocialMediaUtil linkToShare={linkToShare} className={classes} useIcons={AppConstants.trueText} />
                                </div>}
                                <Card.Text onClick={e => { e.stopPropagation(); window.open(`${process.env.REACT_APP_FRONT_END_URL}${courseCard.userRole === AppConstants.userRoleExpert ? AppConstants.expert : AppConstants.agency}${AppConstants.forwardSlash}${courseCard.userSlug}`) }} className={`${styles.createdBy} ${styles.cardTimeAndDate}`} >By : {courseCard.organizedBy}</Card.Text>
                                <div className="cardTimeAndDate">
                                    <div className="dateTimeText">
                                        <span className="competitionDateTimeFormat"><ViewSelectedWeekDays selectedValues={courseCard.weekDays} /></span>
                                    </div>
                                </div>
                                {courseCard.courseType === AppConstants.structuredCourseType && <div className="cardTimeAndDate">
                                    <div><BiCalendarCheck className="calendarIcon" />&nbsp;</div>
                                    <div className="dateTimeText">
                                        <span className="competitionDateTimeFormat">Starts {getCourseStartTime(courseCard.startTime, courseCard.endTime)} {courseCard.preferredTimezone === AppConstants.utcTimezone && `(${courseCard.preferredTimezone})`}</span>
                                    </div>
                                </div>}
                                {courseCard.courseType === AppConstants.flexibleCourseType && <div className="cardTimeAndDate">
                                    <div><AiOutlineClockCircle className="calendarIcon" />&nbsp;</div>
                                    <div className="dateTimeText">
                                        <span className="competitionDateTimeFormat">{`${DateTimeUtil.convertUtcTimeToLocalTime(courseCard.windowStartTimeString)} to ${DateTimeUtil.convertUtcTimeToLocalTime(courseCard.windowEndTimeString)}`} {timeZone === AppConstants.utcTimezone && `(${timeZone})`} {courseCard.reviewCount >= props.minimumReviewCount && <><span className="durationSeparator">|</span> {`${MathUtil.formatNumberToLocaleString(courseCard.reviewCount)} ${courseCard.reviewCount > 1 ? "Reviews" : "Review"}`}</>}</span>
                                    </div>
                                </div>}

                                <div className="cardTimeAndDate">
                                    <div><FaChalkboardTeacher className="durationIcon calendarIcon" />&nbsp;</div><div className="dateTimeText">
                                        {courseCard.trackingType !== AppConstants.durationBasedTrackingText && <span data-tooltip-id={`${courseCard.uniqueId}-remainingClasses`} className="competitionDateTimeFormat">&nbsp;{courseCard.numberOfClasses} {courseCard.numberOfClasses > 1 ? "Classes" : "Class"} <span className="durationSeparator">|</span> {courseCard.duration} {courseCard.duration > 1 ? "Hours" : "Hour"} </span>}
                                        {courseCard.trackingType === AppConstants.durationBasedTrackingText && <span className="competitionDateTimeFormat">&nbsp; {courseCard.packageDuration} {courseCard.packageDuration > 1 ? "Days" : "Day"}</span>}
                                    </div>
                                </div>
                                <div className={styles.buttonSection}>
                                    <div className={styles.learningActivityCostInfo}>
                                        <BiWallet /> {PaymentsUtil.calculateCost({ cost: courseCard.cost, discount: courseCard.discount })}
                                    </div>

                                    {courseCard.isOfflinePaymentRequested === AppConstants.yText ?
                                        <Button disabled={true} className={styles.cardButton}>Requested</Button> : <Button disabled={getCourseActionButtonText(courseCard) === "Enrolled"} onClick={(e) => { stopPropagation(e); confirmBeforeEnrolment({ id: courseCard.courseId, title: courseCard.courseTitle, uniqueId: courseCard.uniqueId, startTime: courseCard.courseType === AppConstants.structuredCourseType ? `${courseCard.utcStartDate} ${courseCard.windowStartTime}` : AppConstants.nullText, payOfflineFlag: courseCard.payOfflineFlag, creatorName: courseCard.organizedBy, creatorRole: courseCard.userRole }) }} className={styles.cardButton}>{getCourseActionButtonText(courseCard)}</Button>}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Row xs={1} sm={1} md={1} lg={1} xl={1} className="loadMore">
                {loading && cardCount < cardDetails?.length && (
                    <div className="pageLoader"><BiLoaderAlt className="spinner" /></div>
                )}
            </Row>
            {(props.coursesOfCategory !== null && props.allActivitiesForSkill?.length >= AppConstants.ISPLearningActivityCardsLimit && ((Auth.isLogin() && Auth.getUserRole() === AppConstants.userRoleStudent) || !Auth.isLogin())) && <div className={classes.exploreMoreButton}>
                {(cardCount >= props.coursesOfCategory.length) && <Button onClick={handleLoginModalShow}>{Auth.isLogin() ? `Explore More` : `Login to Explore`}</Button>}
            </div>}
            {(props.coursesOfCategory == null || props.allActivitiesForSkill?.length === 0) && <p className='noDataAvailableError'>{`No courses for '${props.skill}' skill to display at the moment`}</p>}
            {(message !== AppConstants.emptyString) && (showModal) && <EnrolmentModal onShow={handleModalShow} message={message} modalTypeError={modalTypeError} onClose={handleModalClose} enrolInCourse={enrolInCourse} setUserLoggedInAndEnroled={setUserLoggedInAndEnroled} userLoggedInAndEnroled={userLoggedInAndEnroled} learningActivityType={AppConstants.learningActivitiesTypes.course} paymentStatus={paymentStatus} transactionId={transactionId} confirmBeforeEnrolment={confirmBeforeEnrolment} enrolmentDetails={confirmationPopupActivityDetails} displayNetworkError={displayNetworkError} onClickRetry={onClickRetry}></EnrolmentModal>}
            {showLoginModal && <LoginModal onShow={handleLoginModalShow} onClose={handleLoginModalClose} />}
            {showConfirmationModal && <PostStartEnrolmentModal updateConfirmationStatus={updateConfirmationStatus} learningActivityDetails={confirmationPopupActivityDetails} />}
            {showOfflinePaymentModal && <PayOfflineConfirmationModal getLearnerPaymentMethod={getLearnerPaymentMethod} learningActivityDetails={confirmationPopupActivityDetails} />}
        </>
    );
}