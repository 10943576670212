import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Tooltip } from 'react-tooltip';
import { DateTime } from "luxon";
import { BiCalendarCheck, BiCalendarX, BiWallet } from "react-icons/bi";
import { CgPin } from "react-icons/cg";
import { AppConstants } from "../../constants/AppConstants";
import Moment from 'react-moment';
import 'moment-timezone';
import './CompetitionCards.css';
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { EnrolmentModal } from "../modal/EnrolmentModal";
import classes from "./StudentRecentlyPublishedLearningActivitiesCards.module.css"
import { RiGitRepositoryPrivateFill } from 'react-icons/ri';
import { PaymentsUtil } from "../../utils/PaymentsUtil";
import { ErrorMessages } from "../../constants/ErrorMessages";
import { FaChalkboardTeacher } from "react-icons/fa";
import { PostStartEnrolmentModal } from "../modal/PostStartEnrolmentModal";
import { AiOutlineClockCircle } from "react-icons/ai";
import { StringUtils } from "../../utils/StringUtils";
import { MathUtil } from "../../utils/MathUtil";
import { PayOfflineConfirmationModal } from "../modal/PayOfflineConfirmationModal";
import { useEnrolment } from "../../hooks/useEnrolment";
import useWarnOnLeave from "../../hooks/useWarnOnLeave";
import { ViewSelectedWeekDays } from "../calendar/ViewSelectedWeekDays";

export const StudentFeaturedLearningActivitiesCards = (props) => {
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [cardCount, setCardCount] = useState(0);
    const [viewMoreCardsCount, setViewMoreCardsCount] = useState(0);
    const [cardDetails, setCardDetails] = useState(props.cardDetails);
    const [message, setMessage] = useState(AppConstants.emptyString);
    const [modalTypeError, setModalTypeError] = useState(AppConstants.falseText);
    const [showModal, setShowModal] = useState(AppConstants.falseText);
    const [participantsImageList, setParticipantImageList] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [paymentParam, setPaymentParam] = useState(searchParams.get(AppConstants.paymentUrlParam));
    const [paramData, setParamData] = useState({});
    const [paymentStatus, setPaymentStatus] = useState(AppConstants.emptyString);
    const [transactionId, setTransactionId] = useState(AppConstants.emptyString);
    const learningActivitiesCardsCount = AppConstants.cardCounts;
    const [showConfirmationModal, setShowConfirmationModal] = useState(AppConstants.falseText);
    const [confirmationPopupActivityDetails, setConfirmationPopupActivityDetails] = useState({});
    const [showOfflinePaymentModal, setShowOfflinePaymentModal] = useState(AppConstants.falseText);
    const { enrolInLearningActivity } = useEnrolment();
    const [displayNetworkError, setDisplayNetworkError] = useState(AppConstants.falseText);
    const [paymentInitiateResponse, setPaymentInitiateResponse] = useState({});
    const [shouldWarn, setShouldWarn] = useState(AppConstants.falseText);
    useWarnOnLeave(shouldWarn);
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const defaultCompetitionThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.competitionDefaultThumbnailImage}`;
    const defaultCourseThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.courseDefaultThumbnailImage}`;
    const defaultWorkshopThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.workshopDefaultThumbnailImage}`;

    //set cardCount to 4 on tablet screen and 2 on mobile screen
    const handleResize = () => {
        const viewportWidth = window.innerWidth;
        if (viewportWidth >= 992) {
            setCardCount(learningActivitiesCardsCount.learningActivityDesktopCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.learningActivityDesktopCount);
        }
        if (viewportWidth >= 576 && viewportWidth <= 991) {
            setCardCount(learningActivitiesCardsCount.learningActivityTabCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.learningActivityTabCount);
        }
        if (viewportWidth <= 575) {
            setCardCount(learningActivitiesCardsCount.learningActivityMobileCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.learningActivityMobileCount);
        }
    };

    //call handleResize() on screen width change
    useEffect(() => {
        handleResize();
        window.addEventListener('orientationchange', handleResize);
        return () => {
            window.removeEventListener('orientationchange', handleResize);
        };
    }, []);

    useEffect(() => {
        const populateParticipantImageList = () => {
            let imageArray = [];
            for (let i = 0; i < cardDetails?.length; i++) {
                let partcipantsData = cardDetails[i].participantsDetails;
                let imageList = [];
                partcipantsData?.map(data => {
                    if (data.studentImage) imageList.push(data.studentImage)
                });
                imageArray.push(imageList);
            }
            setParticipantImageList(imageArray);
        }
        populateParticipantImageList();
    }, [cardDetails]);

    useEffect(() => {
        setCardDetails(props.cardDetails);

    }, [props.learningActivityType, props.cardDetails]);

    useEffect(() => {
        const verifyPayment = async (data, learningActivity) => {
            try {
                PaymentsUtil.verifyPayment(data).then((response) => {
                    if (response.verificationStatus === AppConstants.paymentStatus.verified) {
                        setTransactionId(response.transactionId);
                        setPaymentStatus(AppConstants.paymentStatus.verified);
                        if (data.learningActivityType === AppConstants.learningActivitiesTypes.competition) {
                            enrolInCompetition(learningActivity.competitionId, learningActivity.title, learningActivity.uniqueId, AppConstants.paymentStatus.verified);
                        } else if (data.learningActivityType === AppConstants.learningActivitiesTypes.course) {
                            enrolInCourse(learningActivity.courseId, learningActivity.courseTitle, learningActivity.courseUniqueId, AppConstants.paymentStatus.verified)
                        } else {
                            enrolInWorkshop(learningActivity.workshopId, learningActivity.workshopTitle, learningActivity.workshopUniqueId, AppConstants.paymentStatus.verified)
                        }
                    } else {
                        setModalTypeError(AppConstants.trueText);
                        setMessage(response.message);
                        setTransactionId(response.transactionId);
                        setPaymentStatus(AppConstants.emptyString);
                        handleModalShow();
                    }
                }).catch((error) => {
                    setModalTypeError(AppConstants.trueText);
                    setMessage(ErrorMessages.verifyPaymentFailedError);
                    setPaymentStatus(AppConstants.emptyString);
                    handleModalShow();
                });
            } catch (error) {
                setPaymentStatus(AppConstants.emptyString);
            }
        };

        if (paymentParam && cardDetails.length > 0) {
            const data = StringUtils.decodeBase64EncodedString(paymentParam);
            if (data !== AppConstants.nullText && data.category === props.category) {
                let learningActivity;
                if (data.learningActivityType === AppConstants.learningActivitiesTypes.competition) {
                    learningActivity = cardDetails.filter(card => card.uniqueId === data.uniqueId)[0];
                    setConfirmationPopupActivityDetails({ id: learningActivity.competitionId, title: learningActivity.title, uniqueId: learningActivity.uniqueId, startTime: learningActivity.learningActivityStartTime, type: AppConstants.learningActivitiesTypes.competition, creatorRole: learningActivity.userRole, creatorName: learningActivity.organizedBy, payOfflineFlag: learningActivity.payOfflineFlag })
                } else if (data.learningActivityType === AppConstants.learningActivitiesTypes.course) {
                    learningActivity = cardDetails.filter(card => card.courseUniqueId === data.uniqueId)[0];
                    setConfirmationPopupActivityDetails({ id: learningActivity.courseId, title: learningActivity.courseTitle, uniqueId: learningActivity.courseUniqueId, startTime: learningActivity.courseType === AppConstants.structuredCourseType ? learningActivity.learningActivityStartTime : AppConstants.nullText, type: AppConstants.learningActivitiesTypes.course, payOfflineFlag: learningActivity.payOfflineFlag, creatorRole: learningActivity.userRole, creatorName: learningActivity.organizedBy })
                } else {
                    learningActivity = cardDetails.filter(card => card.workshopUniqueId === data.uniqueId)[0];
                    setConfirmationPopupActivityDetails({ id: learningActivity.workshopId, title: learningActivity.workshopTitle, uniqueId: learningActivity.workshopUniqueId, startTime: learningActivity.learningActivityStartTime, type: AppConstants.learningActivitiesTypes.workshop, creatorRole: learningActivity.userRole, creatorName: learningActivity.organizedB, payOfflineFlag: learningActivity.payOfflineFlagy })
                }

                if (!learningActivity) {
                    setParamData({});
                    setPaymentStatus(AppConstants.emptyString);
                    return;
                }

                data.learningActivityName = (data.learningActivityType === AppConstants.learningActivitiesTypes.competition) ? learningActivity.title : (data.learningActivityType === AppConstants.learningActivitiesTypes.course) ? learningActivity.courseTitle : learningActivity.workshopTitle;
                setParamData(data);
                setPaymentStatus(AppConstants.paymentStatus.pending);

                const timer = setTimeout(() => {
                    verifyPayment(data, learningActivity);
                }, AppConstants.paymentVerificationTimeout);

                return () => clearTimeout(timer);
            }
        }
    }, [paymentParam, cardDetails]);

    const confirmBeforeEnrolment = ({ id, title, uniqueId, startTime, type, payOfflineFlag, creatorRole, creatorName }) => {
        setConfirmationPopupActivityDetails({ id, title, startTime, uniqueId, type, payOfflineFlag, creatorRole, creatorName });

        if (type === AppConstants.learningActivitiesTypes.course && startTime === AppConstants.nullText) {
            if (payOfflineFlag === AppConstants.yText) {
                setShowOfflinePaymentModal(AppConstants.trueText);
            } else {
                enrolInCourse(id, title, uniqueId);
            }
        } else {
            const startDateTime = DateTime.fromISO(startTime?.replace(' ', 'T') + 'Z').setZone(timeZone);
            const currentDateTime = DateTime.local().setZone(timeZone);

            // check if the learning activity has started.
            if (currentDateTime > startDateTime) {
                // show confirmation modal to the user.
                setShowConfirmationModal(AppConstants.trueText);
            } else {
                if (payOfflineFlag === AppConstants.yText) {
                    setShowOfflinePaymentModal(AppConstants.trueText);
                } else {
                    if (type === AppConstants.learningActivitiesTypes.competition) {
                        enrolInCompetition(id, title, uniqueId);
                    } else if (type === AppConstants.learningActivitiesTypes.course) {
                        enrolInCourse(id, title, uniqueId);
                    } else {
                        enrolInWorkshop(id, title, uniqueId);
                    }
                }
            }
        }
    };

    const updateConfirmationStatus = (status, learningActivityType) => {
        setShowConfirmationModal(AppConstants.falseText);
        // if user selects yes -> 
        if (status) {
            if (confirmationPopupActivityDetails.payOfflineFlag === AppConstants.yText) {
                setShowOfflinePaymentModal(AppConstants.trueText);
            } else {
                if (learningActivityType === AppConstants.learningActivitiesTypes.competition) {
                    enrolInCompetition(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
                } else if (learningActivityType === AppConstants.learningActivitiesTypes.course) {
                    enrolInCourse(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
                } else {
                    enrolInWorkshop(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
                }
            }
        } else {
            setConfirmationPopupActivityDetails({});
        }
    };

    const getLearnerPaymentMethod = (paymentType) => {
        setShowOfflinePaymentModal(AppConstants.falseText);
        if (paymentType === AppConstants.paymentMethod.online) {
            if (confirmationPopupActivityDetails.type === AppConstants.learningActivitiesTypes.course) {
                enrolInCourse(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
            } else if (confirmationPopupActivityDetails.type === AppConstants.learningActivitiesTypes.competition) {
                enrolInCompetition(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
            } else {
                enrolInWorkshop(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
            }
        } else {
            setConfirmationPopupActivityDetails({});
        }
    };

    const enrolInCompetition = async (competitionId, competitionTitle, uniqueId, status) => {
        const cost = PaymentsUtil.calculateCost({ cost: cardDetails.filter(card => card.uniqueId === uniqueId && card.learningActivityType === AppConstants.learningActivitiesTypes.competition)[0].competitionCost, discount: 0 })
        let response = await enrolInLearningActivity({ uniqueId, cost, learningActivityName: competitionTitle, learningActivityId: competitionId, learningActivityType: AppConstants.learningActivitiesTypes.competition }, status)
        setModalTypeError(response.modalTypeError);
        setPaymentStatus(response.paymentStatus);
        setMessage(response.message);
        setShowModal(response.showModal);
        setShouldWarn(response.shouldWarn);
        setDisplayNetworkError(response.networkError);
        if (response.showRazorpayPopup) {
            setTimeout(() => {
                showRazorpayPopup(response);
            }, AppConstants.paymentGatewayRedirectTimeout);
        }
    }
    const enrolInCourse = async (courseId, courseTitle, courseUniqueId, status) => {
        const course = cardDetails.filter(card => card.courseUniqueId === courseUniqueId)[0];
        const cost = PaymentsUtil.calculateCost({ cost: course.courseCost, discount: course.courseDiscount });
        const response = await enrolInLearningActivity({
            uniqueId: courseUniqueId,
            cost,
            learningActivityType: AppConstants.learningActivitiesTypes.course,
            learningActivityId: courseId,
            learningActivityName: courseTitle,
            courseType: course.courseType,
            subscriptionType: course.courseType === AppConstants.flexibleCourseType && course.courseEnrolledOnDate !== null ? course.trackingType : AppConstants.falseText
        }, status);

        setModalTypeError(response.modalTypeError);
        setPaymentStatus(response.paymentStatus);
        setMessage(response.message);
        setShowModal(response.showModal);
        setDisplayNetworkError(response.networkError);
        setShouldWarn(response.shouldWarn);
        if (response.showRazorpayPopup) {
            setTimeout(() => {
                showRazorpayPopup(response);
            }, AppConstants.paymentGatewayRedirectTimeout);
        }
    };


    const enrolInWorkshop = async (id, title, uniqueId, status) => {
        const workshop = cardDetails.filter(card => card.workshopUniqueId === uniqueId)[0];
        const cost = PaymentsUtil.calculateCost({ cost: workshop.workshopCost, discount: 0 });
        const response = await enrolInLearningActivity({ uniqueId, cost, learningActivityName: title, learningActivityId: id, learningActivityType: AppConstants.learningActivitiesTypes.workshop }, status)

        setModalTypeError(response.modalTypeError);
        setPaymentStatus(response.paymentStatus);
        setMessage(response.message);
        setShowModal(response.showModal);
        setDisplayNetworkError(response.networkError);
        setShouldWarn(response.shouldWarn);
        if (response.showRazorpayPopup) {
            setTimeout(() => {
                showRazorpayPopup(response);
            }, AppConstants.paymentGatewayRedirectTimeout);
        }
    };

    const showRazorpayPopup = ({ options, learningActivityDetails }) => {
        setShowModal(AppConstants.falseText);
        const rzp1 = new window.Razorpay({
            ...options,
            handler: async (response) => {
                setShowModal(AppConstants.trueText);
                setTransactionId(response.razorpay_payment_id);
                setPaymentStatus(AppConstants.paymentStatus.pending);
                setShouldWarn(AppConstants.trueText);
                setPaymentInitiateResponse({ response, learningActivityDetails });
                setTimeout(async () => {
                    if (learningActivityDetails.learningActivityType === AppConstants.learningActivitiesTypes.competition) {
                        enrolInCompetition(learningActivityDetails.learningActivityId, learningActivityDetails.learningActivityTitle, learningActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
                    } else if (learningActivityDetails.learningActivityType === AppConstants.learningActivitiesTypes.course) {
                        enrolInCourse(learningActivityDetails.learningActivityId, learningActivityDetails.learningActivityTitle, learningActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
                    } else {
                        enrolInWorkshop(learningActivityDetails.learningActivityId, learningActivityDetails.learningActivityTitle, learningActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
                    }
                    PaymentsUtil.verifyRazorpayPaymentStatus(response, learningActivityDetails);
                }, AppConstants.enrolmentDelayTimeout);
            }

        });

        rzp1.on(AppConstants.razorpaypaymentFailed, function (response) {
            PaymentsUtil.handleRazorpayPaymentFailure(response, learningActivityDetails);
        });

        rzp1.open();
    };

    const onClickRetry = () => {
        setPaymentStatus(AppConstants.paymentStatus.pending);
        if (confirmationPopupActivityDetails.type === AppConstants.learningActivitiesTypes.course) {
            enrolInCourse(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
        } else if (confirmationPopupActivityDetails.type === AppConstants.learningActivitiesTypes.competition) {
            enrolInCompetition(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
        } else {
            enrolInWorkshop(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
        }
        PaymentsUtil.verifyRazorpayPaymentStatus(paymentInitiateResponse.response, paymentInitiateResponse.learningActivityDetails);
    }



    const handleModalClose = () => {
        setShowModal(AppConstants.falseText);
        if (paymentParam) {
            window.location.replace(window.location.href.split(AppConstants.questionMark)[0]);
        } else if (transactionId) {
            window.location.reload();
        }
    }
    const handleModalShow = () => {
        setShowModal(AppConstants.trueText);
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleCardClick = (url) => {
        window.open(url, AppConstants.newPageTargetSelf);
    };

    return (
        <>
            <Row xs={1} sm={2} md={2} lg={3} xl={3} className="cardSection">
                {cardDetails.slice(0, cardCount).map((cardData, index) => (
                    <Col key={index}>
                        {cardData.learningActivityType === AppConstants.tabCompetitionValueText &&
                            <Card className="cardDiv" onClick={() => handleCardClick(`${AppConstants.forwardSlash}${AppConstants.competitionsText}${AppConstants.forwardSlash}${cardData.categorySlug}${AppConstants.forwardSlash}${cardData.skillSlug}${AppConstants.forwardSlash}${cardData.competionSlug}`)}>
                                <Card.Img variant="top" className="cardImage" src={cardData.thumbnailImagePath ? cardData.thumbnailImagePath : defaultCompetitionThumbnail} />
                                <Card.Body className="cardBody">
                                    <div className="subTitleContainer">
                                        <div className={`cardSubTitle ${classes.activityTypeCompetition}`}>{cardData.learningActivityType.toUpperCase()} <span className="seperator">{AppConstants.verticalBar}</span> {cardData.minAge !== 0 && cardData.maxAge !== 0 && "AGE " + cardData.minAge + "-" + cardData.maxAge} {cardData.minAge === 0 && cardData.maxAge === 0 && " ALL AGES"} {cardData.minAge !== 0 && cardData.maxAge === 0 && "AGE " + cardData.minAge + "+"} {cardData.minAge === 0 && cardData.maxAge !== 0 && "AGE " + props.minimumAgeLimit + "-" + cardData.maxAge} <span className="seperator">{AppConstants.verticalBar}</span> {(cardData.type.toUpperCase() === AppConstants.physicalText || cardData.type.toUpperCase() === AppConstants.offlineText) ? <span onClick={stopPropagation} className="modeSubtitle" data-tooltip-place="top" data-tooltip-id={cardData.uniqueId}><a href={cardData.geoLocation} target={AppConstants.openInNewTab} className="workshopLink onlineIconCompetition"><CgPin className="geoLocationIcon onlineIconCompetition" /> {cardData.type.toUpperCase()}</a></span> : <span className="onlineIconCompetition modeSubtitle">{cardData.type.toUpperCase()}</span>}</div>
                                    </div>
                                    <Tooltip className="tooltipMessage" id={cardData.uniqueId}><span className="tooltipMessage">{cardData.venueLocation}</span></Tooltip>
                                    <Card.Text className="cardTitle titleFixedHeight">
                                        <Link className="cardLink" data-tooltip-id={`${cardData.uniqueId}-title`}>{cardData.title}</Link>
                                        <Tooltip className="courseTitleTooltip" id={`${cardData.uniqueId}-title`}><span>{cardData.title}</span></Tooltip>
                                    </Card.Text>
                                    <Card.Text onClick={e => { e.stopPropagation(); window.open(`${process.env.REACT_APP_FRONT_END_URL}${cardData.userRole === AppConstants.userRoleExpert ? AppConstants.expert : AppConstants.agency}${AppConstants.forwardSlash}${cardData.userSlug}`) }} className="cardTimeAndDate">By : {cardData.organizedBy}</Card.Text>
                                    <div className="emptyDivForCalendar"></div>
                                    <div className="cardTimeAndDate">
                                        <div><BiCalendarCheck className="calendarIcon" />&nbsp;</div>
                                        <div className="dateTimeText">
                                            Start:&nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{cardData.utcStartTime.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{cardData.utcStartTime.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${timeZone})`}</span>
                                        </div>
                                    </div>
                                    <div className="cardTimeAndDate">
                                        <div><BiCalendarX className="calendarIcon" />&nbsp;</div>
                                        <div className="dateTimeText">
                                            End:&nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{cardData.utcEndTime.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{cardData.utcEndTime.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${timeZone})`}</span>
                                        </div>
                                    </div>
                                    {cardData.disablePublicEnrolmentFlag === AppConstants.nText && <div className="buttonSection">
                                        <div className="learningActivityCostInfo"><BiWallet />  {PaymentsUtil.calculateCost({ cost: cardData.competitionCost, discount: 0 })}</div>
                                        <Button type="button" className="cardButton" onClick={(e) => { stopPropagation(e); confirmBeforeEnrolment({ id: cardData.competitionId, title: cardData.title, uniqueId: cardData.uniqueId, startTime: cardData.learningActivityStartTime, type: AppConstants.learningActivitiesTypes.competition, creatorRole: cardData.userRole, creatorName: cardData.organizedBy, payOfflineFlag: cardData.payOfflineFlag }) }}>Enrol Now</Button>
                                    </div>}
                                    {cardData.disablePublicEnrolmentFlag === AppConstants.yText && <div className="buttonSection">
                                        <div className="learningActivityCostInfo"><BiWallet />  {PaymentsUtil.calculateCost({ cost: cardData.competitionCost, discount: 0 })}</div>
                                        <span data-tooltip-id={`${cardData.learningActivitySlug}-private`}><RiGitRepositoryPrivateFill /></span>
                                        <Tooltip className="tooltipMessagePrivate" id={`${cardData.learningActivitySlug}-private`}><span>This is a private competition. Public enrolments are disabled for this competition.</span></Tooltip>
                                    </div>}
                                </Card.Body>
                            </Card>
                        }
                        {cardData.learningActivityType === AppConstants.tabCoursesValueText &&
                            <Card className="cardDiv" onClick={() => handleCardClick(`${AppConstants.forwardSlash}${AppConstants.coursesText}${AppConstants.forwardSlash}${cardData.categorySlug}${AppConstants.forwardSlash}${cardData.skillSlug}${AppConstants.forwardSlash}${cardData.courseSlug}`)}>
                                <Card.Img variant="top" className="cardImage" src={cardData.courseThumbnailImagePath ? cardData.courseThumbnailImagePath : defaultCourseThumbnail} />
                                <Card.Body className="cardBody">
                                    <div className="subTitleContainer">
                                        <div className={`cardSubTitle ${classes.activityTypeCourse}`}>{cardData.learningActivityType.toUpperCase()} <span className="seperator">{AppConstants.verticalBar}</span> {cardData.modeOfDelivery.toLowerCase() === AppConstants.offlineText.toLocaleLowerCase() ? <span onClick={stopPropagation} className="modeSubtitle" data-tooltip-place="top" data-tooltip-id={cardData.courseSlug}><a href={cardData.geoLocation} target={AppConstants.openInNewTab} className="workshopLink onlineIconCourse"><CgPin className="geoLocationIcon onlineIconCourse" /> {cardData.modeOfDelivery.toUpperCase()}</a> </span > : <span className="onlineIconCourse modeSubtitle">{cardData.modeOfDelivery.toUpperCase()}</span>}</div>
                                    </div>
                                    <Tooltip className="tooltipMessage" id={cardData.courseSlug}><span className="tooltipMessage">{cardData.courseAddress}</span></Tooltip>
                                    <Card.Text className="cardTitle titleFixedHeight">
                                        <Link className="cardLink" data-tooltip-id={`${cardData.courseSlug}-title`} >{cardData.courseTitle}</Link>
                                        <Tooltip className="courseTitleTooltip" id={`${cardData.courseSlug}-title`}><span>{cardData.courseTitle}</span></Tooltip>
                                    </Card.Text>
                                    <Card.Text onClick={e => { e.stopPropagation(); window.open(`${process.env.REACT_APP_FRONT_END_URL}${cardData.userRole === AppConstants.userRoleExpert ? AppConstants.expert : AppConstants.agency}${AppConstants.forwardSlash}${cardData.userSlug}`) }} className="cardTimeAndDate">By : {cardData.organizedBy}</Card.Text>
                                    <div className="cardTimeAndDate">
                                        <div className="dateTimeText">
                                            <span className="competitionDateTimeFormat"><ViewSelectedWeekDays selectedValues={cardData.weekDays} /></span>
                                        </div>
                                    </div>
                                    {cardData.courseType === AppConstants.structuredCourseType && <div className="cardTimeAndDate">
                                        <div><BiCalendarCheck className="calendarIcon" />&nbsp;</div>
                                        <div className="dateTimeText">
                                            <span className="competitionDateTimeFormat">Starts {DateTimeUtil.getDateTimeForCourses(cardData.utcStartTime)} {timeZone === AppConstants.utcTimezone && `(${timeZone})`}</span>
                                        </div>
                                    </div>}
                                    {cardData.courseType === AppConstants.flexibleCourseType && <div className="cardTimeAndDate">
                                        <div><AiOutlineClockCircle className="calendarIcon" />&nbsp;</div>
                                        <div className="dateTimeText">
                                            <span className="competitionDateTimeFormat">{`${DateTimeUtil.convertUtcTimeToLocalTime(cardData.windowStartTime)} - ${DateTimeUtil.convertUtcTimeToLocalTime(cardData.windowEndTime)}`}  {timeZone === AppConstants.utcTimezone && `(${timeZone})`} {cardData.reviewCount >= props.minimumReviewCount && <><span className="durationSeparator">|</span> {`${MathUtil.formatNumberToLocaleString(cardData.reviewCount)} ${cardData.reviewCount > 1 ? "Reviews" : "Review"}`}</>}</span>
                                        </div>
                                    </div>}
                                    <div className="cardTimeAndDate">
                                        <div><FaChalkboardTeacher className="durationIcon calendarIcon" />&nbsp;</div><div className="dateTimeText">
                                            {cardData.trackingType !== AppConstants.durationBasedTrackingText && <span data-tooltip-id={`${cardData.learningActivitySlug}-remainingClasses`} className="competitionDateTimeFormat">&nbsp;{cardData.numberOfClasses} {cardData.numberOfClasses > 1 ? "Classes" : "Class"} <span className="durationSeparator">|</span> {cardData.courseDuration} {cardData.courseDuration > 1 ? "Hours" : "Hour"} </span>}
                                            {cardData.trackingType === AppConstants.durationBasedTrackingText && <span data-tooltip-id={`${cardData.learningActivitySlug}-remainingClasses`} className="competitionDateTimeFormat">&nbsp; {cardData.packageDuration} {cardData.packageDuration > 1 ? "Days" : "Day"}</span>}
                                        </div>
                                    </div>
                                    <div className="buttonSection">
                                        <div className="learningActivityCostInfo"><BiWallet /> {PaymentsUtil.calculateCost({ cost: cardData.courseCost, discount: cardData.courseDiscount })}</div>
                                        <Button type="button" className="cardButton" onClick={(e) => { stopPropagation(e); confirmBeforeEnrolment({ id: cardData.courseId, title: cardData.courseTitle, uniqueId: cardData.courseUniqueId, startTime: cardData.courseType === AppConstants.structuredCourseType ? cardData.learningActivityStartTime : AppConstants.nullText, type: AppConstants.learningActivitiesTypes.course, payOfflineFlag: cardData.payOfflineFlag, creatorRole: cardData.userRole, creatorName: cardData.organizedBy }) }}>{(cardData.courseEnrolledOnDate === null || cardData.courseType === AppConstants.structuredCourseType) ? "Enrol Now" : AppConstants.renewPlanText}</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        }
                        {cardData.learningActivityType === AppConstants.tabWorkshopValueText &&
                            <Card className="cardDiv" onClick={() => handleCardClick(`${AppConstants.forwardSlash}${AppConstants.workshopsText}${AppConstants.forwardSlash}${cardData.categorySlug}${AppConstants.forwardSlash}${cardData.skillSlug}${AppConstants.forwardSlash}${cardData.workshopSlug}`)}>
                                <Card.Img variant="top" className="cardImage" src={cardData.workshopThumbnailImagePath ? cardData.workshopThumbnailImagePath : defaultWorkshopThumbnail} />
                                <Card.Body className="cardBody">
                                    <div className="subTitleContainer">
                                        <div className={`cardSubTitle ${classes.activityTypeWorkshop}`}>{cardData.learningActivityType.toUpperCase()} <span className="seperator">{AppConstants.verticalBar}</span> {cardData.workshopModeOfDelivery.toLowerCase() === AppConstants.offlineText.toLocaleLowerCase() ? <span onClick={stopPropagation} className="modeSubtitle" data-tooltip-place="top" data-tooltip-id={cardData.workshopSlug}><a href={cardData.workshopGeoLocation} target={AppConstants.openInNewTab} className="workshopLink onlineIconWorkshop"><CgPin className="geoLocationIcon onlineIconWorkshop" /> {cardData.workshopModeOfDelivery.toUpperCase()}</a></span> : <span className="onlineIconWorkshop modeSubtitle">{cardData.workshopModeOfDelivery.toUpperCase()}</span>}</div>
                                    </div>
                                    <Tooltip className="tooltipMessage" id={cardData.workshopSlug}><span className="tooltipMessage">{cardData.workshopAddress}</span></Tooltip>
                                    <Card.Text className="cardTitle titleFixedHeight">
                                        <Link className="cardLink" data-tooltip-id={`${cardData.workshopSlug}-title`}>{cardData.workshopTitle}</Link>
                                        <Tooltip className="courseTitleTooltip" id={`${cardData.workshopSlug}-title`}><span>{cardData.workshopTitle}</span></Tooltip>
                                    </Card.Text>
                                    <Card.Text onClick={e => { e.stopPropagation(); window.open(`${process.env.REACT_APP_FRONT_END_URL}${cardData.userRole === AppConstants.userRoleExpert ? AppConstants.expert : AppConstants.agency}${AppConstants.forwardSlash}${cardData.userSlug}`) }} className="cardTimeAndDate">By : {cardData.organizedBy}</Card.Text>
                                    <div className="emptyDivForCalendar"></div>
                                    <div className="cardTimeAndDate">
                                        <div><BiCalendarCheck className="calendarIcon" />&nbsp;</div>
                                        <div className="dateTimeText">
                                            Start:&nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{cardData.workshopUtcStartDateString?.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{cardData.workshopUtcStartDateString?.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${timeZone})`}</span>
                                        </div>
                                    </div>
                                    <div className="cardTimeAndDate">
                                        <div><BiCalendarX className="calendarIcon" />&nbsp;</div>
                                        <div className="dateTimeText">
                                            End:&nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{cardData.workshopUtcEndDateString?.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{cardData.workshopUtcEndDateString.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${timeZone})`}</span>
                                        </div>
                                    </div>
                                    <div className="buttonSection">
                                        <div className="learningActivityCostInfo"><BiWallet /> {PaymentsUtil.calculateCost({ cost: cardData.workshopCost, discount: 0 })}</div>
                                        <Button type="button" className="cardButton" onClick={(e) => { stopPropagation(e); confirmBeforeEnrolment({ id: cardData.workshopId, title: cardData.workshopTitle, uniqueId: cardData.workshopUniqueId, startTime: cardData.learningActivityStartTime, type: AppConstants.learningActivitiesTypes.workshop, creatorRole: cardData.userRole, creatorName: cardData.organizedBy, payOfflineFlag: cardData.payOfflineFlag }) }}>Enrol Now</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        }
                    </Col>
                ))}
            </Row>
            <Row xs={1} sm={1} md={1} lg={1} xl={1} className="loadMore">
                <Col className="loadMoreButtonSection">
                    {cardCount < cardDetails.length && <Button className="loadMoreButton" onClick={() => setCardCount(cardCount + viewMoreCardsCount)}>Load more</Button>}
                </Col>
            </Row>
            {(message !== AppConstants.emptyString || paramData.category === props.category) && (showModal || paramData.category === props.category) && <EnrolmentModal onShow={handleModalShow} message={message} modalTypeError={modalTypeError} onClose={handleModalClose} paymentStatus={paymentStatus} transactionId={transactionId} enrolmentDetails={confirmationPopupActivityDetails} displayNetworkError={displayNetworkError} onClickRetry={onClickRetry}></EnrolmentModal>}
            {showConfirmationModal && <PostStartEnrolmentModal updateConfirmationStatus={updateConfirmationStatus} learningActivityDetails={confirmationPopupActivityDetails} />}
            {showOfflinePaymentModal && <PayOfflineConfirmationModal getLearnerPaymentMethod={getLearnerPaymentMethod} learningActivityDetails={confirmationPopupActivityDetails} />}
        </>
    )
}
