import React, { useState, useRef, useEffect } from "react";
import { AppConstants } from "../../constants/AppConstants";
import { ErrorMessages } from "../../constants/ErrorMessages";
import classes from './StudentProfile.module.scss';
import { Form } from "react-bootstrap";
import { BiChevronDown } from 'react-icons/bi';
import Auth from "../../utils/Auth";
import { MdOutlineDelete } from "react-icons/md";

export const AdvanceProfilingStep3 = ({ formValues, setFormValues, setProfilePictureFile, genderErrorMessage }) => {

  const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
  const profileOverlayPicture = `${mediaContent}${AppConstants.profileOverlayImageS3Path}`;
  const blueBackgroundImage = `${mediaContent}${AppConstants.studentProfileBackgroundMobile}`;

  const userInitials = Auth.getLoggedInUserDetails().nameInitials;

  const [profilePic, setProfilePic] = useState(AppConstants.emptyString);
  const [imageError, setImageError] = useState(AppConstants.emptyString);
  const uploadImageProfile = `${mediaContent}${AppConstants.staticContentImagesS3Path}${AppConstants.forwardSlash}${AppConstants.studentProfileS3Path}${AppConstants.forwardSlash}${AppConstants.studentProfileImageUpload}`;
  const manAvatar = `${mediaContent}${AppConstants.staticContentImagesS3Path}${AppConstants.forwardSlash}${AppConstants.studentProfileS3Path}${AppConstants.forwardSlash}${AppConstants.studentProfileManAvatar}`;
  const womanAvatar = `${mediaContent}${AppConstants.staticContentImagesS3Path}${AppConstants.forwardSlash}${AppConstants.studentProfileS3Path}${AppConstants.forwardSlash}${AppConstants.studentProfileWonamAvatar}`;
  const hiddenFileInput = useRef(null);
  const defaultImages = [uploadImageProfile, manAvatar, womanAvatar];

  useEffect(() => {
    document.documentElement.style.setProperty('--blue-background-image', `url(${blueBackgroundImage})`);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setImageError(AppConstants.emptyString);
    }, AppConstants.messageDisappearTime);

    return () => clearTimeout(timer);
  }, [imageError]);


  const handleProfileChange = (e) => {
    setImageError(AppConstants.emptyString);
    if (e.target.files[0]) {
      const imageType = e.target.files[0].type;
      const imageSize = e.target.files[0].size / (1024 * 1024);
      if (imageType === AppConstants.jpegImageType || imageType === AppConstants.pngImageType) {
        if (imageSize < 5) {
          const imageURL = URL.createObjectURL(e.target.files[0]);
          setProfilePic(imageURL);
          setProfilePictureFile(e.target.files[0]);
          // setFormValues({ ...formValues, profilePic: imageURL });
        } else {
          setImageError(ErrorMessages.userProfileMoreThan5mbError);
        }
      } else {
        setImageError(ErrorMessages.userProfileImageTypeError);
      }
    } else {
      setProfilePictureFile(null);
      setProfilePic(defaultImages[0]);
      // setFormValues({ ...formValues, profilePic: '' });
    }
  };

  // Handle default image selection
  const handleDefaultImageClick = (url) => {
    setProfilePic(url);
    setProfilePictureFile(null);
    // setFormValues({ ...formValues, profilePic: url });
  };


  const GenderDropdown = () => {
    // Define options for the dropdown
    const options = [
      { value: 'Male', label: 'Male' },
      { value: 'Female', label: 'Female' },
      { value: 'Other', label: 'Other' },
    ];

    // Handle change event
    const handleChange = (event) => {
      setFormValues({ ...formValues, gender: event.target.value });
    };

    return (
      <div className='col-md-4 col-sm-4 col-6 mb-2 step3Container'>
        <div className={classes.formGroup}>
          <div className={classes.inputWrapper}>
            <Form.Select className={formValues.gender ? classes.formInput : classes.formInputPlaceholder} onChange={handleChange} name="gender" required value={formValues.gender}>
              <option value="">Select Gender</option>
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Select>
            <BiChevronDown className={classes.inputIcon} />
          </div>
          {/* <p className={classes.optionalText}>Optional</p> */}
          {genderErrorMessage && <p className={`${classes.optionalText} errorText`}>Please select a gender</p>}
        </div>
      </div>
    );
  }

  return (
    <div className={classes.form3Container}>

      <p className={classes.chooseProfileText}>Choose your profile</p>

      <div className={classes.imagePreviewContainer}>
        <div className={classes.topHalf}>
          <div className={classes.imagePreviewBox}>
            {profilePic ? <img alt="preview" className={classes.imagePreview} src={profilePic}></img> : <div className={classes.userInitials}><div>{userInitials}</div></div>}
          </div>
          {profilePic && <p onClick={() => setProfilePic(AppConstants.nullText)} className={classes.deleteProfilePicture}>Remove <MdOutlineDelete /></p>}
          {imageError && <p className={`${classes.errorText} errorText`}>{imageError}</p>}
        </div>

        <div className={classes.bottomHalf}>
          <input className={classes.addProfileInput}
            type="file"
            title=""
            name="profilePic" onChange={handleProfileChange}
            id="photo" accept="image/*"
            ref={hiddenFileInput}
          />

          <div className={classes.defaultImageRow}>
            {defaultImages.map((url, index) => (
              <div className={classes.defaultImageContainer}>
                {index === 0 && <img className={classes.selectedImageOverlay} src={profileOverlayPicture} alt="overlay" />}
                <img
                  key={index}
                  src={url}
                  alt={`Default ${index + 1}`}
                  className={classes.defaultImagesShape}
                  onClick={() => handleDefaultImageClick(url)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Default image options */}
      <div className={classes.step3ContainerInput}>
        <GenderDropdown />
      </div>
    </div>
  );

}
