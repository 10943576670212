import { useState, useEffect } from "react";
import { MdClear } from "react-icons/md";
import { AppConstants } from "../../constants/AppConstants";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import { ErrorMessages } from "../../constants/ErrorMessages";
import classes from "./ExpertManageCoursesPage.module.css";
import { ViewWorkshopCards } from "../../component/cards/viewWorkshopCards/ViewWorkshopCards";
import { ViewWorkshopDetailsAccordion } from "../../component/manageWorkshopComponent/ViewWorkshopDetailsAccordion";
import { EditWorkshopComponent } from "../../component/editWorkshopComponent/EditWorkshopComponent";
import { EventStatusNavTab } from "../../component/expertManageCourses/EventStatusNavTabs";

export const ManageWorkshopPage = () => {
    const workshopStatus = AppConstants.workshopStatus;
    const [selectedTab, setSelectedTab] = useState(AppConstants.workshopStatus.upcoming);
    const [upcomingWorkshopSection, setUpcomingWorkshopSection] = useState(AppConstants.trueText);
    const [ongoingWorkshopSection, setOngoingWorkshopSection] = useState(AppConstants.falseText);
    const [pastWorkshopSection, setPastWorkshopSection] = useState(AppConstants.falseText);
    const [showWorkshopDetailsSection, setShowWorkshopDetailsSection] = useState(AppConstants.falseText);
    const [upcomingWorkshopArray, setupcomingWorkshopArray] = useState([]);
    const [ongoingWorkshopArray, setOngoingWorkshopArray] = useState([]);
    const [pastWorkshopArray, setPastWorkshopArray] = useState([]);
    const [filteredWorkshops, setFilteredWorkshops] = useState([]);
    const [filterWorkshopText, setFilterWorkshopText] = useState(AppConstants.emptyString);
    const [selectedWorkshop, setSelectedWorkshop] = useState();
    const [showEditWorkshopSection, setShowEditWorkshopSection] = useState(AppConstants.falseText);

    useEffect(() => {
        //fetch Upcoming workshops
        async function getUpcomingWorkshopData() {
            const url = `${AppConstants.fetchWorkshopsByStatusAPI}${workshopStatus.upcoming}`;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response !== undefined) {
                setupcomingWorkshopArray(response);
                setFilteredWorkshops(response);
            } else {
                setupcomingWorkshopArray([]);
                setFilteredWorkshops([]);
            }
        }
        getUpcomingWorkshopData();
    }, [])

    function changeStatus(status) {
        setSelectedTab(status);
        setFilteredWorkshops([]);
        setFilterWorkshopText(AppConstants.emptyString);
        setShowWorkshopDetailsSection(AppConstants.falseText);
        setShowEditWorkshopSection(AppConstants.falseText);
        setSelectedWorkshop([]);
        if (status === workshopStatus.upcoming) {
            setUpcomingWorkshopSection(AppConstants.trueText);
            setOngoingWorkshopSection(AppConstants.falseText);
            setPastWorkshopSection(AppConstants.falseText);
            async function getUpcomingWorkshopData() {
                const url = `${AppConstants.fetchWorkshopsByStatusAPI}${workshopStatus.upcoming}`;
                const response = await AuthorizedDataService.getRequest(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );
                if (response !== undefined) {
                    setupcomingWorkshopArray(response);
                    setFilteredWorkshops(response);
                } else {
                    setupcomingWorkshopArray([]);
                    setFilteredWorkshops([]);
                }
            }
            getUpcomingWorkshopData();
        } else if (status === workshopStatus.ongoing) {
            setOngoingWorkshopSection(AppConstants.trueText);
            setUpcomingWorkshopSection(AppConstants.falseText);
            setPastWorkshopSection(AppConstants.falseText);
            //fetch ongoing workshops
            async function getOngoingWorkshopData() {
                const url = `${AppConstants.fetchWorkshopsByStatusAPI}${workshopStatus.ongoing}`;
                const response = await AuthorizedDataService.getRequest(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );
                if (response !== undefined) {
                    setOngoingWorkshopArray(response);
                    setFilteredWorkshops(response);
                } else {
                    setOngoingWorkshopArray([]);
                    setFilteredWorkshops([]);
                }
            }
            getOngoingWorkshopData();
        } else if (status === workshopStatus.past) {
            setUpcomingWorkshopSection(AppConstants.falseText);
            setPastWorkshopSection(AppConstants.trueText);
            setOngoingWorkshopSection(AppConstants.falseText);
            //fetch past workshop
            async function getPastWorkshopData() {
                const url = `${AppConstants.fetchWorkshopsByStatusAPI}${workshopStatus.past}`;
                const response = await AuthorizedDataService.getRequest(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );
                if (response !== undefined) {
                    setPastWorkshopArray(response);
                    setFilteredWorkshops(response);
                } else {
                    setPastWorkshopArray([]);
                    setFilteredWorkshops([]);
                }
            }
            getPastWorkshopData();
        }
    };

    const searchWorkshop = (e) => {
        setFilterWorkshopText(e.target.value);
        let workshopsArray;
        if (selectedTab === workshopStatus.upcoming) {
            workshopsArray = upcomingWorkshopArray;
        } else if (selectedTab === workshopStatus.ongoing) {
            workshopsArray = ongoingWorkshopArray
        } else if (selectedTab === workshopStatus.past) {
            workshopsArray = pastWorkshopArray;
        }
        let filteredArray = e.target.value.toLowerCase() !== AppConstants.emptyString
            ? workshopsArray.filter((item) => ((item.workshopTitle.toLowerCase()).includes(e.target.value.toLowerCase()) || (item.workshopId.toLowerCase()).includes(e.target.value.toLowerCase())))
            : workshopsArray;
        setFilteredWorkshops(filteredArray);
    };

    const resetFilterText = () => {
        setFilterWorkshopText(AppConstants.emptyString);
        if (selectedTab === workshopStatus.upcoming) {
            setFilteredWorkshops(upcomingWorkshopArray);
        } else if (selectedTab === workshopStatus.ongoing) {
            setFilteredWorkshops(ongoingWorkshopArray);
        } else if (selectedTab === workshopStatus.past) {
            setFilteredWorkshops(pastWorkshopArray);
        }

    };

    const onClickEdit = async (workshop) => {
        if (workshop.workshopStatus === AppConstants.workshopStatus.past) return;

        setSelectedWorkshop(workshop);
        setShowEditWorkshopSection(AppConstants.trueText);
    };

    const onClickView = async (workshop) => {
        setSelectedWorkshop(workshop);
        setShowWorkshopDetailsSection(AppConstants.trueText);
    }

    const onHideEdit = () => {
        setShowEditWorkshopSection(AppConstants.falseText);
        changeStatus(selectedTab)
        setSelectedWorkshop([]);
    }

    const onHideDetails = () => {
        setSelectedWorkshop([]);
        setShowWorkshopDetailsSection(AppConstants.falseText);
    }

    return (
        <div className="expertContentSection">
            <div className={classes.manageCoursesHeading}>Manage Workshops</div>
            <EventStatusNavTab changeStatus={changeStatus} tabs={AppConstants.courseStatus} />
            {!showWorkshopDetailsSection && !showEditWorkshopSection && <div>
                <div className={classes.inputBoxContainer}>
                    <input type="text" className={classes.searchInputBox} value={filterWorkshopText} onChange={(e) => { searchWorkshop(e) }} placeholder="Search workshop" />
                    {filterWorkshopText.length > 0 && <MdClear className={classes.resetSearchIcon} onClick={() => resetFilterText()} />}
                </div>
                {upcomingWorkshopSection &&
                    <div>
                        {filteredWorkshops.length !== 0 && <ViewWorkshopCards
                            workshopData={filteredWorkshops}
                            onClickView={onClickView}
                            onClickEdit={onClickEdit}
                            workshopStatus={AppConstants.workshopStatus.upcoming}
                        />}
                        {filteredWorkshops.length === 0 && <div>
                            <p className="noDataAvailableError">{ErrorMessages.expertNoUpcomingWorkshopFallbackMessage}</p>
                        </div>}
                    </div>
                }
                {ongoingWorkshopSection && <div>
                    {filteredWorkshops.length !== 0 && <ViewWorkshopCards
                        workshopData={filteredWorkshops}
                        onClickView={onClickView}
                        onClickEdit={onClickEdit}
                        workshopStatus={AppConstants.workshopStatus.ongoing}
                    />}
                    {filteredWorkshops.length === 0 && <div>
                        <p className="noDataAvailableError">{ErrorMessages.expertNoOngoingWorkshopFallbackMessage}</p>
                    </div>}
                </div>}
                {pastWorkshopSection && <div>
                    {filteredWorkshops.length !== 0 && <ViewWorkshopCards
                        workshopData={filteredWorkshops}
                        onClickEdit={onClickEdit}
                        onClickView={onClickView}
                        workshopStatus={AppConstants.workshopStatus.past}
                    />}
                    {filteredWorkshops.length === 0 && <div>
                        <p className="noDataAvailableError">{ErrorMessages.expertNoPastWorkshopFallbackMessage}</p>
                    </div>}
                </div>}
            </div>}

            {showWorkshopDetailsSection && <div className={classes.viewCourseDetailsSection}>
                <div><ViewWorkshopDetailsAccordion selectedTab={selectedTab} selectedWorkshop={selectedWorkshop.uniqueId} onClickHide={onHideDetails} /></div>
            </div>}
            {showEditWorkshopSection && <div><EditWorkshopComponent selectedWorkshop={selectedWorkshop.uniqueId} onHideEdit={onHideEdit} /></div>}
        </div>
    )
}