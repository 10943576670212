import React, { useState, useEffect } from "react";
import { NavLink, useSearchParams, useNavigate } from 'react-router-dom';
import { Button, Form } from "react-bootstrap";
import { RiErrorWarningLine } from "react-icons/ri"
import classes from './SignupAndEnrolInCompetition.module.css';
import { AppConstants } from "../../constants/AppConstants";
import { ErrorMessages } from '../../constants/ErrorMessages';
import { SuccessMessage } from '../../constants/SuccessMessage';
import { DataService } from '../../services/DataService';
import { FaInfo, FaEye, FaEyeSlash, FaEdit, FaCheckCircle } from 'react-icons/fa';
import { BiLoaderAlt } from 'react-icons/bi';
import { Tooltip } from 'react-tooltip';
import formClass from "../../common/FormStyling.module.css"
import OTPInput, { ResendOTP } from "otp-input-react";
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { DateTime } from 'luxon';

export const SignupAndEnrolInCompetition = () => {
    const [showSpinner, setShowSpinner] = useState(false);
    const [showError, setShowError] = useState([]);
    const [showSuccess, setShowSuccess] = useState([]);
    const [showMessageScreen, setShowMessageScreen] = useState(AppConstants.falseText);
    const [mobileVerificationScreen, setMobileVerificationScreen] = useState(AppConstants.falseText);
    const [successUserName, setSuccessUserName] = useState(AppConstants.emptyString);
    const [countriesList, setCountriesList] = useState([]);
    const [callingCode, setCallingCode] = useState([]);
    const [showVerifyMobileText, setShowVerifyMobileText] = useState(AppConstants.falseText);
    const [emailVerified, setEmailVerified] = useState(AppConstants.trueText);
    const [mobileOtp, setMobileOtp] = useState(AppConstants.emptyString);
    const [countryName, setCountryName] = useState("India")
    const [mobileVerified, setMobileVerified] = useState(AppConstants.trueText);
    const [searchParams, setSearchParams] = useSearchParams();
    const [competitionSlug, setCompetitionSlug] = useState(searchParams.get("c"));
    const [userParamEmail, setUserParamEmail] = useState(searchParams.get("u"));
    const [showLoginSpinner, setShowLoginSpinner] = useState(AppConstants.falseText);
    const [enrolmentError, setEnrolmentError] = useState(AppConstants.emptyString);
    const [pageLoader, setPageLoader] = useState(AppConstants.trueText);
    const [isStudentEligible, setIsStudentEligible] = useState(AppConstants.falseText);
    const [competitionName, setCompetitionName] = useState(AppConstants.emptyString);
    const errorPageUrl = AppConstants.forwardSlash + AppConstants.asterisk;
    const [capitalCity, setCapitalCity] = useState("Delhi");
    const navigate = useNavigate();
    const initialValues = { firstName: AppConstants.emptyString, lastName: AppConstants.emptyString, email: atob(searchParams.get("u")), password: AppConstants.emptyString, countryId: AppConstants.countryIdIndia, mobileNumber: AppConstants.emptyString, dob: AppConstants.emptyString, gender: AppConstants.emptyString };
    const [passwordType, setPasswordType] = useState('password');
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(AppConstants.emptyString);
    const [signupMinimumAge, setSignupMinimumAge] = useState(AppConstants.emptyString);
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const qrenciaLogoUrl = `${mediaContent}${AppConstants.logoImageS3Path}/${AppConstants.qrenciaTaglineLogoFile}`;

    if (competitionSlug === AppConstants.emptyString || competitionSlug === AppConstants.nullText || competitionSlug === undefined || userParamEmail === AppConstants.emptyString || userParamEmail === AppConstants.nullText || userParamEmail === undefined) {
        navigate(AppConstants.asterisk);
    }

    const togglePassword = () => {
        if (passwordType === AppConstants.fieldTypePassword) {
            setPasswordType(AppConstants.fieldTypeText);
            return;
        }
        else {
            setPasswordType(AppConstants.fieldTypePassword);
            return;
        }
    }

    const numberInputs = document.querySelectorAll('input[type=number]');
    numberInputs.forEach(input => {
        input.addEventListener('wheel', (e) => e.preventDefault());
    });

    useEffect(() => {
        const checkStudentEligibilityToEnrol = async () => {
            try {
                let url = `${process.env.REACT_APP_API_URL}${AppConstants.checkStudentEligibilityToEnrolPartOneAPI}${competitionSlug}${AppConstants.checkStudentEligibilityToEnrolPartTwoAPI}${encodeURIComponent(initialValues.email)}`;
                const response = await fetch(url)

                if (response.status === AppConstants.httpResponseCodes.responseCode200) {
                    setPageLoader(AppConstants.falseText);
                    setIsStudentEligible(AppConstants.trueText);
                    setShowMessageScreen(AppConstants.falseText);
                } else if (response.status === AppConstants.httpResponseCodes.responseCode409) {
                    setIsStudentEligible(AppConstants.falseText);
                    setPageLoader(AppConstants.falseText);
                    navigate(errorPageUrl);
                } else {
                    setIsStudentEligible(AppConstants.falseText);
                    setShowMessageScreen(AppConstants.trueText);
                    setPageLoader(AppConstants.falseText);
                    setEnrolmentError(`${ErrorMessages.bulkSignupEligibilityError}${AppConstants.space}${competitionName ? `${competitionName}` : "the competition"}`);
                }
            } catch (error) {
                setIsStudentEligible(AppConstants.trueText);
                setShowMessageScreen(AppConstants.falseText);
                setPageLoader(AppConstants.falseText);
            }
        };

        const fetchCompetitionDetails = async () => {
            try {
                const url = `${AppConstants.getIndividualCompetitionPageAPIWithParameter}${competitionSlug}`;
                const response = await DataService.get(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );

                if (response !== undefined) {
                    setCompetitionName(response.competitionTitle);
                } else {
                    setCompetitionName(AppConstants.emptyString);
                }
            } catch {
                setCompetitionName(AppConstants.emptyString);
            }
        }
        async function fetchCountries() {
            const url = AppConstants.fetchCountriesAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            setCountriesList(response);
            setSelectedCountry(response.filter(country => country.countryCode === "IN")[0]);
            return null;
        };
        fetchCountries();

        checkStudentEligibilityToEnrol();
        fetchCompetitionDetails();

        const fetchConfigurableKeys = async () => {
            const url = AppConstants.fetchConfigurableKeysAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setSignupMinimumAge(parseInt(response.find(item => item.keyName === AppConstants.signupMinAgeKey).keyValue));
            }
        };

        fetchConfigurableKeys();

    }, []);

    //display calling on selecting country
    const onSelectingCountry = (e) => {
        setCallingCode(countriesList.filter((item) => item.id.toString() === e.target.value));
    }

    //display calling on selecting country
    const handleChangeMobileNumber = (e) => {
        setCallingCode(countriesList.filter((item) => item.id.toString() === formValues.countryId));
    };

    const changeCountryCapital = (e) => {
        let country = countriesList.filter((item) => item.id.toString() === e.target.value);
        setCapitalCity(country[0].capitalCity.trim());
        setCountryName(country[0].country.trim());
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "dob") {
            setFormValues({ ...formValues, [name]: value?.replace(/(\d{4})-(\d{2})-(\d{2})/, '$1-$2-$3') });
        } else {
            setFormValues({ ...formValues, [name]: value });
        }
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === AppConstants.zeroIndex && isSubmit) {
            async function postUserDetails() {
                setShowSpinner(true);
                const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
                const trimmedFormValues = { ...formValues, competitionSlug: competitionSlug, capitalCity: capitalCity, locale: timeZone, countryName: countryName };
                trimmedFormValues.firstName = trimmedFormValues.firstName.trim();
                trimmedFormValues.lastName = trimmedFormValues.lastName.trim();

                const url = AppConstants.signUpAndEnrolInCompetitionAPI;
                const data = trimmedFormValues;
                const response = await DataService.post(
                    url,
                    data,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );

                if (!response.ok) {
                    if (response.status === AppConstants.httpResponseCodes.responseCode409) {
                        setShowError(ErrorMessages.signupDuplicateEmailError);
                        setShowSuccess(AppConstants.emptyString);
                        setShowSpinner(false);
                        const timer = setTimeout(() => {
                            setShowError(AppConstants.emptyString);
                        }, AppConstants.messageDisappearTime);
                        return () => clearTimeout(timer);
                    }
                    else if (response.status === AppConstants.httpResponseCodes.responseCode500) {
                        // details could not be fetched
                        setShowError(ErrorMessages.signupError);
                        setShowSuccess(AppConstants.emptyString);
                        setShowSpinner(false);
                        const timer = setTimeout(() => {
                            setShowError(AppConstants.emptyString);
                        }, AppConstants.messageDisappearTime);
                        return () => clearTimeout(timer);
                    } else if (response.status === AppConstants.httpResponseCodes.responseCode502) {
                        // competition has started
                        setEnrolmentError(`${ErrorMessages.bulkSignupCompetitionStartedError}${AppConstants.space}${competitionName ? `${competitionName}` : "the competition"}. It has already Started`);
                        setShowMessageScreen(AppConstants.trueText);
                        setShowError(AppConstants.emptyString);
                        setShowSpinner(AppConstants.falseText);
                        setSuccessUserName(formValues.firstName);
                        setIsSubmit(AppConstants.falseText);
                    } else if (response.status === AppConstants.httpResponseCodes.responseCode504) {
                        // seats full
                        setEnrolmentError(`${ErrorMessages.bulkSignupSeatsFullError}${AppConstants.space}${competitionName ? `${competitionName}` : "the competition"}`);
                        setShowMessageScreen(AppConstants.trueText);
                        setShowError(AppConstants.emptyString);
                        setShowSpinner(AppConstants.falseText);
                        setSuccessUserName(formValues.firstName);
                        setIsSubmit(AppConstants.falseText);
                    } else if (response.status === AppConstants.httpResponseCodes.responseCode406) {
                        // registration has been closed 
                        setEnrolmentError(`${ErrorMessages.bulkSignupCompetitionStartedError}${AppConstants.space}${competitionName ? `${competitionName}` : "the competition"} as the registrations are closed`);
                        setShowMessageScreen(AppConstants.trueText);
                        setShowError(AppConstants.emptyString);
                        setShowSpinner(AppConstants.falseText);
                        setSuccessUserName(formValues.firstName);
                        setIsSubmit(AppConstants.falseText);
                    } else if (response.status === 400) {
                        // Age limit check
                        const body = await response.text();
                        setEnrolmentError(body);
                        setShowMessageScreen(AppConstants.trueText);
                        setShowError(AppConstants.emptyString);
                        setShowSpinner(AppConstants.falseText);
                        setSuccessUserName(formValues.firstName);
                        setIsSubmit(AppConstants.falseText);
                    } else {
                        setShowError(ErrorMessages.signupError);
                        setShowSuccess(AppConstants.emptyString);
                        setShowSpinner(false);
                        const timer = setTimeout(() => {
                            setShowError(AppConstants.emptyString);
                        }, AppConstants.messageDisappearTime);
                        return () => clearTimeout(timer);
                    }
                }
                else if (response.ok && response.status === AppConstants.httpResponseCodes.responseCode200) {
                    setShowSuccess(SuccessMessage.signupSuccess);
                    setShowMessageScreen(AppConstants.trueText);
                    setShowError(AppConstants.emptyString);
                    setShowSpinner(AppConstants.falseText);
                    setSuccessUserName(formValues.firstName);
                    setIsSubmit(AppConstants.falseText);
                    const slug = await response.text();
                    loginUser(formValues.email, formValues.password, `${AppConstants.competitions}${AppConstants.forwardSlash}${slug}`);
                }
                return null;
            };
            postUserDetails();
        }
    }, [formErrors]);

    const validate = (values) => {
        const errors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const nameRegex = /^[a-zA-Z ]*$/;
        const indianMobileRegex = /^\d{10}$/;
        const currentDate = new Date();
        currentDate.setHours(AppConstants.zeroIndex, AppConstants.zeroIndex, AppConstants.zeroIndex, AppConstants.zeroIndex);
        const dob = new Date(values.dob);
        dob.setHours(AppConstants.zeroIndex, AppConstants.zeroIndex, AppConstants.zeroIndex, AppConstants.zeroIndex);
        if (!values.firstName.trim()) {
            errors.firstName = ErrorMessages.invalidUserFirstName;
        }
        if (!values.lastName.trim()) {
            errors.lastName = ErrorMessages.invalidUserLastName;
        }
        if (!nameRegex.test(values.firstName)) {
            errors.firstName = ErrorMessages.invalidUserFirstNameWithRegex;
        }
        if (!nameRegex.test(values.lastName)) {
            errors.lastName = ErrorMessages.invalidUserLastNameWithRegex;
        }
        if (values.gender === AppConstants.emptyString) {
            errors.gender = ErrorMessages.invalidUserGender;
        }
        if (!emailRegex.test(values.email)) {
            errors.email = ErrorMessages.invalidEmailFormat;
        } else {
            if (!emailVerified) {
                errors.email = ErrorMessages.signupVerifyEmailError;
            }
        }
        if (!values.password.trim()) {
            errors.password = ErrorMessages.invalidPassword;
        }
        if (values.countryId === AppConstants.countryIdIndia) {
            if (!indianMobileRegex.test(values.mobileNumber)) {
                errors.mobileNumber = ErrorMessages.invalidMobileIndian;
            } else {
                if (!mobileVerified) {
                    errors.mobileNumber = ErrorMessages.signupVerifyMobileError;
                }
            }
        } else if (values.countryId !== AppConstants.countryIdIndia) {
            if (values.mobileNumber.length < AppConstants.nonIndianMinMobileNumber || values.mobileNumber.length > AppConstants.nonIndianMaxMobile) {
                errors.mobileNumber = ErrorMessages.invalidMobileNonIndian;
            } else {
                if (!mobileVerified) {
                    errors.mobileNumber = ErrorMessages.signupVerifyMobileError;
                }
            }
        }
        if (values.dob !== AppConstants.emptyString && values.dob !== AppConstants.nullText && values.dob !== undefined) {
            if (new Date(values.dob) >= currentDate) {
                errors.dob = ErrorMessages.signupDateOfBirthGreaterThanToday;
            }
            else if (DateTime.fromJSDate(new Date(currentDate)).diff(DateTime.fromJSDate(new Date(dob)), 'years').years < signupMinimumAge) {
                if (signupMinimumAge === 1) {
                    errors.dob = ErrorMessages.signupDateOfBirthLessThanAllowedYears + signupMinimumAge + " year" + ErrorMessages.signupDateOfBirthLessThanAllowedYearsPart2;
                } else {
                    errors.dob = ErrorMessages.signupDateOfBirthLessThanAllowedYears + signupMinimumAge + " years" + ErrorMessages.signupDateOfBirthLessThanAllowedYearsPart2;
                }
            }
        } else {
            errors.dob = ErrorMessages.signupInvalidDateOfBirth;
        }
        return errors;
    };

    const registerUser = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(AppConstants.trueText);
    };

    const loginUser = async (userName, password, slug) => {
        try {
            const authData = {
                userName: userName,
                userPassword: password
            }

            const resp = await DataService.post(
                AppConstants.authenticationUri,
                authData,
                AppConstants.emptyString
            );

            const body = await resp.json();

            if (body !== AppConstants.nullText) {
                const token = body.accessToken;
                if (token !== AppConstants.nullText && token !== undefined) {
                    localStorage.setItem(AppConstants.sessionKeyAccesToken, token);
                    localStorage.setItem(AppConstants.sessionKeyUserDetails, JSON.stringify(body));
                    setTimeout(function () {
                        window.location.href = slug;
                    }, 5000);
                    // Delay of 5000 milliseconds (5 seconds)
                }
            }
        } catch (error) {
            setShowLoginSpinner(AppConstants.falseText);
        }
    }

    //show verify mobile screen
    // async function handleVerifyMobile() {
    //     const url = AppConstants.signupPageGenerateMobileOtpAPI;
    //     const mobileWithCountryCode = callingCode[AppConstants.zeroIndex]?.countryCallingCode + formValues.mobileNumber;
    //     const data = { "mobileNumber": mobileWithCountryCode?.replace("+", "") };
    //     const response = await DataService.post(
    //         url,
    //         data,
    //         AppConstants.emptyString,
    //         AppConstants.emptyString
    //     );
    //     if (response?.status === AppConstants.httpResponseCodes.responseCode201) {
    //         setMobileVerificationScreen(AppConstants.trueText);
    //         setFormErrors(formErrors.errors = { mobileNumber: AppConstants.emptyString });
    //         setMobileOtp(AppConstants.emptyString);
    //     }
    //     if (response?.status === AppConstants.httpResponseCodes.responseCode200) {
    //         setMobileVerified(AppConstants.trueText);
    //         setFormErrors(formErrors.errors = { mobileNumber: AppConstants.emptyString });
    //     }
    //     if (response?.status === AppConstants.httpResponseCodes.responseCode500) {
    //         setFormErrors(formErrors.errors = { mobileNumber: ErrorMessages.signupGenerateMobileOtpError });
    //     }
    // }

    //show signup form
    function backToForm() {
        setMobileVerificationScreen(AppConstants.falseText);
    }

    //handle mobile change for verification
    function handleMobileChange(e) {
        const value = e.target.value;
        const indianMobileRegex = /^\d{10}$/;
        if (formValues?.countryId === AppConstants.countryIdIndia) {
            if (indianMobileRegex.test(value)) {
                setMobileVerified(AppConstants.trueText);
                // setShowVerifyMobileText(AppConstants.trueText);
                // setMobileVerified(AppConstants.falseText);
            } else {
                setMobileVerified(AppConstants.trueText);
                // setShowVerifyMobileText(AppConstants.falseText);
                // setMobileVerified(AppConstants.falseText);
            }
        } else if (formValues?.countryId !== AppConstants.countryIdIndia) {
            if (value?.length >= AppConstants.nonIndianMinMobileNumber && value?.length <= AppConstants.nonIndianMaxMobile) {
                setMobileVerified(AppConstants.trueText);
                // setShowVerifyMobileText(AppConstants.trueText);
                // setMobileVerified(AppConstants.falseText);
            } else {
                setMobileVerified(AppConstants.trueText);
                // setShowVerifyMobileText(AppConstants.falseText);
                // setMobileVerified(AppConstants.falseText);
            }
        }
    }

    const validateMobileNumber = (event) => {
        let excludedKeywords = ["-", "e", "+", "E", ".", AppConstants.emptyString];
        if (excludedKeywords.includes(event.key)) event.preventDefault();
    }

    //on country change handle
    useEffect(() => {
        function handleCountryChange() {
            if (formValues?.mobileNumber) {
                const indianMobileRegex = /^\d{10}$/;
                if (formValues?.countryId === AppConstants.countryIdIndia) {
                    if (indianMobileRegex.test(formValues?.mobileNumber)) {
                        setMobileVerified(AppConstants.trueText);
                        // setShowVerifyMobileText(AppConstants.trueText);
                        // setMobileVerified(AppConstants.falseText);
                    } else {
                        setMobileVerified(AppConstants.trueText);
                        // setShowVerifyMobileText(AppConstants.falseText);
                        // setMobileVerified(AppConstants.falseText);
                    }
                } else if (formValues?.countryId !== AppConstants.countryIdIndia) {
                    if (formValues?.mobileNumber?.length >= AppConstants.nonIndianMinMobileNumber && formValues?.mobileNumber?.length <= AppConstants.nonIndianMaxMobile) {
                        setMobileVerified(AppConstants.trueText);
                        // setShowVerifyMobileText(AppConstants.trueText);
                        // setMobileVerified(AppConstants.falseText);
                    } else {
                        setMobileVerified(AppConstants.trueText);
                        // setShowVerifyMobileText(AppConstants.falseText);
                        // setMobileVerified(AppConstants.falseText);
                    }
                }
            }
        }
        handleCountryChange();
    }, [formValues?.countryId])

    //resend OTP button
    const renderButton = buttonProps => {
        return (
            <button className={classes.resendOtpButton} {...buttonProps}>
                {buttonProps.remainingTime !== AppConstants.zeroIndex
                    ? `Resend code (in ${buttonProps.remainingTime} sec)`
                    : "Resend"}
            </button>
        )
    }
    const renderTime = () => React.Fragment;


    //verify mobile OTP
    // useEffect(() => {
    //     async function handleVerifyMobileOtp() {
    //         if (mobileOtp?.length === 6) {
    //             const url = AppConstants.signupPageVerifyMobileAPI;
    //             const mobileWithCountryCode = callingCode[AppConstants.zeroIndex]?.countryCallingCode + formValues.mobileNumber;
    //             const data = { "mobileNumber": mobileWithCountryCode?.replace("+", ""), "otp": mobileOtp };
    //             const response = await DataService.post(
    //                 url,
    //                 data,
    //                 AppConstants.emptyString,
    //                 AppConstants.emptyString
    //             );
    //             if (response?.status === AppConstants.httpResponseCodes.responseCode200) {
    //                 setMobileOtp(AppConstants.emptyString);
    //                 setMobileVerificationScreen(AppConstants.falseText);
    //                 setMobileVerified(AppConstants.trueText);
    //                 setFormErrors(formErrors.errors = { mobileOtpError: AppConstants.nullText });
    //             }
    //             if (response?.status === AppConstants.httpResponseCodes.responseCode409) {
    //                 setMobileVerificationScreen(AppConstants.trueText);
    //                 setMobileVerified(AppConstants.falseText);
    //                 setFormErrors(formErrors.errors = { mobileOtpError: ErrorMessages.signupEmailOtpVerifyError });
    //             }
    //             if (response?.status === AppConstants.httpResponseCodes.responseCode500) {
    //                 setMobileVerificationScreen(AppConstants.trueText);
    //                 setMobileVerified(AppConstants.falseText);
    //                 setFormErrors(formErrors.errors = { mobileOtpError: ErrorMessages.signupEmailOtpVerifyError });
    //             }
    //         }
    //     }
    //     handleVerifyMobileOtp();
    // }, [mobileOtp])

    return (
        <>
            {(!showMessageScreen && !pageLoader) &&
                <div className={classes.signUpFormSection}>
                    <div>
                        {/* <h1 className={classes.formHeading}>{AppConstants.signupHeadingText}</h1> */}
                        <div className={classes.qrenciaLogoSection}>
                            <NavLink to="/">
                                <img
                                    className={classes.qrenciaLogo}
                                    src={qrenciaLogoUrl}
                                    alt="Qrencia Logo"
                                />
                            </NavLink>
                        </div>
                        <h1 className={classes.formHeading}>{AppConstants.signupHeadingText}</h1>
                        <form id="signUpForm" className={classes.formSection} onSubmit={registerUser}>
                            <div className={classes.searchFormElementsSection}>
                                <div className={classes.nameSection}>
                                    <div className={classes.nameField}>
                                        <div className={classes.inputContainer}>
                                            <Form.Label className={`${formClass.formLabel} ${classes.inputLabel}`}>{AppConstants.enterFirstNameLabelText}</Form.Label>
                                            <Form.Control className={`${formClass.formInput} ${classes.formInputBox}`} name="firstName" value={formValues.firstName} onChange={handleChange} minLength={3} maxLength={40} type="text" required />
                                        </div>
                                        <p className={classes.errorMessage}>{formErrors.firstName}</p>
                                    </div>
                                    <div className={classes.nameField}>
                                        <div className={classes.inputContainer}>
                                            <Form.Label className={`${formClass.formLabel} ${classes.inputLabel}`}>{AppConstants.enterLastNameLabelText}</Form.Label>
                                            <Form.Control name="lastName" value={formValues.lastName} onChange={handleChange} minLength={3} maxLength={40} className={`${formClass.formInput} ${classes.formInputBox}`} type="text" required />
                                        </div>
                                        <p className={classes.errorMessage}>{formErrors.lastName}</p>
                                    </div>
                                </div>
                                <div className={classes.nameSection}>
                                    <div className={classes.nameField}>
                                        <div className={`${classes.inputContainer} ${formClass.dateTimeInput}`}>
                                            <Form.Label className={`${formClass.formLabel} ${classes.inputLabel}`}>{AppConstants.enterDobSignup} <span data-tooltip-place="top" data-tooltip-id="dobTooltip"><FaInfo className={classes.iButton} /></span></Form.Label>
                                            <Form.Control name="dob" value={formValues.dob} onChange={handleChange} className={`${formClass.formInput} ${classes.formInputBox}`} type="date" required />
                                            <Tooltip className={classes.tooltipMessage} id="dobTooltip"><p>{AppConstants.iButtonMessageForDOB}</p></Tooltip>
                                        </div>
                                        <p className={classes.errorMessage}>{formErrors.dob}</p>
                                    </div>
                                    <div className={classes.nameField}>
                                        <div className={classes.inputContainer}>
                                            <Form.Label className={`${formClass.formLabel} ${classes.inputLabel}`}>Gender</Form.Label>
                                            <Form.Select className={formClass.selectInput} value={formValues.gender} onChange={handleChange} name="gender">
                                                <option value={AppConstants.emptyString}>Please select gender</option>
                                                {AppConstants.gender.map((gender) => (
                                                    <option value={gender} key={gender}>{gender}</option>
                                                ))}
                                            </Form.Select>
                                        </div>
                                        <p className={classes.errorMessage}>{formErrors.gender}</p>
                                    </div>
                                </div>

                                {<div className={`${classes.inputContainer} ${classes.positionRelative}`}>
                                    <Form.Label className={`${formClass.formLabel} ${classes.inputLabel}`}>{AppConstants.enterEmailLabelText}
                                        <span data-tooltip-place="top" data-tooltip-id="emailToolTip"><FaInfo className={classes.iButton} /></span></Form.Label>
                                    <Tooltip className={classes.tooltipMessage} id="emailToolTip"><p>{AppConstants.iButtonMessageForEmail}</p></Tooltip>
                                    <Form.Control name="email" value={formValues.email} className={`${formClass.formInput} ${classes.formInputBox}`} type="email" required disabled={emailVerified} />
                                    <div className={classes.verifyOtpText}>{emailVerified && <p><FaCheckCircle color="green" fontSize="1.2em" /></p>}</div>
                                </div>}
                                {<p className={classes.errorMessage}>{formErrors.email}</p>}

                                {<div className={`${classes.inputContainer} ${classes.positionRelative}`}>
                                    <Form.Label className={`${formClass.formLabel} ${classes.inputLabel}`}>{AppConstants.enterPasswordLabelText}</Form.Label>
                                    <Form.Control name="password" value={formValues.password} onChange={handleChange} className={`${formClass.formInput} ${classes.formInputBox}`} minLength={8} maxLength={16} type={passwordType} required />
                                    <div className={classes.showHidePasswordIcon}>{passwordType === AppConstants.fieldTypePassword ? <FaEye onClick={togglePassword} /> : <FaEyeSlash onClick={togglePassword} />}</div>
                                </div>}
                                {<p className={classes.errorMessage}>{formErrors.password}</p>}
                                {<div className={classes.selectCountry}>
                                    <Form.Label className={`${formClass.formLabel} ${classes.inputLabel}`}>{AppConstants.selectCountryLabelText}</Form.Label>
                                    <Form.Select className={`${formClass.selectInput} ${classes.formInputBox}`}
                                        value={formValues.countryId} name="countryId"
                                        selected={selectedCountry}
                                        onChange={e => { handleChange(e); onSelectingCountry(e); changeCountryCapital(e); }}
                                        required>
                                        <option value="" defaultValue></option>
                                        {countriesList.map((countries) => (
                                            <option value={countries.id} key={countries.id}>{countries.country}</option>
                                        ))}
                                    </Form.Select>
                                </div>}
                                {!mobileVerificationScreen && <div className={`${classes.inputContainer} ${classes.positionRelative}`}>
                                    <label className={`${classes.mobileLabel} ${formClass.formLabel} ${classes.inputLabel}`}>{AppConstants.enterMobileText}
                                        <span data-tooltip-place="top" data-tooltip-id="mobileToolTip"><FaInfo className={classes.iButton} /></span></label>
                                    <Tooltip className={classes.tooltipMessage} id="mobileToolTip"><p>{AppConstants.iButtonMessageForMobile}</p></Tooltip>
                                    <div className={`${classes.unitInput}`} >
                                        <span className={`${classes.unitInputPrepend} ${callingCode.length === AppConstants.zeroIndex ? classes.displayNone : classes.unitInputPrepend}`}>{callingCode.length === AppConstants.zeroIndex ? null : callingCode[AppConstants.zeroIndex].countryCallingCode}</span>
                                        <input name="mobileNumber" value={formValues.mobileNumber} onKeyUp={handleMobileChange} onChange={e => { handleChange(e); handleChangeMobileNumber(e) }} onKeyDown={validateMobileNumber} min={0} step={1} className={`${classes.formInputBox} ${classes.unitInputBox}`} type="number" required />
                                    </div>
                                    {/* <div className={classes.verifyOtpText}>{!mobileVerified && showVerifyMobileText && <p onClick={handleVerifyMobile}>Verify</p>} {mobileVerified && <p><FaCheckCircle color="green" fontSize="1.2em" /></p>}</div> */}
                                </div>}
                                {!mobileVerificationScreen && <p className={classes.errorMessage}>{formErrors.mobileNumber}</p>}

                                {/* {mobileVerificationScreen && <div>
                                    <Form.Label className={`${formClass.formLabel} ${classes.inputLabel}`}>{AppConstants.enterMobileText}</Form.Label>
                                    <div className={classes.verifyEmailContainer}>
                                        <div className={classes.emailContainer}>
                                            <p className={classes.emailDisabledText}>{callingCode[AppConstants.zeroIndex]?.countryCallingCode + "-" + formValues.mobileNumber}</p>
                                            <div onClick={backToForm} className={classes.editEmailText}><FaEdit /> Edit</div>
                                        </div>
                                        <div className={`${classes.emailOtpInputContainer}`}>
                                            <OTPInput
                                                value={mobileOtp}
                                                onChange={setMobileOtp}
                                                autoFocus
                                                OTPLength={6}
                                                otpType="number"
                                                disabled={false}
                                                className={classes.emailOtpInputBox}
                                            />
                                            <ResendOTP onResendClick={() => handleVerifyMobile()} renderButton={renderButton} renderTime={renderTime} />
                                        </div>
                                        {formErrors.mobileOtpError ? <p className={classes.errorMessage}>{formErrors.mobileOtpError}</p> : <p className={classes.otpInfoText}>Please enter 6 digit code sent to your mobile number</p>}
                                    </div>
                                </div>} */}

                                {!mobileVerificationScreen && <div>
                                    {showSpinner && <Button className={classes.signupButton}><BiLoaderAlt className={classes.spinner} /></Button>}
                                    {!showSpinner && <Button type="submit" value="Submit" className={classes.signupButton}>{AppConstants.signUpButtonText}</Button>}
                                </div>}
                                <div>
                                    <p className={`${showError.length === AppConstants.zeroIndex ? classes.displayNone : classes.errorMessageText}`}>{showError}</p>
                                    <p className={`${showSuccess.length === AppConstants.zeroIndex ? classes.displayNone : classes.successMessageText}`}>{showSuccess}</p>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div>
                        {!mobileVerificationScreen && <div className={classes.tncTextContainer}>
                            <p className={classes.tncText}>By Signing Up, you agree to Qrencia <NavLink target="_blank" className={classes.tncTextHyper} to={AppConstants.termsAndConditions}>Terms & Conditions</NavLink>
                                &nbsp;and <NavLink target="_blank" className={classes.tncTextHyper} to={AppConstants.privacyPolicy}>Privacy Policy</NavLink></p>
                        </div>}
                    </div>
                </div>
            }

            {(showMessageScreen && !pageLoader) &&
                <div className={classes.successScreenContainer}>
                    {enrolmentError.length === AppConstants.zeroIndex && <p className={classes.signupSuccessSalutation}>{SuccessMessage.signupSuccessSalutation1} <span className={classes.signupUsername}>{successUserName}</span>{SuccessMessage.signupSuccessSalutation2} </p>}
                    {enrolmentError.length === AppConstants.zeroIndex && <p className={classes.signupSuccessSubHeading}>Thanks for signing up. You have been successfully enrolled in {competitionName ? `${competitionName}` : "the competition"}.</p>}
                    {enrolmentError.length === AppConstants.zeroIndex && <p className={`${classes.tncText} ${classes.signupSuccessSubtitle}`}>Please wait while we take you to the competition page < BiLoaderAlt className="spinner" /></p>}
                    {enrolmentError.length > AppConstants.zeroIndex && <p className={classes.signupSuccessSubHeading}></p>}
                    {enrolmentError.length > AppConstants.zeroIndex && <p className={classes.signupSuccessSalutation}><RiErrorWarningLine /></p>}
                    {/* {enrolmentError.length > AppConstants.zeroIndex && <p className={classes.signupSuccessSubHeading}>Dear Learner,</p>} */}
                    {enrolmentError.length > AppConstants.zeroIndex && <p className={classes.signupSuccessSubHeading}>{enrolmentError}</p>}
                    {enrolmentError.length > AppConstants.zeroIndex && <div className={classes.loginTextContainer}>
                        <p className={classes.tncText}>Please <NavLink className={classes.tncTextHyper} to={AppConstants.signup} >signup</NavLink> to explore more competitions on Qrencia</p>
                    </div>}
                </div>}
            {(pageLoader) && <div className={classes.loaderContainer}>
                <div> <BiLoaderAlt className="spinner" /></div>
            </div>}
        </>
    );

}