import { useState, useEffect } from "react";
import { StudentRecentlyPublishedCompetitionCards } from "../../../cards/StudentRecentlyPublishedCompetitionCards";
import { AppConstants } from "../../../../constants/AppConstants";
import { AuthorizedDataService } from "../../../../services/AuthorizedDataService";
import { DateTimeUtil } from "../../../../utils/DateTimeUtil";

export const StudentRecentlyPublishedCompetitions = (props) => {
    const [competitions, setCompetitions] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const preferredTimeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const zeroIndex = 0;

    useEffect(() => {
        async function fetchRecentlyPublishedCompetitions() {
            const url = `${AppConstants.getRecentlyPublishedCompetitionsForStudentAPI}`;
            const requestHeaders = {
                preferredTimeZone
            }
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString,
                requestHeaders
            );
            setCompetitions(response);
            setFilteredList(response);
            return null;
        }

        if (props.categoryList?.length > zeroIndex) {
            fetchRecentlyPublishedCompetitions();
        }
    }, [props.categoryList]);

    useEffect(() => {
        if (props.selectedCategory) {
            if (props.selectedCategory === AppConstants.AllText) {
                setFilteredList(competitions);
            } else {
                setFilteredList(competitions.filter(competition => competition.categoryName === props.selectedCategory));
            }
        } else {
            setFilteredList(competitions);
        }
    }, [props.selectedCategory]);

    return (
        <>
            {filteredList?.length > 0 && <StudentRecentlyPublishedCompetitionCards minimumAgeLimit={props.minimumAgeLimit} cardDetails={filteredList} category="Recently Published" />}
            {(!filteredList || filteredList?.length === 0) && props.selectedCategory !== AppConstants.emptyString && props.selectedCategory !== AppConstants.AllText && <p className='noDataAvailableError'>{`No recently published competitions for skill category '${props.selectedCategory}' to display at the moment`}</p>}
            {(!filteredList || filteredList?.length === 0) && (props.selectedCategory === AppConstants.emptyString || props.selectedCategory === AppConstants.AllText) && <p className="noDataAvailableError">No recently published competitions to display at the moment</p>}
        </>
    )
}