import { useEffect, useState, useRef } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import commonStyles from "../../../common/NudgesDetailsSectionStyle.module.css";
import { AppConstants } from "../../../constants/AppConstants";
import ReactQuill, { Quill } from "react-quill";
import Moment from "react-moment";
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import parse from 'html-react-parser';
import { AuthorizedDataService } from "../../../services/AuthorizedDataService";
import { BiLoaderAlt } from "react-icons/bi";
import { AiFillStar, AiOutlineExclamationCircle, AiOutlineStar } from "react-icons/ai";
import { FaCalendarAlt, FaCalendarCheck, FaPaperPlane, FaUserTie } from "react-icons/fa";
import { ErrorSuccessAlertMessage } from "../../errorSuccessMessage/ErrorSuccessAlertMessage";
import { SuccessMessage } from "../../../constants/SuccessMessage";
import { ErrorMessages } from "../../../constants/ErrorMessages";
import Auth from "../../../utils/Auth";
import formClass from "../../../common/FormStyling.module.css";
import "../../formControls/StudentRaiseNudgeForm.css"
import { FileOperationsUtil } from "../../../utils/FileOperationsUtil";
import { FileUploadInput } from "../../fileUploadInput/FileUploadInput";

export const ExpertNudgeDetailsSection = (props) => {
    const nudgeDetails = props.nudgesData;
    const [expertPreviousResponsesArray, setExpertPreviousResponsesArray] = useState([]);
    const [responseText, setResponseText] = useState(AppConstants.emptyEditorString);
    const reactQuillRef = useRef();
    const [responseError, setResponseError] = useState(false);
    const [responseErrorMessage, setResponseErrorMessage] = useState(false);
    const [submitSpinner, setSubmitSpinner] = useState(false);
    const [submitError, setSubmitError] = useState(false);
    const [submitErrorMessage, setSubmitErrorMessage] = useState(AppConstants.emptyString);
    const [submitErrorVarient, setSubmitErrorVarient] = useState(AppConstants.emptyString);
    const [rating, setRating] = useState(4);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const [nudgeFile, setNudgeFile] = useState(AppConstants.emptyString);
    const [nudgeFileError, setNudgeFileError] = useState(AppConstants.emptyString);
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const Link = Quill.import('formats/link');
    Link.sanitize = function (url) {
        try {
            // quill by default creates relative links if scheme is missing.
            if (!url.startsWith(`${AppConstants.internetProtocols.http}://`) && !url.startsWith(`${AppConstants.internetProtocols.https}://`)) {
                return `${AppConstants.internetProtocols.http}://${url}`
            }
            return url;
        } catch (e) {
            return url;
        }
    }


    useEffect(() => {
        //set rating
        if (nudgeDetails.rating !== null) {
            const expertRating = parseInt(nudgeDetails.rating.substr(0, 1))
            setRating(expertRating);
        } else {
            setRating(null);
        }

        // get experts responses
        async function getExpertResponses() {
            const url = `${AppConstants.getExpertsResponsesAPIWithParam}${nudgeDetails.uniqueId}`;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response !== undefined) {
                setExpertPreviousResponsesArray(response);
            } else {
                setExpertPreviousResponsesArray([]);
            }
        }
        getExpertResponses();
    }, [refreshFlag]);

    //handle description change
    const handleTextChange = (value) => {
        setResponseText(value);
    }

    //handle file change
    const handleUploadFileChange = (e) => {
        var fileTypeArray = AppConstants.executableFileTypes;
        if (e.target.files[0] !== undefined) {
            const name = e.target.files[0].name;
            const lastDot = name.lastIndexOf('.');
            const fileName = name.substring(0, lastDot);
            const ext = name.substring(lastDot + 1);

            if ((fileTypeArray.includes(ext.toUpperCase()))) {
                document.getElementById("nudgeFile").value = null;
                setNudgeFile(null);
                setNudgeFileError(ErrorMessages.executableFileUploadError);
            } else {
                setNudgeFile(e.target.files[0]);
                setNudgeFileError(AppConstants.emptyString);
            }
        } else {
            setNudgeFile(null);
            setNudgeFileError(AppConstants.emptyString);
        }
    }

    const removeFile = () => {
        let input = document.getElementById("nudgeFile");
        input.value = AppConstants.emptyString;
        input.type = AppConstants.inputFieldTypes.file;
        setNudgeFile(null)
    }

    //submit nudge response
    async function submitNudgeResponse(e) {
        e.preventDefault();
        setResponseError(false);
        let isSubmit = true;
        setSubmitSpinner(true);
        const regex = /<(.|\n)*?>/g;
        if (responseText === undefined || responseText === null || responseText.replace(regex, '').trim().length === 0) {
            setSubmitSpinner(false);
            setResponseError(true);
            setResponseErrorMessage(ErrorMessages.expertRespondToNudgeInvalidResponseError)
            isSubmit = false;
        }
        if (nudgeFile !== null && nudgeFile !== undefined && nudgeFile !== AppConstants.emptyString) {
            let fileName = `${AppConstants.expertNudgeResponseFeedbackText}${AppConstants.dot}${nudgeFile.name.split(".").pop()}`;
            const contentDisposition = `attachment;filename="${AppConstants.expertNudgeResponseFeedbackText}${AppConstants.underscore}${Auth.getLoggedInUserDetails().userId}${AppConstants.dot}${nudgeFile.name.split(".").pop()}"`
            const path = `${AppConstants.nudgesTextForUploadFilePath}${AppConstants.forwardSlash}${DateTimeUtil.fetchCurrentTimestamp()}${AppConstants.hyphen}${Auth.getLoggedInUserDetails().userId}${AppConstants.hyphen}${fileName}`;
            const result = await FileOperationsUtil.uploadFileThroughPreSignedURL(nudgeFile, path, AppConstants.s3filePermissionPrivate, contentDisposition);
            if (result.objectKey !== undefined) {
                var nudgeFilePath = result.objectKey;
            } else {
                nudgeFilePath = null;
            }
        }

        if (isSubmit) {
            const formValues = {
                response: responseText,
                nudgeUniqueId: nudgeDetails.uniqueId,
                nudgeResponseFilePath: nudgeFilePath
            }
            const url = AppConstants.expertRevertNudgeAPI;
            const data = formValues;
            const response = await AuthorizedDataService.postRequest(
                url,
                data,
                AppConstants.emptyString,
                AppConstants.emptyString
            ).then(response => {
                setSubmitSpinner(false);
                if (response !== undefined) {
                    if (response.status === AppConstants.httpResponseCodes.responseCode201) {
                        setSubmitError(true);
                        setSubmitErrorVarient(AppConstants.alertVarient[0]);
                        setSubmitErrorMessage(SuccessMessage.expertRevertToNudgeSuccess);
                        setTimeout(() => setSubmitError(false), AppConstants.messageDisappearTime);
                        //clear form values
                        document.getElementById("revertToNudgeForm").reset();
                        setResponseText(null);
                        nudgeFilePath = null;
                        setNudgeFile(null);
                        isSubmit = false;
                        setRefreshFlag(!refreshFlag);
                    } else {
                        setSubmitError(true);
                        setSubmitErrorVarient(AppConstants.alertVarient[1]);
                        setSubmitErrorMessage(ErrorMessages.expertRevertToNudgeError);
                        setTimeout(() => setSubmitError(false), AppConstants.messageDisappearTime);
                    }
                } else {
                    setSubmitError(true);
                    setSubmitErrorVarient(AppConstants.alertVarient[1]);
                    setSubmitErrorMessage(ErrorMessages.expertRevertToNudgeError);
                    setTimeout(() => setSubmitError(false), AppConstants.messageDisappearTime);
                }
            });
        }
    }

    return (
        <>
            <div className="mb5">
                <div className={commonStyles.mainHeadingContainer}>
                    <h1 className={commonStyles.mainHeading}>Nudge Details</h1>
                </div>
                <Row>
                    <div className={commonStyles.bottomContainer}>
                        <div className={commonStyles.detailsGroup}>
                            <div className={commonStyles.detailsIconSection}>
                                <FaPaperPlane className={commonStyles.detailsIcon} />
                            </div>
                            <div className={commonStyles.detailsSubsection}>
                                <p className={commonStyles.detailsSubHeading}>Skill</p>
                                <p className={commonStyles.detailsHeading}>{nudgeDetails.skillName}</p>
                            </div>
                        </div>
                        {nudgeDetails.raisedOnUtc !== null && <div className={commonStyles.detailsGroup}>
                            <div className={commonStyles.detailsIconSection}>
                                <FaCalendarAlt className={commonStyles.detailsIcon} />
                            </div>
                            <div className={commonStyles.detailsSubsection}>
                                <p className={commonStyles.detailsSubHeading}>Raised On</p>
                                <p className={commonStyles.detailsHeading}><Moment format={AppConstants.nudgeRaisedOnTimeFormat} tz={timeZone}>{nudgeDetails.raisedOnUtc.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${nudgeDetails.preferredTimezone})`}</p>
                            </div>
                        </div>}
                        {nudgeDetails.status === AppConstants.closedText && nudgeDetails.closedOnUtc !== null && <div className={commonStyles.detailsGroup}>
                            <div className={commonStyles.detailsIconSection}>
                                <FaCalendarCheck className={commonStyles.detailsIcon} />
                            </div>
                            <div className={commonStyles.detailsSubsection}>
                                <p className={commonStyles.detailsSubHeading}>Closed On</p>
                                <p className={commonStyles.detailsHeading}><Moment format={AppConstants.nudgeRaisedOnTimeFormat} tz={timeZone}>{nudgeDetails.closedOnUtc.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${nudgeDetails.preferredTimezone})`}</p>
                            </div>
                        </div>}
                        <div className={commonStyles.detailsGroup}>
                            <div className={commonStyles.detailsIconSection}>
                                <FaUserTie className={commonStyles.detailsIcon} />
                            </div>
                            <div className={commonStyles.detailsSubsection}>
                                <p className={commonStyles.detailsSubHeading}>Raised By</p>
                                <p className={commonStyles.detailsHeading}>{nudgeDetails.studentName}</p>
                            </div>
                        </div>
                    </div>

                    <Col className="mt-4" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <p className={commonStyles.detailHeading}>Question</p>
                        <p className={commonStyles.detailSubHeading}>{nudgeDetails.summary}</p>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className={commonStyles.downloadButtonContainer}>
                            <p className={commonStyles.detailHeading}>Description</p>
                            {nudgeDetails !== AppConstants.nullText && nudgeDetails.filePath !== AppConstants.nullText && <Button href={nudgeDetails.filePath} className={commonStyles.downloadButton}>View Work</Button>}
                        </div>
                        <div className={`${commonStyles.descriptionText} ql-editor`}>{parse(nudgeDetails.description)}</div>
                    </Col>
                    {nudgeDetails.status === AppConstants.activeText && <form id="revertToNudgeForm" onSubmit={(e) => { submitNudgeResponse(e) }}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="studentRaiseNudgeForm mt-2 p-0">
                                <Form.Label className="descriptionLabel">Response</Form.Label>
                                <ReactQuill
                                    className="StudentRaiseNudgeQuillInputBox"
                                    placeholder="Type Your Response"
                                    value={responseText}
                                    ref={reactQuillRef}
                                    formats={AppConstants.richTextEditorFormats} modules={AppConstants.richTextEditorModules}
                                    onChange={handleTextChange} />
                            </div>
                            {responseError && <p className={commonStyles.formErrorMessage}><AiOutlineExclamationCircle /> {responseErrorMessage}</p>}
                        </Col>
                        <Col className="studentRaiseNudgeFormInputBox mt-3" xs={12} sm={12} md={12} lg={10} xl={10}>
                            <Form.Group className={formClass.fileUpload}>
                                <FileUploadInput id="nudgeFile" name="nudgeFile" label="Upload File" handleFileChange={event => handleUploadFileChange(event)} inputFile={nudgeFile} />
                                {/* <Form.Label className={formClass.formLabel}>Upload File</Form.Label>
                                <Form.Control onChange={handleUploadFileChange} className={formClass.uploadFileInput} type="file" id="nudgeFile"></Form.Control> */}
                                {nudgeFile && <p onClick={removeFile} className={`removeFileText removeFileOption`}>Remove file</p>}
                                {nudgeFileError && <p className="formErrorMessage"><AiOutlineExclamationCircle /> {nudgeFileError}</p>}
                            </Form.Group>
                        </Col>
                        <div className={commonStyles.submitButtonContainer}>
                            <Button type="submit" disabled={submitSpinner} className={commonStyles.submitButton}>Submit {submitSpinner && <BiLoaderAlt className="spinner" />}</Button>
                            {submitError && <ErrorSuccessAlertMessage message={submitErrorMessage} varient={submitErrorVarient} />}
                        </div>
                    </form>}
                </Row >
                {
                    expertPreviousResponsesArray.length !== 0 && <div>
                        <div className={commonStyles.previousResponsesContainer}>
                            <div className={commonStyles.expertNameContainer}>
                                <div className={commonStyles.profilePicMainCnt}>
                                    <p className={`${commonStyles.mainHeading}`}>Previous Responses</p>
                                </div>
                                {rating !== null && <div>
                                    {AppConstants.rateNudge5starArray.map((value) => (
                                        <span
                                            key={value}
                                            className={commonStyles.ratingStars}>
                                            {value <= rating ? <AiFillStar /> : <AiOutlineStar />}
                                        </span>
                                    ))}
                                </div>}
                            </div>
                            {expertPreviousResponsesArray.length !== 0 && <div>
                                {expertPreviousResponsesArray.map((response, index) => (
                                    <div key={index}>
                                        <div className={commonStyles.responseCardContainer}>
                                            <div className={`${commonStyles.responseCard} ql-editor`}>{parse(response.response)}
                                                {response.nudgeResponseFilePath !== null && <Button href={response.nudgeResponseFilePath} className={commonStyles.viewAttachmentButton}>{AppConstants.viewExpertNudgeAttachment}</Button>}
                                            </div>
                                            <p className={commonStyles.responseDateAndTime}><Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{response.respondedOn.replace(' ', 'T') + 'Z'}</Moment> | <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{response.respondedOn.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${response.preferredTimezone})`}</p>
                                        </div>
                                    </div>))}
                            </div>}
                        </div>
                    </div>
                }
            </div >
        </>
    )
}