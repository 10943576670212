import { Button, Col, Modal, Row } from "react-bootstrap"
import { AppConstants } from "../../constants/AppConstants"
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useRef, useState } from "react";
import modalClass from "../cards/CompetitionsCards.module.css";
import classes from "./NudgeAsigneeDetailsModal.module.css";
import { StringUtils } from "../../utils/StringUtils";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import { BiLoaderAlt } from "react-icons/bi";
import { FileOperationsUtil } from "../../utils/FileOperationsUtil";

export const NudgeAsigneeDetailsModal = (props) => {
    const [asigneeDetails, setAsigneeDetails] = useState([]);
    const [showSpinner, setShowSpinner] = useState(AppConstants.trueText);
    const gridRef = useRef(null);

    useEffect(() => {
        const fetchAsigneeDetails = async () => {
            const url = StringUtils.createURLWithParams(AppConstants.getAssignedCreatorsForNudgeIdAPI, { nudgeUniqueId: props.selectedNudgeId });
            const response = await AuthorizedDataService.getRequest(url, AppConstants.emptyString, AppConstants.emptyString);

            if (response) {
                setAsigneeDetails(response);
                setShowSpinner(AppConstants.falseText);
            } else {
                setShowSpinner(AppConstants.falseText);
            }
        }

        fetchAsigneeDetails();
    }, []);

    const onBtnExport = useCallback(() => {
        const selectedColumns = ['userQrenciaId', 'creatorName', 'userRole', 'mobileNumber'];

        const csvData = gridRef.current?.api.getDataAsCsv({
            columnKeys: selectedColumns,
            columnSeparator: AppConstants.agGridColumnSeparator,
            suppressQuotes: AppConstants.trueText
        });

        FileOperationsUtil.downloadCsvDataAsExcel(csvData, "Nudge-Assigned-Creators.xlsx");
    }, []);

    const columnDefs = [
        {
            headerName: "ID",
            field: "userQrenciaId",
            tooltipValueGetter: ({ data }) => `${data.userQrenciaId}`,
            valueGetter: (params) => {
                return params.data.userQrenciaId;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: "Name",
            field: "creatorName",
            tooltipValueGetter: ({ data }) => `${data.creatorName}`,
            valueGetter: (params) => {
                return `${params.data.creatorName}`;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: "Type",
            field: "userRole",
            tooltipValueGetter: ({ data }) => `${data.userRole}`,
            valueGetter: (params) => {
                return `${params.data.userRole}`;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: "Mobile Number",
            field: "mobileNumber",
            tooltipValueGetter: ({ data }) => `${data.mobileNumber}`,
            valueGetter: (params) => {
                return `${params.data.mobileNumber}`;
            },
            filter: 'agTextColumnFilter'
        }
    ];

    return (
        <Modal size="xl" show={AppConstants.trueText} onHide={props.closeNudgeAsigneeModal} backdrop="static" keyboard={false} centered>
            {showSpinner ? <p className={classes.loaderIcon}><BiLoaderAlt className="spinner" /></p> : <Modal.Body className={modalClass.modalBody}>
                <Row>
                    <h3 className={classes.assingedHeading}>Assigned Creators</h3>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className="ag-theme-alpine">
                            <AgGridReact
                                className="expertObrDetailsGrid"
                                columnDefs={columnDefs}
                                pagination={true}
                                ref={gridRef}
                                paginationPageSize={AppConstants.adminDashboardPaginationSize}
                                rowData={asigneeDetails}
                                enableBrowserTooltips={true}
                                domLayout='autoHeight'
                                suppressRowHoverHighlight={true}
                                defaultColDef={{
                                    filter: true,
                                    sortable: true,
                                    flex: 1,
                                    minWidth: 100,
                                    resizable: true,
                                    suppressMenu: true,
                                    suppressHorizontalScroll: true,
                                    floatingFilterComponentParams: { suppressFilterButton: true },
                                    floatingFilter: true,
                                }}
                                enableFilter={true}
                                enableSorting={true}
                                enableSearching={true}
                                headerClass={classes.obrGridHeader}
                                rowClass={classes.obrGridTable}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <div className={classes.buttonRow}>
                        {asigneeDetails.length > 0 && <Button className={modalClass.modalCloseButton} onClick={onBtnExport}>Download</Button>}
                        <Button className={modalClass.modalCloseButton} onClick={props.closeNudgeAsigneeModal}>Close</Button>
                    </div>
                </Row>
            </Modal.Body>}
        </Modal>
    )
}